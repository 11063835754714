import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';

import {
  DeleteItemGqlQuery,
  DeleteItemCacheKeys
} from '../../../../../../types';

import { deleteItem } from '../baseActions/deleteItem';

import {
  ErrorType,
  parseRequestError
} from '../../../../../../utils/parseRequestError';

interface DeleteQueryOptions<DeleteQueryInput> {
  action: string;
  query: DeleteItemGqlQuery;
  /**
   * @deprecated You should use cacheKeys instead
   */
  pluralScope?: string;
  cacheKeys?: DeleteItemCacheKeys;
  onOptimisticDelete?: (input: DeleteQueryInput) => null | (() => void);
}

interface DeleteQueryContext {
  rollback?: () => void;
}

function useDeleteQuery<
  DeleteQueryInput,
  DeleteQueryResponse,
  DeleteQueryError extends ErrorType
>({
  action,
  pluralScope,
  query,
  cacheKeys,
  onOptimisticDelete
}: DeleteQueryOptions<DeleteQueryInput>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    DeleteQueryResponse,
    DeleteQueryError,
    DeleteQueryInput,
    DeleteQueryContext
  >(
    (queryInput) =>
      deleteItem<DeleteQueryInput, DeleteQueryResponse>({
        action,
        query,
        queryInput
      }),
    {
      onMutate: (input) => {
        const rollback = onOptimisticDelete?.(input) || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: async () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
        if (pluralScope) {
          await queryClient.invalidateQueries(pluralScope);
        }
      }
    }
  );

  return {
    deleteQueryData: data,
    deleteQueryError: error,
    deleteQueryLoading: isLoading,
    deleteQueryErrorMessage: parseRequestError(error),
    deleteQuery: mutateAsync,
    deleteQueryReset: reset
  };
}

export default useDeleteQuery;
