import {
  MessageCommandsDataType,
  MessageCommandsEnum,
  MessageCommandTypesEnum,
  MessageMessageTypes,
  MessagesSettingsType
} from './messagesTypes';

import { messagesKeys } from '../../locales/keys';
import {
  chatGptCasualTranslateCommandSystemContent,
  chatGptCheckGrammarCommandSystemContent,
  chatGptFriendlyTranslateCommandSystemContent,
  chatGptTodoListFormPdfOrImageCommandSystemContent,
  chatGptTranslateCommandSystemContent
} from '../chatGptCompletions/chatGptCompletionsConstants';

export const INITIAL_MESSAGES_FILTERS = {};
export const INITIAL_MESSAGES_PAGE = 1;
export const INITIAL_MESSAGES_SORT = ['CREATED_AT_DESC'];
export const INITIAL_MESSAGES_LIMIT = 50;

export const INITIAL_MESSAGES_SETTING: MessagesSettingsType = {
  autoPinMessageWithTodoItem: false,
  displayTime: 15,
  withWhiteboardMessages: true
};

export const billMessageTypes = [
  MessageMessageTypes.BILL_BUTTON,
  MessageMessageTypes.TWOCHECKOUT_BUTTON,
  MessageMessageTypes.EASYPAY_BUTTON,
  MessageMessageTypes.STRIPE_BUTTON,
  MessageMessageTypes.FONDY_BUTTON,
  MessageMessageTypes.EWAY_BUTTON,
  MessageMessageTypes.INTERKASSA_BUTTON,
  MessageMessageTypes.LIQPAY_BUTTON,
  MessageMessageTypes.PAYONEER_BUTTON,
  MessageMessageTypes.RAIFFEISEN_BANK_BUTTON,
  MessageMessageTypes.RIETUMU_BUTTON,
  MessageMessageTypes.PAYMENT_BUTTON,
  MessageMessageTypes.PAID_MESSAGE
];

export const messageCommandsData: MessageCommandsDataType = {
  [MessageCommandsEnum.CASUAL_TRANSLATE]: {
    i18nTitle: messagesKeys.casualToneOfVoiceTranslation,
    i18nDescription: messagesKeys.translateTheTextIntoEnglishInACasualTone,
    systemContent: chatGptCasualTranslateCommandSystemContent,
    type: MessageCommandTypesEnum.CHAT_GPT
  },
  [MessageCommandsEnum.CHAT_GPT]: {
    i18nTitle: messagesKeys.chatGpt,
    i18nDescription: messagesKeys.chatGptDescription,
    type: MessageCommandTypesEnum.CHAT_GPT
  },
  [MessageCommandsEnum.CHECK_GRAMMAR]: {
    i18nTitle: messagesKeys.checkGrammar,
    i18nDescription: messagesKeys.correctGrammarWithChatGpt,
    systemContent: chatGptCheckGrammarCommandSystemContent,
    type: MessageCommandTypesEnum.CHAT_GPT
  },
  [MessageCommandsEnum.FRIENDLY_TRANSLATE]: {
    i18nTitle: messagesKeys.friendlyToneOfVoiceTranslation,
    i18nDescription: messagesKeys.translateTheTextIntoEnglishInAFriendlyTone,
    systemContent: chatGptFriendlyTranslateCommandSystemContent,
    type: MessageCommandTypesEnum.CHAT_GPT
  },
  [MessageCommandsEnum.THANKS_A_LOT]: {
    i18nTitle: messagesKeys.thanksALot,
    i18nDescription: messagesKeys.createAMessageThanksALot,
    i18nUserContent: messagesKeys.thanksALot,
    type: MessageCommandTypesEnum.INSERT_TEXT
  },
  [MessageCommandsEnum.TRANSLATE]: {
    i18nTitle: messagesKeys.translateIntoEnglish,
    i18nDescription:
      messagesKeys.translateTheTextAndMakeItAppropriateForSendingToTheClient,
    systemContent: chatGptTranslateCommandSystemContent,
    type: MessageCommandTypesEnum.CHAT_GPT
  },
  [MessageCommandsEnum.TODO_LIST_FROM_PDF]: {
    i18nTitle: messagesKeys.todoListFromPdfOrImage,
    i18nDescription: messagesKeys.createATodoListFromAPdf,
    systemContent: chatGptTodoListFormPdfOrImageCommandSystemContent,
    type: MessageCommandTypesEnum.CHAT_GPT
  }
};

export const enum MessagesPermissions {
  READ_MESSAGE_SEND_IMAGES_TO_WHITEBOARD_BUTTON = 'read_message_send_images_to_whiteboard_button',
  READ_MESSAGES_COMPACT_INDEX_PAGE = 'read_messages_compact_index_page',
  READ_MESSAGE_DOWNLOAD_ATTACHMENTS_BUTTON = 'read_message_download_attachments_button',
  READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON = 'read_message_download_file_attachments_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCTS_BUTTON = 'read_message_download_selected_products_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCT_BUTTON = 'read_message_download_selected_product_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLES_BUTTON = 'read_message_download_selected_lifestyles_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_MATERIALS_BUTTON = 'read_message_download_selected_materials_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLE_BUTTON = 'read_message_download_selected_lifestyle_button',
  READ_MESSAGE_ATTACH_TODO_LIST_BUTTON = 'read_message_attach_todo_list_button',
  READ_MESSAGE_CREATE_TODO_LIST_BUTTON = 'read_message_create_todo_list_button',
  CHANGE_MESSAGE_VISIBLE_FOR_CLIENTS_IN_CREATE_TODO_LIST_MODAL = 'change_message_visible_for_client_in_create_todo_list_modal',
  READ_MESSAGE_EDIT_TODO_LIST_BUTTON = 'read_message_edit_todo_list_button',
  READ_MESSAGE_CHECK_ALL_BUTTON = 'read_message_check_all_button',
  READ_MESSAGE_UNCHECK_ALL_BUTTON = 'read_message_uncheck_all_button',
  READ_MESSAGE_FAVORITE_BUTTON = 'read_message_favorite_button',
  READ_MESSAGE_FLAGGED_BUTTON = 'read_message_flagged_button',
  READ_MESSAGE_FORWARD_BUTTON = 'read_message_forward_button',
  READ_MESSAGE_REPLY_BUTTON = 'read_message_reply_button',
  READ_MESSAGE_NEW_FORWARD_BUTTON = 'read_message_new_forward_button',
  READ_MESSAGE_RED_BUTTON = 'read_message_red_button',
  READ_MESSAGE_LIKES_BUTTON = 'read_message_likes_button',
  READ_MESSAGE_PINNED_BUTTON = 'read_message_pinned_button',
  READ_MESSAGE_SELECT_BUTTON = 'read_message_select_button',
  READ_MESSAGE_PROPAGATE_BUTTON = 'read_message_propagate_button',
  READ_MESSAGE_OPEN_IN_WHITEBOARD_LINK = 'read_message_open_in_whiteboard_link',
  READ_MESSAGE_OPEN_IN_WHITEBOARD_ICON_LINK = 'read_message_open_in_whiteboard_icon_link',
  READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON = 'read_message_visible_for_clients_button',
  READ_MESSAGE_VERSION_VISIBLE_FOR_CLIENTS_BUTTON = 'read_message_version_visible_for_clients_button',
  READ_MESSAGE_CHECK_BILL_BUTTON = 'read_message_check_bill_button',
  READ_MESSAGE_CANCEL_BILL_BUTTON = 'read_message_cancel_bill_button',
  READ_MESSAGE_ADD_TO_DESCRIPTION_BUTTON = 'read_message_add_to_description_button',
  READ_MESSAGE_ADD_TO_RECORDS_BUTTON = 'read_message_add_to_records_button',
  READ_MESSAGE_TOGGLE_AI_NOT_RELEVANT_BUTTON = 'read_message_toggle_ai_not_relevant_button',
  READ_MESSAGE_BILL_MENU_DROPDOWN = 'read_message_bill_menu_dropdown',
  READ_MESSAGE_LINK_BLANK = 'read_message_link_blank',
  READ_MESSAGE_ATTACH_MATERIALS_BUTTON = 'read_message_attach_materials_button',
  READ_MESSAGE_COPY_ID_BUTTON = 'read_message_copy_id_button',
  READ_MESSAGE_COPY_UUID_BUTTON = 'read_message_copy_uuid_button',
  READ_MESSAGE_ID = 'read_message_id',
  READ_MESSAGE_BILL = 'read_message_bill',
  READ_MESSAGE_BILLS = 'read_message_bills',
  READ_MESSAGE_RED = 'read_message_red',
  READ_MESSAGE_WHITEBOARD_LOG = 'read_message_whiteboard_log',
  READ_MESSAGE_INVOICE = 'read_message_invoice',
  READ_MESSAGE_PAID_INVOICE = 'read_message_paid_invoice',
  READ_MESSAGE_REFUND_INVOICE = 'read_message_refund_invoice',
  READ_MESSAGE_CHARGEBACK_INVOICE = 'read_message_chargeback_invoice',
  READ_MESSAGE_AI_SEARCH = 'read_message_ai_search',
  READ_MESSAGE_AI_SEARCH_DETECTED_ITEM_TITLE = 'read_message_ai_search_detected_item_title',
  READ_MESSAGE_CREATE_FORM_ATTACHED_PREVIEW = 'read_message_create_form_attached_preview',
  READ_MESSAGE_CREATE_FORM_REPLY_MESSAGE_PREVIEW = 'read_message_create_form_reply_message_preview',
  READ_MESSAGE_CREATE_FORM_AI_SEARCH_MODAL_BUTTON = 'read_message_create_form_ai_search_modal_button',
  READ_MESSAGE_CREATE_FORM_AI_DETECT_SEARCH_MODAL_BUTTON = 'read_message_create_form_ai_detect_search_modal_button',
  READ_MESSAGE_CREATE_FORM_TOGGLE_FORMATTING_BUTTON = 'read_message_create_form_toggle_formatting_button',
  CHANGE_MESSAGE_CREATE_FORM_AI_DETECT_SEARCH_MODAL_BUTTON_CATEGORY = 'change_message_create_form_ai_detect_search_modal_button_category',
  CHANGE_MESSAGE_AI_DETECT_SEARCH_CATEGORY = 'change_message_ai_detect_search_category',
  READ_MESSAGE_INVOICE_REFUND_BUTTON = 'read_message_invoice_refund_button',
  READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON = 'read_message_invoice_chargeback_button',
  READ_MESSAGE_INVOICE_VIEW_INVOICE_BUTTON = 'read_message_invoice_view_invoice_button',
  READ_MESSAGE_INVOICE_CANCEL_BUTTON = 'read_message_invoice_cancel_button',
  READ_MESSAGE_INVOICE_APPROVE_BUTTON = 'read_message_invoice_approve_button',
  READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON = 'read_message_invoice_confirm_payment_button',
  READ_MESSAGE_INVOICE_COPY_LINK_BUTTON = 'read_message_invoice_copy_link_button',
  READ_MESSAGE_INVOICE_MAIL_BUTTON = 'read_message_invoice_mail_button',
  READ_MESSAGE_INVOICE_VOID_BUTTON = 'read_message_invoice_void_button',
  READ_MESSAGE_INVOICE_PAY_INTERNAL_BUTTON = 'read_message_invoice_pay_internal_button',
  READ_MESSAGE_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON = 'read_message_invoice_cancel_smart_contracts_button',
  READ_MESSAGE_INVOICE_DOWNLOAD_BUTTON = 'read_message_invoice_download_button',
  READ_MESSAGE_INVOICE_VISIBLE_FOR_CLIENTS_BUTTON = 'read_message_invoice_visible_for_clients_button',
  READ_MESSAGE_INVOICE_TOGGLE_BUTTON = 'read_message_invoice_toggle_button',
  READ_MESSAGE_INVOICE_PAY_BUTTON = 'read_message_invoice_pay_button',
  READ_MESSAGE_INVOICE_AV_BILLING_INFO = 'read_message_invoice_av_billing_info',
  READ_MESSAGE_INVOICE_BILLING_INFO = 'read_message_invoice_billing_info',
  READ_MESSAGE_INVOICE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON = 'read_message_invoice_update_invoice_billing_info_modal_button',
  READ_MESSAGE_INVOICE_TEAM_UPSALERS = 'read_message_invoice_team_upsalers',
  READ_MESSAGE_INVOICE_ITEMS = 'read_message_invoice_items',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_FIELD = 'read_message_invoice_items_group_by_field',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_PROJECTS_OPTION = 'read_message_invoice_items_group_by_projects_option',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_TASKS_OPTION = 'read_message_invoice_items_group_by_tasks_option',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEMS_OPTION = 'read_message_invoice_items_group_by_items_option',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEM_CATEGORIES_OPTION = 'read_message_invoice_items_group_by_item_categories_option',
  READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEM_TAGS_OPTION = 'read_message_invoice_items_group_by_item_tags_option',
  READ_MESSAGE_INVOICE_ITEMS_MY_ITEMS_ONLY_FIELD = 'read_message_invoice_items_my_items_only_field',
  READ_MESSAGE_INVOICE_NOTES = 'read_message_invoice_notes',
  READ_MESSAGE_INVOICE_TERMS = 'read_message_invoice_terms',
  READ_MESSAGE_INVOICE_PAYMENT_METHOD = 'read_message_invoice_payment_method',
  READ_MESSAGE_PAID_BILL = 'read_message_paid_bill',
  READ_MESSAGE_PAYMENT_PROCESSING = 'read_message_payment_processing',
  READ_MESSAGE_PAYMENT_PROCESSING_INVOICE = 'read_message_payment_processin_invoice',
  READ_MESSAGE_VERSION = 'read_message_version',
  CHANGE_MESSAGE_VERSION = 'change_message_version',
  READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON = 'read_message_visible_for_client_icon',
  READ_MESSAGE_FORWARD_MESSAGE = 'read_message_forward_message',
  READ_MESSAGE_REPLY_MESSAGE = 'read_message_reply_message',
  READ_MESSAGE_UNHAPPY_MESSAGE = 'read_message_unhappy_message',
  READ_MESSAGE_RESULT_EDIT_BUTTON = 'read_message_result_edit_button',
  READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_result_submit_source_files_button',
  READ_MESSAGE_RESULT_DOWNLOAD_BUTTON = 'read_message_result_download_button',
  READ_MESSAGE_RESULT_SEND_TO_CLIENT_BUTTON = 'read_message_result_send_to_client_button',
  READ_MESSAGE_RESULT_VISIBLE_FOR_CLIENT_BUTTON = 'read_message_result_visible_for_client_button',
  READ_MESSAGE_RESULT_IS_RESULT_BUTTON = 'read_message_result_is_result_button',
  READ_MESSAGE_RESULT_UPLOAD_TO_LIBRARY_BUTTON = 'read_message_result_upload_to_library_button',
  READ_MESSAGE_RESULT_BUTTONS = 'read_message_result_buttons',
  READ_MESSAGE_RESULT_FIN_BUTTONS = 'read_message_result_fin_buttons',
  READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON = 'read_message_file_attachment_edit_button',
  READ_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_file_attachment_submit_source_files_button',
  READ_SELF_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON = 'read_self_message_file_attachment_submit_source_files_button',
  READ_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_file_attachments_submit_source_files_button',
  READ_SELF_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON = 'read_self_message_file_attachments_submit_source_files_button',
  READ_MESSAGE_FILE_ATTACHMENTS_UPLOAD_TO_LIBRARY_BUTTON = 'read_message_file_attachments_upload_to_library_button',
  READ_MESSAGE_SEND_EMAIL_NOTIFICATION_BUTTON = 'read_message_send_email_notification_button',
  READ_MESSAGES_INDEX_PAGE = 'read_messages_index_page',
  READ_MESSAGES_SEARCH_FILTERS_POPOVER = 'read_messages_search_filters_popover',
  READ_MESSAGES_SAVED_FILTERS_MENU = 'read_messages_saved_filters_menu',
  READ_MESSAGES_TAG_IDS_FILTER = 'read_messages_tag_ids_filter',
  READ_MESSAGES_CHECKBOX_FILTERS = 'read_messages_checkbox_filters',
  READ_MESSAGES_UNREAD_FILTER = 'read_messages_unread_filter',
  READ_MESSAGES_STARRED_FILTER = 'read_messages_starred_filter',
  READ_MESSAGES_FLAGGED_FILTER = 'read_messages_flagged_filter',
  READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER = 'read_messages_visible_for_client_filter',
  READ_MESSAGES_WITH_ATTACHMENT_FILTER = 'read_messages_with_attachment_filter',
  READ_MESSAGES_TASK_MEMBER_FILTER = 'read_messages_task_member_filter',
  READ_MESSAGES_PROJECT_MEMBER_FILTER = 'read_messages_project_member_filter',
  READ_MESSAGES_CREATED_AT_FILTER = 'read_messages_created_at_filter',
  READ_MESSAGE_ID_FILTER = 'read_message_id_filter',
  READ_MESSAGES_USER_UUIDS_FILTER = 'read_messages_user_uuids_filter',
  READ_MESSAGES_MY_MESSAGES_FILTER_MENU = 'read_messages_my_messages_filter_menu',
  READ_MESSAGES_UNREAD_FILTER_MENU = 'read_messages_unread_filter_menu',
  READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER_MENU = 'read_messages_visible_for_client_filter_menu',
  READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU = 'read_messages_only_clients_filter_menu',
  READ_MESSAGE_CLIENT_ICON = 'read_message_client_icon',
  READ_MESSAGE_RED_ICON = 'read_message_red_icon',
  READ_MESSAGE_VIEW_3D_MODEL_FILE_ATTACHMENT_BUTTON = 'read_message_view_3d_model_file_attachment_button',
  READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON = 'read_message_download_file_attachment_button',
  READ_MESSAGE_DELETE_FILE_ATTACHMENT_BUTTON = 'read_message_delete_file_attachment_button',
  READ_MESSAGE_REMOVE_FILE_ATTACHMENT_BUTTON = 'read_message_remove_file_attachment_button',
  READ_MESSAGE_DELETE_SELECTED_MATERIAL_BUTTON = 'read_message_delete_selected_material_button',
  READ_MESSAGE_DELETE_SELECTED_PRODUCT_BUTTON = 'read_message_delete_selected_product_button',
  READ_MESSAGE_DELETE_SELECTED_LIFESTYLE_BUTTON = 'read_message_delete_selected_lifestyle_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_MATERIAL_BUTTON = 'read_message_download_selected_material_button',
  READ_MESSAGES_CREATE_MESSAGE_IN_TASK_BUTTON = 'read_messages_create_message_in_task_button',
  READ_MESSAGES_CREATE_MESSAGE_IN_PROJECT_BUTTON = 'read_messages_create_message_in_project_button',
  READ_MESSAGES_UNREAD_FILTER_MENU_COUNT = 'read_messages_unread_filter_menu_count',
  READ_MESSAGES_SAVED_FILTER_COUNT = 'read_messages_saved_filter_count',
  READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU_COUNT = 'read_messages_only_clients_filter_menu_count',
  READ_MESSAGE_USER_MENTIONS = 'read_message_user_mentions',
  READ_MESSAGES_HEADER = 'read_messages_header',
  READ_MESSAGES_HEADER_VISIBLE_FOR_CLIENTS_FILTER = 'read_messages_header_visible_for_clients_filter',
  READ_MESSAGES_HEADER_AUTHORS_FILTER = 'read_messages_header_authors_filter',
  READ_MESSAGES_HEADER_MESSAGES_FILTER = 'read_messages_header_messages_filter',
  READ_MESSAGES_HEADER_FILTER_BY_MESSAGE_TYPE_BUTTON = 'read_messages_header_filter_by_message_type_button',
  READ_MESSAGES_HEADER_FILTER_BY_ATTACHMENT_TYPE_BUTTON = 'read_messages_header_filter_by_attachment_type_button',
  READ_MESSAGES_HEADER_PINNED_BUTTON = 'read_messages_header_pinned_button',
  READ_MESSAGES_HEADER_STARRED_BUTTON = 'read_messages_header_starred_button',
  READ_MESSAGES_HEADER_FLAGGED_BUTTON = 'read_messages_header_flagged_button',
  READ_MESSAGES_HEADER_JUMP_TO_BUTTON = 'read_messages_header_jump_to_button',
  READ_MESSAGES_HEADER_SETTING_BUTTON = 'read_messages_header_setting_button',
  READ_MESSAGES_BATCH_CREATE_TODO_LIST_BUTTON = 'read_messages_batch_create_todo_list_button',
  READ_MESSAGES_BATCH_FORWARD_MESSAGES_BUTTON = 'read_messages_batch_forward_messages_button',
  READ_MESSAGES_BATCH_PINNED_BUTTON = 'read_messages_batch_pinned_button',
  READ_MESSAGES_BATCH_FLAGGED_BUTTON = 'read_messages_batch_flagged_button',
  READ_MESSAGES_BATCH_FAVORITE_BUTTON = 'read_messages_batch_favorite_button',
  READ_MESSAGES_BATCH_VISIBLE_FOR_CLIENT_BUTTON = 'read_messages_batch_visible_for_client_button',
  READ_MESSAGES_HEADER_SAVED_FILTERS_BUTTON = 'read_messages_header_saved_filters_button',
  READ_MESSAGE_SEND_IMAGE_TO_WHITEBOARD_BUTTON = 'read_message_send_image_to_whiteboard_button',
  READ_MESSAGE_SEND_RESULT_TO_WHITEBOARD_BUTTON = 'read_message_send_result_to_whiteboard_button',
  READ_MESSAGE_SEND_RESULTS_TO_WHITEBOARD_BUTTON = 'read_message_send_results_to_whiteboard_button',
  READ_MESSAGE_RESULT_SORT_BUTTON = 'read_message_result_sort_button',
  READ_MESSAGE_THREAD_KEY_ID = 'read_message_thread_key_id',
  READ_MESSAGE_INVOICE_HEADER_CUSTOM_FIELDS = 'read_message_invoice_header_custom_fields',
  READ_MESSAGE_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'read_message_invoice_av_billing_info_custom_fields',
  READ_MESSAGE_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'read_message_invoice_billing_info_custom_fields',
  READ_MESSAGE_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'read_message_invoice_amount_due_custom_fields',
  READ_MESSAGE_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'read_message_invoice_terms_and_notes_custom_fields',
  READ_PINNED_MESSAGES_TODO_CHECKBOX = 'read_pinned_messages_todo_checkbox',
  READ_PINNED_MESSAGES_DOWNLOAD_ALL_BUTTON = 'read_pinned_messages_download_all_button',
  READ_MESSAGE_COMMANDS = 'read_message_commands',
  READ_MESSAGE_CHECK_GRAMMAR_COMMAND = 'read_message_check_grammar_command',
  READ_MESSAGE_TRANSLATE_COMMAND = 'read_message_translate_command',
  READ_MESSAGE_CHAT_GPT_COMMAND = 'read_message_chat_gpt_command',
  READ_MESSAGE_CASUAL_TRANSLATE_COMMAND = 'read_message_casual_translate_command',
  READ_MESSAGE_FRIENDLY_TRANSLATE_COMMAND = 'read_message_friendly_translate_command',
  READ_MESSAGE_THANKS_A_LOT_COMMAND = 'read_message_thanks_a_lot_command',
  READ_MESSAGE_TODO_LIST_FROM_PDF_COMMAND = 'read_message_todo_list_from_pdf_command'
}
