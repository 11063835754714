import React, { ForwardedRef, useCallback } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Translate } from '../../Translate';

type PureRefIconButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureRefIconButtonHelperDefaultProps {
  id?: string;
  blurOnMouseLeave?: boolean;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: PureRefIconButtonHelperOnClick;
  onMouseEnter?: () => void;
}

export interface PureRefIconButtonHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureRefIconButtonHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureRefIconButtonHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureRefIconButtonHelperProps = PureRefIconButtonHelperDefaultProps &
  (
    | PureRefIconButtonHelperIconProps
    | PureRefIconButtonHelperWithI18nProps
    | PureRefIconButtonHelperWithTextProps
  );

const PureRefIconButtonHelper = React.forwardRef<
  HTMLButtonElement,
  PureRefIconButtonHelperProps
>(
  (
    componentProps: PureRefIconButtonHelperProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      id,
      blurOnMouseLeave,
      className,
      disabled,
      icon = null,
      iconClassName,
      onClick,
      onMouseEnter,
      i18nTextClassName,
      i18nText,
      text
    } = componentProps;
    const handleClick = useCallback<PureRefIconButtonHelperOnClick>(
      (e) => {
        e.preventDefault();
        onClick?.(e);
      },
      [onClick]
    );

    const handleMouseLeave = useCallback(
      (event) => (event.target as HTMLButtonElement)?.blur(),
      []
    );

    return (
      <button
        id={id}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
        ref={ref}
        type="button"
      >
        {icon ? (
          <Icon
            className={iconClassName}
            icon={icon}
            id={id ? `${id}-icon` : undefined}
          />
        ) : null}
        {icon && i18nText ? ' ' : null}
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
      </button>
    );
  }
);

PureRefIconButtonHelper.displayName = 'PureRefIconButtonHelper';

export default PureRefIconButtonHelper;
