import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AccountsKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
}

export const accountingTransactionsKeys = keyPathMirror<
  AccountsKeyType,
  string
>(
  {
    plural: null,
    singular: null
  },
  'models.accountingTransactions.'
);
