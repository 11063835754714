import { useQuery } from 'react-query';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  FetchItemCacheKey,
  FetchItemGqlQuery,
  NanoID,
  UUID
} from '../../../../../../types';

import { fetchReadItem } from '../baseActions/fetchReadItem';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

import {
  ReadShowQueryOpts,
  ReadShowQueryErrorType
} from './useReadShowQuery.types';

interface ReadShowQueryOptions<ResponseType> {
  cacheKey: FetchItemCacheKey;
  itemKey: string;
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
  placeholderData?: ResponseType;
  options: ReadShowQueryOpts<ResponseType>;
}

function useReadShowQuery<ResponseType, ItemType>({
  cacheKey,
  itemKey,
  query,
  uuid,
  placeholderData,
  options = {}
}: ReadShowQueryOptions<ResponseType>) {
  const localForageCacheKey = `${cacheKey}-${uuid}`;

  const {
    data: localForagePlaceholderData,
    isFetched: placeholderDataFetched
  } = useQuery<ResponseType | null>(
    `${localForageCacheKey}-placeholder`,
    () => LocalForage.getItem<ResponseType>(localForageCacheKey),
    {
      enabled: options.enabledPlaceholder,
      onSuccess: (data) => options.onSuccess?.(data)
    }
  );

  const { data, isFetched, isLoading, isFetching, error, isPlaceholderData } =
    useQuery<ResponseType, ReadShowQueryErrorType>(
      [cacheKey, uuid],
      () =>
        fetchReadItem({
          query,
          uuid
        }),
      {
        cacheTime: options.cacheTime,
        staleTime: options.staleTime,
        enabled:
          options.enabled ||
          (options.enabledPlaceholder && placeholderDataFetched),
        placeholderData:
          placeholderData || localForagePlaceholderData || undefined,
        onSettled: options.onSettled,
        onSuccess: (data) => {
          options.onSuccess?.(data);
          return LocalForage.setItem<ResponseType>(localForageCacheKey, data);
        },
        onError: options.onError
      }
    );

  const item: ItemType | null = data?.[itemKey] || null;

  return {
    item,
    itemErrorData: error,
    itemError: parseRequestError(error),
    itemFetched: isFetched,
    itemFetching: isFetching,
    itemLoading: isLoading,
    itemIsPlaceholderData: isPlaceholderData
  };
}

export default useReadShowQuery;
