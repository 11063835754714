import { components, DropdownIndicatorProps } from 'react-select';

import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../Icon';

function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator {...props}>
      {props.isMulti && (
        <Icon className="h-5 w-5 text-gray-500" icon={IconsEnum.SELECTOR} />
      )}
    </components.DropdownIndicator>
  );
}

export default DropdownIndicator;
