import { FetchItemsPage } from '../../../../../../../../types';
import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { PAGINATE_ITEMS } from '../../indexRequestConstants';

function paginateItemsAction(nextPage: FetchItemsPage): IndexRequestAction {
  return {
    type: PAGINATE_ITEMS,
    nextPage
  };
}

export default paginateItemsAction;
