import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TaskNotificationsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
}

export const taskNotificationsKeys = keyPathMirror<
  TaskNotificationsKeyType,
  string
>(
  {
    list: null,
    plural: null,
    singular: null
  },
  'models.taskNotifications.'
);
