import {
  CreateFeedBackGqlQuery,
  CreateFeedBackGqlStatus,
  FeedBackBody,
  FeedBackEmail,
  FeedBackName,
  FeedBackSubject,
  FeedBackUUID
} from '../../feedBacksTypes';
import { FeedBackGqlError } from '../../feedBacksTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface FeedBackOptions {
  query: CreateFeedBackGqlQuery;
}

export interface FeedBackResponse<FeedBackRecordType> {
  forwardMessage: {
    status: CreateFeedBackGqlStatus;
    errors: FeedBackError;
    record: FeedBackRecordType;
    recordUuid: FeedBackUUID;
  };
}

export interface FeedBackInput {
  name: FeedBackName;
  email: FeedBackEmail;
  subject: FeedBackSubject;
  body: FeedBackBody;
}

export interface FeedBackError {
  fullMessages: FeedBackGqlError;
}

const action = 'createFeedBack';

function useCreateFeedBack<FeedBackRecordType>({ query }: FeedBackOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    FeedBackInput,
    FeedBackResponse<FeedBackRecordType>,
    FeedBackError,
    FeedBackRecordType
  >({ action, query });

  return {
    createFeedBackData: createQueryData,
    createFeedBackError: createQueryError,
    createFeedBackLoading: createQueryLoading,
    createFeedBackErrorMessage: createQueryErrorMessage,
    createFeedBack: createQuery,
    createFeedBackReset: createQueryReset
  };
}

export default useCreateFeedBack;
