import { Control, Controller, FieldPath } from 'react-hook-form';

import { I18nText } from '../../../types';
import {
  RadioButtonOptionType,
  RadioButtonValueType
} from '../../RadioButtonInput';

import { RadioButtonsFieldControl } from './components/RadioButtonsFieldControl';
import { RadioButtonsFieldControlCommonProps } from './components/RadioButtonsFieldControl/RadioButtonsFieldControl';

interface RadioButtonsFieldWithTextProps {
  label: string;
  i18nLabel?: never;
}

interface RadioButtonsFieldWithI18nTextProps {
  label?: never;
  i18nLabel: I18nText;
}

type RadioButtonsFieldProps<FormType, ValueType> = {
  control: Control<FormType>;
  name: FieldPath<FormType>;
} & RadioButtonsFieldControlCommonProps<ValueType> &
  (RadioButtonsFieldWithTextProps | RadioButtonsFieldWithI18nTextProps);

function RadioButtonsField<FormType, ValueType extends RadioButtonOptionType>({
  className,
  control,
  disabled,
  i18nLabel,
  label,
  labelClassName,
  name,
  options,
  optionClassName,
  radioButtonLabelClassName
}: RadioButtonsFieldProps<FormType, ValueType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <RadioButtonsFieldControl
          className={className}
          disabled={disabled}
          error={error?.message}
          i18nLabel={i18nLabel}
          label={label}
          labelClassName={labelClassName}
          name={name}
          onChange={onChange}
          options={options}
          optionClassName={optionClassName}
          radioButtonLabelClassName={radioButtonLabelClassName}
          value={value as RadioButtonValueType}
        />
      )}
    />
  );
}

export default RadioButtonsField;
