import { IconProps } from '../types';

function FolderMaterialSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1.2 1.5l-4.2 4.2-1.2-1.2 3-3h2.4zm-6.9 6.9c-.2-.1-.4-.4-.4-.7v-1.3l1.2-1.2 1.2 1.2-2 2zm.4-6.9H11L7.5 11V8.3c0-.4.4-.8.8-.8zm.9 7l4.3-4.3 1.2 1.2-3.1 3.1H9.2zm7.3-.8c0 .5-.4.8-.8.8H13l3.5-3.5v2.7zm-1.1-3l-1.2-1.2 1.9-1.9c.2.1.4.4.4.7v1.3l-1.1 1.1z" />
    </svg>
  );
}

export default FolderMaterialSolidIcon;
