import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  GeneralLedgerCreatedAt,
  GeneralLedgerID,
  GeneralLedgerUUID,
  GeneralLedgerNanoID,
  GeneralLedgerName,
  GeneralLedgerGeneralLedgerType,
  GeneralLedgerCashAccountBalance,
  GeneralLedgerDefaultShare,
  GeneralLedgerCompanyID,
  GeneralLedgerCompanyNanoID,
  GeneralLedgerCompanyName,
  GeneralLedgerCompanyImageFile,
  GeneralLedgerDefaultShareType,
  GeneralLedgerItemsAwaitedTotal
} from '../generalLedgersTypes';

export interface FetchGeneralLedgersQueryResponse {
  id: GeneralLedgerID;
  uuid: GeneralLedgerUUID;
  nanoId: GeneralLedgerNanoID;
  createdAt: GeneralLedgerCreatedAt;
  name: GeneralLedgerName;
  defaultShare: GeneralLedgerDefaultShare;
  defaultShareType: GeneralLedgerDefaultShareType;
  generalLedgerType: GeneralLedgerGeneralLedgerType;
  cashAccount: MayBe<{
    balance: GeneralLedgerCashAccountBalance;
  }>;
  itemsAwaitedTotal?: {
    convertedAmount?: GeneralLedgerItemsAwaitedTotal;
  };
  company: MayBe<{
    id: GeneralLedgerCompanyID;
    nanoId: GeneralLedgerCompanyNanoID;
    name: GeneralLedgerCompanyName;
    image: MayBe<{
      file: GeneralLedgerCompanyImageFile;
    }>;
  }>;
}

export const FETCH_GENERAL_LEDGERS_QUERY = gql`
  query GeneralLedgers(
    $convertTo: Currency
    $itemsAvaitedFilters: GeneralLedgersItemsFilters
    $filters: GeneralLedgersFilters
    $limit: Int
    $offset: Int
    $sort: [GeneralLedgersSortEnum!]
  ) {
    generalLedgers(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      appVersion
      nodes {
        id
        uuid
        nanoId
        createdAt
        name
        defaultShare
        defaultShareType
        generalLedgerType
        cashAccount {
          balance
        }
        itemsAwaitedTotal(
          convertTo: $convertTo
          filters: $itemsAvaitedFilters
        ) {
          convertedAmount
        }
        company {
          id
          nanoId
          name
          image {
            file
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
