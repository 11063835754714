import React, { Fragment, useCallback } from 'react';

import { I18nText, ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  AddFundsModalButtonCloseAction,
  AddFundsModalButtonSteps
} from './AddFundsModalButton.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { useModal } from '../../../../../helpers/modals/hooks/useModal';
import { useAddFundsModalButton } from './hooks/useAddFundsModalButton';

import { AddFundsModalButtonContent } from './components/AddFundsModalButtonContent';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';

import { invoicesKeys } from '../../../../../locales/keys';

interface AddFundsModalButtonProjectsProps {
  i18nText?: I18nText;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function AddFundsModalButton({
  i18nText,
  className,
  icon,
  iconClassName
}: AddFundsModalButtonProjectsProps) {
  const currentUser = useCurrentUser();

  const {
    addFundsData,
    addFundsStep,
    handleResetAddFundsData,
    handlePaymentMethodAndAmountStep,
    handleWireBillingInfoStep,
    handleStepBack
  } = useAddFundsModalButton();

  const { isOpen, showModal, hideModal } = useModal();

  const handleClose = useCallback<AddFundsModalButtonCloseAction>(() => {
    hideModal();
    if (addFundsStep === AddFundsModalButtonSteps.WIRE_INVOICE_INFO) {
      setTimeout(() => handleResetAddFundsData(), 200);
    }
  }, [addFundsStep, hideModal, handleResetAddFundsData]);

  if (!currentUser.currentTeam?.nanoId) {
    return null;
  }

  return (
    <Fragment>
      <PureIconButtonHelper
        i18nText={i18nText}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        onClick={showModal}
      />
      <SimpleModal
        hideModal={handleClose}
        i18nTitle={invoicesKeys.addFunds}
        modalSize={
          addFundsStep === AddFundsModalButtonSteps.WIRE_INVOICE_INFO
            ? '3xl'
            : 'md'
        }
        isOpen={isOpen}
        withoutButtons
        childrenClassName="flex flex-col overflow-hidden"
      >
        <AddFundsModalButtonContent
          companyNanoId={currentUser.currentTeam.nanoId}
          addFundsData={addFundsData}
          addFundsStep={addFundsStep}
          onPaymentMethodAndAmountStep={handlePaymentMethodAndAmountStep}
          onWireBillingInfoStep={handleWireBillingInfoStep}
          onStepBack={handleStepBack}
          onClose={handleClose}
        />
      </SimpleModal>
    </Fragment>
  );
}

export default AddFundsModalButton;
