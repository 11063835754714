import { gql } from 'graphql-request';

export const FETCH_TASKS_TOTAL_COUNT_QUERY = gql`
  query TasksTotalCountQuery($tasksFilters: TasksFilters) {
    tasks(filters: $tasksFilters) {
      paginationInfo {
        totalCount
      }
    }
  }
`;
