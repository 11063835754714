import { useMemo, useEffect, useCallback } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import pickBy from 'lodash/pickBy';

import { MultiSelectDataType } from '../../../../MultiSelect/types';
import {
  FetchAvBillingInfosSortTypes,
  FetchFinAvBillingInfosFilters
} from '../../../../../main/avBillingInfos/avBillingInfosTypes';

import { AvBillingInfoMultiSelectValueType } from './AvBillingInfosMultiSelectFieldControl.types';
import {
  MultiSelectChangeCallbackType,
  MultiSelectInputChangeCallbackType
} from '../../../../MultiSelect/types';

import {
  FETCH_AV_BILLING_INFOS_QUERY,
  FetchAvBillingInfosQueryResponse
} from '../../../../../main/avBillingInfos/queries/fetchAvBillingInfos.query';

import { useFinPaginatedAvBillingInfos } from '../../../../../main/avBillingInfos/hooks/useFinPaginatedAvBillingInfos';

import {
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueType
} from '../../../../MultiSelect';

import { AvBillingInfoCache } from '../../../../../main/avBillingInfos/AvBillingInfoCache';

const initialLimit = 1000;

interface AvBillingInfosMultiSelectFieldControlProps {
  paymentMethodFilter?: string[];
  country?: string;
  value?: AvBillingInfoMultiSelectValueType;
  onChange: (updatedValue) => void;
  onInputChange?: MultiSelectInputChangeCallbackType;
  isSearchable?: boolean;
  optionsLoading?: boolean;
  onMenuScrollToBottom?: () => void;
  isLoading?: boolean;
}

function AvBillingInfosMultiSelectFieldControl({
  paymentMethodFilter,
  country,
  classNamePrefix = null,
  closeMenuOnSelect = true,
  defaultValue = null,
  disabled = false,
  error,
  errorClassName,
  emptyValue = null,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  isLoading = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  name,
  optionsLoading = false,
  placeholder = null,
  showError,
  value,
  onInputChange,
  onBlur,
  onChange,
  onMenuScrollToBottom
}: MultiSelectProps & AvBillingInfosMultiSelectFieldControlProps) {
  const filters = useMemo<FetchFinAvBillingInfosFilters>(
    () =>
      pickBy<FetchFinAvBillingInfosFilters>({
        paymentMethod: { in: paymentMethodFilter },
        excludedCountries: { notEq: country },
        disabledAt: {
          isNull: true
        }
      }),
    [paymentMethodFilter, country]
  );

  const {
    avBillingInfos,
    avBillingInfosErrorMessage,
    avBillingInfosFetched,
    avBillingInfosFilters,
    filterAvBillingInfos
  } = useFinPaginatedAvBillingInfos<FetchAvBillingInfosQueryResponse>({
    cacheKey: AvBillingInfoCache.selectCacheKey(),
    initialLimit,
    initialSort: [FetchAvBillingInfosSortTypes.NAME_ASC],
    initialFilters: filters,
    query: FETCH_AV_BILLING_INFOS_QUERY,
    options: {
      withoutPrefetch: true
    }
  });

  useEffect(() => {
    if (!isEqual(avBillingInfosFilters, filters)) {
      filterAvBillingInfos(filters);
    }
  }, [filters, avBillingInfosFilters, filterAvBillingInfos]);

  const avBillingInfosOptions = useMemo<MultiSelectDataType[]>(
    () =>
      map(avBillingInfos, (item) => ({
        label: item.name,
        value: item.id?.toString() || ''
      })),
    [avBillingInfos]
  );

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue) => {
      onChange(
        isArray(updateValue) ? map(updateValue, 'value') : updateValue?.value
      );
    },
    [onChange]
  );

  const multiSelectValue = useMemo<MultiSelectValueType>(
    () => filter(avBillingInfosOptions, (option) => value === option.value),
    [avBillingInfosOptions, value]
  );

  return (
    <MultiSelect
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={closeMenuOnSelect}
      data={avBillingInfosOptions}
      defaultValue={defaultValue}
      disabled={disabled}
      emptyValue={emptyValue}
      error={error || avBillingInfosErrorMessage}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isClearable={isClearable}
      isSearchable={isSearchable}
      labelClassName={labelClassName}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      multi={false}
      onChange={handleChange}
      onInputChange={onInputChange}
      optionsLoading={optionsLoading}
      placeholder={placeholder}
      value={multiSelectValue}
      name={name}
      onMenuScrollToBottom={onMenuScrollToBottom}
      isLoading={isLoading || !avBillingInfosFetched}
      onBlur={onBlur}
      errorClassName={errorClassName}
      showError={showError}
    />
  );
}

export default AvBillingInfosMultiSelectFieldControl;
