import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { SmartContractNanoID } from '../smartContracts/smartContractsTypes';
import { UserGroupID, UserNanoID } from '../users/usersTypes';
import { WhiteboardStorageBackupID } from '../whiteboardStorageBackups/whiteboardStorageBackupsTypes';

import { ProjectNanoID } from '../projects/projectsTypes';
import { TaskNanoID, TaskStatuses } from './tasksTypes';

const basePath = '/tasks';

export class TaskPath {
  static index() {
    return basePath;
  }

  static compactIndex() {
    return `${basePath}/compact`;
  }

  static show(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}`;
  }

  static brief(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/brief`;
  }

  static burning() {
    return `${basePath}/filters/burning`;
  }

  static invitations() {
    return `${basePath}/invitations`;
  }

  static compactInvitations() {
    return `${basePath}/compact/invitations`;
  }

  static compactNotes() {
    return `${basePath}/compact/notes`;
  }

  static compactMyNotes() {
    return `${this.compactMyTasks()}/notes`;
  }

  static compactTimeline() {
    return `${basePath}/compact/timeline`;
  }

  static compactMyTimeline() {
    return `${this.compactMyTasks()}/timeline`;
  }

  static compactFinance() {
    return `${basePath}/compact/finance`;
  }

  static compactMyFinance() {
    return `${this.compactMyTasks()}/finance`;
  }

  static compactProjectFinance(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasks(projectNanoId)}/finance`;
  }

  static compactProjectTimeline(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasks(projectNanoId)}/timeline`;
  }

  static compactProjectNotes(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasks(projectNanoId)}/notes`;
  }

  static compactProjectWithoutSourceFiles(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasks(projectNanoId)}/without-source-files`;
  }

  static compactProjectResults(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasks(projectNanoId)}/results`;
  }

  static favorite() {
    return `${basePath}/filters/favorite`;
  }

  static myTasks() {
    return `${basePath}/filters/my-tasks`;
  }

  static compactMyTasks() {
    return `${basePath}/compact/filters/my-tasks`;
  }

  static compactProjectTasks(projectNanoId: ProjectNanoID) {
    return `${basePath}/compact/filters/project/${projectNanoId}`;
  }

  static compactMyResults() {
    return `${this.compactMyTasks()}/results`;
  }

  static compactResults() {
    return `${basePath}/compact/results`;
  }

  static compactWithoutSourceFiles() {
    // return `${basePath}/compact/filters/without-source-files`;
    return `${basePath}/compact/without-source-files`;
  }

  static compactMyWithoutSourceFiles() {
    return `${this.compactMyTasks()}/without-source-files`;
  }

  static indexUserGroupTasks() {
    return `${basePath}/compact/filters/user-group`;
  }

  static indexUserGroupFinance() {
    return `${this.indexUserGroupTasks()}/finance`;
  }

  static indexUserGroupNotes() {
    return `${this.indexUserGroupTasks()}/notes`;
  }

  static indexUserGroupTimeline() {
    return `${this.indexUserGroupTasks()}/timeline`;
  }

  static indexUserGroupResults() {
    return `${this.indexUserGroupTasks()}/results`;
  }

  static indexUserGroupWithoutSourceFiles() {
    return `${this.indexUserGroupTasks()}/without-source-files`;
  }

  static userGroupTasks(userGroupId: UserGroupID) {
    return `${basePath}/compact/filters/user-group/${userGroupId}`;
  }

  static userGroupFinance(userGroupId: UserGroupID) {
    return `${this.userGroupTasks(userGroupId)}/finance`;
  }

  static userGroupNotes(userGroupId: UserGroupID) {
    return `${this.userGroupTasks(userGroupId)}/notes`;
  }

  static userGroupTimeline(userGroupId: UserGroupID) {
    return `${this.userGroupTasks(userGroupId)}/timeline`;
  }

  static userGroupResults(userGroupId: UserGroupID) {
    return `${this.userGroupTasks(userGroupId)}/results`;
  }

  static userGroupWithoutSourceFiles(userGroupId: UserGroupID) {
    return `${this.userGroupTasks(userGroupId)}/without-source-files`;
  }

  static withoutSourceFiles() {
    return `${basePath}/filters/without-source-files`;
  }

  static currentTasks() {
    return `${basePath}/filters/current-tasks`;
  }

  static myResults() {
    return `${basePath}/filters/my-results`;
  }

  static itemsResults() {
    return `${basePath}/results`;
  }

  static itemsResultsFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/results/filters/${searchFilterNanoId}`;
  }

  static compactResultsFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/compact/filters/${searchFilterNanoId}/results`;
  }

  static userTasks(userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${userNanoId}`;
  }

  static userTasksByStatus(userNanoId: UserNanoID, status: TaskStatuses) {
    return `${basePath}/filters/user/${userNanoId}/${status}`;
  }

  static userResults(userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${userNanoId}/results`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static compactFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/compact/filters/${searchFilterNanoId}`;
  }
  static accept(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/accept`;
  }

  static attachments(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/attachments`;
  }

  static files(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/files`;
  }

  static images(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/images`;
  }

  static sourceFiles(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/source-files`;
  }

  static payments(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/payments`;
  }

  static products(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/products`;
  }

  static lifestyles(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/lifestyles`;
  }

  static materials(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/materials`;
  }

  static results(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/results`;
  }

  static team(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/team`;
  }

  static notifications(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/notifications`;
  }

  static transactions(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/transactions`;
  }

  static activity(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/activity`;
  }

  static smartContracts(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/smart-contracts`;
  }

  static journalRecords(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/journal-records`;
  }

  static taskSmartContracts(
    taskNanoId: TaskNanoID,
    smartContractNanoId: SmartContractNanoID
  ) {
    return `${basePath}/${taskNanoId}/smart-contracts/${smartContractNanoId}`;
  }

  static whiteboard(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/whiteboard`;
  }

  static whiteboardBackups(taskNanoId: TaskNanoID) {
    return `${basePath}/${taskNanoId}/whiteboard-backups`;
  }

  static whiteboardBackup(
    projectNanoId: TaskNanoID,
    whiteboardBackupId: WhiteboardStorageBackupID
  ) {
    return `${basePath}/${projectNanoId}/whiteboard-backups/${whiteboardBackupId}`;
  }
}
