export default {
  url: 'URL',
  status: 'Status',
  progress: 'Progress',
  transactionImport: 'Transaction Import',
  transactionImportId: 'Transaction Import Id',
  transactionsImports: 'Transaction imports',
  rowCount: 'Row Count',
  errorMessage: 'Error Message',
  searchByKey: 'Search by Key',
  searchById: 'Search by ID',
  searchByStatus: 'Search by Status'
};
