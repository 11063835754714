import { MultiValueGenericProps, components } from 'react-select';

function SortableMultiValueRemove(props: MultiValueGenericProps) {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        onPointerDown: (e) => e.stopPropagation(),
        ...props.innerProps
      }}
    />
  );
}

export default SortableMultiValueRemove;
