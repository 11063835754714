import { ItemTypesKeyType } from '../../keys';

const itemTypesKeys: ItemTypesKeyType = {
  plural: 'Item types',
  pluralLowerCase: 'item types',
  defaultPlural: 'Default item types',
  search: 'Search item types',
  select: 'Select item type...',
  create: 'Create item type',
  update: 'Update item type',
  delete: 'Delete item type',
  duplicate: 'Duplicate item type',
  oneOrMoreItemsWasNotFound: 'One or more items was not found',
  import: 'Import item types'
};

export default itemTypesKeys;
