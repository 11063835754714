import { FiltersViewGroups } from '../../../../../types';

class ExtendedFiltersSettingsStorage {
  static getKeyByFiltersViewGroup(filtersViewGroup: FiltersViewGroups): string {
    return `extended-filters-${filtersViewGroup}-settings-filters`;
  }

  static getItem<T>(filtersViewGroup: FiltersViewGroups): T | undefined {
    if (typeof window === 'undefined') {
      return;
    }

    try {
      const savedValue = window.localStorage.getItem(
        this.getKeyByFiltersViewGroup(filtersViewGroup)
      );

      return savedValue ? JSON.parse(savedValue) : undefined;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }

  static setItem<T>(filtersViewGroup: FiltersViewGroups, value: T) {
    if (typeof window === 'undefined') {
      return;
    }

    window.localStorage.setItem(
      this.getKeyByFiltersViewGroup(filtersViewGroup),
      JSON.stringify(value)
    );
  }
}

export default ExtendedFiltersSettingsStorage;
