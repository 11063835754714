import { IconProps } from '../types';

function DocumentUploadOutlineIcon({ className, id, strokeWidth }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth || '2'}
    >
      <path
        d="m7,21l10,0a2,2 0 0 0 2,-2l0,-9.586a1,1 0 0 0 -0.293,-0.707l-5.414,-5.414a1,1 0 0 0 -0.707,-0.293l-5.586,0a2,2 0 0 0 -2,2l0,14a2,2 0 0 0 2,2z"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="svg_5"
        y2="15.19424"
        x2="12"
        y1="9.51079"
        x1="12"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="svg_6"
        y2="9.51079"
        x2="12"
        y1="12.2446"
        x1="8.83453"
      />
      <line
        stroke="null"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="svg_8"
        y2="12.2446"
        x2="14.94964"
        y1="9.51079"
        x1="12.07194"
      />
    </svg>
  );
}

export default DocumentUploadOutlineIcon;
