import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { RoleUUID } from './rolesTypes';

const basePath = '/roles';

export class RolePath {
  static index() {
    return basePath;
  }

  static show(roleUuid: RoleUUID) {
    return `${basePath}/${roleUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
