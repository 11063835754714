import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface EvaluationsKeyType {
  downloadExcel: LocalesKey;
  plural: LocalesKey;
  set: LocalesKey;
  singular: LocalesKey;
  no: LocalesKey;
  count: LocalesKey;
  pleaseHelpUsToBecomeBetter: LocalesKey;
  postponeUntilTomorrow: LocalesKey;
  fields: {
    accuracy: LocalesKey;
    communication: LocalesKey;
    timing: LocalesKey;
    quality: LocalesKey;
    taskError: LocalesKey;
    projectError: LocalesKey;
    profileError: LocalesKey;
    briefError: LocalesKey;
    estimateError: LocalesKey;
    styleError: LocalesKey;
    burningTasks: LocalesKey;
    respondRate: LocalesKey;
    estimateRate: LocalesKey;
    qualityAssurance: LocalesKey;
    textureLightingCamera: LocalesKey;
    compositionDetailsStorytelling: LocalesKey;
  };
}

export const evaluationsKeys = keyPathMirror<EvaluationsKeyType, string>(
  {
    downloadExcel: null,
    plural: null,
    set: null,
    singular: null,
    no: null,
    count: null,
    pleaseHelpUsToBecomeBetter: null,
    postponeUntilTomorrow: null,
    fields: {
      accuracy: null,
      communication: null,
      timing: null,
      quality: null,
      taskError: null,
      projectError: null,
      profileError: null,
      briefError: null,
      estimateError: null,
      styleError: null,
      burningTasks: null,
      respondRate: null,
      estimateRate: null,
      qualityAssurance: null,
      textureLightingCamera: null,
      compositionDetailsStorytelling: null
    }
  },
  'models.evaluations.'
);
