import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { CategoryUUID } from './categoriesTypes';

const basePath = '/categories';

export class CategoryPath {
  static index() {
    return basePath;
  }

  static show(categoryUuid: CategoryUUID) {
    return `${basePath}/${categoryUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
