import { ReactNode, createContext, useContext } from 'react';
import { useRouter } from 'next/router';

import { usePreviousValue } from '../../../common/hooks/usePreviousValue';

interface RouterHistoryContext {
  previousPath?: string;
}

const RouterHistoryContext = createContext<RouterHistoryContext>({});

interface RouterHistoryProviderProps {
  children: ReactNode;
}

export function RouterHistoryProvider({
  children
}: RouterHistoryProviderProps) {
  const { asPath } = useRouter();

  const previousPath = usePreviousValue(asPath);

  const routerHistoryContext = {
    previousPath
  };

  return (
    <RouterHistoryContext.Provider value={routerHistoryContext}>
      {children}
    </RouterHistoryContext.Provider>
  );
}

function useRouterHistory() {
  const { previousPath } = useContext(RouterHistoryContext);

  return {
    previousPath
  };
}

export default useRouterHistory;
