export default {
  all: 'Усi повідомлення',
  areYouSureYouWantToSendThisMessageToAllProjectTasks:
    'Ви впевнені, що хочете відправити повідомлення в усі задачі проекту?',
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible:
    'Ви впевнені, що хочете позначити всі повідомлення як прочитані? Ця дія незворотна.',
  markAsRead: 'Позначити прочитаним',
  markAsUnread: 'Позначити непрочитаним',
  markAllAsRead: 'Позначити все прочитаним',
  my: 'Мої повідомлення',
  messageId: 'ID Повідомлення',
  noPlural: 'Повідомлення відсутні',
  showOlder: 'Показати старі повідомлення'
};
