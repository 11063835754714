import {
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  ItemGqlError,
  UUID
} from '../../types';

export type FeedBackUUID = UUID;
export type FeedBackSubject = string;
export type FeedBackBody = string;
export type FeedBackName = string;
export type FeedBackEmail = string;

export const enum FeedBackFields {
  NAME = 'name',
  EMAIL = 'email',
  BODY = 'body',
  SUBJECT = 'subject'
}

export type FeedBackGqlError = ItemGqlError;
export type CreateFeedBackGqlQuery = CreateItemGqlQuery;
export type CreateFeedBackGqlStatus = CreateItemGqlStatus;
