import { I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { WorkspaceSecondaryMenuItems } from '../../CompactWorkspaceSecondaryMenu.types';

import { MessagePath } from '../../../../../../messages/MessagePath';
import { ProjectPath } from '../../../../../../projects/ProjectPath';
import { TaskPath } from '../../../../../../tasks/TaskPath';

import { CommonPermissions } from '../../../../../commonConstants';

import {
  messagesKeys,
  projectsKeys,
  tasksKeys
} from '../../../../../../../locales/keys';

export const workspaceMenuLinks = (permissions: {
  projects: string[];
  tasks: string[];
  messages: string[];
}) => [
  {
    name: WorkspaceSecondaryMenuItems.PROJECTS,
    href: ProjectPath.compactIndex(),
    i18nText: projectsKeys.plural,
    permissions: permissions.projects
  },
  {
    name: WorkspaceSecondaryMenuItems.TASKS,
    href: TaskPath.compactIndex(),
    i18nText: tasksKeys.plural,
    permissions: permissions.tasks
  },
  {
    name: WorkspaceSecondaryMenuItems.MESSAGES,
    href: MessagePath.compactIndex(),
    i18nText: messagesKeys.plural,
    permissions: permissions.messages
  }
];

export interface CompactWorkspaceSecondaryMenuLinksConfigItem {
  action: CommonPermissions;
  active: boolean;
  count?: number;
  href: string;
  i18nText: I18nText;
  icon: IconsEnum;
  visible?: boolean;
}

export interface CompactWorkspaceSecondaryMenuLinksConfig {
  tasks: CompactWorkspaceSecondaryMenuLinksConfigItem[];
  projects: CompactWorkspaceSecondaryMenuLinksConfigItem[];
  messages: CompactWorkspaceSecondaryMenuLinksConfigItem[];
}
