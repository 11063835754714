import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SelectedMaterialsKeyType {
  plural: LocalesKey;
  download: LocalesKey;
}

export const selectedMaterialsKeys = keyPathMirror<
  SelectedMaterialsKeyType,
  string
>(
  {
    plural: null,
    download: null
  },
  'models.selectedMaterials.'
);
