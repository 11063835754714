import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface MaterialsKeyType {
  addMaterial: LocalesKey;
  all: LocalesKey;
  attach: LocalesKey;
  attachMaterial: LocalesKey;
  attachSelected: LocalesKey;
  categories: {
    stone: LocalesKey;
    ceramics: LocalesKey;
    fabric: LocalesKey;
    glass: LocalesKey;
    metal: LocalesKey;
    wood: LocalesKey;
  };
  category: LocalesKey;
  deleteMaterial: LocalesKey;
  deleteWarning: LocalesKey;
  download: LocalesKey;
  editMaterial: LocalesKey;
  my: LocalesKey;
  name: LocalesKey;
  oneOrMoreSkuWasNotFoundInMaterialLibrary: LocalesKey;
  plural: LocalesKey;
  search: LocalesKey;
  searchMaterialsByName: LocalesKey;
  selected: LocalesKey;
  singular: LocalesKey;
  threeDStock: LocalesKey;
  viewAll: LocalesKey;
}

export const materialsKeys = keyPathMirror<MaterialsKeyType, string>(
  {
    addMaterial: null,
    all: null,
    attach: null,
    attachMaterial: null,
    attachSelected: null,
    categories: {
      stone: null,
      ceramics: null,
      fabric: null,
      glass: null,
      metal: null,
      wood: null
    },
    category: null,
    deleteMaterial: null,
    deleteWarning: null,
    download: null,
    editMaterial: null,
    my: null,
    name: null,
    oneOrMoreSkuWasNotFoundInMaterialLibrary: null,
    plural: null,
    search: null,
    searchMaterialsByName: null,
    selected: null,
    singular: null,
    threeDStock: null,
    viewAll: null
  },
  'models.materials.'
);
