import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SentMessagesKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  name: LocalesKey;
  search: LocalesKey;
  view: LocalesKey;
  kind: LocalesKey;
  mailer: LocalesKey;
  hostName: LocalesKey;
  originalId: LocalesKey;
  messageId: LocalesKey;
  apiId: LocalesKey;
  properties: LocalesKey;
}

export const sentMessagesKeys = keyPathMirror<SentMessagesKeyType, string>(
  {
    list: null,
    plural: null,
    create: null,
    delete: null,
    name: null,
    search: null,
    view: null,
    kind: null,
    mailer: null,
    hostName: null,
    originalId: null,
    messageId: null,
    apiId: null,
    properties: null
  },
  'models.sentMessages.'
);
