import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { CategoryUUID, FetchCategoriesScopes } from './categoriesTypes';

export class CategoryCache {
  static indexCacheKey() {
    return 'categories';
  }

  static categoriesSelectFieldCacheKey(scope: FetchCategoriesScopes) {
    return `categories-select-field-${scope}`;
  }

  static categoriesSelectSearchableFieldCacheKey(scope: FetchCategoriesScopes) {
    return `categories-select-searchable-field-${scope}`;
  }

  static selectedCategoriesSelectSearchableFieldCacheKey(
    scope: FetchCategoriesScopes
  ) {
    return `selected-categories-select-searchable-field-${scope}`;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `categories-filtered-${searchFilterNanoId}`;
  }

  static showCacheKey() {
    return 'category';
  }

  static showQueryKey(categoryUuid: CategoryUUID) {
    return ['category', categoryUuid];
  }

  static registerCategoriesCacheKey() {
    return 'registerCategories';
  }

  static usersProfileCategoriesCacheKey(fieldName: string) {
    return `categories-users-profile-${fieldName}`;
  }

  static categoriesRadioButtonsFieldCacheKey(fieldName: string) {
    return `categories-radio-buttons-field-${fieldName}`;
  }

  static brandName() {
    return 'categories-brand-name';
  }
}
