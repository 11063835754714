import { IconProps } from '../types';

function MaterialSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M6.1 10l8-8h2.1c.2 0 .4 0 .6.1l-9.3 9.3L6.1 10zM15 8.9l3-3V3.8c0-.2 0-.4-.1-.6l-4.3 4.3L15 8.9zM6.1 5l1.4 1.4L12 2H9.1l-3 3zm6.4 3.6l-9.3 9.3c.2.1.4.1.6.1h2.1l8-8-1.4-1.4zM5 11.1l-3 3v2.1c0 .2 0 .4.1.6l4.3-4.3L5 11.1zm-3-2V12l4.5-4.5L5 6.1l-3 3zM7 2H3.8C2.8 2 2 2.8 2 3.8V7l5-5zm6.9 13l-1.5-1.5L8 18h2.9l3-3zm-.9 3h3.2c1 0 1.8-.8 1.8-1.8V13l-5 5zm5-7.1V8l-4.5 4.5L15 14l3-3.1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default MaterialSolidIcon;
