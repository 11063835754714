import React from 'react';

import {
  AddFundsModalButtonDataInvoice,
  AddFundsModalButtonDataBillingInfo
} from '../../AddFundsModalButton.types';

import { AvBillingInfoLogo } from '../../../../../../avBillingInfos/helpers/AvBillingInfoLogo';

import { Translate } from '../../../../../../../helpers/Translate';
import { DateHelper } from '../../../../../../../helpers/DateHelper';

import { invoicesKeys } from '../../../../../../../locales/keys';

interface AddFundsModalButtonInvoiceInfoProps {
  invoice: AddFundsModalButtonDataInvoice;
  billingInfo: AddFundsModalButtonDataBillingInfo;
}

function AddFundsModalButtonInvoiceInfo({
  invoice,
  billingInfo
}: AddFundsModalButtonInvoiceInfoProps) {
  return (
    <div className="relative bg-gray-50 dark:bg-gray-850">
      <div className="max-w-3xl mx-auto flex gap-6 px-6 py-8">
        <div className="flex-1">
          {billingInfo?.avBillingInfo?.nanoId ? (
            <AvBillingInfoLogo
              avBillingInfoNanoId={billingInfo.avBillingInfo.nanoId}
              className="object-cover w-20 h-20 rounded"
            />
          ) : null}
        </div>
        <div className="flex-1">
          <div className="space-y-2">
            <h1 className="text-2xl font-medium">
              <Translate id={invoicesKeys.singular} />
              &nbsp;
              {`#${invoice?.id}`}
            </h1>
            <div>
              <div className="text-xs flex gap-2">
                <div className="font-semibold">
                  <Translate id={invoicesKeys.issueDate} />
                  {':'}
                </div>
                <div className="text-gray-600 dark:text-gray-400">
                  <DateHelper date={invoice?.createdAt} />
                </div>
              </div>
              <div className="text-xs flex gap-2">
                <div className="font-semibold">
                  <Translate id={invoicesKeys.dueDate} />
                  {':'}
                </div>
                <div className="text-gray-600 dark:text-gray-400">
                  <DateHelper date={invoice?.dueDate} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFundsModalButtonInvoiceInfo;
