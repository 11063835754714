import React, { memo } from 'react';
import Head from 'next/head';
import compact from 'lodash/compact';

import { APP_CRM_NAME } from '../../config';

import { useTranslate } from '../../common/hooks/useTranslate';

interface HeadTitleProps {
  i18nTitle?: string;
  titleSeparator?: string;
  title?: string;
}

function HeadTitle({
  i18nTitle,
  titleSeparator = ' | ',
  title
}: HeadTitleProps) {
  const { t } = useTranslate();

  return (
    <Head>
      <title>
        {compact([i18nTitle ? t(i18nTitle) : title, APP_CRM_NAME]).join(
          titleSeparator
        )}
      </title>
    </Head>
  );
}

export default memo(HeadTitle);
