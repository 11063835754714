import React, { useCallback } from 'react';
import cl from 'classnames';

import { I18nText } from '../../../../../../../../../types';

import { InvoicePaymentMethods } from '../../../../../../../invoicesTypes';

import { Translate } from '../../../../../../../../../helpers/Translate';

interface AddFundsModalButtonPaymentMethodSelectItemProps {
  active: boolean;
  method: InvoicePaymentMethods;
  labelI18nText: I18nText;
  descriptionI18nText: I18nText;
  rounded: 'top' | 'bottom';
  onChange: (method: InvoicePaymentMethods) => void;
}

function AddFundsModalButtonPaymentMethodSelectItem({
  active,
  method,
  labelI18nText,
  descriptionI18nText,
  rounded,
  onChange
}: AddFundsModalButtonPaymentMethodSelectItemProps) {
  const handleChange = useCallback<() => void>(() => {
    onChange(method);
  }, [method, onChange]);

  return (
    <label
      className={cl(
        'relative border p-4 flex cursor-pointer focus:outline-none',
        {
          'rounded-tl-md rounded-tr-md': rounded === 'top',
          'rounded-bl-md rounded-br-md': rounded === 'bottom'
        },
        active
          ? 'bg-blue-50 border-blue-200 dark:border-blue-600 z-10 dark:bg-blue-500 dark:bg-opacity-10'
          : 'border-gray-200 dark:border-gray-600'
      )}
    >
      <input
        type="radio"
        name="payment-method"
        value={method}
        checked={active}
        className="basic-radio"
        aria-labelledby={`payment-method-label-${method}`}
        aria-describedby={`payment-method-description-${method}`}
        onChange={handleChange}
      />
      <span className="ml-3 flex flex-col">
        <span
          id={`payment-method-label-${method}`}
          className="block text-sm font-medium text-gray-900 dark:text-gray-200"
        >
          <Translate id={labelI18nText} />
        </span>
        <span
          id={`payment-method-description-${method}`}
          className={cl(
            'block text-sm',
            active
              ? 'text-blue-700 dark:text-blue-300'
              : 'text-gray-500 dark:text-gray-400'
          )}
        >
          <Translate id={descriptionI18nText} />
        </span>
      </span>
    </label>
  );
}

export default AddFundsModalButtonPaymentMethodSelectItem;
