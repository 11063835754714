import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useDownloadManagerCornerModal } from './hooks/useDownloadManagerCornerModal';

import { DownloadManagerCornerModalItem } from './components/DownloadManagerCornerModalItem';

import { Translate } from '../../../../../helpers/Translate';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { downloadsKeys, words } from '../../../../../locales/keys';

function DownloadManagerCornerModal() {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    closeDownloadManagerBlock,
    handleStopAll,
    handleClear,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading
  } = useDownloadManagerCornerModal();

  useEffect(() => {
    if (downloadManagerBlockOpened) {
      return closeDownloadManagerBlock;
    }
  }, [closeDownloadManagerBlock, downloadManagerBlockOpened]);

  return downloadsFetched && downloadManagerBlockOpened ? (
    <div className="absolute bottom-16 sm:bottom-6 2xl:bottom-8 right-2.5 z-5">
      <div className="overflow-hidden pt-1 px-2.5 w-80 3xl:w-96 4xl:w-112 flex flex-col max-h-80 bg-white dark:bg-gray-850 border border-gray-200 dark:border-gray-700 rounded-t-3xl">
        {/* header */}
        <div className="shrink relative pb-2.5 pt-3.5 border-b border-gray-300 dark:border-gray-700">
          <div className="text-2xs sm:text-xs text-center truncate">
            <Translate id={downloadsKeys.plural} />
          </div>

          <div className="absolute right-0 inset-y-0 flex items-center">
            <PureIconButtonHelper
              className="py-1 pl-1 pr-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.X}
              iconClassName="h-5 w-5"
              onClick={closeDownloadManagerBlock}
            />
          </div>
        </div>

        <AlertMessage message={downloadsError} />
        <LoadingSkeleton loaded={downloadsFetched}>
          <ul className="flex-1 space-y-1.5 w-full overflow-x-hidden pt-1.5">
            {size(downloads) > 0 ? (
              downloads.map((download) => (
                <DownloadManagerCornerModalItem
                  key={download.uuid}
                  download={download}
                />
              ))
            ) : (
              <div className="text-center text-xs my-5">
                <Translate id={downloadsKeys.noDownloads} />
              </div>
            )}
          </ul>
        </LoadingSkeleton>

        <div className="px-4">
          <AlertMessage message={deleteDownloadsErrorMessage} />
        </div>

        {/* footer */}
        {!isEmpty(downloads) && (
          <div className="py-2.5 flex space-x-1 justify-between">
            <PureButtonHelper
              className="py-1 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
              i18nText={words.clear}
              disabled={deleteDownloadsLoading}
              onClick={handleClear}
            />
            <PureButtonHelper
              className="py-1 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
              i18nText={words.stopAll}
              disabled={deleteDownloadsLoading}
              onClick={handleStopAll}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
}

export default DownloadManagerCornerModal;
