import { IconProps } from '../types';

function DrawCurveOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      className={className || 'h-6 w-6'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.8 3c-.4.2-2.3 1.2-2.7 3-.1.4-.2 1 0 1.6.1.1.3.8 1.3 1.3 1.6.9 4 1 6.1-.2 1.5-.8 2.3-2 2.6-2.8.1-.2.2-.7-.1-1.1-.2-.4-.7-.5-.9-.5-1.4-.3-3 1.1-3.4 1.4-1.3 1.3-1.8 2.8-2 3.4-.2.8-1.1 3.5.4 5.6.7 1.1 1.8 1.5 2.3 1.7 1.3.6 2.4.5 3.1.5.4 0 2.1-.1 3.9-1.2h0c2-1 4.9-3.5 4.7-6.4-.1-1.1-.6-1.6-.7-1.7-.5-.5-1.2-.5-1.4-.5-1.9 0-6.4 2.9-7.3 7.9-.2 1.1-.7 3.6.8 5 1.1 1.1 2.7 1 3.4 1 2.6-.1 4.3-1.7 4.8-2.2"
      ></path>
    </svg>
  );
}

export default DrawCurveOutlineIcon;
