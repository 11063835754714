import { IconProps } from '../types';

function TransferOutgoingSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M15.6 8.6c.3.8.4 1.6.4 2.4 0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7c.8 0 1.6.1 2.4.4L9.7 6.1C9.5 6 9.2 6 9 6c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-.2 0-.5-.1-.7l1.7-1.7zM17 2h-4c-.6 0-1 .4-1 1s.4 1 1 1h1.6l-7.3 7.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L16 5.4V7c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TransferOutgoingSolidIcon;
