import forms from './forms';
import httpErrors from './httpErrors';
import messages from './messages';
import models from './models';
import strings from './strings';
import words from './words';

export default {
  forms,
  httpErrors,
  messages,
  models,
  strings,
  words
};
