import startsWith from 'lodash/startsWith';

import { StockSecondaryMenuItems } from './CompactStockSecondaryMenu.types';

import { LifestylePath } from '../../../../lifestyles/LifestylePath';
import { MaterialPath } from '../../../../materials/MaterialPath';
import { ProductPath } from '../../../../products/ProductPath';
import { StockPath } from '../../../../stock/StockPath';

import { CommonPermissions } from '../../../commonConstants';

import {
  lifestylesKeys,
  materialsKeys,
  productsKeys
} from '../../../../../locales/keys';

export const stockMenuLinks = [
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, StockPath.products()) ||
      startsWith(pathname, ProductPath.index()),
    name: StockSecondaryMenuItems.PRODUCTS,
    href: StockPath.products(),
    i18nText: productsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_PRODUCTS_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, StockPath.lifestyles()) ||
      startsWith(pathname, LifestylePath.index()),
    name: StockSecondaryMenuItems.LIFESTYLES,
    href: StockPath.lifestyles(),
    i18nText: lifestylesKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_LIFESTYLES_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, StockPath.materials()) ||
      startsWith(pathname, MaterialPath.index()),
    name: StockSecondaryMenuItems.MATERIALS,
    href: StockPath.materials(),
    i18nText: materialsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_MATERIALS_LINK]
  }
];
