import { gql } from 'graphql-request';

export const DELETE_DOWNLOAD_QUERY = gql`
  mutation DeleteDownload($uuid: ID!) {
    deleteDownload(input: { uuid: $uuid }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
