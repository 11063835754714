import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TaskMembersKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  timeTracked: LocalesKey;
  muteTask: LocalesKey;
}

export const taskMembersKeys = keyPathMirror<TaskMembersKeyType, string>(
  {
    list: null,
    plural: null,
    timeTracked: null,
    muteTask: null
  },
  'models.taskMembers.'
);
