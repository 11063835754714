export default {
  are_you_sure_you_want_to_delete_permission_this_action_cannot_be_undone:
    'Вы уверены, что хотите удалить доступ? Это действие не может быть отменено.',
  are_you_sure_you_want_to_delete_saved_filter_this_action_cannot_be_undone:
    'Вы уверены, что хотите удалить фильтр? Это действие не может быть отменено.',
  check_your_email: 'Проверьте свою электронную почту',
  did_not_receive_the_email: {
    did_not_receive: 'Не получили письмо? Проверьте свой спам-фильтр или',
    try_another: 'попробуйте другой адрес электронной почты'
  },
  enter_the_email_associated_with_your_account:
    'Введите адрес электронной почты, связанный с вашей учетной записью, и мы отправим электронное письмо с инструкциями по изменению пароля.',
  permission_action_must_contain_only_latin_letters_numbers_or_the_underscore_character:
    'Действие должно содержать только латинские буквы, цифры и символы подчеркивания.',
  start_typing_to_search_for_users:
    'Начните вводить имя, чтобы найти пользователей',
  we_have_sent_a_password_recover_instructions:
    'Мы отправили инструкции по восстановлению пароля на вашу электронную почту.'
};
