import set from 'lodash/set';

import { IndexQueryResponse } from '../../../../useIndexQuery.types';

interface GetUpdatedQueryDataOptions<T> {
  updateFunction: (item: T) => Partial<T>;
  previousQueryData: IndexQueryResponse<T>;
  scope: string;
  itemIndex: number;
}

function getUpdatedQueryData<T>({
  updateFunction,
  previousQueryData,
  scope,
  itemIndex
}: GetUpdatedQueryDataOptions<T>): IndexQueryResponse<T> | null {
  const previousItem = previousQueryData[scope].nodes[itemIndex];

  if (!previousItem) {
    return null;
  }

  const updateItemData = updateFunction ? updateFunction(previousItem) : {};

  const updatedItem = { ...previousItem, ...updateItemData };

  const updatedQueryData = {
    ...previousQueryData,
    [scope]: {
      ...previousQueryData[scope],
      nodes: set([...previousQueryData[scope].nodes], itemIndex, updatedItem)
    }
  };

  return updatedQueryData;
}

export default getUpdatedQueryData;
