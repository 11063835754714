import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ImagesKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
  attach: LocalesKey;
  main: LocalesKey;
}

export const imagesKeys = keyPathMirror<ImagesKeyType, string>(
  {
    plural: null,
    singular: null,
    attach: null,
    main: null
  },
  'models.images.'
);
