import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface StatusesKeyType {
  pluralDowncase: LocalesKey;
  select: LocalesKey;
  types: {
    acceptance: LocalesKey;
    canceled: LocalesKey;
    checking: LocalesKey;
    definition: LocalesKey;
    discussion: LocalesKey;
    distribution: LocalesKey;
    implementation: LocalesKey;
    incompleteSpecification: LocalesKey;
    paid: LocalesKey;
    paused: LocalesKey;
    paymentWaiting: LocalesKey;
    performed: LocalesKey;
    qa: LocalesKey;
  };
}

export const statusesKeys = keyPathMirror<StatusesKeyType, string>(
  {
    pluralDowncase: null,
    select: null,
    types: {
      acceptance: null,
      canceled: null,
      checking: null,
      definition: null,
      discussion: null,
      distribution: null,
      implementation: null,
      incompleteSpecification: null,
      paid: null,
      paused: null,
      paymentWaiting: null,
      performed: null,
      qa: null
    }
  },
  'models.statuses.'
);
