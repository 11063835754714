import { IconProps } from '../types';

function CoronaSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5 rounded h-6 w-6'}
      viewBox="0 0 40 40"
      fill="currentColor"
    >
      <path fill="#F3712B" d="M40 0H0V40H40V0Z" clipRule="evenodd" />
      <path
        fill="white"
        d="M26.8827 28.7998C23.4327 31.2831 18.616 30.5331 16.0993 27.1165C16.0327 27.0165 15.966 26.9165 15.8993 26.7998C13.6493 23.3998 14.4493 18.8331 17.7993 16.4165C21.1994 13.9665 25.916 14.6665 28.466 17.9498C28.4993 17.9998 28.5494 18.0498 28.5827 18.0998C31.0827 21.5165 30.3327 26.2998 26.8827 28.7998ZM27.0327 9.19979C27.3827 10.7665 26.4827 11.9665 26.4827 11.9665C23.7993 7.68313 21.616 6.86646 19.816 6.79979C20.8493 8.53313 19.6827 10.0331 19.6493 10.0331C16.4993 4.88313 12.3327 7.16646 12.3327 7.16646C12.3327 7.16646 15.2827 9.26646 14.1493 11.7165C9.74935 8.78313 6.66602 11.8331 6.66602 11.8331C6.66602 11.8331 11.316 13.6831 10.1827 16.5665C9.38268 18.4165 7.08268 17.9831 6.93268 17.8498C6.91602 19.1498 7.54935 23.1998 11.4993 25.3498C11.4993 25.3498 10.0827 26.3165 8.28268 25.8665C8.63268 26.5665 9.06602 27.2831 9.61602 27.9831C13.8827 33.7998 22.0827 35.0831 27.9493 30.8498C33.816 26.6165 35.0993 18.4831 30.8327 12.6665C29.716 10.9831 27.916 9.73313 27.0327 9.19979Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CoronaSolidIcon;
