import { LibrariesKeyType } from '../../keys';

const librariesKeys: LibrariesKeyType = {
  addCategory: 'Add category',
  addAttribute: 'Add attribute',
  addSubcategories: 'Add subcategories',
  addSubcategoryToTables: 'Add subcategory to Tables',
  addOptions: 'Add options',
  allCategories: 'All categories',
  attributes: 'Attributes',
  categories: 'Categories',
  categoryName: 'Category name',
  create: 'Create library',
  createYourOwnLibrary: 'Create your own library',
  free: 'Free',
  premium: 'Premium',
  removeAttribute: 'Remove attribute',
  removeCategory: 'Remove category',
  removeOption: 'Remove option',
  type: 'Type',
  multiple: 'Multiple',
  name: 'Library name',
  options: 'Options',
  attributeFilters: {
    disableFilter: 'Disable filter',
    searchLine: 'Search line',
    searchWithCheckboxes: 'Search with checkboxes',
    radioSelector: 'Radio selector',
    dropdownSelector: 'Dropdown selector',
    dropdownMultiselector: 'Dropdown multiselector',
    rangeSelector: 'Range selector',
    checkbox: 'Checkbox',
    yesNoAnyRadioSelector: 'Yes/No/Any radio selector'
  },
  attributeTypes: {
    text: 'Text',
    number: 'Number',
    selector: 'Selector',
    checkbox: 'Checkbox',
    tags: 'Tags'
  },
  attributeDefaultValues: {
    checked: 'Checked',
    unchecked: 'Unhecked'
  }
};

export default librariesKeys;
