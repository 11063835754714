import React, { ReactNode, useState } from 'react';

import { ClassName, I18nText } from '../../types';
import { TooltipDelay, TooltipOffset } from '../tooltips/types';

import { Tooltip } from '../tooltips/Tooltip';
import { Translate } from '../Translate';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';

interface ContentWithTooltipProps {
  appendTo?: 'parent' | Element;
  children: ReactNode;
  className?: ClassName;
  delay?: TooltipDelay;
  interactive?: boolean;
  offset?: TooltipOffset;
  tooltipChildren?: ReactNode;
  tooltipClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  withArrow?: boolean;
}

function ContentWithTooltip({
  appendTo,
  children,
  className,
  delay,
  interactive,
  offset,
  tooltipChildren,
  tooltipClassName,
  tooltipI18nText,
  tooltipPlacement,
  withArrow
}: ContentWithTooltipProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  if (!children) {
    return null;
  }

  return (
    <>
      <div className={className} ref={setReferenceTooltipElement}>
        {children}
      </div>
      <Tooltip
        appendTo={appendTo}
        className={tooltipClassName}
        delay={delay}
        interactive={interactive}
        offset={offset}
        placement={tooltipPlacement}
        referenceElement={referenceTooltipElement}
        withArrow={withArrow}
      >
        {tooltipI18nText ? <Translate id={tooltipI18nText} /> : tooltipChildren}
      </Tooltip>
    </>
  );
}

export default ContentWithTooltip;
