import { ProfilesKeyType } from '../../keys';

const profilesKeys: ProfilesKeyType = {
  activeTasks: 'Active tasks',
  airtable: 'Airtable',
  access: 'Access',
  accounts: 'Accounts',
  atLowerCase: 'at',
  averageEvaluation: 'Avg. evaluation',
  balance: 'Balance',
  bonusAmount: 'Bonus amount',
  basicInfo: 'Basic info',
  dashboard: 'Dashboard',
  debt: 'Debt',
  description: 'Description',
  documents: 'Documents',
  editProfilePicture: 'Edit profile picture',
  evaluations: 'Evaluations',
  files: 'Files',
  finance: 'Finance',
  financeInfo: 'Finance info',
  invitations: 'Invitations',
  latestResults: 'Latest results',
  marketingInfo: 'Marketing info',
  mar: 'MAR',
  myProjects: 'My projects',
  myTasks: 'My tasks',
  myMessages: 'My messages',
  myResults: 'My results',
  openSmartContracts: 'Open smart contracts',
  personalization: 'Personalization',
  qualityControl: 'Quality control',
  rate: 'Rate',
  ratio: 'Ratio',
  records: 'Records',
  reports: 'Reports',
  resume: 'Resume',
  requirements: 'Requirements',
  revenue: 'Revenue',
  singular: 'Profile',
  status: 'Status',
  smartContractSettings: 'Smart contract settings',
  statistic: 'Statistic',
  tasks: 'Tasks',
  tasksByStatus: 'Tasks by status',
  tasksWithoutSourceFiles: 'Tasks without source files',
  teamAndPosition: 'Team and position',
  timeByDate: 'Time by date',
  underpayments: 'Underpayments',
  totalHours: 'Total hours',
  totalHoursDay: 'Total hours (day)',
  totalPayments: 'Total payments',
  totalProjects: 'Total projects',
  totalTasks: 'Total tasks',
  kAvr: 'K avr',
  kAverage: 'K average',
  kMed: 'K med',
  kMedian: 'K median',
  userMessages: 'User messages',
  userResults: 'User results'
};

export default profilesKeys;
