export default {
  hide: 'Скрыть меню',
  keepOpen: 'Держать меню открытым',
  links: {
    access: 'Доступ',
    active: 'Активные',
    blocked: 'Заблокированные',
    dashboard: 'Dashboard',
    emailTemplates: 'Шаблоны Email',
    favorite: 'Избранное',
    invoices: 'Счета',
    main: 'Главная',
    permissions: 'Разрешения',
    people: 'Люди',
    pages: 'Страницы',
    reports: 'Отчеты',
    roles: 'Роли',
    content: 'Контент',
    products: 'Модели',
    profile: 'Профиль',
    projects: 'Проекты',
    tasks: 'Задачи',
    transactionsImports: 'Импорты транзакций',
    messages: 'Сообщения'
  },
  tabs: {
    dashboard: 'Dashboard',
    notifications: 'Уведомления',
    tasksAndProjects: 'Задачи и проекты',
    stock3d: '3D каталог',
    users: 'Пользователи',
    workers: 'Работники',
    people: 'Люди',
    clients: 'Клиенты',
    admin: 'Панель администратора'
  }
};
