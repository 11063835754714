import React, {
  ReactNode,
  MouseEvent,
  useCallback,
  CSSProperties
} from 'react';

type ButtonHelperOnClick = (e: MouseEvent<HTMLButtonElement>) => void;

interface ButtonHelperProps {
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  blurOnMouseLeave?: boolean;
  children: ReactNode;
  onClick?: ButtonHelperOnClick;
  onMouseEnter?: () => void;
}

function ButtonHelper({
  children,
  className,
  disabled,
  style,
  blurOnMouseLeave,
  onClick,
  onMouseEnter
}: ButtonHelperProps) {
  const handleClick = useCallback<ButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <button
      type="button"
      className={className}
      disabled={disabled}
      style={style}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
    >
      {children}
    </button>
  );
}

export default ButtonHelper;
