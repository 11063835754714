import React from 'react';
import Link from 'next/link';

import { ClassName, I18nText } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../../helpers/Translate';

type NextPureLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

interface NextPureLinkHelperProps {
  className?: ClassName;
  href: string;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  text?: string;
  prefetch?: boolean;
  onClick?: NextPureLinkHelperOnClick;
  onMouseEnter?: () => void;
}

function NextPureLinkHelper({
  className,
  href,
  i18nText,
  icon,
  iconClassName,
  text,
  prefetch,
  onClick,
  onMouseEnter
}: NextPureLinkHelperProps) {
  return (
    <Link
      href={href}
      prefetch={prefetch}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {icon ? <Icon className={iconClassName} icon={icon} /> : null}
      {i18nText ? <Translate id={i18nText} /> : text}
    </Link>
  );
}

export default NextPureLinkHelper;
