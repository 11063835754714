import React from 'react';
import {
  QueryClientProvider as BaseQueryClientProvider,
  QueryClientProviderProps
} from 'react-query';

type QueryClientProviderType = React.FC<
  React.PropsWithChildren<QueryClientProviderProps>
>;

const QueryClientProvider: QueryClientProviderType = BaseQueryClientProvider;

// Fix children typescript error
export { QueryClientProvider };
