import { SupersetReportsKeyType } from '../../keys/models/supersetReports';

const supersetReports: SupersetReportsKeyType = {
  create: 'Create superset report',
  delete: 'Delete superset report',
  deleteReport: 'Delete report',
  editReport: 'Edit report',
  plural: 'Superset reports',
  singular: 'Superset report',
  supersetDashboardId: 'Superset dashboard id',
  update: 'Update superset report'
};

export default supersetReports;
