import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';

import {
  IndexQueryResponse,
  UpdateIndexQueryItemCacheAction
} from '../../useIndexQuery.types';

import { getItemIndex } from './utils/getItemIndex';
import { getUpdatedQueryData } from './utils/getUpdatedQueryData';

interface UpdateIndexQueryItemCacheOptions {
  fullCacheKey: QueryKey;
  scope: string;
}

function useUpdateIndexQueryItemCache<ItemType>({
  fullCacheKey,
  scope
}: UpdateIndexQueryItemCacheOptions) {
  const queryClient = useQueryClient();

  return useCallback<UpdateIndexQueryItemCacheAction<ItemType>>(
    ({ selector, updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<IndexQueryResponse<ItemType>>(fullCacheKey);

      if (!previousQueryData) {
        return null;
      }

      const { itemIndex } = getItemIndex<ItemType>({
        data: previousQueryData,
        selector,
        scope
      });

      const updatedQueryData = getUpdatedQueryData<ItemType>({
        updateFunction,
        previousQueryData,
        scope,
        itemIndex
      });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<IndexQueryResponse<ItemType>>(
        fullCacheKey,
        updatedQueryData
      );

      return () => {
        queryClient.setQueryData<IndexQueryResponse<ItemType>>(
          fullCacheKey,
          previousQueryData
        );
      };
    },
    [fullCacheKey, queryClient, scope]
  );
}

export default useUpdateIndexQueryItemCache;
