import compact from 'lodash/compact';

import { ProjectStatuses } from '../../../../../../projects/projectsTypes';
import { TaskStatuses } from '../../../../../../tasks/tasksTypes';

import { FETCH_MESSAGES_TOTAL_COUNT_QUERY } from '../../../../../../messages/queries/fetchMessagesTotalCount.query';
import { FETCH_PROJECT_MEMBER_INVITES_TOTAL_COUNT_QUERY } from '../../../../../../projectMemberInvites/queries/fetchProjectMemberInvitesTotalCount.query';
import { FETCH_TASKS_TOTAL_COUNT_QUERY } from '../../../../../../tasks/queries/fetchTasksTotalCount.query';
import { FETCH_TASK_MEMBER_INVITES_TOTAL_COUNT_QUERY } from '../../../../../../taskMemberInvites/queries/fetchTaskMemberInvitesTotalCount.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useTotalsQuery } from '../../../../../hooks/base/reactQuery/useTotalsQuery';

import { MessageCache } from '../../../../../../messages/MessageCache';
import { ProjectMemberInviteCache } from '../../../../../../projectMemberInvites/ProjectMemberInviteCache';
import { TaskCache } from '../../../../../../tasks/TaskCache';
import { TaskMemberInviteCache } from '../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { MessagesPermissions } from '../../../../../../messages/messagesConstants';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { WorkspaceSecondaryMenuItems } from '../../CompactWorkspaceSecondaryMenu.types';

type MyTasksTotalCountQueryKey = 'tasks';
type OnlyClientsMessagesTotalCountQueryKey = 'messages';
type ProjectMemberInvitesTotalCountQueryKey = 'projectMemberInvites';
type TaskMemberInvitesTotalCountQueryKey = 'taskMemberInvites';
type TasksResultsTotalCountQueryKey = 'tasks';
type TasksWithoutSourceFilesTotalCountQueryKey = 'tasks';
type UnreadMessagesTotalCountQueryKey = 'messages';

interface CompactWorkspaceSecondaryMenuTotalsOptions {
  activeMenu: WorkspaceSecondaryMenuItems;
}

function useCompactWorkspaceSecondaryMenuTotals({
  activeMenu
}: CompactWorkspaceSecondaryMenuTotalsOptions) {
  const currentUser = useCurrentUser();

  const withTasksInvitationsCount =
    activeMenu === WorkspaceSecondaryMenuItems.TASKS &&
    currentUser.hasPermissions(
      TasksPermissions.READ_TASKS_INVITATIONS_FILTER_MENU_COUNT
    );

  const withProjectsInvitationsCount =
    activeMenu === WorkspaceSecondaryMenuItems.PROJECTS &&
    currentUser.hasPermissions(
      ProjectsPermissions.READ_PROJECTS_INVITATIONS_FILTER_MENU_COUNT
    );

  //
  // const withBurningTasksCount = currentUser.hasPermissions(
  //   TasksPermissions.READ_TASKS_BURNING_FILTER_MENU_COUNT
  // );

  const withTasksResultsQACount =
    activeMenu === WorkspaceSecondaryMenuItems.TASKS &&
    currentUser.hasPermissions(
      TasksPermissions.READ_TASKS_RESULTS_MENU_QA_COUNT
    );

  const withTasksResultsRFACount =
    activeMenu === WorkspaceSecondaryMenuItems.TASKS &&
    currentUser.hasPermissions(
      TasksPermissions.READ_TASKS_RESULTS_MENU_RFA_COUNT
    );

  // const withMyTasksCount = currentUser.hasPermissions(
  //   TasksPermissions.READ_TASKS_MY_TASKS_FILTER_MENU_COUNT
  // );
  //
  // const withTasksWithoutSourceFilesCount = currentUser.hasPermissions(
  //   TasksPermissions.READ_TASKS_WITHOUT_SOURCE_FILES_FILTER_MENU_COUNT
  // );

  const withUnreadMessagesCount =
    activeMenu === WorkspaceSecondaryMenuItems.MESSAGES &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGES_UNREAD_FILTER_MENU_COUNT
    );

  const withOnlyClientsMessagesCount =
    activeMenu === WorkspaceSecondaryMenuItems.MESSAGES &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU_COUNT
    );

  const tasksResultsFilter = {
    visibleResultsCount: { gt: 0 },
    status: {
      in: compact([
        withTasksResultsQACount ? TaskStatuses.QUALITY_CONTROL : null,
        withTasksResultsRFACount ? TaskStatuses.READY_FOR_ACCEPTANCE : null
      ])
    }
  };

  // const { data: burningTasksData } =
  //   useTotalsQuery<BurningTasksTotalCountQueryKey>({
  //     query: FETCH_TASKS_TOTAL_COUNT_QUERY,
  //     filters: {
  //       tasksFilters: {
  //         burning: true,
  //         performerIds: [currentUser?.id]
  //       }
  //     },
  //     cacheKey: TaskCache.burningTotalCountCacheKey(),
  //     options: {
  //       enabledPlaceholder: withBurningTasksCount,
  //       enabled: withBurningTasksCount
  //     }
  //   });

  // const { data: myTasksData } = useTotalsQuery<MyTasksTotalCountQueryKey>({
  //   query: FETCH_TASKS_TOTAL_COUNT_QUERY,
  //   filters: {
  //     tasksFilters: {
  //       performerIds: [currentUser?.id],
  //       status: { eq: TaskStatuses.IN_PROGRESS }
  //     }
  //   },
  //   cacheKey: TaskCache.myTotalCountCacheKey(),
  //   options: {
  //     enabledPlaceholder: withMyTasksCount,
  //     enabled: withMyTasksCount
  //   }
  // });
  //
  // const { data: tasksWithoutSourceFilesData } =
  //   useTotalsQuery<TasksWithoutSourceFilesTotalCountQueryKey>({
  //     query: FETCH_TASKS_TOTAL_COUNT_QUERY,
  //     filters: {
  //       tasksFilters: {
  //         sourceFilesCount: { eq: 0 },
  //         performerIds: [currentUser?.id],
  //         status: { eq: TaskStatuses.IN_PROGRESS }
  //       }
  //     },
  //     cacheKey: TaskCache.withoutSourceFilesTotalCountCacheKey(),
  //     options: {
  //       enabledPlaceholder: withTasksWithoutSourceFilesCount,
  //       enabled: withTasksWithoutSourceFilesCount
  //     }
  //   });

  const { data: tasksInvitationsData } =
    useTotalsQuery<TaskMemberInvitesTotalCountQueryKey>({
      query: FETCH_TASK_MEMBER_INVITES_TOTAL_COUNT_QUERY,
      filters: {
        taskMemberInvitesFilters: {
          invitedUserNanoId: currentUser.nanoId,
          taskStatusNe: [TaskStatuses.CANCELED]
        }
      },
      cacheKey: TaskMemberInviteCache.totalCountCacheKey(),
      options: {
        enabledPlaceholder: withTasksInvitationsCount,
        enabled: withTasksInvitationsCount
      }
    });

  const { data: tasksResultsData } =
    useTotalsQuery<TasksResultsTotalCountQueryKey>({
      query: FETCH_TASKS_TOTAL_COUNT_QUERY,
      filters: {
        tasksFilters: tasksResultsFilter
      },
      cacheKey: TaskCache.resultsTotalCountCacheKey(),
      options: {
        enabledPlaceholder: withTasksResultsQACount || withTasksResultsRFACount,
        enabled: withTasksResultsQACount || withTasksResultsRFACount
      }
    });

  const { data: unreadMessagesData } =
    useTotalsQuery<UnreadMessagesTotalCountQueryKey>({
      query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
      filters: {
        messagesFilters: {
          unread: true,
          withoutHoursAndMinutes: true
        }
      },
      cacheKey: MessageCache.unreadTotalCountCacheKey(),
      options: {
        enabledPlaceholder: withUnreadMessagesCount,
        enabled: withUnreadMessagesCount
      }
    });

  const { data: onlyClientsMessagesData } =
    useTotalsQuery<OnlyClientsMessagesTotalCountQueryKey>({
      query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
      filters: {
        messagesFilters: {
          unread: true,
          onlyClients: true,
          withoutHoursAndMinutes: true
        }
      },
      cacheKey: MessageCache.onlyClientsTotalCountCacheKey(),
      options: {
        enabledPlaceholder: withOnlyClientsMessagesCount,
        enabled: withOnlyClientsMessagesCount
      }
    });

  const { data: projectsInvitationsData } =
    useTotalsQuery<ProjectMemberInvitesTotalCountQueryKey>({
      query: FETCH_PROJECT_MEMBER_INVITES_TOTAL_COUNT_QUERY,
      filters: {
        projectMemberInvitesFilters: {
          invitedUserNanoId: currentUser.nanoId,
          projectStatusNe: [ProjectStatuses.CANCELED, ProjectStatuses.DONE]
        }
      },
      cacheKey: ProjectMemberInviteCache.totalCountCacheKey(),
      options: {
        enabledPlaceholder: withProjectsInvitationsCount,
        enabled: withProjectsInvitationsCount
      }
    });

  return {
    // burningTasksTotalCount:
    //   burningTasksData?.tasks?.paginationInfo?.totalCount || 0,
    // myTasksTotalCount: myTasksData?.tasks?.paginationInfo?.totalCount || 0,
    tasksResultsTotalCount:
      tasksResultsData?.tasks?.paginationInfo?.totalCount || 0,
    // tasksWithoutSourceFilesTotalCount:
    //   tasksWithoutSourceFilesData?.tasks?.paginationInfo?.totalCount || 0
    tasksInvitationsTotalCount:
      tasksInvitationsData?.taskMemberInvites?.paginationInfo?.totalCount || 0,
    projectsInvitationsTotalCount:
      projectsInvitationsData?.projectMemberInvites?.paginationInfo
        ?.totalCount || 0,
    unreadMessagesTotalCount:
      unreadMessagesData?.messages?.paginationInfo?.totalCount || 0,
    onlyClientsMessagesTotalCount:
      onlyClientsMessagesData?.messages?.paginationInfo?.totalCount || 0
  };
}

export default useCompactWorkspaceSecondaryMenuTotals;
