import { IconProps } from '../types';

function VraySolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5 rounded h-6 w-6'}
      viewBox="0 0 40 40"
      fill="currentColor"
    >
      <path fill="#2857AF" d="M40 0H0V40H40V0Z" clipRule="evenodd" />
      <path
        fill="white"
        d="M16.3168 24.2837C16.5835 24.667 16.9002 24.8837 17.1668 24.867C17.4835 24.8503 17.8335 24.5337 18.1002 24.0503C18.9502 22.467 19.5001 20.917 20.0335 19.4337C20.7835 17.3337 21.5502 15.167 23.1002 13.117C24.7335 10.9837 27.3168 10.1503 28.2668 9.91699C28.4168 9.88366 28.4502 9.70032 28.3335 9.60032C26.0335 7.76699 23.1502 6.66699 20.0002 6.66699C18.4501 6.66699 16.9668 6.93366 15.5835 7.41699C13.8835 8.01699 12.7335 9.61699 12.6835 11.417C12.6335 14.917 13.1835 19.9003 16.3168 24.2837Z"
        clipRule="evenodd"
      />
      <path
        fill="white"
        d="M28.7168 13.0003C27.3002 13.1837 26.2502 14.2003 25.6335 15.017C24.3835 16.6837 23.7502 18.4337 23.0168 20.4837C22.4335 22.0837 21.8502 23.7503 20.8835 25.5503C20.0835 27.0337 18.8002 28.5503 17.3668 28.6503C17.2835 28.6503 17.2168 28.6503 17.1335 28.6503C15.8335 28.6503 14.5835 27.3503 13.7168 26.117C10.5002 21.6003 9.60016 16.667 9.50016 12.8503C9.50016 12.5003 9.05016 12.3837 8.85016 12.667C6.95016 15.4337 6.06683 19.3003 7.05016 23.2003C8.30016 28.1837 12.3335 31.9337 16.9668 32.967C25.6335 34.917 33.3168 28.317 33.3168 19.967C33.3168 18.2837 33.0002 16.6837 32.4335 15.2003C32.0168 14.117 31.1002 13.2837 29.9668 13.0337C29.5668 12.967 29.1335 12.9503 28.7168 13.0003Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default VraySolidIcon;
