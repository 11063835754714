import cl from 'classnames';
import isObject from 'lodash/isObject';

import {
  ClassName,
  ErrorMessage,
  I18nText,
  IsDisabled
} from '../../../../../types';

import {
  RadioButtonInput,
  RadioButtonOptionType,
  RadioButtonValueType
} from '../../../../RadioButtonInput';

import { Translate } from '../../../../Translate';

export type RadioButtonsFieldControlCommonProps<ValueType> = {
  className?: ClassName;
  disabled?: IsDisabled;
  labelClassName?: ClassName;
  options: ValueType[];
  optionClassName?: ClassName;
  radioButtonLabelClassName?: ClassName;
};

type RadioButtonsFieldControlProps<ValueType> = {
  error?: ErrorMessage;
  errorClassName?: ClassName;
  i18nLabel?: I18nText;
  label?: string;
  name: string;
  onChange: (value: RadioButtonValueType) => void;
  value?: RadioButtonValueType;
} & RadioButtonsFieldControlCommonProps<ValueType>;

function RadioButtonsFieldControl<ValueType extends RadioButtonOptionType>({
  className,
  disabled,
  error,
  errorClassName,
  i18nLabel,
  label,
  labelClassName,
  name,
  onChange,
  options = [],
  optionClassName,
  radioButtonLabelClassName,
  value
}: RadioButtonsFieldControlProps<ValueType>) {
  return (
    <div className={className || 'flex flex-col gap-2'}>
      <label className={labelClassName}>
        {i18nLabel ? <Translate id={i18nLabel} /> : label}
      </label>

      <div className="flex">
        {options.map((option, index) => (
          <RadioButtonInput
            disabled={disabled}
            key={index}
            labelClassName={radioButtonLabelClassName}
            name={name}
            onChange={onChange}
            i18nText={isObject(option) ? option.label : undefined}
            option={isObject(option) ? option.value : option}
            optionClassName={optionClassName}
            value={value}
          />
        ))}

        {error && (
          <p className={cl(errorClassName || 'text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </div>
    </div>
  );
}

export default RadioButtonsFieldControl;
