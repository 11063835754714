import {
  payButtonId,
  payButtonContainerId,
  paymentNetworksId
} from './payoneerCheckoutPaymentConstants';
import { PayoneerCheckoutPaymentBeforeOtherWidgetRun } from './PayoneerCheckoutPayment.types';

interface PayoneerCheckoutPaymentRunWidgetOptions {
  listUrl: string;
  theme: string;
  widgetId: string;
  beforeOtherWidgetRun?: PayoneerCheckoutPaymentBeforeOtherWidgetRun;
}

class PayoneerCheckoutPayment {
  static beforeOtherWidgetRun: () => void | undefined;
  static currentWidgetId: string;

  static runWidget({
    listUrl,
    theme,
    widgetId,
    beforeOtherWidgetRun
  }: PayoneerCheckoutPaymentRunWidgetOptions): void {
    if (PayoneerCheckoutPayment.currentWidgetId !== widgetId) {
      PayoneerCheckoutPayment.beforeOtherWidgetRun?.();
      PayoneerCheckoutPayment.currentWidgetId = widgetId;
    }
    PayoneerCheckoutPayment.beforeOtherWidgetRun = beforeOtherWidgetRun;

    import('op-payment-widget-v3').then((imported) =>
      imported.default(paymentNetworksId, {
        payButton: payButtonId,
        payButtonContainer: payButtonContainerId,
        widgetCssUrl:
          theme === 'dark'
            ? window.location.origin + '/css/payoneer-checkout-widget-dark.css'
            : window.location.origin +
              '/css/payoneer-checkout-widget-light.css',
        listUrl,
        smartSwitch: true
      })
    );
  }
}

export default PayoneerCheckoutPayment;
