import { graphQLReadClient } from '../../graphQLClients';

import {
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchItemsProps {
  query: FetchItemsGqlQuery;
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  page: FetchItemsPage;
  limit: FetchItemsLimit;
}

export function fetchReadItems({
  query,
  filters,
  sort,
  page,
  limit
}: FetchItemsProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchReadItems',
    { filters, sort, page, limit },
    rawName,
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  graphQLReadClient.addQueryParams(name, requestId);

  return graphQLReadClient.request(
    query,
    {
      filters,
      sort,
      limit,
      offset: (page - 1) * limit
    },
    { requestId }
  );
}
