const defaultItemTypesBasePath = '/default-item-types';
const indexItemTypesBasePath = '/item-types';

export class ItemTypePath {
  static index() {
    return indexItemTypesBasePath;
  }

  static default() {
    return defaultItemTypesBasePath;
  }
}
