import { IconProps } from '../types';

function FilterListSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-5 w-5'}
      fill="currentColor"
      id={id}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 16.4l-1.3 1.3c-.3.3-.7.4-1.1.2S7 17.4 7 17v-5.6L2.3 6.7C2.1 6.5 2 6.3 2 6V3c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v3c0 .3-.1.5-.3.7L14.4 8H11c-.6 0-1 .4-1 1v7.4zm1-.4c0 .6.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1zm0-3c0 .6.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1zm0-3c0 .6.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default FilterListSolidIcon;
