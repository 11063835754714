import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../main/categories/categoriesTypes';
import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../MultiSelectField';
import { MultiSelectDataType } from '../../MultiSelect/types';

import {
  FetchProfileCategoriesQueryResponse,
  FETCH_PROFILE_CATEGORIES_QUERY
} from '../../../main/users/queries/fetchUserProfileCategories.query';

import { useFinPaginatedCategories } from '../../../main/categories/hooks/useFinPaginatedCategories';

import { StateMultiSelectFieldControl } from '../StatesSelectField/components/StateMultiSelectFieldControl';
import { ProfileMultiSelectValueType } from '../../../main/teams/helpers/ProfileMultiSelectField/components/ProfileMultiSelectFieldControl';

import { CategoryCache } from '../../../main/categories/CategoryCache';

const initialFilters = {
  scope: { eq: FetchCategoriesScopes.STATES }
};

interface StatesNameSelectFieldProps {
  withSecondNewAvPrefix?: boolean;
}

function StatesNameSelectField<FormDataType>({
  control,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error,
  defaultValue,
  withSecondNewAvPrefix
}: StatesNameSelectFieldProps &
  MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType>) {
  const { categories: states } =
    useFinPaginatedCategories<FetchProfileCategoriesQueryResponse>({
      query: FETCH_PROFILE_CATEGORIES_QUERY,
      cacheKey: CategoryCache.categoriesSelectFieldCacheKey(
        FetchCategoriesScopes.STATES
      ),
      initialFilters,
      initialLimit: 1000,
      initialSort: [FetchCategoriesSortTypes.NAME_ASC]
    });

  const statesOptions = useMemo<MultiSelectDataType[]>(
    () =>
      states.map((cat) => ({
        label: cat.localizedName,
        value: cat.localizedName
      })),
    [states]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <StateMultiSelectFieldControl
          data={statesOptions}
          defaultValue={defaultValue}
          disabled={disabled}
          error={fieldError?.message || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          labelClassName={labelClassName}
          multi={false}
          onChange={onChange}
          value={value as ProfileMultiSelectValueType}
          name={name}
          classNamePrefix={
            withSecondNewAvPrefix
              ? fieldError
                ? 'second_new_av_error'
                : 'second_new_av'
              : 'av'
          }
          inputWrapperClassName="w-full"
          isSearchable
        />
      )}
    />
  );
}

export default StatesNameSelectField;
