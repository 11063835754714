import { IconProps } from '../types';

function MaterialOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM3 9.7L9.7 3M3.1 15.2L15.3 3M3.6 20.4L20.4 3.6M8.7 21L21 8.7M14.3 21l6.7-6.7m-11.8.5L6.3 12m11.4 5.7l-2.8-2.8m2.8-2.9l-2.8-2.8m-5.7 0L6.3 6.3"
      />
    </svg>
  );
}

export default MaterialOutlineIcon;
