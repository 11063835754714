import {
  FetchLifestylesPathQueryFilters,
  LifestyleCategoryID,
  LifestyleClientID,
  LifestyleNanoID,
  LifestylesFiltersNavBasePath
} from './lifestylesTypes';

const basePath = '/lifestyles';
const libraryBasePath = '/library/lifestyles';

export class LifestylePath {
  static index() {
    return basePath;
  }

  static premium() {
    return `${basePath}/premium`;
  }

  static free() {
    return `${basePath}/free`;
  }

  static libraryIndex() {
    return libraryBasePath;
  }

  static show(nanoId: LifestyleNanoID) {
    return `${basePath}/${nanoId}`;
  }

  static edit(nanoId: LifestyleNanoID) {
    return `${basePath}/${nanoId}/edit`;
  }

  static libraryUser(clientId: LifestyleClientID) {
    return `${libraryBasePath}/${clientId}`;
  }

  static indexFiltersQuery(filters: FetchLifestylesPathQueryFilters) {
    return `${basePath}?filters=${JSON.stringify(filters)}`;
  }
  static indexFiltersCustomBaseQuery(
    customBasePath: LifestylesFiltersNavBasePath,
    filters: FetchLifestylesPathQueryFilters
  ) {
    return `${customBasePath}?filters=${JSON.stringify(filters)}`;
  }

  static lifestylesCategory(id: LifestyleCategoryID) {
    return `${basePath}/categories/${id}`;
  }

  static create() {
    return `${basePath}/create`;
  }
}
