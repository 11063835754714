import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { words } from '../../../locales/keys';

export enum ColorSchemeEnum {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum SelectedColorSchemeEnum {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system'
}

const fullNameThemes = {
  light: words.colorSchemeLight,
  dark: words.colorSchemeDark,
  system: words.system
};

function getPreferredTheme() {
  return window.matchMedia('(prefers-color-scheme: light)').matches
    ? ColorSchemeEnum.LIGHT
    : ColorSchemeEnum.DARK;
}

function getSelectedThemeFromTheme(
  theme: ColorSchemeEnum
): SelectedColorSchemeEnum {
  return theme === ColorSchemeEnum.LIGHT
    ? SelectedColorSchemeEnum.LIGHT
    : SelectedColorSchemeEnum.DARK;
}

function getThemeFromSelectedTheme(
  selectedTheme: SelectedColorSchemeEnum
): ColorSchemeEnum {
  switch (selectedTheme) {
    case SelectedColorSchemeEnum.DARK:
      return ColorSchemeEnum.DARK;
    case SelectedColorSchemeEnum.LIGHT:
      return ColorSchemeEnum.LIGHT;
    case SelectedColorSchemeEnum.SYSTEM:
      return getPreferredTheme();
    default:
      return ColorSchemeEnum.LIGHT;
  }
}

const darkThemeClassName = 'dark';
const themeLocalStorageKey = 'theme';
const selectedThemeLocalStorageKey = 'selectedTheme';

const ThemeContext = createContext({
  theme: null,
  selectedTheme: null,
  themeNameTranslation: null,
  setThemeHandler: (selectedTheme: SelectedColorSchemeEnum) => {
    console.log(
      `error: setThemeHandler should be initialized. ${selectedTheme}`
    );
  }
});

interface MenuProviderProps {
  children: ReactNode;
}

export function ThemeProvider({ children }: MenuProviderProps) {
  const [themeNameTranslation, setThemeNameTranslation] = useState<string>('');
  const [theme, setTheme] = useState<ColorSchemeEnum>(ColorSchemeEnum.LIGHT);
  const [selectedTheme, setSelectedTheme] = useState<SelectedColorSchemeEnum>(
    SelectedColorSchemeEnum.LIGHT
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localStorageSelectedTheme = localStorage.getItem(
        selectedThemeLocalStorageKey
      ) as SelectedColorSchemeEnum | undefined;

      const preferredTheme = getSelectedThemeFromTheme(getPreferredTheme());
      const selectedTheme =
        localStorageSelectedTheme ||
        preferredTheme ||
        SelectedColorSchemeEnum.LIGHT;
      const theme = getThemeFromSelectedTheme(selectedTheme);

      if (theme === ColorSchemeEnum.DARK) {
        document.documentElement.classList.add(darkThemeClassName);
      } else {
        document.documentElement.classList.remove(darkThemeClassName);
      }

      setThemeNameTranslation(fullNameThemes[selectedTheme]);
      setSelectedTheme(selectedTheme);
      setTheme(theme);
    }
  }, []);

  const updateThemeState = useCallback<
    (selectedTheme: SelectedColorSchemeEnum) => void
  >((selectedTheme) => {
    const theme = getThemeFromSelectedTheme(selectedTheme);
    setTheme(theme);
    localStorage.setItem(themeLocalStorageKey, theme);

    setSelectedTheme(selectedTheme);
    localStorage.setItem(selectedThemeLocalStorageKey, selectedTheme);

    setThemeNameTranslation(fullNameThemes[selectedTheme]);
  }, []);

  const setDarkTheme = useCallback<() => void>(() => {
    document.documentElement.classList.add(darkThemeClassName);
    updateThemeState(SelectedColorSchemeEnum.DARK);
  }, [updateThemeState]);

  const setLightTheme = useCallback<() => void>(() => {
    document.documentElement.classList.remove(darkThemeClassName);
    updateThemeState(SelectedColorSchemeEnum.LIGHT);
  }, [updateThemeState]);

  const setSystemTheme = useCallback<() => void>(() => {
    const preferredTheme = getPreferredTheme();

    if (preferredTheme === ColorSchemeEnum.DARK) {
      document.documentElement.classList.add(darkThemeClassName);
    } else {
      document.documentElement.classList.remove(darkThemeClassName);
    }

    updateThemeState(SelectedColorSchemeEnum.SYSTEM);
  }, [updateThemeState]);

  const setThemeHandler = useCallback<
    (selectedTheme: SelectedColorSchemeEnum) => void
  >(
    (selectedTheme) => {
      if (selectedTheme === SelectedColorSchemeEnum.SYSTEM) {
        setSystemTheme();
      } else if (selectedTheme === SelectedColorSchemeEnum.DARK) {
        setDarkTheme();
      } else {
        setLightTheme();
      }
    },
    [setDarkTheme, setLightTheme, setSystemTheme]
  );

  const themeContext = {
    theme,
    selectedTheme,
    themeNameTranslation,
    setThemeHandler
  };

  return (
    <ThemeContext.Provider value={themeContext}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => {
  return useContext(ThemeContext);
};
