import { MouseEventHandler } from 'react';
import { MultiValueProps } from 'react-select';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { MultiSelectExtendedDataType } from '../../types';

import { MultiValue } from '../MultiValue';

function SortableMultiValue(
  props: MultiValueProps<MultiSelectExtendedDataType>
) {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const innerProps = { ...props.innerProps, onMouseDown };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.data.value
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <MultiValue {...props} innerProps={innerProps} />
    </div>
  );
}

export default SortableMultiValue;
