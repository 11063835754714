import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TagsKeyType {
  select: LocalesKey;
}

export const transactionTagsKeys = keyPathMirror<TagsKeyType, string>(
  {
    select: null
  },
  'models.transactionTags.'
);
