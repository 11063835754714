import { FiltersViewGroups } from '../../../types';

export type ExtendedFiltersBaseFilters = Record<string, any>;

export type ExtendedFiltersGeneratedViewGroup = string;

export type ExtendedFiltersViewGroup =
  | FiltersViewGroups
  | ExtendedFiltersGeneratedViewGroup;

export const enum ExtendedFiltersGroups {
  PAGE = 'page',
  TAB = 'tab',
  SETTINGS = 'settings',
  SELECTED = 'selected'
}
export type ExtendedFiltersContextState<T = ExtendedFiltersBaseFilters> =
  Partial<Record<ExtendedFiltersGroups, T>>;

export type ExtendedFiltersContextStateByViewGroup = Partial<
  Record<ExtendedFiltersViewGroup, ExtendedFiltersContextState>
>;

export type ExtendedFiltersContextStateByViewGroupRef = {
  current: ExtendedFiltersContextStateByViewGroup;
};

export type ExtendedFiltersAllGroups<T = ExtendedFiltersBaseFilters> = Partial<
  Record<ExtendedFiltersGroups, T>
>;

export type ExtendedFiltersContextChangeStateByViewGroupAction = (
  viewGroup: ExtendedFiltersViewGroup,
  filtersState: ExtendedFiltersContextState
) => void;

export interface ExtendedFiltersContext {
  filtersStateByViewGroup: ExtendedFiltersContextStateByViewGroup;
  filtersStateByViewGroupRef: ExtendedFiltersContextStateByViewGroupRef;
  changeFiltersStateByViewGroup: ExtendedFiltersContextChangeStateByViewGroupAction;
}

export type ExtendedFiltersOnFiltersStateChange<
  T = ExtendedFiltersBaseFilters
> = (options: {
  prevState: ExtendedFiltersContextState<T>;
  nextState: ExtendedFiltersContextState<T>;
}) => ExtendedFiltersContextState<T>;
