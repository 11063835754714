import {
  FetchTeamCacheKey,
  FetchTeamGqlQuery,
  TeamNanoID,
  TeamUUID
} from '../../teamsTypes';

import { useFinShowQuery } from '../../../common/hooks/base/reactQuery/useFinShowQuery';

interface TeamResponse<ItemType> {
  team: ItemType;
}

interface TeamOptions {
  cacheKey: FetchTeamCacheKey;
  query: FetchTeamGqlQuery;
  uuid: TeamUUID | TeamNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    keepPreviousData?: boolean;
    staleTime?: number;
  };
}

interface useFinTeamItem {
  uuid: TeamUUID;
}

const itemKey = 'team';

function useFinTeam<ItemType extends useFinTeamItem>({
  cacheKey,
  query,
  uuid,
  options = {}
}: TeamOptions) {
  const {
    item,
    itemErrorData,
    itemError,
    itemLoading,
    itemFetched,
    itemIsPlaceholderData
  } = useFinShowQuery<TeamResponse<ItemType>, ItemType>({
    cacheKey,
    itemKey,
    query,
    uuid,
    options,
    placeholderData: null
  });

  return {
    team: item,
    teamErrorData: itemErrorData,
    teamError: itemError,
    teamLoading: itemLoading,
    teamFetched: itemFetched,
    teamIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useFinTeam;
