import React, { useState, useCallback } from 'react';
import includes from 'lodash/includes';

import { TeamPreferredPaymentMethod } from '../../../../../../teams/teamsTypes';
import { InvoicePaymentMethods } from '../../../../../invoicesTypes';
import {
  AddFundsModalButtonData,
  AddFundsModalButtonPaymentMethodAndAmountStepAction,
  AddFundsModalButtonCloseAction
} from '../../AddFundsModalButton.types';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { AddFundsModalButtonPaymentMethodSelect } from '../../helpers/AddFundsModalButtonPaymentMethodSelect';
import { AddFundsModalButtonGeneralInfo } from '../../helpers/AddFundsModalButtonGeneralInfo';

import { words } from '../../../../../../../locales/keys';

interface AddFundsModalButtonPaymentMethodAndAmountStepProps {
  companyCashBalance: number;
  companyPreferredPaymentMethod: TeamPreferredPaymentMethod;
  addFundsData: AddFundsModalButtonData;
  onPaymentMethodAndAmountStep: AddFundsModalButtonPaymentMethodAndAmountStepAction;
  onClose: AddFundsModalButtonCloseAction;
}

function AddFundsModalButtonPaymentMethodAndAmountStep({
  companyCashBalance,
  companyPreferredPaymentMethod,
  onPaymentMethodAndAmountStep,
  onClose
}: AddFundsModalButtonPaymentMethodAndAmountStepProps) {
  const [amount, setAmount] = useState<number>(0);

  const [paymentMethod, setPaymentMethod] = useState<InvoicePaymentMethods>(
    includes(
      [InvoicePaymentMethods.CARD, InvoicePaymentMethods.WIRE],
      companyPreferredPaymentMethod
    )
      ? (companyPreferredPaymentMethod as InvoicePaymentMethods)
      : null
  );

  const handleProceed = useCallback<() => void>(() => {
    onPaymentMethodAndAmountStep({ method: paymentMethod, amount });
  }, [amount, paymentMethod, onPaymentMethodAndAmountStep]);

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <div className="p-4 space-y-12">
          <AddFundsModalButtonGeneralInfo
            companyCashBalance={companyCashBalance}
            amount={amount}
            onAmountChange={setAmount}
          />

          <AddFundsModalButtonPaymentMethodSelect
            method={paymentMethod}
            onChange={setPaymentMethod}
          />
        </div>
      </div>
      <div className="flex p-4 space-x-1 justify-between">
        <div className="flex space-x-1">
          <PureButtonHelper
            i18nText={words.cancel}
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            onClick={onClose}
          />
        </div>
        <div className="flex space-x-1" />
        <div className="flex space-x-1">
          <PureButtonHelper
            i18nText={words.proceed}
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
            disabled={!amount || !paymentMethod}
            onClick={handleProceed}
          />
        </div>
      </div>
    </div>
  );
}

export default AddFundsModalButtonPaymentMethodAndAmountStep;
