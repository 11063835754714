import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface DownloadsKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
  toDownload: LocalesKey;
  failed: LocalesKey;
  preparing: LocalesKey;
  noDownloads: LocalesKey;
}

export const downloadsKeys = keyPathMirror<DownloadsKeyType, string>(
  {
    plural: null,
    singular: null,
    toDownload: null,
    failed: null,
    preparing: null,
    noDownloads: null
  },
  'models.downloads.'
);
