import React, { Fragment, memo } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../assets/icons/types';
import { UserNanoID } from '../../../../main/users/usersTypes';

import { useMenu } from '../../../hooks/useMenu';
import { useThirdPersonView } from '../../../hooks/useThirdPersonView';

import { useAuth } from '../../../../auth/hooks/useAuth';
import { usePrimaryMenuHover } from './hooks/usePrimaryMenuHover';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';
import { Icon } from '../../../../helpers/Icon';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { PrimaryNav } from '../PrimaryNav';
import { CompanyViewPrimaryNav } from '../CompanyViewPrimaryNav';
import { UserMenu, UserMenuTypes } from '../../UserMenu';

import { PrimaryMenuKeepSecondaryButton } from './components/PrimaryMenuKeepSecondaryButton';
import { PrimaryMenuMobilesToggleSecondaryMenu } from './components/PrimaryMenuMobilesToggleSecondaryMenu';

import { HelpCenterModalButton } from '../../modalButtons/HelpCenterModalButton';
import { ContactSupportModalButton } from '../../modalButtons/ContactSupportModalButton';

import { getAppIcon } from '../../../../app/utils/getAppIcon';

import { MainPath } from '../../../../main/MainPath';

import { AppPermissions } from '../../../appConstants';

import { words } from '../../../../locales/keys';

interface PrimaryMenuProps {
  onLogoutUser?: () => void;
  userNanoId?: UserNanoID;
}

function PrimaryMenu({ onLogoutUser, userNanoId }: PrimaryMenuProps) {
  const { isAuthenticated, currentUser } = useAuth();

  const {
    isSecondaryMenuKeepOpen,
    openSecondaryMenu,
    toggleKeepSecondaryMenu
  } = useMenu();

  const { isThirdPersonView, companyNanoId } = useThirdPersonView();

  const { handleMouseEnter, handleMouseLeave } = usePrimaryMenuHover({
    openSecondaryMenu
  });

  const { appIcon, appIconBgColorClassName } = getAppIcon();

  return (
    <div
      data-id="nav-primary-wrap"
      className="z-20 flex sm:flex-col h-10 sm:h-full w-full sm:w-16 border-t sm:border-t-0 sm:border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isAuthenticated ? (
        <div
          className={cl(
            'hidden sm:inline-flex h-16 w-16 items-center justify-center text-white',
            appIconBgColorClassName
          )}
        >
          {currentUser?.hasPermissions(
            AppPermissions.READ_MENU_DASHBOARD_LINK
          ) ? (
            <NextPureLinkHelper
              href={MainPath.index()}
              className="outline-none h-full w-full flex items-center justify-center"
              icon={appIcon}
            />
          ) : (
            <Icon icon={appIcon} />
          )}
        </div>
      ) : null}

      <nav
        data-id="nav-primary"
        className="flex-grow w-full md:flex-shrink-0 relative"
        aria-label="Sidebar"
      >
        <div className="absolute inset-0 flex flex-col sm:overflow-y-auto sm:overflow-x-hidden justify-between">
          <div className="flex sm:flex-col sm:py-3 sm:px-1 sm:space-y-1 h-full w-full sm:h-auto">
            <PrimaryMenuMobilesToggleSecondaryMenu />
            {isAuthenticated && isThirdPersonView && companyNanoId ? (
              <CompanyViewPrimaryNav
                companyNanoId={companyNanoId}
                userNanoId={userNanoId}
              />
            ) : null}

            {isAuthenticated && !isThirdPersonView ? <PrimaryNav /> : null}
            <div className="p-1 sm:hidden">
              {isAuthenticated && !isThirdPersonView && onLogoutUser ? (
                <UserMenu
                  type={UserMenuTypes.MOBILE}
                  onLogoutUser={onLogoutUser}
                  userNanoId={currentUser.nanoId}
                />
              ) : null}
            </div>
          </div>

          <div
            data-id="nav-primary-middle"
            className="flex-shrink-0 p-3 hidden sm:block"
          >
            <PrimaryMenuKeepSecondaryButton
              isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
              toggleKeepSecondaryMenu={toggleKeepSecondaryMenu}
            />
          </div>

          <div
            data-id="nav-primary-bottom"
            className="flex-shrink-0 py-3 hidden sm:block space-y-3"
          >
            <Fragment>
              {isAuthenticated && !isThirdPersonView && (
                <div className="px-3">
                  <TooltipSingletonSourceWrapper
                    placement={TooltipPlacement.TOP}
                    withArrow
                  >
                    <CheckPermissions
                      action={AppPermissions.READ_HELP_CENTER_MODAL_BUTTON}
                    >
                      <HelpCenterModalButton
                        icon={IconsEnum.QUESTION_MARK_CIRCLE_SOLID}
                        iconClassName="h-6 w-6 p-0.5"
                        tooltipI18nText={words.helpCenter}
                      />
                    </CheckPermissions>
                    <CheckPermissions
                      action={AppPermissions.READ_CONTACT_SUPPORT_MODAL_BUTTON}
                    >
                      <ContactSupportModalButton
                        isAuthenticated={isAuthenticated}
                        icon={IconsEnum.CONTACT_SUPPORT}
                        iconClassName="h-6 w-6 p-0.5"
                      />
                    </CheckPermissions>
                  </TooltipSingletonSourceWrapper>
                </div>
              )}
              <div className="profile-menu-tour">
                {isAuthenticated && !isThirdPersonView && (
                  <UserMenu
                    type={UserMenuTypes.DESKTOP}
                    onLogoutUser={onLogoutUser}
                    userNanoId={currentUser.nanoId}
                  />
                )}
              </div>
            </Fragment>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default memo<PrimaryMenuProps>(PrimaryMenu);
