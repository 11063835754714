import { IconProps } from '../types';

function PushpinOffOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.1 7.6l1.4-1-.7-.7c-.4-.4-.4-1 0-1.4L15.3 3c.4-.4 1-.4 1.4 0L21 7.3c.4.4.4 1 0 1.4l-1.4 1.4c-.4.4-1 .4-1.4 0l-.7-.7-1.1 1.5m.3 4.8l-2.1 2.1c-.4.4-1 .4-1.4 0l-7.1-7.1c-.4-.4-.4-1 0-1.4l2.1-2.1m1.5 7.1L4 20m16-1L5 4"
      />
    </svg>
  );
}

export default PushpinOffOutlineIcon;
