import { FiltersKeyType } from '../../keys';

const filtersKeys: FiltersKeyType = {
  booleanOptions: {
    any: 'any',
    false: 'false',
    true: 'true'
  },
  dateOptions: {
    exactDay: 'exact day',
    numberOfDaysAgo: 'number of days ago',
    numberOfDaysFromNow: 'number of days from now',
    oneMonthAgo: 'one month ago',
    oneMonthFromNow: 'one month from now',
    oneWeekAgo: 'one week ago',
    oneWeekFromNow: 'one week from now',
    theNextMonth: 'the next month',
    theNextNumberOfDays: 'the next number of days',
    theNextWeek: 'the next week',
    theNextYear: 'the next year',
    thePastMonth: 'the past month',
    thePastNumberOfDays: 'the past number of days',
    thePastWeek: 'the past week',
    thePastYear: 'the past year',
    thisCalendarMonth: 'this calendar month',
    thisCalendarWeek: 'this calendar week',
    thisCalendarYear: 'this calendar year',
    today: 'today',
    tomorrow: 'tomorrow',
    yesterday: 'yesterday'
  },
  enterDays: 'Enter days',
  enterFilterName: 'Enter filter name',
  saveCurrentFilters: 'Save current filters',
  modes: {
    contains: 'contains',
    containsAllOf: 'contains all of',
    containsAnyOf: 'contains any of',
    containsNoneOf: 'contains none of',
    containsOnly: 'contains only',
    doesNotContainAnyOf: "doesn't contain any of",
    endsWith: 'ends with',
    is: 'is',
    isAfter: 'is after',
    isAnyOf: 'is any of',
    isBefore: 'is before',
    isEmpty: 'is empty',
    isNoneOf: 'is none of',
    isNot: 'is not',
    isNotEmpty: 'is not empty',
    isOnOrAfter: 'is on or after',
    isOnOrBefore: 'is on or before',
    isWithin: 'is within',
    startsWith: 'starts with',
    greaterThanEqual: 'greater than equal',
    lessThanEqual: 'less than equal'
  }
};

export default filtersKeys;
