export default {
  code401errorText: 'Access denied',
  code401statusTextText:
    'Sorry, but only authorized users have access to this page.',
  code403errorText: 'Access denied',
  code403statusTextText: 'Sorry, but you do not have access to this page.',
  code404errorText: 'Page Not Found',
  code404statusTextText:
    'Sorry, but the page you are looking for has not been found.',
  code500errorText: 'Internal server error',
  code500statusTextText: 'Sorry, but an error has occurred.'
};
