import { WhiteboardsKeyType } from '../../keys';

const whiteboardsKeys: WhiteboardsKeyType = {
  canNotDeterminatePage: 'Can not determinate page',
  connectionLostTryingToReconnect: 'Connection lost. Trying to reconnect...',
  connectionSuccessfullyRestored: 'Connection successfully restored',
  couldNotRestoreTheConnection: 'Could not restore the connection',
  createMissingShapes: 'Create missing shapes',
  draftCommentIsNotVisibleToOtherProjectMembersUntilItIsPublished:
    'Draft comment is not visible to other project members until it is published',
  goTo: 'Go to whiteboard',
  imageAlreadyAttachedToThisTask: 'Image already attached to this task',
  markAsResolved: 'Mark as resolved',
  markAsUnresolved: 'Mark as unresolved',
  open: 'Open in whiteboard',
  page: 'Page',
  pageName: 'Page name',
  pleaseSelectAnImage: 'Please, select an image',
  preview: 'Preview',
  project: 'Project whiteboard',
  publishedCommentIsVisibleToAllProjectMembers:
    'Published comment is visible to all project members',
  records: 'records',
  select: 'Select whiteboard',
  selectPage: 'Select page',
  sendToTask: 'Send to task',
  sentToWhiteboard: 'Sent to whiteboard',
  shapes: 'Shapes',
  singularLowerCase: 'whiteboard',
  task: 'Task whiteboard',
  thereIsNoMissingShapes: 'There is no missing shapes',
  updateEventDate: 'Update event date',
  whiteboardBackups: 'Whiteboard backups',
  whiteboardDeleteEvents: 'Whiteboard delete events',
  whiteboardIsExportingToPdf: 'Whiteboard is exporting to PDF..',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  zoomTo100: 'Zoom to 100%',
  zoomToFit: 'Zoom to fit',
  zoomToSelection: 'Zoom to selection'
};

export default whiteboardsKeys;
