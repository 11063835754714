import { useCallback, useRef, useState, KeyboardEvent, useMemo } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import {
  MultiSelectChangeCallbackType,
  MultiSelectDataType,
  MultiSelectMixedDataType
} from '../../types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { MultiSelectValueType } from '../../MultiSelect.types';
import map from 'lodash/map';

interface MultiSelectOptions {
  data?: MultiSelectMixedDataType[];
  emptyValue?: MultiSelectDataType | null;
  menuIsOpen?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  optionsLoading?: boolean;
  onChange?: MultiSelectChangeCallbackType;
  value?: MultiSelectValueType;
}

function useMultiSelect({
  data,
  emptyValue,
  menuIsOpen,
  onMenuClose,
  onMenuOpen,
  optionsLoading,
  onChange,
  value
}: MultiSelectOptions) {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(menuIsOpen);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    onMenuClose?.();
  }, [setMenuOpen, onMenuClose]);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
    onMenuOpen?.();
  }, [setMenuOpen, onMenuOpen]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Escape' && isMenuOpen) {
        e.stopPropagation();
      }
    },
    [isMenuOpen]
  );

  const { t } = useTranslate();

  const localizeValue = useCallback(
    (value) =>
      isEmpty(value)
        ? null
        : {
            value: value.value,
            label: value.i18nLabel ? t(value.i18nLabel) : value.label
          },
    [t]
  );

  const dataWithEmptyValue = emptyValue
    ? compact(
        concat<MultiSelectMixedDataType | null>(localizeValue(emptyValue), data)
      )
    : data;

  const optionsLoadingRef = useRef(optionsLoading);
  optionsLoadingRef.current = optionsLoading;

  const handleOptionsLoadingMessage = useCallback(
    () => (optionsLoading ? t('words.searching') : t('words.noSearchResults')),
    [optionsLoading, t]
  );

  const handleFilterOption = useCallback(
    () => !optionsLoadingRef.current,
    [optionsLoadingRef]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active || !over || !Array.isArray(value)) return;

      const items = value as MultiSelectDataType[];

      const oldIndex = items.findIndex((item) => item.value === active.id);
      const newIndex = items.findIndex((item) => item.value === over.id);

      onChange(arrayMove(items, oldIndex, newIndex), {
        action: 'select-option'
      });
    },
    [onChange, value]
  );

  const sortableData = useMemo(() => {
    if (!Array.isArray(value)) return [];

    return map(value as MultiSelectDataType[], (item) => item?.value);
  }, [value]);

  return {
    dataWithEmptyValue,
    handleFilterOption,
    handleKeyDown,
    handleMenuClose,
    handleMenuOpen,
    handleOptionsLoadingMessage,
    localizeValue,
    handleDragEnd,
    sortableData
  };
}

export default useMultiSelect;
