import { JournalRecordImportRowId } from './journalRecordImportRowsTypes';

const journalRecordImportRowsBasePath = '/transaction-import-rows';

export class JournalRecordImportRowsPath {
  static journalRecordImportRows() {
    return journalRecordImportRowsBasePath;
  }

  static showJournalRecordImportRowsAccountingTransactions(
    journalRecordImportRowId: JournalRecordImportRowId
  ) {
    return `${journalRecordImportRowsBasePath}/${journalRecordImportRowId}`;
  }
}
