import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SelectedProductsKeyType {
  plural: LocalesKey;
  download: LocalesKey;
}

export const selectedProductsKeys = keyPathMirror<
  SelectedProductsKeyType,
  string
>(
  {
    plural: null,
    download: null
  },
  'models.selectedProducts.'
);
