export default {
  create: 'Create permission',
  delete: 'Delete permission',
  new: 'New permission',
  no: 'No permissions',
  plural: 'Permissions',
  pluralDowncase: 'permissions',
  permissionName: 'Permission name',
  scopes: {
    index: 'Index',
    create: 'Create',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
    download: 'Download'
  },
  search: 'Search permissions'
};
