import React, { Fragment, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import repeat from 'lodash/repeat';
import map from 'lodash/map';

import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingProps {
  children?: ReactNode;
  loaded?: boolean;
  count?: number;
}

function LoadingPrimaryMenuSkeleton({
  children,
  loaded = false,
  count = 3
}: LoadingProps) {
  return (
    <Fragment>
      {loaded ? (
        children
      ) : (
        <Fragment>
          {map(repeat('*', count), (v, index) => (
            <Skeleton
              key={index}
              count={1}
              containerClassName="inline-flex flex-shrink-0 items-center justify-center h-full sm:h-14 flex-grow sm:w-14 sm:rounded-lg sm:flex-grow-0 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 stroke-1.75"
              className="w-full h-full"
            />
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

export default LoadingPrimaryMenuSkeleton;
