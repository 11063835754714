import reduce from 'lodash/reduce';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import concat from 'lodash/concat';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import includes from 'lodash/includes';
import every from 'lodash/every';

const EXTENDABLE_ARRAY_FILTER_KEYS = ['notIn'];
const NARROWED_ARRAY_FILTER_KEYS = ['in'];

function mergeFilters<T extends Record<string, any>>({
  primary,
  secondary
}: {
  primary: T;
  secondary: T;
}): T {
  return mergeWith(
    {},
    primary,
    secondary,
    (primaryValue, secondaryValue, key) => {
      if (isNil(primaryValue)) {
        return secondaryValue || primaryValue;
      }
      if (isNil(secondaryValue)) {
        return primaryValue;
      }

      if (
        isArray(primaryValue) &&
        isArray(secondaryValue) &&
        includes(EXTENDABLE_ARRAY_FILTER_KEYS, key)
      ) {
        return concat(primaryValue, secondaryValue);
      }

      if (
        isArray(primaryValue) &&
        isArray(secondaryValue) &&
        includes(NARROWED_ARRAY_FILTER_KEYS, key) &&
        every(secondaryValue, (secondaryValueItem) =>
          includes(primaryValue, secondaryValueItem)
        )
      ) {
        return secondaryValue;
      }

      if (
        !isArray(primaryValue) &&
        !isArray(secondaryValue) &&
        isObject(primaryValue) &&
        isObject(secondaryValue)
      ) {
        return mergeFilters({
          primary: primaryValue,
          secondary: secondaryValue
        });
      }

      return primaryValue;
    }
  );
}

function mergeFiltersByPriority<T extends Record<string, any>>(
  filtersListByPriority: T[]
): T {
  return reduce<T, T>(
    filtersListByPriority,
    (acc, filters) => {
      return mergeFilters({ primary: acc, secondary: filters });
    },
    {} as T
  );
}

export default mergeFiltersByPriority;
