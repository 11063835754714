import { IconProps } from '../types';

function CommandOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-4 w-4'}
      fill="none"
      id={id}
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9l-3 6m10 0h-4m-8 4h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CommandOutlineIcon;
