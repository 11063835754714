import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import {
  FETCH_BILLING_INFOS_QUERY,
  FetchBillingInfosQueryResponse
} from '../../../../../../billingInfos/queries/fetchBillingInfos.query';

import { useFinPaginatedBillingInfos } from '../../../../../../billingInfos/hooks/useFinPaginatedBillingInfos';
import { BillingInfoCache } from '../../../../../../billingInfos/BillingInfoCache';

interface AddFundsModalButtonBillingInfosOptions {
  companyNanoId: TeamNanoID;
}

function useAddFundsModalButtonBillingInfos({
  companyNanoId
}: AddFundsModalButtonBillingInfosOptions) {
  const { billingInfos, billingInfosLoading, billingInfosErrorMessage } =
    useFinPaginatedBillingInfos<FetchBillingInfosQueryResponse>({
      cacheKey: BillingInfoCache.addFundsCacheKey(companyNanoId),
      initialLimit: 1000,
      initialFilters: {
        companyNanoId: { eq: companyNanoId }
      },
      query: FETCH_BILLING_INFOS_QUERY
    });

  return {
    billingInfos,
    billingInfosErrorMessage,
    billingInfosLoading
  };
}

export default useAddFundsModalButtonBillingInfos;
