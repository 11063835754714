import { USE_CONSOLE } from '../../config';

function productionConsole(
  action: 'log' | 'error' | 'warn' = 'log',
  ...args: unknown[]
) {
  if (USE_CONSOLE) {
    return console[action](...args);
  }

  return null;
}

export default productionConsole;
