import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { CreateItemUrl, CreateItemCacheKeys } from '../../../../../../types';

import {
  parseRequestError,
  ErrorType
} from '../../../../../../utils/parseRequestError';

import { BffApiRequest } from '../../../../../../utils/BffApiRequest';

interface BffCreateQueryOptions {
  url: CreateItemUrl;
  cacheKeys?: CreateItemCacheKeys;
}

export interface BffCreateQueryContext<ItemType> {
  previousData: ItemType;
}

function useBffCreateQuery<
  CreateQueryInput,
  CreateQueryResponse,
  CreateQueryError extends ErrorType,
  ItemType
>({ url, cacheKeys }: BffCreateQueryOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    CreateQueryResponse,
    CreateQueryError,
    CreateQueryInput,
    BffCreateQueryContext<ItemType>
  >(
    async (input: CreateQueryInput) => {
      try {
        const response = await BffApiRequest.post<CreateQueryResponse>(
          url,
          input
        );

        return response.data;
      } catch (err) {
        throw isEmpty(err?.response?.data?.error?.fullMessages)
          ? err
          : err?.response?.data?.error;
      }
    },
    {
      onSettled: () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    createQueryData: data,
    createQueryError: error,
    createQueryLoading: isLoading,
    createQueryErrorMessage: parseRequestError(error),
    createQuery: mutateAsync,
    createQueryReset: reset
  };
}

export default useBffCreateQuery;
