import { useState, useCallback } from 'react';

import { InvoicePaymentMethods } from '../../../../../invoicesTypes';

import {
  AddFundsModalButtonData,
  AddFundsModalButtonSteps,
  AddFundsModalButtonStepBackAction,
  AddFundsModalButtonPaymentMethodAndAmountStepAction,
  AddFundsModalButtonWireBillingInfoStepAction
} from '../../AddFundsModalButton.types';

const dataEmptyValue: AddFundsModalButtonData = {
  paymentMethodAndAmount: null,
  billingInfoAndInvoice: null
};

function getPreviousStepAddFunds(
  step: AddFundsModalButtonSteps,
  data: AddFundsModalButtonData
): AddFundsModalButtonData {
  if (step === AddFundsModalButtonSteps.WIRE_INVOICE_INFO) {
    return { ...data, billingInfoAndInvoice: null };
  }
  if (
    step === AddFundsModalButtonSteps.WIRE_BILLING_INFO ||
    step === AddFundsModalButtonSteps.CARD_BILLING_INFO_AND_CARD
  ) {
    return { ...data, paymentMethodAndAmount: null };
  }

  return dataEmptyValue;
}

function getPreviousStep(
  step: AddFundsModalButtonSteps
): AddFundsModalButtonSteps {
  if (
    step === AddFundsModalButtonSteps.WIRE_BILLING_INFO ||
    step === AddFundsModalButtonSteps.CARD_BILLING_INFO_AND_CARD
  ) {
    return AddFundsModalButtonSteps.PAYMENT_METHOD_AND_AMOUNT;
  }

  return AddFundsModalButtonSteps.PAYMENT_METHOD_AND_AMOUNT;
}

function useAddFundsModalButton() {
  const [addFundsStep, setAddFundsStep] = useState<AddFundsModalButtonSteps>(
    AddFundsModalButtonSteps.PAYMENT_METHOD_AND_AMOUNT
  );

  const [addFundsData, setAddFundsData] =
    useState<AddFundsModalButtonData>(dataEmptyValue);

  const handleResetAddFundsData = useCallback<() => void>(() => {
    setAddFundsData(dataEmptyValue);
    setAddFundsStep(AddFundsModalButtonSteps.PAYMENT_METHOD_AND_AMOUNT);
  }, []);

  const handlePaymentMethodAndAmountStep =
    useCallback<AddFundsModalButtonPaymentMethodAndAmountStepAction>(
      (paymentMethodAndAmount) => {
        setAddFundsData((prev) => ({ ...prev, paymentMethodAndAmount }));
        setAddFundsStep(
          paymentMethodAndAmount?.method === InvoicePaymentMethods.CARD
            ? AddFundsModalButtonSteps.CARD_BILLING_INFO_AND_CARD
            : AddFundsModalButtonSteps.WIRE_BILLING_INFO
        );
      },
      []
    );

  const handleWireBillingInfoStep =
    useCallback<AddFundsModalButtonWireBillingInfoStepAction>(
      (billingInfoAndInvoice) => {
        setAddFundsData((prev) => ({ ...prev, billingInfoAndInvoice }));
        setAddFundsStep(AddFundsModalButtonSteps.WIRE_INVOICE_INFO);
      },
      []
    );

  const handleStepBack = useCallback<AddFundsModalButtonStepBackAction>(() => {
    setAddFundsData((prev) => getPreviousStepAddFunds(addFundsStep, prev));
    setAddFundsStep(getPreviousStep(addFundsStep));
  }, [addFundsStep]);

  return {
    addFundsData,
    addFundsStep,
    handleResetAddFundsData,
    handlePaymentMethodAndAmountStep,
    handleWireBillingInfoStep,
    handleStepBack
  };
}

export default useAddFundsModalButton;
