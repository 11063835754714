import React, { Fragment, useState } from 'react';
import isNumber from 'lodash/isNumber';

import { I18nText } from '../../../../../../../types';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../../../../helpers/Translate';
import { NumberHelper } from '../../../../../../../helpers/NumberHelper';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';

interface UserFinMenuProfileInfoMoneyWithTooltipProps {
  value: number;
  tooltipI18nText: I18nText;
}

function UserFinMenuProfileInfoMoneyWithTooltip({
  value,
  tooltipI18nText
}: UserFinMenuProfileInfoMoneyWithTooltipProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!isNumber(value)) {
    return null;
  }

  return (
    <Fragment>
      <span ref={setReferenceTooltipElement}>
        <NumberHelper value={value} />
      </span>
      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.TOP}
        interactive={true}
        followCursor="horizontal"
        withArrow
      >
        <Translate id={tooltipI18nText} />
      </Tooltip>
    </Fragment>
  );
}

export default UserFinMenuProfileInfoMoneyWithTooltip;
