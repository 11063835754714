import {
  FetchItemGqlQuery,
  ID,
  NanoID,
  UUID
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLReadFinClient } from '../../graphQLClients';

interface FetchFinItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID | ID;
}

export function fetchFinItem({ query, uuid }: FetchFinItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole('log', 'fetchFinItem', uuid, { rawName, name });

  const requestId = generateUuid();

  graphQLReadFinClient.addQueryParams(name, requestId);

  return graphQLReadFinClient.request(query, { uuid }, { requestId });
}
