import React from 'react';
import cl from 'classnames';

import { IconProps } from '../types';

import { ProgressCircleIconCommonProps } from './ProgressCircleIcon.types';

type ProgressCircleIconProps = ProgressCircleIconCommonProps;

const fullCircle = 565.48;

function ProgressCircleIcon({
  className,
  id,
  color,
  progress
}: IconProps & ProgressCircleIconProps) {
  const offset = fullCircle - (fullCircle / 100.0) * progress;
  return (
    <svg
      id={id}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'absolute inset-0'}
      viewBox="0 0 200 200"
      strokeWidth="1.5em"
    >
      <defs>
        <clipPath id="mask">
          <circle cx="100" cy="100" r="90" />
        </clipPath>
      </defs>
      <g clipPath="url(#mask)" className="transform -rotate-90 origin-center">
        <circle
          className={cl({
            'text-gray-500': color === 'blue',
            'text-red-500': color === 'red'
          })}
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          stroke="currentColor"
          opacity="0.25"
        />
        <circle
          className={cl({
            'text-blue-500': color === 'blue',
            'text-red-500': color === 'red'
          })}
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset={`${offset}px`}
          stroke="currentColor"
        />
      </g>
    </svg>
  );
}

export default ProgressCircleIcon;
