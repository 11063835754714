import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface RolesKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  name: LocalesKey;
  search: LocalesKey;
  view: LocalesKey;
}

export const rolesKeys = keyPathMirror<RolesKeyType, string>(
  {
    list: null,
    plural: null,
    create: null,
    delete: null,
    name: null,
    search: null,
    view: null
  },
  'models.roles.'
);
