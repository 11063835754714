import { IconProps } from '../types';

function DocumentAudioSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M15.4 6L12 2.6c-.4-.4-.9-.6-1.4-.6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7.4c0-.5-.2-1-.6-1.4zM14 12.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5c.2 0 .3 0 .5.1V8.7l-4 1.6v4.1c0 .9-.7 1.6-1.5 1.6S6 15.3 6 14.5 6.7 13 7.5 13c.2 0 .3 0 .5.1V9c0-.2.1-.4.3-.5l5-2c.2-.1.3 0 .5 0 .1.2.2.3.2.5v5.5z"></path>
    </svg>
  );
}

export default DocumentAudioSolidIcon;
