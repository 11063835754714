import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../../types';

type ChangeAppLocaleFunction = (locale: Locale) => void;

interface UseChangeAppLocaleReturnType {
  changeAppLocale: ChangeAppLocaleFunction;
  currentLocale: Locale;
}

function useChangeAppLocale(): UseChangeAppLocaleReturnType {
  const { i18n } = useTranslation();

  const changeAppLocale = useCallback<ChangeAppLocaleFunction>(
    (locale) => {
      if (i18n.language !== locale) {
        i18n.changeLanguage(locale).then(() => {
          if (typeof document !== 'undefined') {
            document.documentElement.setAttribute('lang', locale);
          }
        });
      }
    },
    [i18n]
  );

  return { changeAppLocale, currentLocale: i18n.language as Locale };
}

export default useChangeAppLocale;
