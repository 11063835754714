import { IconProps } from '../types';

function CubesOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 10.509 14.368 L 5.755 12 L 1 14.368 M 10.509 14.368 L 5.755 16.736 M 10.509 14.368 L 10.509 20.288 L 5.755 22.656 M 5.755 16.736 L 1 14.368 M 5.755 16.736 L 5.755 22.656 M 1 14.368 L 1 20.288 L 5.755 22.656"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 23.009 14.368 L 18.254 12 L 13.5 14.368 M 23.009 14.368 L 18.254 16.736 M 23.009 14.368 L 23.009 20.288 L 18.254 22.656 M 18.254 16.736 L 13.5 14.368 M 18.254 16.736 L 18.254 22.656 M 13.5 14.368 L 13.5 20.288 L 18.254 22.656"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 16.478 3.368 L 11.724 1 L 6.969 3.368 M 16.478 3.368 L 11.724 5.736 M 16.478 3.368 L 16.478 9.288 L 11.724 11.656 M 11.724 5.736 L 6.969 3.368 M 11.724 5.736 L 11.724 11.656 M 6.969 3.368 L 6.969 9.288 L 11.724 11.656"
      />
    </svg>
  );
}

export default CubesOutlineIcon;
