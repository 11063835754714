import {
  FetchAccountsCacheKey,
  FetchFinAccountsFilters,
  FetchAccountsSort,
  FetchAccountsPage,
  FetchAccountsLimit,
  FetchAccountsGqlQuery,
  StatementPeriod
} from '../../accountsTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_ACCOUNTS_FILTERS,
  INITIAL_ACCOUNTS_LIMIT,
  INITIAL_ACCOUNTS_PAGE,
  INITIAL_ACCOUNTS_SORT
} from '../../accountsConstants';

interface AccountsOptions {
  cacheKey: FetchAccountsCacheKey;
  query: FetchAccountsGqlQuery;
  initialFilters?: FetchFinAccountsFilters;
  initialSort?: FetchAccountsSort;
  initialPage?: FetchAccountsPage;
  initialLimit?: FetchAccountsLimit;
  additionalParams?: { statementPeriods: StatementPeriod[] };
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

const scope = 'accounts';

function useFinPaginatedAccounts<FetchAccountsAccountType>({
  cacheKey,
  initialFilters = INITIAL_ACCOUNTS_FILTERS,
  initialSort = INITIAL_ACCOUNTS_SORT,
  initialPage = INITIAL_ACCOUNTS_PAGE,
  initialLimit = INITIAL_ACCOUNTS_LIMIT,
  additionalParams,
  query,
  options = {}
}: AccountsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
    // prefetchItem
  } = useFinIndexQuery<FetchAccountsAccountType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    additionalParams,
    options
  });

  return {
    accountsData: data,
    accounts: items,
    accountsError: itemsError,
    accountsErrorMessage: itemsErrorMessage,
    accountsTotalCount: itemsTotalCount,
    accountsFetched: isFetched,
    accountsLoading: isLoading,
    accountsIsPlaceholderData: isPlaceholderData,
    accountsFilters: currentFilters,
    accountsSort: currentSort,
    accountsPage: currentPage,
    accountsLimit: currentLimit,
    updateAccountCache: updateItemCache,
    filterAccounts: filterItems,
    changeAccountsFilters: changeItemsFilters,
    clearAccountsFilters: clearItemsFilters,
    sortAccounts: sortItems,
    paginateAccounts: paginateItems,
    limitAccounts: limitItems,
    prefetchAccounts: prefetchItems
  };
}

export default useFinPaginatedAccounts;
