import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { PermissionUUID } from './permissionsTypes';

const basePath = '/permissions';

export class PermissionPath {
  static index() {
    return basePath;
  }

  static show(permissionUuid: PermissionUUID) {
    return `${basePath}/${permissionUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
