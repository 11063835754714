import {
  DeleteDownloadsGqlQuery,
  DeleteDownloadsGqlStatus,
  DownloadGqlError,
  DownloadUUID
} from '../../downloadsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { DownloadCache } from '../../DownloadCache';

interface DeleteDownloadsInput {
  uuids: DownloadUUID[];
}

interface DeleteDownloadsResponse {
  deleteDownloads: {
    status: DeleteDownloadsGqlStatus;
    errors: DeleteDownloadsError;
  };
}

interface DeleteDownloadsError {
  fullMessages: DownloadGqlError;
}

interface DeleteDownloadsOptions {
  query: DeleteDownloadsGqlQuery;
}

const action = 'deleteDownloads';
const pluralScope = DownloadCache.indexCacheKey();

function useDeleteDownloads({ query }: DeleteDownloadsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DeleteDownloadsInput,
    DeleteDownloadsResponse,
    DeleteDownloadsError,
    undefined
  >({ action, pluralScope, query });

  return {
    deleteDownloadsData: updateQueryData,
    deleteDownloadsError: updateQueryError,
    deleteDownloadsLoading: updateQueryLoading,
    deleteDownloadsErrorMessage: updateQueryErrorMessage,
    deleteDownloads: updateQuery,
    deleteDownloadsReset: updateQueryReset
  };
}

export default useDeleteDownloads;
