const basePath = '/3d-stock';

export class StockPath {
  static index() {
    return basePath;
  }

  static products() {
    return `${basePath}/products`;
  }

  static lifestyles() {
    return `${basePath}/lifestyles`;
  }

  static materials() {
    return `${basePath}/materials`;
  }
}
