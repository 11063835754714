export default {
  acceptance: 'Готовые результаты',
  account: 'Счет задачи',
  add: 'Добавить задачу',
  add_body: 'Добавить описание задачи',
  addDescription: 'Добавить описание задачи',
  addTags: 'Добавить тэги задачи',
  description: 'Описание задачи',
  burning: 'Горящие задачи',
  cancel: 'Отменить задачу',
  changeStatus: 'Изменить статус',
  count: 'Количество задач',
  create: 'Создать задачу',
  create_multiple: 'Создать несколько задач',
  create_new: 'Создать новую задачу',
  current: 'Текущая задача',
  details: 'Основная информация',
  download_excel: 'Скачать документ excel со списком всех задач',
  download_project_excel: 'Скачать документ excel со списком задач проекта',
  download_selected_excel: 'Скачать документ excel со списком выбранных задач',
  duplicate: 'Копировать задачи',
  displayOptions: 'Параметры отображения',
  edit_description: 'Редактировать описание задачи',
  edit_details: 'Редактировать основную информацию',
  enter_name: 'Введите название задачи',
  favorite: 'Избранные задачи',
  import: 'Импорт задач',
  inviteToMultipleTasks: 'Приглашение в несколько задач',
  inviteUser: 'Пригласить участника задачи',
  invitations: 'Приглашения',
  leave: 'Покинуть задачу',
  members: 'Участники задачи',
  members_lower_case: 'участники задачи',
  members_genitive_lower_case: 'участников задачи',
  multiple: 'Несколько задач',
  my: 'Мои задачи',
  name: 'Название задачи',
  name_a_z: 'Название задачи (А-Я)',
  name_z_a: 'Название задачи (Я-А)',
  new: 'Новая задача',
  'new...': 'Что нужно сделать?',
  no: 'Задач нет',
  pause: 'Приостановить задачу',
  plural: 'Задачи',
  pluralLowerCase: 'задачи',
  pluralGenitiveLowerCase: 'задач',
  plural_prepositional_lower_case: 'задачах',
  profile: 'Типовая задача',
  qa: 'Конроль качества',
  finishTasks: 'Завершить задачи',
  finishMultipleTasks: 'Завершить несколько задач',
  remove_from: 'Удалить из задачи',
  restore: 'Возобновить задачу',
  search: 'Поиск задач',
  searchByTaskName: 'Поиск по названию задачи',
  searchByProjectName: 'Поиск задач по названию или @пользователю',
  select: 'Выберите задачу...',
  selectUser: 'Выбрать участника задачи',
  selected_users: 'Выбранные участники задачи',
  set_cost: 'Установить стоимость задачи',
  sendInvoice: 'Отправить счет',
  setDueDate: 'Установить дату завершения задачи',
  show_all: 'Показать все задачи',
  show_done: 'Показать выполненные задачи',
  show_hidden: 'Показать скрытые задачи',
  show_visible: 'Показать видимые задачи',
  show_only_visible: 'Показать только видимые задачи',
  show_with_canceled: 'Показать c отмененными задачами',
  show_without_canceled: 'Показать без отмененных задач',
  show_with_done: 'Показать с выполненными задачами',
  show_without_done: 'Показать без выполненных задач',
  show_with_hidden: 'Показать также скрытые задачи',
  showHiddenTasks: 'Показать скрытые задачи',
  showCanceledTasks: 'Показать отмененные задачи',
  single: 'Одна задача',
  singular: 'Задача',
  singular_lower_case: 'задача',
  states: {
    acceptance: 'Приемка',
    canceled: 'Отменено',
    definition: 'Постановка',
    incompleteSpecification: 'Требует уточнения',
    paused: 'Приостановлено',
    performed: 'Завершено',
    qa: 'Контроль качества'
  },
  buttons: {
    accept: 'Принять',
    approve: 'Утвердить',
    cancel: 'Отменить задачу',
    checkIn: 'Check in',
    pause: 'Приостановить задачу',
    pendingMoreInfo: 'Требует уточнения',
    restore: 'Возобновить задачу',
    sendToRevision: 'Корректировка'
  },
  status: 'Статус задачи',
  status_change: 'Изменение статуса задачи',
  tableSettings: 'Настройки таблицы',
  tags: 'Теги задачи',
  tags_change: 'Изменение меток',
  taskName: 'Название задачи',
  task_timer: 'Таймер задачи',
  trackTimeInMultipleTasks: 'Отслеживайте время в нескольких задачах',
  team: 'Участники задачи',
  unassigned: 'Нерозданные задачи',
  view: 'Просмотреть задачу'
};
