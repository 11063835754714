import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface PreviewModelsKeyType {
  clickOnModel: LocalesKey;
  clickOutsideModel: LocalesKey;
  dragWithOneFinder: LocalesKey;
  dragWithTwoFingers: LocalesKey;
  fitToScreen: LocalesKey;
  focus: LocalesKey;
  lmbMouseMove: LocalesKey;
  mouseWheel: LocalesKey;
  pan: LocalesKey;
  pinchZoomGesture: LocalesKey;
  rmbMouseMove: LocalesKey;
  rotate: LocalesKey;
  tapOnModel: LocalesKey;
  tapOutsideModel: LocalesKey;
  zoom: LocalesKey;
}

export const previewModelsKeys = keyPathMirror<PreviewModelsKeyType, string>(
  {
    clickOnModel: null,
    clickOutsideModel: null,
    dragWithOneFinder: null,
    dragWithTwoFingers: null,
    fitToScreen: null,
    focus: null,
    lmbMouseMove: null,
    mouseWheel: null,
    pan: null,
    pinchZoomGesture: null,
    rmbMouseMove: null,
    rotate: null,
    tapOnModel: null,
    tapOutsideModel: null,
    zoom: null
  },
  'models.previewModels.'
);
