import { useCallback } from 'react';
import { QueryKey, useQuery, useQueryClient } from 'react-query';
import isUndefined from 'lodash/isUndefined';

function useReactQueryState<ValueType>(
  cacheKey: QueryKey,
  initialValue: ValueType = null
) {
  const queryClient = useQueryClient();

  const { data } = useQuery<ValueType>(
    cacheKey,
    () => {
      const cachedValue = queryClient.getQueryData<ValueType>(cacheKey);

      return isUndefined(cachedValue) ? initialValue : cachedValue;
    },
    { initialData: initialValue }
  );

  const clearValue = useCallback<() => void>(
    () => queryClient.setQueryData<ValueType>(cacheKey, null),
    [queryClient, cacheKey]
  );

  const setValue = useCallback<
    (
      valueOrUpdater:
        | ValueType
        | null
        | ((oldData: ValueType | null) => ValueType | null)
    ) => void
  >(
    (valueOrUpdater) =>
      queryClient.setQueryData<ValueType>(cacheKey, valueOrUpdater),
    [queryClient, cacheKey]
  );

  return {
    clearValue,
    setValue,
    value: data
  };
}

export default useReactQueryState;
