import { IconProps } from '../types';

function ArrowNarrowUpOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={className || 'h-6 w-6'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7l4-4m0 0l4 4m-4-4v18"
      />
    </svg>
  );
}

export default ArrowNarrowUpOutlineIcon;
