export default {
  author: 'Author',
  date: 'Date',
  progress: 'Progress',
  status: 'Status',
  statuses: {
    completed: 'Completed',
    failed: 'Failed',
    processing: 'Processing'
  },
  tasks: 'Tasks',
  type: 'Type',
  types: {
    batch_task_creation: 'Batch task creation',
    multiple_task_creation: 'Multiple task creation',
    batch_allocate_funds: 'Batch allocate funds',
    batch_transactions_import: 'Batch transactions import',
    batch_users_payoneer_ids_import: 'Batch users payoneer ids import',
    batch_revenue_share_items_import: 'Batch revenue share items import',
    batch_users_payoneer_ids_pe_import: 'Batch users payoneer ids pe import',
    batch_revenue_shares_import: 'Batch revenue shares import'
  }
};
