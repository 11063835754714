import compact from 'lodash/compact';

import { ClassName } from '../../../../types';

import { Files } from '../../../../utils/Files';

import { ImageItemImageVersions } from '../../../ImageHelper';

const colorStyles = [
  'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700',
  'bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900',
  'bg-orange-100 text-orange-800 dark:text-orange-50 dark:bg-orange-900',
  'bg-yellow-100 text-yellow-800 dark:text-yellow-50 dark:bg-yellow-900',
  'bg-lime-100 text-lime-800 dark:text-lime-50 dark:bg-lime-900',
  'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900',
  'bg-cyan-100 text-cyan-800 dark:text-cyan-50 dark:bg-cyan-900',
  'bg-blue-100 text-blue-800 dark:text-blue-50 dark:bg-blue-900',
  'bg-purple-100 text-purple-800 dark:text-purple-50 dark:bg-purple-900',
  'bg-pink-100 text-pink-800 dark:text-pink-50 dark:bg-pink-900'
];

interface ValueImageProps {
  image: string;
  label: string;
  className?: ClassName;
}

function ValueImage({ image, label, className }: ValueImageProps) {
  const colorStyle = colorStyles[label.charCodeAt(0).toString().slice(-1)];

  const text = compact(label.split(' '))
    .map((word, index) => (index < 2 ? word[0] : ''))
    .join('');

  return image ? (
    <img
      className={className}
      src={Files.image({ file: image }, ImageItemImageVersions.Thumb160x160)}
    />
  ) : (
    <div className={className} style={{ overflow: 'hidden' }}>
      <div
        className={`h-full w-full flex items-center justify-center ${colorStyle}`}
      >
        <span className="text-xs uppercase">{text}</span>
      </div>
    </div>
  );
}

export default ValueImage;
