import React, { ChangeEvent, FocusEvent, ForwardedRef, memo } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../types';

import { useTranslate } from '../../../common/hooks/useTranslate';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';

interface TextAreaFieldProps {
  autoFocus?: boolean;
  className?: ClassName;
  disabled?: IsDisabled;
  error?: string | null;
  errorClassName?: ClassName;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  id?: string;
  label?: string;
  labelClassName?: ClassName;
  name: string;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPointerDown?: (e: React.PointerEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  textAreaClassName?: string;
  textAreaWrapperClassName?: string;
  value?: string;
}

const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      autoFocus,
      className,
      disabled,
      error,
      errorClassName,
      i18nLabel,
      i18nPlaceholder,
      id,
      label,
      labelClassName,
      name,
      onBlur,
      onChange,
      onPointerDown,
      placeholder,
      required,
      rows,
      textAreaClassName,
      textAreaWrapperClassName,
      value
    }: TextAreaFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const { t } = useTranslate();

    return (
      <div className={className}>
        {i18nLabel || label ? (
          <label
            htmlFor={id || name}
            className={cl(
              labelClassName ||
                'block text-sm font-medium text-gray-700 dark:text-gray-300'
            )}
          >
            {i18nLabel ? <Translate id={i18nLabel} /> : label}
          </label>
        ) : null}

        <div className={cl(textAreaWrapperClassName || 'mt-1 relative')}>
          <textarea
            autoFocus={autoFocus}
            className={cl(textAreaClassName || 'basic-input', {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                error
            })}
            disabled={disabled}
            id={id || name}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onPointerDown={onPointerDown}
            placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
            ref={ref}
            required={required}
            rows={rows}
            value={value}
          />

          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <Icon
                className="h-5 w-5 text-red-500"
                icon={IconsEnum.EXCLAMATION_CIRCLE}
              />
            </div>
          )}
        </div>

        {error && (
          <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </div>
    );
  }
);

TextAreaField.displayName = 'TextAreaField';

export default memo(TextAreaField);
