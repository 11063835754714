import React, { memo } from 'react';
import Head from 'next/head';

function HeadMeta() {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
    </Head>
  );
}

export default memo(HeadMeta);
