import { IconProps } from '../types';

function TransferIncomingSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M15.6 8.7c.3.7.4 1.5.4 2.3 0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7c.8 0 1.6.1 2.3.4L9.7 6.1C9.5 6 9.2 6 9 6c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-.2 0-.5-.1-.7l1.7-1.6zm1.1-4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L8 10.6V9c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1s-.4-1-1-1H9.4l7.3-7.3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TransferIncomingSolidIcon;
