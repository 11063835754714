import React, { Fragment } from 'react';
import filesize from 'filesize';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

interface SizeHelperProps {
  size: number;
}

function SizeHelper({ size }: SizeHelperProps) {
  if (!isNumber(size) && !isString(size)) {
    return null;
  }

  return <Fragment>{filesize(size)}</Fragment>;
}

export default SizeHelper;
