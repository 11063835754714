import { IconProps } from '../types';

function AppleIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M7.8 18c-2.4 0-4.4-4.9-4.4-7.4 0-4 3-4.9 4.2-4.9.5 0 1.1.2 1.6.4.3.1.7.3.9.3.1 0 .4-.1.6-.2.5-.2 1.2-.5 2-.5.6 0 2.3.1 3.4 1.7l.2.4-.3.2c-.5.4-1.4 1.1-1.4 2.4 0 1.6 1 2.3 1.5 2.6.2.1.4.3.4.6 0 .2-1.6 4.4-3.8 4.4-.6 0-.9-.2-1.3-.3-.3-.1-.7-.3-1.1-.3-.3 0-.6.1-.9.2-.5.2-1 .4-1.6.4zM13 2c.1 2.1-1.5 3.6-3 3.5C9.7 3.8 11.5 2 13 2z" />
    </svg>
  );
}

export default AppleIcon;
