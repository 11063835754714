import { gql } from 'graphql-request';

import {
  DownloadID,
  DownloadUUID,
  DownloadCreatedAt,
  DownloadName,
  DownloadFile,
  DownloadStatus,
  DownloadProgress,
  DownloadType,
  DownloadSize,
  DownloadDeviceNanoId
} from '../downloadsTypes';

export interface FetchDownloadsQueryResponse {
  id: DownloadID;
  uuid: DownloadUUID;
  name: DownloadName;
  file: DownloadFile;
  status: DownloadStatus;
  progress: DownloadProgress;
  type: DownloadType;
  size: DownloadSize;
  deviceNanoId: DownloadDeviceNanoId;
  createdAt: DownloadCreatedAt;
}

export const FETCH_DOWNLOADS_QUERY = gql`
  query Downloads(
    $filters: DownloadsFilters
    $sort: [DownloadsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    downloads(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        name
        file
        status
        progress
        type
        size
        deviceNanoId
        createdAt
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
