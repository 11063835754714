import {
  InvoicePaymentMethods,
  InvoiceID,
  InvoiceCreatedAt,
  InvoiceDueDate,
  InvoiceNotes,
  InvoiceTerms
} from '../../../invoicesTypes';
import {
  BillingInfoID,
  BillingInfoCompanyName,
  BillingInfoContactPhone,
  BillingInfoContactEmail,
  BillingInfoCountry,
  BillingInfoState,
  BillingInfoCity,
  BillingInfoAddress,
  BillingInfoZipCode,
  BillingInfoPaymentMethod,
  BillingInfoFirstName,
  BillingInfoLastName,
  BillingInfoDefaultAt,
  BillingInfoVatNumber
} from '../../../../billingInfos/billingInfosTypes';
import {
  AvBillingInfoID,
  AvBillingInfoNanoID,
  AvBillingInfoName,
  AvBillingInfoCompanyName,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoContactEmail,
  AvBillingInfoCountry,
  AvBillingInfoState,
  AvBillingInfoCity,
  AvBillingInfoAddress,
  AvBillingInfoZipCode,
  AvBillingInfoBankDetails,
  AvBillingInfoNotes
} from '../../../../avBillingInfos/avBillingInfosTypes';

export interface AddFundsModalButtonDataBillingInfo {
  id: BillingInfoID;
  companyName: BillingInfoCompanyName;
  firstName: BillingInfoFirstName;
  lastName: BillingInfoLastName;
  contactPhone: BillingInfoContactPhone;
  contactEmail: BillingInfoContactEmail;
  country: BillingInfoCountry;
  state: BillingInfoState;
  city: BillingInfoCity;
  address: BillingInfoAddress;
  zipCode: BillingInfoZipCode;
  defaultAt: BillingInfoDefaultAt | null;
  paymentMethod?: BillingInfoPaymentMethod;
  vatNumber?: BillingInfoVatNumber;
  avBillingInfo: {
    id: AvBillingInfoID;
    nanoId: AvBillingInfoNanoID;
    name: AvBillingInfoName;
    companyName: AvBillingInfoCompanyName;
    contactName: AvBillingInfoContactName;
    contactPhone: AvBillingInfoContactPhone;
    contactEmail: AvBillingInfoContactEmail;
    country: AvBillingInfoCountry;
    state: AvBillingInfoState;
    city: AvBillingInfoCity;
    address: AvBillingInfoAddress;
    zipCode: AvBillingInfoZipCode;
    bankDetails: AvBillingInfoBankDetails;
    notes?: AvBillingInfoNotes;
  };
}

export interface AddFundsModalButtonDataInvoice {
  id: InvoiceID;
  createdAt: InvoiceCreatedAt;
  dueDate: InvoiceDueDate;
  notes?: InvoiceNotes;
  terms?: InvoiceTerms;
}

export interface AddFundsModalButtonDataMethodAndAmountInfo {
  method: InvoicePaymentMethods;
  amount: number;
}

export interface AddFundsModalButtonDataBillingInfoAndInvoice {
  billingInfo: AddFundsModalButtonDataBillingInfo;
  invoice: AddFundsModalButtonDataInvoice;
}

export interface AddFundsModalButtonData {
  paymentMethodAndAmount: AddFundsModalButtonDataMethodAndAmountInfo | null;
  billingInfoAndInvoice: AddFundsModalButtonDataBillingInfoAndInvoice | null;
}

export const enum AddFundsModalButtonSteps {
  PAYMENT_METHOD_AND_AMOUNT = 'payment_method_and_amount',
  WIRE_BILLING_INFO = 'wire_billing_info',
  CARD_BILLING_INFO_AND_CARD = 'billing_address_billing_info_and_card',
  WIRE_INVOICE_INFO = 'wire_invoice_info'
}

export type AddFundsModalButtonStepBackAction = () => void;

export type AddFundsModalButtonPaymentMethodAndAmountStepAction = (
  paymentMethodAndAmount: AddFundsModalButtonDataMethodAndAmountInfo
) => void;

export type AddFundsModalButtonWireBillingInfoStepAction = (
  billingInfoAndInvoice: AddFundsModalButtonDataBillingInfoAndInvoice
) => void;

export type AddFundsModalButtonCloseAction = () => void;
