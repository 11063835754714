export default {
  create: 'Создать доступ',
  plural: 'Доступы',
  pluralDowncase: 'доступы',
  new: 'Создать доступ',
  no: 'Нет доступов',
  delete: 'Удалить доступ',
  scopes: {
    index: 'Индекс',
    create: 'Создание',
    read: 'Считывание',
    update: 'Изменение',
    delete: 'Удаление',
    download: 'Скачивание'
  },
  search: 'Искать доступы'
};
