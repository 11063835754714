import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { ClassName } from '../../../types';
import { TooltipDelay, TooltipOffset } from '../types';

import { LazyTippy } from '../LazyTippy';

import { Translate } from '../../Translate';

import {
  followCursorPlugin,
  defaultDelay,
  defaultDuration,
  defaultOffset,
  defaultTouch,
  TooltipPlacement
} from '../tooltipsConstants';

interface TooltipBaseProps<T> {
  appendTo?: 'parent' | Element;
  className?: ClassName;
  delay?: TooltipDelay;
  duration?: number | [number | null, number | null];
  followCursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  interactive?: boolean;
  lazy?: boolean;
  maxWidth?: number | 'none';
  offset?: TooltipOffset;
  placement?: TooltipPlacement;
  referenceElement: T;
  visible?: boolean;
  withArrow?: boolean;
  zIndex?: number;
}

interface TooltipWithI18nProps {
  children?: never;
  tooltipI18nText: string;
  tooltipText?: never;
}

interface TooltipWithTextProps {
  children?: never;
  tooltipI18nText?: never;
  tooltipText: string;
}

interface TooltipWithChildrenProps {
  children: ReactNode;
  tooltipI18nText?: never;
  tooltipText?: never;
}

function Tooltip<T extends HTMLElement | null>({
  appendTo,
  children,
  className,
  delay,
  followCursor,
  interactive = false,
  lazy = false,
  maxWidth,
  offset = defaultOffset,
  placement = TooltipPlacement.BOTTOM,
  referenceElement,
  tooltipI18nText,
  tooltipText,
  visible,
  withArrow = false,
  zIndex
}: TooltipBaseProps<T> &
  (TooltipWithI18nProps | TooltipWithTextProps | TooltipWithChildrenProps)) {
  if (!tooltipI18nText && !tooltipText && !children) {
    return null;
  }

  if (lazy) {
    return (
      <LazyTippy
        visible={visible}
        reference={referenceElement}
        interactive={interactive}
        placement={placement}
        arrow={withArrow}
        delay={delay || defaultDelay}
        duration={defaultDuration}
        offset={offset}
        appendTo={appendTo}
        className={className}
        maxWidth={maxWidth}
        content={
          tooltipI18nText ? (
            <Translate id={tooltipI18nText} />
          ) : (
            tooltipText || children
          )
        }
        zIndex={zIndex}
        plugins={followCursor ? followCursorPlugin : undefined}
        touch={defaultTouch}
        {...(followCursor ? { followCursor } : {})}
      />
    );
  }

  return (
    <Tippy
      visible={visible}
      reference={referenceElement}
      interactive={interactive}
      placement={placement}
      arrow={withArrow}
      delay={delay || defaultDelay}
      duration={defaultDuration}
      offset={offset}
      appendTo={appendTo}
      className={className}
      maxWidth={maxWidth}
      content={
        tooltipI18nText ? (
          <Translate id={tooltipI18nText} />
        ) : (
          tooltipText || children
        )
      }
      zIndex={zIndex}
      plugins={followCursor ? followCursorPlugin : undefined}
      touch={defaultTouch}
      {...(followCursor ? { followCursor } : {})}
    />
  );
}

export default Tooltip;
