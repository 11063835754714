import { ReportSectionsKeyType } from '../../keys/models/reportSections';

const reportSections: ReportSectionsKeyType = {
  singular: 'Report section',
  plural: 'Report sections',
  create: 'Create report section',
  update: 'Update report section',
  delete: 'Delete report section',
  selectSection: 'Select section'
};

export default reportSections;
