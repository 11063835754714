import { IconProps } from '../types';

function WhiteboardSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-3.5 w-3.5'}
      id={id}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M18 4v9h-2V4H4v9H2V4c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2zM9 14v-2H5v2H2c0 1.1.9 2 2 2h1v1c0 .6.4 1 1 1s1-.4 1-1v-1h6v1c0 .6.4 1 1 1s1-.4 1-1v-1h1c1.1 0 2-.9 2-2H9zM6.6 8.8c.8-.6 1.3-.8 1.5-.9v.3c0 .6 0 1.4.7 1.9.3.3.7.4 1.2.4 1 0 2.4-.6 4.4-1.9.5-.3.6-.9.3-1.4-.3-.5-.9-.6-1.4-.3-2.1 1.4-3 1.6-3.2 1.6V8c0-.6 0-1.5-.8-2-1.2-.8-2.7.1-3.9 1-.4.5-.5 1.1-.2 1.6.3.4.9.5 1.4.2z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default WhiteboardSolidIcon;
