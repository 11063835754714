import React, { Fragment } from 'react';

import format from 'date-fns/format';

import { Locale } from '../../types';

import { dateFnsLocales } from '../../utils/dateFnsLocales';

interface DateHelperProps {
  customFormat?: string;
  date: Date | string | null;
  locale?: Locale;
  nullValue?: string;
}

function DateHelper({
  customFormat,
  date,
  locale = 'en',
  nullValue = null
}: DateHelperProps) {
  const localeObj = dateFnsLocales.getLocale(locale);

  const formatOptions = localeObj ? { locale: localeObj } : {};

  let formatedDate = nullValue;
  try {
    formatedDate = date
      ? format(new Date(date), customFormat || 'dd MMM yyyy', formatOptions)
      : nullValue;
  } catch {
    //
  }

  return <Fragment>{formatedDate}</Fragment>;
}

export default DateHelper;
