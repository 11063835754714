import { IconProps } from '../types';

function FlagOffAltOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3v18m18 0L3 3m17.3 12.3l1-.2c-.4-3.5-.4-7 0-10.5l-3.1.7c-2 .5-4.2.2-6.1-.7l-.1-.1c-1-.5-2.1-.8-3.2-.9M16 16c-1.3 0-2.7-.3-3.9-.9L12 15c-1.9-1-4.1-1.2-6.2-.7L3 15"
      />
    </svg>
  );
}

export default FlagOffAltOutlineIcon;
