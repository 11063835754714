export default {
  select: 'Выбор статуса',
  change: 'Изменить статус',
  set: 'Установить статус',
  types: {
    acceptance: 'Приемка',
    canceled: 'Отменено',
    checking: 'Проверка',
    definition: 'Постановка',
    discussion: 'Задача',
    distribution: 'Распределение',
    implementation: 'В работе',
    incompleteSpecification: 'Требует уточнения',
    paid: 'Оплачено',
    paused: 'Приостановлено',
    paymentWaiting: 'Ожидание оплаты',
    performed: 'Завершено',
    qa: 'Контроль качества'
  }
};
