import { TeamNanoID } from '../teams/teamsTypes';
import { BillingInfoID } from './billingInfosTypes';

export class BillingInfoCache {
  static showCacheKey(uuid: BillingInfoID) {
    return `billing-info-${uuid}`;
  }
  static teamCacheKey(teamNanoId: TeamNanoID) {
    return `billing-infos-team-${teamNanoId}`;
  }
  static addFundsCacheKey(teamNanoId: TeamNanoID) {
    return `billing-infos-add-funds-${teamNanoId}`;
  }
}
