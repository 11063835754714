import { useState, useEffect } from 'react';
import { NextRouter } from 'next/router';

import { PathUrl } from '../../../types';

import { isCompanyViewRoute } from '../../utils/isCompanyViewRoute';

export const MAIN_SCROLL_CACHE_KEY = 'mainScroll';

function useNonTpvLastPath(router: NextRouter) {
  const [nonTpvLastPath, setNonTpvLastPath] = useState<PathUrl>(null);

  useEffect(() => {
    setNonTpvLastPath((prev) =>
      isCompanyViewRoute(router) ? prev : router.asPath
    );
  }, [router]);

  return nonTpvLastPath;
}

export default useNonTpvLastPath;
