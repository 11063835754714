import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface NotificationsKeyType {
  plural: LocalesKey;
}

export const notificationsKeys = keyPathMirror<NotificationsKeyType, string>(
  {
    plural: null
  },
  'models.notifications.'
);
