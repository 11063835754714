import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ItemsKeyType {
  add: LocalesKey;
  activity: LocalesKey;
  areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction: LocalesKey;
  bind: LocalesKey;
  bindingStatus: LocalesKey;
  bindingStatuses: {
    postponed: LocalesKey;
    mixed: LocalesKey;
    binded: LocalesKey;
  };
  bound: LocalesKey;
  createDate: LocalesKey;
  createNewTag: LocalesKey;
  createItemTag: LocalesKey;
  deleteItemTag: LocalesKey;
  description: LocalesKey;
  groupSimilarItems: LocalesKey;
  hideCompleted: LocalesKey;
  id: LocalesKey;
  invoice: LocalesKey;
  itemTags: LocalesKey;
  itemTag: LocalesKey;
  inDraft: LocalesKey;
  mixed: LocalesKey;
  move: LocalesKey;
  moveTo: LocalesKey;
  name: LocalesKey;
  noScopeOfWorkYet: LocalesKey;
  payFromDeposit: LocalesKey;
  plural: LocalesKey;
  pluralLowerCase: LocalesKey;
  price: LocalesKey;
  postpone: LocalesKey;
  postponed: LocalesKey;
  postponedItems: LocalesKey;
  postponedItemsTotal: LocalesKey;
  projectCreatedDate: LocalesKey;
  projectCreated: LocalesKey;
  qty: LocalesKey;
  rendering: LocalesKey;
  scopeOfWork: LocalesKey;
  searchByItemName: LocalesKey;
  select: LocalesKey;
  selectedTotal: LocalesKey;
  sentDate: LocalesKey;
  shownTotal: LocalesKey;
  singular: LocalesKey;
  singularLowerCase: LocalesKey;
  status: LocalesKey;
  statusChangeDate: LocalesKey;
  subTotal: LocalesKey;
  tag: LocalesKey;
  taskCreatedDate: LocalesKey;
  taskCreated: LocalesKey;
  threeDRenderingServicesPrepayment: LocalesKey;
  total: LocalesKey;
  void: LocalesKey;
  updateItemTag: LocalesKey;
  unbind: LocalesKey;
  unbound: LocalesKey;
  typeNotesHere: LocalesKey;
  youShouldAddAtLeastOneItem: LocalesKey;
  splitParts: LocalesKey;
  noSplit: LocalesKey;
  allProformaInvoices: LocalesKey;
  allProformaTasks: LocalesKey;
  allProformaProjects: LocalesKey;
  allProformaItemCategories: LocalesKey;
}

export const itemsKeys = keyPathMirror<ItemsKeyType, string>(
  {
    add: null,
    activity: null,
    areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction: null,
    bind: null,
    bindingStatus: null,
    bindingStatuses: {
      postponed: null,
      mixed: null,
      binded: null
    },
    bound: null,
    createDate: null,
    createNewTag: null,
    createItemTag: null,
    deleteItemTag: null,
    description: null,
    groupSimilarItems: null,
    hideCompleted: null,
    id: null,
    inDraft: null,
    itemTag: null,
    itemTags: null,
    move: null,
    moveTo: null,
    invoice: null,
    mixed: null,
    name: null,
    noScopeOfWorkYet: null,
    payFromDeposit: null,
    plural: null,
    pluralLowerCase: null,
    price: null,
    postpone: null,
    postponed: null,
    postponedItems: null,
    postponedItemsTotal: null,
    projectCreatedDate: null,
    projectCreated: null,
    qty: null,
    rendering: null,
    scopeOfWork: null,
    searchByItemName: null,
    select: null,
    selectedTotal: null,
    sentDate: null,
    shownTotal: null,
    singular: null,
    singularLowerCase: null,
    status: null,
    statusChangeDate: null,
    subTotal: null,
    tag: null,
    taskCreatedDate: null,
    taskCreated: null,
    threeDRenderingServicesPrepayment: null,
    total: null,
    void: null,
    updateItemTag: null,
    unbind: null,
    unbound: null,
    typeNotesHere: null,
    youShouldAddAtLeastOneItem: null,
    splitParts: null,
    noSplit: null,
    allProformaInvoices: null,
    allProformaTasks: null,
    allProformaProjects: null,
    allProformaItemCategories: null
  },
  'models.items.'
);
