import { useMemo } from 'react';

import {
  FetchPageCacheKey,
  FetchPageGqlQuery,
  PageNanoID,
  PageUUID
} from '../../pagesTypes';

import { useReadShowQuery } from '../../../common/hooks/base/reactQuery/useReadShowQuery';

interface PageResponse<ItemType> {
  page: ItemType | null;
}

interface PageOptions<ItemType> {
  pages: ItemType[];
  cacheKey: FetchPageCacheKey;
  query: FetchPageGqlQuery;
  uuid: PageUUID | PageNanoID;
  initFetch?: boolean;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface UsePageItem {
  uuid: PageUUID;
}

const itemKey = 'page';

function usePage<ItemType extends UsePageItem>({
  pages,
  cacheKey,
  query,
  uuid,
  options = {}
}: PageOptions<ItemType>) {
  const placeholderData = useMemo<PageResponse<ItemType>>(() => {
    const page = pages?.find((page) => page.uuid === uuid);

    return page ? { page } : null;
  }, [pages, uuid]);

  const { item, itemError, itemFetched, itemLoading, itemIsPlaceholderData } =
    useReadShowQuery<PageResponse<ItemType>, ItemType>({
      cacheKey,
      itemKey,
      query,
      uuid,
      placeholderData,
      options
    });

  return {
    page: item,
    pageError: itemError,
    pageFetched: itemFetched,
    pageLoading: itemLoading,
    pageIsPlaceholderData: itemIsPlaceholderData
  };
}

export default usePage;
