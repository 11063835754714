const paymentsBasePath = '/payments';

export class PaymentPath {
  static index() {
    return paymentsBasePath;
  }

  static smartContracts() {
    return `${paymentsBasePath}/smart-contracts`;
  }
}
