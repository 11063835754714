import { useQuery } from 'react-query';

import { DeviceNanoId } from '../../../../types';

import { generateNanoId } from '../../../../utils/generateNanoId';

const cacheKey = 'download-nano-id';

const downloadNanoId: DeviceNanoId = generateNanoId() as DeviceNanoId;

function useDownloadNanoId() {
  const { data } = useQuery<DeviceNanoId>(cacheKey, () => downloadNanoId);

  return {
    downloadNanoId: data
  };
}

export default useDownloadNanoId;
