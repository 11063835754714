import { IconProps } from '../types';

function SearchAiOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-6 w-6'}
      fill="none"
      id={id}
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-6-6M3.7 13c-.5-.9-.7-1.9-.7-3 0-3.9 3.1-7 7-7s7 3.1 7 7c0 2.5-1.4 4.8-3.4 6M5 16c-1.1 0-2 1.1-2 2.5S3.9 21 5 21s2-1.1 2-2.5S6.1 16 5 16zm2 0v5m3-5v5"
      />
    </svg>
  );
}

export default SearchAiOutlineIcon;
