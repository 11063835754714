import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface UsersKeyType {
  activateUser: LocalesKey;
  activateWarning: LocalesKey;
  add: LocalesKey;
  addCellsToUsers: LocalesKey;
  addGroupsToUsers: LocalesKey;
  all: LocalesKey;
  allCells: LocalesKey;
  balance: LocalesKey;
  blockedSingular: LocalesKey;
  blockedPlural: LocalesKey;
  blockUser: LocalesKey;
  blockWarning: LocalesKey;
  clientsPlural: LocalesKey;
  cm: LocalesKey;
  createShare: LocalesKey;
  changePassword: LocalesKey;
  changeEmail: LocalesKey;
  debt: LocalesKey;
  deleteAccount: LocalesKey;
  id: LocalesKey;
  group: LocalesKey;
  groups: LocalesKey;
  plural: LocalesKey;
  pluralLowerCase: LocalesKey;
  workersPlural: LocalesKey;
  pendingPayments: {
    plural: LocalesKey;
    singular: LocalesKey;
    types: {
      badDebt: LocalesKey;
      debt: LocalesKey;
      underpayment: LocalesKey;
    };
    no: LocalesKey;
  };
  search: LocalesKey;
  select: LocalesKey;
  sigmaPayments: LocalesKey;
  name: LocalesKey;
  phone: LocalesKey;
  pm: LocalesKey;
  email: LocalesKey;
  financeRole: LocalesKey;
  experience: LocalesKey;
  editShare: LocalesKey;
  revenue: LocalesKey;
  kAvr: LocalesKey;
  kEdp: LocalesKey;
  lastPayments: LocalesKey;
  lastActivity: LocalesKey;
  legalInformationUpdate: LocalesKey;
  mentor: LocalesKey;
  cell: LocalesKey;
  role: LocalesKey;
  roles: LocalesKey;
  createdAt: LocalesKey;
  selectClient: LocalesKey;
  invite: LocalesKey;
  status: LocalesKey;
  cdn: {
    usa: LocalesKey;
    eu: LocalesKey;
  };
  statusUpdated: LocalesKey;
  statuses: {
    new: LocalesKey;
    active: LocalesKey;
    paused: LocalesKey;
    declined: LocalesKey;
    realDecline: LocalesKey;
    noPayments: LocalesKey;
  };
  unblockUser: LocalesKey;
}

export const usersKeys = keyPathMirror<UsersKeyType, string>(
  {
    activateUser: null,
    activateWarning: null,
    add: null,
    addCellsToUsers: null,
    addGroupsToUsers: null,
    all: null,
    allCells: null,
    balance: null,
    blockedSingular: null,
    blockedPlural: null,
    blockUser: null,
    blockWarning: null,
    clientsPlural: null,
    cm: null,
    createShare: null,
    changePassword: null,
    changeEmail: null,
    debt: null,
    deleteAccount: null,
    id: null,
    group: null,
    groups: null,
    plural: null,
    pluralLowerCase: null,
    workersPlural: null,
    pendingPayments: {
      plural: null,
      singular: null,
      types: {
        badDebt: null,
        debt: null,
        underpayment: null
      },
      no: null
    },
    search: null,
    select: null,
    sigmaPayments: null,
    name: null,
    phone: null,
    pm: null,
    email: null,
    financeRole: null,
    experience: null,
    editShare: null,
    revenue: null,
    kAvr: null,
    kEdp: null,
    lastPayments: null,
    lastActivity: null,
    legalInformationUpdate: null,
    mentor: null,
    cell: null,
    role: null,
    roles: null,
    createdAt: null,
    selectClient: null,
    invite: null,
    status: null,
    statusUpdated: null,
    statuses: {
      new: null,
      active: null,
      paused: null,
      declined: null,
      realDecline: null,
      noPayments: null
    },
    cdn: {
      usa: null,
      eu: null
    },
    unblockUser: null
  },
  'models.users.'
);
