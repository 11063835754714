import { IconProps } from '../types';

function MenuTreeSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-6 w-6'}
    >
      <path
        fillRule="evenodd"
        d="M16 11c.6 0 1-.4 1-1s-.4-1-1-1h-6V6h6c.6 0 1-.4 1-1s-.4-1-1-1H4c-.6 0-1 .4-1 1s.4 1 1 1h4v9c0 .6.4 1 1 1h7c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3h6z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default MenuTreeSolidIcon;
