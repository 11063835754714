import { IconProps } from '../types';

function ProjectsIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M7 21H3v-4h4v4zm7-4h-4v4h4v-4zm0-15h-4v4h4V2zm7 15h-4v4h4v-4zM12 6v8m7 0v-4H5v4"
      />
    </svg>
  );
}

export default ProjectsIcon;
