import * as Sentry from '@sentry/nextjs';
import { setLogger } from 'react-query';

import { IS_PRODUCTION, SENTRY_DSN } from '../../config';

function setQueryLogger() {
  if (IS_PRODUCTION && SENTRY_DSN) {
    setLogger({
      log: (message) => {
        Sentry.captureMessage(message);
      },
      warn: (message) => {
        Sentry.captureMessage(message);
      },
      error: (error) => {
        Sentry.captureException(error);
      }
    });
  }
}

export default setQueryLogger;
