import React from 'react';

import { IconProps } from '../types';

function ForwardIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.3 4H20m0 0v5.7M20 4L10 14m0-8H7C5.3 6 4 7.3 4 9v8c0 1.7 1.3 3 3 3h8c1.7 0 3-1.3 3-3v-3"
      />
    </svg>
  );
}

export default ForwardIcon;
