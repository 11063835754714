import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SelectedLifestylesKeyType {
  plural: LocalesKey;
  download: LocalesKey;
}

export const selectedLifestylesKeys = keyPathMirror<
  SelectedLifestylesKeyType,
  string
>(
  {
    plural: null,
    download: null
  },
  'models.selectedLifestyles.'
);
