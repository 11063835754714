import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { PostItemCacheKeys, PostItemUrl } from '../../../../../../types';

import {
  ErrorType,
  parseRequestError
} from '../../../../../../utils/parseRequestError';

import { BffApiRequest } from '../../../../../../utils/BffApiRequest';

interface PostQueryBaseInput extends Record<string, any> {
  actionOptions?: {
    url?: PostItemUrl;
  };
}

export interface BffPostQueryContext {
  rollback?: () => void;
}

interface BffPostQueryOptions<PostQueryInput extends PostQueryBaseInput> {
  url?: PostItemUrl;
  cacheKeys?: PostItemCacheKeys;
  onOptimisticUpdate?: (input: PostQueryInput) => null | (() => void);
}

function useBffPostQuery<
  PostQueryInput extends PostQueryBaseInput,
  PostQueryResponse,
  PostQueryError extends ErrorType
>({ cacheKeys, onOptimisticUpdate, url }: BffPostQueryOptions<PostQueryInput>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    PostQueryResponse,
    PostQueryError,
    PostQueryInput,
    BffPostQueryContext
  >(
    async (input: PostQueryInput) => {
      try {
        const { actionOptions, ...restInput } = input || {};

        const response = await BffApiRequest.post<PostQueryResponse>(
          actionOptions?.url || url,
          restInput
        );

        return response.data;
      } catch (err) {
        throw isEmpty(err?.response?.data?.error?.fullMessages)
          ? err
          : err?.response?.data?.error;
      }
    },
    {
      onMutate: (input) => {
        const rollback = onOptimisticUpdate?.(input) || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    postQueryData: data,
    postQueryError: error,
    postQueryLoading: isLoading,
    postQueryErrorMessage: parseRequestError(error),
    postQuery: mutateAsync,
    postQueryReset: reset
  };
}

export default useBffPostQuery;
