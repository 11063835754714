import React from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';

import { TeamAvatarTeam } from '../../TeamAvatar.types';

const colorStyles = [
  'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700',
  'bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900',
  'bg-orange-100 text-orange-800 dark:text-orange-50 dark:bg-orange-900',
  'bg-yellow-100 text-yellow-800 dark:text-yellow-50 dark:bg-yellow-900',
  'bg-lime-100 text-lime-800 dark:text-lime-50 dark:bg-lime-900',
  'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900',
  'bg-cyan-100 text-cyan-800 dark:text-cyan-50 dark:bg-cyan-900',
  'bg-blue-100 text-blue-800 dark:text-blue-50 dark:bg-blue-900',
  'bg-purple-100 text-purple-800 dark:text-purple-50 dark:bg-purple-900',
  'bg-pink-100 text-pink-800 dark:text-pink-50 dark:bg-pink-900'
];

interface TeamNoAvatarClassNameProps {
  className: string;
  type?: 'main' | 'xl' | 'xs';
  team: TeamAvatarTeam;
}

interface TeamNoAvatarTypedProps {
  className?: string;
  type: 'main' | 'xl' | 'xs';
  team: TeamAvatarTeam;
}

type TeamNoAvatarProps = TeamNoAvatarClassNameProps | TeamNoAvatarTypedProps;

function TeamNoAvatar({ className, type, team }: TeamNoAvatarProps) {
  const colorStyle = colorStyles[team.name.charCodeAt(0).toString().slice(-1)];

  const text = compact(team.name.split(' '))
    .map((word, index) => (index < 2 ? word[0] : ''))
    .join('');

  return (
    <div
      className={
        className ||
        cl('max-w-none rounded-full bg-gray-200 dark:bg-gray-800', {
          'h-9 w-9': type === 'main',
          'h-9 w-9 ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700':
            type === 'xl',
          'h-6 w-6 ring-2 ring-white dark:ring-gray-900 group-hover:ring-gray-200 dark:group-hover:ring-gray-700':
            type === 'xs'
        })
      }
      style={{ overflow: 'hidden' }}
      title={team.name}
    >
      <div
        className={`h-full w-full flex items-center justify-center ${colorStyle}`}
      >
        <span
          className={
            type === 'xs' ? 'text-2xs uppercase' : 'text-base uppercase'
          }
        >
          {text}
        </span>
      </div>
    </div>
  );
}

export default TeamNoAvatar;
