import { SupersetReportID } from './supersetReportsTypes';

const basePath = '/superset-reports';

export class SupersetReportPath {
  static index() {
    return basePath;
  }
  static show(supersetReportId: SupersetReportID) {
    return `${basePath}/${supersetReportId}`;
  }
}
