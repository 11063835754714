import {
  ID,
  UUID,
  CreatedAt,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  UUIDFilter,
  CreatedAtFilter,
  IDFilter,
  FetchItemsFetched,
  FetchItemsTotalCount,
  FetchItemsIsPlaceholderData,
  FetchItemsErrorMessage,
  FetchItemsFilterItems,
  NanoIDFilter
} from '../../types';

import { ProjectNanoID, ProjectUUID } from '../projects/projectsTypes';
import { TaskNanoID, TaskUUID } from '../tasks/tasksTypes';

import {
  TransactionTagUUID,
  TransactionTagLocalizedName
} from '../transactionTags/transactionTagsTypes';

import {
  UserUUID,
  UserFullName,
  UserImageUUID,
  UserImageFile,
  UserNanoID
} from '../users/usersTypes';

export type TransactionID = ID;
export type TransactionUUID = UUID;
export type TransactionBody = string;
export type TransactionBalanceType = TransactionBalanceTypes;
export type TransactionKind = string;
export type TransactionPaymentType = string;
export type TransactionDescription = string;
export type TransactionOperation = TransactionOperationTypes;
export type TransactionAmount = number;
export type TransactionTotalBalance = number;
export type TransactionCreatedAt = CreatedAt;

export enum TransactionDirectionTypes {
  PROJECT_LINK = 'task_link',
  TASK_LINK = 'discussion_link',
  USER_LINK = 'user_link',
  TRANSFER = 'transfer'
}

export type TransactionDirectionType = TransactionDirectionTypes;
export type TransactionDirectionID = ID;
export type TransactionDirectionUUID = UUID;
export type TransactionDirectionNanoID =
  | ProjectNanoID
  | TaskNanoID
  | UserNanoID;
export type TransactionDirectionName = string;
export type TransactionDirectionFromUserName = string;
export type TransactionDirectionToUserName = string;
export type TransactionDirectionCurUserName = string;
export type TransactionDirectionValue = string;

export type TransactionDirection = {
  id: TransactionDirectionID;
  uuid: TransactionDirectionUUID;
  nanoId: TransactionDirectionNanoID;
  name: TransactionDirectionName;
  fromUserName: TransactionDirectionFromUserName;
  toUserName: TransactionDirectionToUserName;
  curUserName: TransactionDirectionCurUserName;
  value: TransactionDirectionValue;
};

export enum TransactionBalanceTypes {
  DEBIT_ACCOUNT = 'debit_account',
  CREDIT_ACCOUNT = 'credit_account',
  BONUS_ACCOUNT = 'bonus_account',
  PROJECT_ACCOUNT = 'task_account',
  TASK_ACCOUNT = 'discussion_account',
  AMOUNT = 'amount',
  PREPAID_AMOUNT = 'prepaid_amount',
  BONUS_AMOUNT = 'bonus_amount',
  BALANCE_AMOUNT = 'balance_amount'
}

export enum TransactionOperationTypes {
  PLUS = 'plus',
  MINUS = 'minus'
}

export type TransactionTaskUUID = TaskUUID;
export type TransactionProjectUUID = ProjectUUID;

export type TransactionAuthorUUID = UserUUID;
export type TransactionAuthorFullName = UserFullName;
export type TransactionAuthorImageUUID = UserImageUUID;
export type TransactionAuthorImageFile = UserImageFile;

export type TransactionUserUUID = UserUUID;
export type TransactionUserNanoID = UserNanoID;
export type TransactionUserFullName = UserFullName;
export type TransactionUserImageUUID = UserImageUUID;
export type TransactionUserImageFile = UserImageFile;

export type TransactionTransactionTags = {
  uuid: TransactionTagUUID;
  localizedName: TransactionTagLocalizedName;
}[];

export interface TransactionAuthorWithImage {
  uuid: TransactionUserUUID;
  nanoId: TransactionUserNanoID;
  fullName: TransactionUserFullName;
  image: {
    uuid: TransactionUserImageUUID;
    file: TransactionUserImageFile;
  };
}

export interface TransactionUserWithImage {
  uuid: TransactionUserUUID;
  fullName: TransactionUserFullName;
  image: {
    uuid: TransactionUserImageUUID;
    file: TransactionUserImageFile;
  };
}

export interface TransactionBalanceUserWithImage {
  user: {
    uuid: TransactionUserUUID;
    nanoId: TransactionUserNanoID;
    fullName: TransactionUserFullName;
    image: {
      uuid: TransactionUserImageUUID;
      file: TransactionUserImageFile;
    };
  };
}

export enum TransactionFields {
  ID = 'id',
  UUID = 'uuid',
  CREATED_AT = 'createdAt',
  BALANCE_TYPE = 'balanceType'
}

export interface FetchTransactionsFilters {
  balanceType?: TransactionBalanceType;
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: CreatedAtFilter;
  balanceUserId?: IDFilter;
  balanceUserUuid?: UUIDFilter;
  balanceUserNanoId?: NanoIDFilter;
}

export enum FetchTransactionsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt',
  KIND = 'kind',
  BALANCE_TYPE = 'balance_type',
  PAYMENT_TYPE = 'payment_type',
  AMOUNT = 'amount',
  TOTAL_BALANCE = 'total_balance'
}

export enum FetchTransactionsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchTransactionsPage = FetchItemsPage;
export type FetchTransactionsLimit = FetchItemsLimit;
export type FetchTransactionsSort = FetchItemsSort;
export type FetchTransactionsSortTransactions = FetchItemsSortItems;
export type FetchTransactionsPaginateTransactions = FetchItemsPaginateItems;
export type FetchTransactionsPrefetchTransactions = FetchItemsPrefetchItems;
export type FetchTransactionsFetched = FetchItemsFetched;
export type FetchTransactionsTotalCount = FetchItemsTotalCount;
export type FetchTransactionsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTransactionsErrorMessage = FetchItemsErrorMessage;
export type FetchTransactionsFilterTransactions =
  FetchItemsFilterItems<FetchTransactionsFilters>;

export type FetchTransactionsGqlQuery = FetchItemsGqlQuery;

export type FetchTransactionsCacheKey = FetchItemsCacheKey;
