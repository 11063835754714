import { Currencies, CurrenciesSymbols, currenciesSymbols } from '../../types';

import { NumberHelper } from '../NumberHelper';

interface MoneyHelperProps {
  value: number;
  currency?: Currencies | string;
  withPrefixIfUsd?: boolean;
  withSuffix?: boolean;
}

function MoneyHelper({
  value,
  currency = Currencies.USD,
  withPrefixIfUsd = false,
  withSuffix = false
}: MoneyHelperProps) {
  const currencySymbol = currenciesSymbols[currency] || currency;

  if (withPrefixIfUsd && currency === Currencies.USD) {
    return (
      <NumberHelper
        value={value}
        prefix={CurrenciesSymbols.USD}
        suffix={currency}
      />
    );
  }

  if (withSuffix) {
    return <NumberHelper value={value} suffix={currency} />;
  }

  return <NumberHelper value={value} prefix={currencySymbol} />;
}

export default MoneyHelper;
