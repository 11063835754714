import { ChatGptPromptsKeyType } from '../../keys';

const chatGptPromptsKeys: ChatGptPromptsKeyType = {
  applicableTo: 'Applicable to',
  create: 'Create ChatGPT prompt',
  delete: 'Delete ChatGPT prompt',
  description: 'Description',
  destination: 'Destination',
  kind: 'Kind',
  name: 'Name',
  new: 'New ChatGPT prompt',
  no: 'No ChatGPT prompts',
  plural: 'ChatGPT prompts',
  pluralDowncase: 'ChatGPT prompts',
  prompt: 'Prompt',
  scopes: {
    index: 'Index',
    create: 'Create',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  },
  search: 'Search ChatGPT prompts',
  user: 'User',
  view: 'View ChatGPT prompt'
};

export default chatGptPromptsKeys;
