const basePath = '/auth';

export class AuthPath {
  static base() {
    return `${basePath}`;
  }

  static login() {
    return `${basePath}/login`;
  }

  static register() {
    return `${basePath}/register`;
  }

  static registerShort() {
    return `/register`;
  }

  static forgot() {
    return `${basePath}/forgot`;
  }

  static inviteTeamMembers() {
    return `${basePath}/invite-team-members`;
  }

  static businessDescription() {
    return `${basePath}/business-description`;
  }

  static createProject() {
    return `${basePath}/create-project`;
  }
}
