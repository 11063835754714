import { SectionUUID } from './sectionsTypes';

const basePath = '/sections';

export class SectionPath {
  static index() {
    return basePath;
  }

  static show(sectionUuid: SectionUUID) {
    return `${basePath}/${sectionUuid}`;
  }
}
