import { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { LOGOUT_USER_QUERY } from '../../../../../auth/queries/logoutUser.query';

import { useLogoutUser } from '../../../../../auth/hooks/useLogoutUser';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface UserMenuLogoutButtonProps {
  closeMenus: () => void;
  onLogout: () => void;
}

function UserMenuLogoutButton({
  closeMenus,
  onLogout
}: UserMenuLogoutButtonProps) {
  const { logoutUser, logoutUserErrorMessage, logoutUserLoading } =
    useLogoutUser({ query: LOGOUT_USER_QUERY });

  useShowToastOnErrorChange({ error: logoutUserErrorMessage });

  const handleClick = useCallback<() => void>(() => {
    logoutUser().then(() => {
      closeMenus();
      onLogout();
    });
  }, [logoutUser, closeMenus, onLogout]);

  return (
    <PureIconButtonHelper
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
      icon={IconsEnum.LOGOUT}
      iconClassName="mr-3 h-5 w-5"
      i18nText="words.log_out"
      disabled={logoutUserLoading}
      onClick={handleClick}
    />
  );
}

export default UserMenuLogoutButton;
