import { graphQLReadClient } from '../../graphQLClients';

import { FetchItemGqlQuery, NanoID, UUID } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
}

export function fetchReadItem({ query, uuid }: FetchItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole('log', 'fetchReadItem', uuid, rawName);

  const requestId = generateUuid();

  graphQLReadClient.addQueryParams(name, requestId);

  return graphQLReadClient.request(query, { uuid }, { requestId });
}
