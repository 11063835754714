import React from 'react';

import { IconProps } from '../types';

function CrossedBellIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 17v1c0 1.7-1.3 3-3 3-1.1 0-2-.6-2.6-1.4M12 17h8l-1.4-1.4c-.4-.4-.6-.9-.6-1.4V11m2-7L4 20M16.7 7.3c-.7-.9-1.6-1.6-2.7-2V5c0-1.1-.9-2-2-2s-2 .9-2 2v.3c-2.3.8-4 3-4 5.7v3.2c0 .5-.2 1.1-.6 1.4L4 17h3"
      />
    </svg>
  );
}

export default CrossedBellIcon;
