import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AccountTypesKeyType {
  accountTypeId: LocalesKey;
  increaseSide: LocalesKey;
  name: LocalesKey;
  shortName: LocalesKey;
  systemName: LocalesKey;
  names: {
    pfbd: LocalesKey;
    pfr: LocalesKey;
  };
}

export const accountTypesKeys = keyPathMirror<AccountTypesKeyType, string>(
  {
    accountTypeId: null,
    increaseSide: null,
    name: null,
    shortName: null,
    systemName: null,
    names: {
      pfbd: null,
      pfr: null
    }
  },
  'models.accountTypes.'
);
