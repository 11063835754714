import { gql } from 'graphql-request';
import {
  BillingInfoAddress,
  BillingInfoAvBillingInfoID,
  BillingInfoCity,
  BillingInfoCompanyName,
  BillingInfoContactEmail,
  BillingInfoContactPhone,
  BillingInfoCountry,
  BillingInfoCreatedAt,
  BillingInfoDefaultAt,
  BillingInfoFirstName,
  BillingInfoID,
  BillingInfoLastName,
  BillingInfoNanoID,
  BillingInfoPaymentMethod,
  BillingInfoState,
  BillingInfoUUID,
  BillingInfoUpdatedAt,
  BillingInfoVatNumber,
  BillingInfoZipCode
} from '../billingInfosTypes';
import { CompanyID, CompanyNanoID } from '../../companies/companiesTypes';
import {
  AvBillingInfoAddress,
  AvBillingInfoBankDetails,
  AvBillingInfoCity,
  AvBillingInfoCompanyName,
  AvBillingInfoContactEmail,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoCountry,
  AvBillingInfoID,
  AvBillingInfoName,
  AvBillingInfoNanoID,
  AvBillingInfoNotes,
  AvBillingInfoPaymentMethods,
  AvBillingInfoState,
  AvBillingInfoZipCode
} from '../../avBillingInfos/avBillingInfosTypes';

export interface FetchBillingInfosQueryResponse {
  id: BillingInfoID;
  company: {
    id: CompanyID;
    nanoId: CompanyNanoID;
  };
  companyName: BillingInfoCompanyName;
  firstName: BillingInfoFirstName;
  lastName: BillingInfoLastName;
  contactPhone: BillingInfoContactPhone;
  contactEmail: BillingInfoContactEmail;
  country: BillingInfoCountry;
  defaultAt: BillingInfoDefaultAt;
  state: BillingInfoState;
  city: BillingInfoCity;
  address: BillingInfoAddress;
  zipCode: BillingInfoZipCode;
  nanoId: BillingInfoNanoID;
  uuid: BillingInfoUUID;
  createdAt: BillingInfoCreatedAt;
  updatedAt: BillingInfoUpdatedAt;
  companyId: BillingInfoCompanyName;
  paymentMethod: BillingInfoPaymentMethod;
  vatNumber?: BillingInfoVatNumber;
  avBillingInfoId: BillingInfoAvBillingInfoID;
  avBillingInfo: {
    id: AvBillingInfoID;
    nanoId: AvBillingInfoNanoID;
    name: AvBillingInfoName;
    companyName: AvBillingInfoCompanyName;
    contactName: AvBillingInfoContactName;
    contactPhone: AvBillingInfoContactPhone;
    contactEmail: AvBillingInfoContactEmail;
    country: AvBillingInfoCountry;
    state: AvBillingInfoState;
    city: AvBillingInfoCity;
    address: AvBillingInfoAddress;
    zipCode: AvBillingInfoZipCode;
    bankDetails: AvBillingInfoBankDetails;
    notes: AvBillingInfoNotes;
    paymentMethod: AvBillingInfoPaymentMethods;
  };
}

export const FETCH_BILLING_INFOS_QUERY = gql`
  query BillingInfos(
    $filters: BillingInfosFilters
    $sort: [BillingInfosSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    billingInfos(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      appVersion
      nodes {
        id
        company {
          id
          nanoId
        }
        companyName
        firstName
        lastName
        contactPhone
        contactEmail
        country
        defaultAt
        state
        city
        address
        zipCode
        nanoId
        uuid
        createdAt
        updatedAt
        companyId
        paymentMethod
        vatNumber
        avBillingInfoId
        avBillingInfo {
          id
          nanoId
          name
          companyName
          contactName
          contactPhone
          contactEmail
          country
          state
          city
          address
          zipCode
          bankDetails
          notes
          paymentMethod
        }
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
