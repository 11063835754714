import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface FiltersKeyType {
  booleanOptions: {
    any: LocalesKey;
    false: LocalesKey;
    true: LocalesKey;
  };
  dateOptions: {
    exactDay: LocalesKey;
    numberOfDaysAgo: LocalesKey;
    numberOfDaysFromNow: LocalesKey;
    oneMonthAgo: LocalesKey;
    oneMonthFromNow: LocalesKey;
    oneWeekAgo: LocalesKey;
    oneWeekFromNow: LocalesKey;
    theNextMonth: LocalesKey;
    theNextNumberOfDays: LocalesKey;
    theNextWeek: LocalesKey;
    theNextYear: LocalesKey;
    thePastMonth: LocalesKey;
    thePastNumberOfDays: LocalesKey;
    thePastWeek: LocalesKey;
    thePastYear: LocalesKey;
    thisCalendarMonth: LocalesKey;
    thisCalendarWeek: LocalesKey;
    thisCalendarYear: LocalesKey;
    today: LocalesKey;
    tomorrow: LocalesKey;
    yesterday: LocalesKey;
  };
  enterDays: LocalesKey;
  enterFilterName: LocalesKey;
  saveCurrentFilters: LocalesKey;
  modes: {
    contains: LocalesKey;
    containsAllOf: LocalesKey;
    containsAnyOf: LocalesKey;
    containsNoneOf: LocalesKey;
    containsOnly: LocalesKey;
    doesNotContainAnyOf: LocalesKey;
    endsWith: LocalesKey;
    is: LocalesKey;
    isAfter: LocalesKey;
    isAnyOf: LocalesKey;
    isBefore: LocalesKey;
    isEmpty: LocalesKey;
    isNoneOf: LocalesKey;
    isNot: LocalesKey;
    isNotEmpty: LocalesKey;
    isOnOrAfter: LocalesKey;
    isOnOrBefore: LocalesKey;
    isWithin: LocalesKey;
    startsWith: LocalesKey;
    greaterThanEqual: LocalesKey;
    lessThanEqual: LocalesKey;
  };
}

export const filtersKeys = keyPathMirror<FiltersKeyType, string>(
  {
    booleanOptions: {
      any: null,
      false: null,
      true: null
    },
    dateOptions: {
      exactDay: null,
      numberOfDaysAgo: null,
      numberOfDaysFromNow: null,
      oneMonthAgo: null,
      oneMonthFromNow: null,
      oneWeekAgo: null,
      oneWeekFromNow: null,
      theNextMonth: null,
      theNextNumberOfDays: null,
      theNextWeek: null,
      theNextYear: null,
      thePastMonth: null,
      thePastNumberOfDays: null,
      thePastWeek: null,
      thePastYear: null,
      thisCalendarMonth: null,
      thisCalendarWeek: null,
      thisCalendarYear: null,
      today: null,
      tomorrow: null,
      yesterday: null
    },
    enterDays: null,
    enterFilterName: null,
    saveCurrentFilters: null,
    modes: {
      contains: null,
      containsAllOf: null,
      containsAnyOf: null,
      containsNoneOf: null,
      containsOnly: null,
      doesNotContainAnyOf: null,
      endsWith: null,
      is: null,
      isAfter: null,
      isAnyOf: null,
      isBefore: null,
      isEmpty: null,
      isNoneOf: null,
      isNot: null,
      isNotEmpty: null,
      isOnOrAfter: null,
      isOnOrBefore: null,
      isWithin: null,
      startsWith: null,
      greaterThanEqual: null,
      lessThanEqual: null
    }
  },
  'models.filters.'
);
