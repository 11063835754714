import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AttachmentsKeyType {
  assignType: LocalesKey;
  plural: LocalesKey;
  pluralGenitive: LocalesKey;
  pluralLowerCase: LocalesKey;
  download: LocalesKey;
  drawing: LocalesKey;
  drawings: LocalesKey;
  gridView: LocalesKey;
  file: LocalesKey;
  listView: LocalesKey;
  reference: LocalesKey;
  references: LocalesKey;
  seeAll: LocalesKey;
  seeAllNAttachments: {
    seeAll: LocalesKey;
    attachments: LocalesKey;
  };
  texture: LocalesKey;
  textures: LocalesKey;
  threeD: LocalesKey;
}

export const attachmentsKeys = keyPathMirror<AttachmentsKeyType, string>(
  {
    assignType: null,
    plural: null,
    pluralGenitive: null,
    pluralLowerCase: null,
    drawing: null,
    drawings: null,
    gridView: null,
    file: null,
    listView: null,
    references: null,
    reference: null,
    seeAll: null,
    seeAllNAttachments: {
      seeAll: null,
      attachments: null
    },
    textures: null,
    texture: null,
    download: null,
    threeD: null
  },
  'models.attachments.'
);
