export default {
  select: 'Обрати статус...',
  change: 'Змінити статус',
  set: 'Встановити статус',
  types: {
    acceptance: 'Очікує схвалення',
    canceled: 'Скасовано',
    // checking: 'Checking task',
    definition: 'Постановка завдання',
    // discussion: 'Discussion',
    // distribution: 'Distribution',
    implementation: 'В роботі',
    incompleteSpecification: 'Потребує уточнень',
    paid: 'Сплачено',
    paused: 'Призупинено',
    paymentWaiting: 'Очікує на сплату',
    performed: 'Завершено',
    qa: 'Контроль якості'
  }
};
