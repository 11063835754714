import { useCallback } from 'react';
import { QueryKey, useQueryClient } from 'react-query';

import {
  IndexQueryResponse,
  UpdateIndexQueryItemsCacheAction
} from '../../useIndexQuery.types';

interface UpdateIndexQueryItemsCacheOptions {
  fullCacheKey: QueryKey;
}

function useUpdateIndexQueryItemsCache<ItemType>({
  fullCacheKey
}: UpdateIndexQueryItemsCacheOptions) {
  const queryClient = useQueryClient();

  return useCallback<UpdateIndexQueryItemsCacheAction<ItemType>>(
    ({ input, updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<IndexQueryResponse<ItemType>>(fullCacheKey);

      if (!previousQueryData) {
        return null;
      }

      const updatedQueryData = updateFunction?.(input, previousQueryData);

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<IndexQueryResponse<ItemType>>(
        fullCacheKey,
        updatedQueryData
      );

      return () => {
        queryClient.setQueryData<IndexQueryResponse<ItemType>>(
          fullCacheKey,
          previousQueryData
        );
      };
    },
    [fullCacheKey, queryClient]
  );
}

export default useUpdateIndexQueryItemsCache;
