import { IconProps } from '../types';

function UserCircleSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M16 17h-4c-.6 0-1-.4-1-1s.4-1 1-1h1.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l2.3 2.3V12c0-.6.4-1 1-1s1 .4 1 1v4c0 .1 0 .3-.1.4 0 .1-.1.2-.2.3s-.2.2-.3.2c-.1.1-.3.1-.4.1zm-8 0H4c-.1 0-.3 0-.4-.1-.1 0-.2-.1-.3-.2s-.2-.2-.2-.3c-.1-.1-.1-.3-.1-.4v-4c0-.6.4-1 1-1s1 .4 1 1v1.6l2.3-2.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4L6.4 15H8c.6 0 1 .4 1 1s-.4 1-1 1zm8-8c-.6 0-1-.4-1-1V6.4l-2.3 2.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L13.6 5H12c-.6 0-1-.4-1-1s.4-1 1-1h4c.1 0 .3 0 .4.1.1 0 .2.1.3.2s.2.2.2.3c.1.1.1.3.1.4v4c0 .6-.4 1-1 1zM8 9c-.3 0-.5-.1-.7-.3L5 6.4V8c0 .6-.4 1-1 1s-1-.4-1-1V4c0-.1 0-.3.1-.4 0-.1.1-.2.2-.3s.2-.2.3-.2c.1-.1.3-.1.4-.1h4c.6 0 1 .4 1 1s-.4 1-1 1H6.4l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
    </svg>
  );
}

export default UserCircleSolidIcon;
