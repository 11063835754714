import includes from 'lodash/includes';

import { WorkspaceSecondaryMenuItems } from '../../CompactWorkspaceSecondaryMenu.types';

function getWorkspaceActiveMenu(
  pathname: string
): WorkspaceSecondaryMenuItems | null {
  if (includes(pathname, 'projects')) {
    return WorkspaceSecondaryMenuItems.PROJECTS;
  }

  if (includes(pathname, 'tasks')) {
    return WorkspaceSecondaryMenuItems.TASKS;
  }

  if (includes(pathname, 'messages')) {
    return WorkspaceSecondaryMenuItems.MESSAGES;
  }

  return null;
}

export default getWorkspaceActiveMenu;
