import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProjectMembersKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  timeTrackedTasks: LocalesKey;
  timeTrackedProject: LocalesKey;
}

export const projectMembersKeys = keyPathMirror<ProjectMembersKeyType, string>(
  {
    list: null,
    plural: null,
    timeTrackedTasks: null,
    timeTrackedProject: null
  },
  'models.projectMembers.'
);
