import { IconProps } from '../types';

function DocumentMediasIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      viewBox="0 0 60 60"
    >
      <g>
        <g>
          <path
            d="m52.94 29.36-.75 3.64-4.09 19.77a2.8 2.8 0 0 1 -2.74 2.23h-42.04a2.319 2.319 0 0 1 -2.32-2.31 2.607 2.607 0 0 1 .05-.48l4.96-23.98a2.8 2.8 0 0 1 2.74-2.23h41.45a2.625 2.625 0 0 1 .8.12 2.792 2.792 0 0 1 1.94 3.24z"
            fill="#e8edfc"
            data-original="#e8edfc"
            className=""
          ></path>
          <path
            d="m51 26.12a2.625 2.625 0 0 0 -.8-.12h-3a2.625 2.625 0 0 1 .8.12 2.792 2.792 0 0 1 1.94 3.24l-.75 3.64-4.09 19.77a2.8 2.8 0 0 1 -2.74 2.23h3a2.8 2.8 0 0 0 2.74-2.23l4.09-19.77.75-3.64a2.792 2.792 0 0 0 -1.94-3.24z"
            fill="#cad9fc"
            data-original="#cad9fc"
          ></path>
          <path
            d="m24.979 32.2-1.237 6a1 1 0 0 1 -.98.8h-11.962a1 1 0 0 1 -.979-1.2l1.237-6a1 1 0 0 1 .98-.8h11.962a1 1 0 0 1 .979 1.2z"
            fill="#c6d8fb"
            data-original="#c6d8fb"
          ></path>
          <path
            d="m24 31h-3a1 1 0 0 1 .979 1.2l-1.237 6a1 1 0 0 1 -.98.8h3a1 1 0 0 0 .98-.8l1.237-6a1 1 0 0 0 -.979-1.2z"
            fill="#a4c3fd"
            data-original="#a4c3fd"
            className=""
          ></path>
          <path
            d="m13 12.07v13.93h-4.25a2.8 2.8 0 0 0 -2.74 2.23l-4.96 23.98a2.607 2.607 0 0 0 -.05.48v-37.69a3 3 0 0 1 3-3h8.39a2.483 2.483 0 0 1 .61.07z"
            fill="#c6d8fb"
            data-original="#c6d8fb"
          ></path>
          <path
            d="m12.39 12h-3a2.483 2.483 0 0 1 .61.07v13.93h3v-13.93a2.483 2.483 0 0 0 -.61-.07z"
            fill="#a4c3fd"
            data-original="#a4c3fd"
            className=""
          ></path>
          <path
            d="m59 11v20a2.006 2.006 0 0 1 -2 2h-4.81l.75-3.64a2.8 2.8 0 0 0 -2.74-3.36h-15.2v-15a2.006 2.006 0 0 1 2-2h20a2.006 2.006 0 0 1 2 2z"
            fill="#e8edfc"
            data-original="#e8edfc"
            className=""
          ></path>
          <path
            d="m57 9h-3a2.006 2.006 0 0 1 2 2v20a2.006 2.006 0 0 1 -2 2h3a2.006 2.006 0 0 0 2-2v-20a2.006 2.006 0 0 0 -2-2z"
            fill="#cad9fc"
            data-original="#cad9fc"
          ></path>
          <path
            d="m35 23.88v2.12h-7.87l-2.27-2.77.01-.01 3.94-4.81a1.1 1.1 0 0 1 1.72 0z"
            fill="#a4c2f7"
            data-original="#a4c2f7"
            className=""
          ></path>
          <path
            d="m37 7v2a2.006 2.006 0 0 0 -2 2v12.88l-4.47-5.47a1.1 1.1 0 0 0 -1.72 0l-3.94 4.81-.01.01 2.27 2.77h-14.13v-19a2.006 2.006 0 0 1 2-2h20a2.006 2.006 0 0 1 2 2z"
            fill="#e8edfc"
            data-original="#e8edfc"
            className=""
          ></path>
          <path
            d="m35 5h-3a2.006 2.006 0 0 1 2 2v2a2.006 2.006 0 0 0 -2 2v9.209l3 3.671v-12.88a2.006 2.006 0 0 1 2-2v-2a2.006 2.006 0 0 0 -2-2z"
            fill="#cad9fc"
            data-original="#cad9fc"
          ></path>
          <path
            d="m43 9h8v7h-8z"
            fill="#a4c2f7"
            data-original="#a4c2f7"
            className=""
          ></path>
          <path
            d="m51 16v10.12a2.625 2.625 0 0 0 -.8-.12h-7.2v-10z"
            fill="#c6d8fb"
            data-original="#c6d8fb"
          ></path>
          <path
            d="m27.13 26h-14.13l6.21-7.59a1.092 1.092 0 0 1 1.71 0l3.94 4.82z"
            fill="#c6d8fb"
            data-original="#c6d8fb"
          ></path>
          <circle
            cx="19.5"
            cy="11.5"
            fill="#e8edfc"
            r="2.5"
            data-original="#e8edfc"
            className=""
          ></circle>
        </g>
        <g>
          <path
            d="m3.32 55h3a2.319 2.319 0 0 1 -2.32-2.31v-37.69a3 3 0 0 1 3-3h4.866c-1.014-.01-3.131 0-7.866 0a3 3 0 0 0 -3 3v37.69a2.319 2.319 0 0 0 2.32 2.31z"
            fill="#ffffff"
            data-original="#ffffff"
          ></path>
        </g>
        <g>
          <path d="m0 15h2v8h-2z" fill="#ffffff" data-original="#ffffff"></path>
          <g fill="#428dff">
            <path
              d="m24 30h-11.958a2.007 2.007 0 0 0 -1.959 1.6l-1.237 6a2 2 0 0 0 1.959 2.4h11.957a2.008 2.008 0 0 0 1.959-1.6l1.238-6a2 2 0 0 0 -1.959-2.4zm-1.238 8h-11.957l1.237-6h11.958z"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></path>
            <circle
              cx="1"
              cy="19"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <path
              d="m57 8h-19v-1a3 3 0 0 0 -3-3h-20a3 3 0 0 0 -3 3v4h-8a4 4 0 0 0 -4 4 1 1 0 0 0 2 0 2 2 0 0 1 2-2h8v12h-3.249a3.816 3.816 0 0 0 -3.72 3.027l-3.031 14.647v-19.674a1 1 0 0 0 -2 0v29.69h.005a3.317 3.317 0 0 0 3.32 3.31h42.035a3.815 3.815 0 0 0 3.72-3.027l3.92-18.973h4a3 3 0 0 0 3-3v-20a3 3 0 0 0 -3-3zm-31.981 17h-9.908l4.873-5.957h.162zm2.581 0-1.448-1.769 3.431-4.189h.164l4.253 5.193v.765zm9.4-15h5v15h-6v-14a1 1 0 0 1 1-1zm7 7h6v8h-6zm6-2h-6v-5h6zm-35-9h20a1 1 0 0 1 1 1v1.184a3 3 0 0 0 -2 2.816v10.075l-2.7-3.3a2.093 2.093 0 0 0 -1.631-.775 2.088 2.088 0 0 0 -1.628.774l-3.176 3.878-3.165-3.866a2.079 2.079 0 0 0 -1.619-.786h-.013a2.1 2.1 0 0 0 -1.632.779l-4.436 5.421v-16.2a1 1 0 0 1 1-1zm36.962 23.156-4.84 23.41a1.8 1.8 0 0 1 -1.762 1.434h-42.035a1.325 1.325 0 0 1 -1.3-1.592l4.965-23.974a1.784 1.784 0 0 1 1.761-1.434h41.449a1.8 1.8 0 0 1 1.761 2.156zm6.038 1.844a1 1 0 0 1 -1 1h-3.585l.5-2.436a3.74 3.74 0 0 0 -1.915-4.093v-15.471h5a1 1 0 0 1 1 1z"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></path>
            <circle
              cx="39"
              cy="13"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="39"
              cy="17"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="39"
              cy="21"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="55"
              cy="13"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="55"
              cy="17"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="55"
              cy="21"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <circle
              cx="55"
              cy="25"
              r="1"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></circle>
            <path
              d="m19.5 15a3.5 3.5 0 1 0 -3.5-3.5 3.5 3.5 0 0 0 3.5 3.5zm0-5a1.5 1.5 0 1 1 -1.5 1.5 1.5 1.5 0 0 1 1.5-1.5z"
              fill="#428dff"
              data-original="#428dff"
              className=""
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DocumentMediasIcon;
