import React, { Fragment, useState } from 'react';

import { ImageVersions, ImageSrcSetVersions } from './ImageHelper.types';

import { Tooltip } from '../tooltips/Tooltip';
import {
  TooltipPlacement,
  bodyTooltipContainer
} from '../tooltips/tooltipsConstants';

import { generateImageSrcSet } from './utils/generateImageSrcSet';

import { Files } from '../../utils/Files';
import { TooltipSingletonTarget } from '../tooltips/TooltipSingletonTarget';

interface ImageHelperBaseProps {
  className?: string;
  src: string;
  srcSetVersions?: ImageSrcSetVersions;
  alt: string;
  title?: string;
  version?: ImageVersions;
  loading?: 'lazy' | 'eager';
  width?: never;
  height?: never;
}

interface ImageHelperBaseWithTooltipAltProps {
  tooltipAlt: boolean;
  tooltipAltInteractive?: boolean;
  tooltipAltAppendToBody?: boolean;
  tooltipSingleton?: boolean;
}

interface ImageHelperBaseWithoutTooltipAltProps {
  tooltipAlt?: never;
  tooltipAltInteractive?: never;
  tooltipAltAppendToBody?: never;
  tooltipSingleton?: never;
}

type ImageHelperProps = ImageHelperBaseProps &
  (ImageHelperBaseWithTooltipAltProps | ImageHelperBaseWithoutTooltipAltProps);

function ImageHelper({
  className,
  src,
  srcSetVersions,
  alt,
  title,
  version,
  width,
  height,
  loading = 'lazy',
  tooltipAlt,
  tooltipAltInteractive,
  tooltipAltAppendToBody,
  tooltipSingleton
}: ImageHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const tooltipView = tooltipAlt ? (
    <Tooltip
      placement={TooltipPlacement.TOP}
      referenceElement={referenceTooltipElement}
      tooltipText={alt}
      interactive={tooltipAltInteractive}
      appendTo={tooltipAltAppendToBody ? bodyTooltipContainer : undefined}
      className="break-words"
      withArrow
    />
  ) : null;

  const tooltipSingletonView = tooltipAlt ? (
    <TooltipSingletonTarget
      referenceElement={referenceTooltipElement}
      tooltipText={alt}
    />
  ) : null;

  return (
    <Fragment>
      <img
        className={className}
        src={Files.urlFromFile(src, version)}
        srcSet={
          srcSetVersions ? generateImageSrcSet(src, srcSetVersions) : undefined
        }
        alt={alt}
        title={title}
        width={width}
        height={height}
        loading={loading}
        ref={setReferenceTooltipElement}
      />
      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </Fragment>
  );
}

export default ImageHelper;
