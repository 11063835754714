import { components, OptionProps } from 'react-select';
import has from 'lodash/has';

import { MultiSelectExtendedDataType } from '../../types';

import { ValueImage } from '../ValueImage';

function Option({
  children,
  ...props
}: OptionProps<MultiSelectExtendedDataType>) {
  return (
    <components.Option {...props}>
      {has(props.data, 'image') ? (
        <div className="flex-1 flex relative items-center space-x-3 truncate">
          <div className="flex-shrink-0">
            <ValueImage
              image={props.data.image}
              label={props.data.label as string}
              className="rounded-full h-8 w-8"
            />
          </div>
          <div className="flex-1 min-w-0">
            <p className="font-medium text-gray-900 dark:text-gray-200 truncate">
              {props.data?.label}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate space-x-1">
              {props.data?.description}
            </p>
          </div>
        </div>
      ) : (
        children
      )}
    </components.Option>
  );
}

export default Option;
