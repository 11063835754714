import React, { Fragment } from 'react';

import { AddFundsModalButtonDataInvoice } from '../../AddFundsModalButton.types';

import { Translate } from '../../../../../../../helpers/Translate';

import { invoicesKeys } from '../../../../../../../locales/keys';

interface AddFundsModalButtonInvoiceNotesAndTermsInfoProps {
  invoice: AddFundsModalButtonDataInvoice;
}

function AddFundsModalButtonInvoiceNotesAndTermsInfo({
  invoice
}: AddFundsModalButtonInvoiceNotesAndTermsInfoProps) {
  return (
    <Fragment>
      <div className="mt-4">
        <label className="text-xs font-semibold">
          <Translate id={invoicesKeys.notes} />:
        </label>
        <div className="text-xs text-gray-600 dark:text-gray-400">
          {invoice?.notes}
        </div>
      </div>
      <div className="mt-2">
        <label className="text-xs font-semibold">
          <Translate id={invoicesKeys.terms} />:
        </label>
        <div className="text-xs text-gray-600 dark:text-gray-400">
          {invoice?.terms}
        </div>
      </div>
    </Fragment>
  );
}

export default AddFundsModalButtonInvoiceNotesAndTermsInfo;
