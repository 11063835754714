const avBillingInfosBase = 'av-billing-infos';

export class AvBillingInfoCache {
  static indexCacheKey() {
    return avBillingInfosBase;
  }
  static selectCacheKey() {
    return `${avBillingInfosBase}-select`;
  }
}
