import { IconProps } from '../types';

function UserSubtractOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      id={id}
      className={className || 'h-6 w-6'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
      ></path>
    </svg>
  );
}

export default UserSubtractOutlineIcon;
