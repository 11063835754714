import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ReportSectionsKeyType {
  singular: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  update: LocalesKey;
  delete: LocalesKey;
  selectSection: LocalesKey;
}

export const reportSectionsKeys = keyPathMirror<ReportSectionsKeyType, string>(
  {
    singular: null,
    plural: null,
    create: null,
    update: null,
    delete: null,
    selectSection: null
  },
  'models.reportSections.'
);
