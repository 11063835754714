import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface PaymentsKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
}

export const paymentsKeys = keyPathMirror<PaymentsKeyType, string>(
  {
    plural: null,
    singular: null
  },
  'models.payments.'
);
