import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface LibrariesKeyType {
  addCategory: LocalesKey;
  addAttribute: LocalesKey;
  addSubcategories: LocalesKey;
  addSubcategoryToTables: LocalesKey;
  addOptions: LocalesKey;
  allCategories: LocalesKey;
  attributes: LocalesKey;
  categories: LocalesKey;
  categoryName: LocalesKey;
  create: LocalesKey;
  createYourOwnLibrary: LocalesKey;
  free: LocalesKey;
  premium: LocalesKey;
  removeAttribute: LocalesKey;
  removeCategory: LocalesKey;
  removeOption: LocalesKey;
  type: LocalesKey;
  multiple: LocalesKey;
  name: LocalesKey;
  options: LocalesKey;
  attributeFilters: {
    disableFilter: LocalesKey;
    searchLine: LocalesKey;
    searchWithCheckboxes: LocalesKey;
    radioSelector: LocalesKey;
    dropdownSelector: LocalesKey;
    dropdownMultiselector: LocalesKey;
    rangeSelector: LocalesKey;
    checkbox: LocalesKey;
    yesNoAnyRadioSelector: LocalesKey;
  };
  attributeTypes: {
    text: LocalesKey;
    number: LocalesKey;
    selector: LocalesKey;
    checkbox: LocalesKey;
    tags: LocalesKey;
  };
  attributeDefaultValues: {
    checked: LocalesKey;
    unchecked: LocalesKey;
  };
}

export const librariesKeys = keyPathMirror<LibrariesKeyType, string>(
  {
    addCategory: null,
    addAttribute: null,
    addSubcategories: null,
    addSubcategoryToTables: null,
    addOptions: null,
    allCategories: null,
    attributes: null,
    categories: null,
    categoryName: null,
    create: null,
    createYourOwnLibrary: null,
    free: null,
    premium: null,
    removeAttribute: null,
    removeCategory: null,
    removeOption: null,
    type: null,
    multiple: null,
    name: null,
    options: null,
    attributeFilters: {
      disableFilter: null,
      searchLine: null,
      searchWithCheckboxes: null,
      radioSelector: null,
      dropdownSelector: null,
      dropdownMultiselector: null,
      rangeSelector: null,
      checkbox: null,
      yesNoAnyRadioSelector: null
    },
    attributeTypes: {
      text: null,
      number: null,
      selector: null,
      checkbox: null,
      tags: null
    },
    attributeDefaultValues: {
      checked: null,
      unchecked: null
    }
  },
  'models.libraries.'
);
