export default {
  downloadExcel: 'Завантажити таблицю з оцінками',
  plural: 'Оцінки',
  set: 'Набір оцінок',
  singular: 'Оцінка',
  no: 'Немає оцінок',
  fields: {
    accuracy: 'Точність',
    communication: 'Комунікація',
    timing: 'Таймінг',
    quality: 'Якість',
    taskError: 'Помилка задачі',
    projectError: 'Помилка проекту',
    profileError: 'Помилка профілю',
    briefError: 'Помилка брифа',
    estimateError: 'Помилка оцінки',
    styleError: 'Стилістична помилка',
    burningTasks: 'Гарячі задачі',
    respondRate: 'Швидкість відповіді',
    estimateRate: 'Оцінка вартості',
    qualityAssurance: 'Забезпечення якості',
    textureLightingCamera: 'Текстура/Освітлення/Камера',
    compositionDetailsStorytelling: 'Композиція/Деталі/Сюжет'
  }
};
