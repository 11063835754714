import { useCallback, useState } from 'react';

import {
  FetchPageCacheKey,
  FetchPageGqlQuery,
  PageNanoID,
  PageUUID
} from '../../pagesTypes';

import { usePage } from '../usePage';

interface UsePageItem {
  uuid: PageUUID;
}

interface PageOptions {
  uuid: PageUUID | PageNanoID;
  query: FetchPageGqlQuery;
  cacheKey: FetchPageCacheKey;
  initFetch?: boolean;
}

function usePageShowPage<ItemType extends UsePageItem>({
  uuid,
  query,
  cacheKey,
  initFetch = false
}: PageOptions) {
  const [pageEnabled, setPageEnabled] = useState<boolean>(initFetch);

  const { page, pageLoading, pageError } = usePage<ItemType>({
    pages: [],
    cacheKey,
    query,
    uuid,
    options: {
      enabled: pageEnabled,
      enabledPlaceholder: pageEnabled
    }
  });

  const togglePageData = useCallback<() => void>(
    () => setPageEnabled((prevState) => !prevState),
    [setPageEnabled]
  );

  return {
    page,
    pageLoading,
    pageError,
    togglePageData
  };
}

export default usePageShowPage;
