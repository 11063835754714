import {
  DeleteDownloadGqlQuery,
  DeleteDownloadGqlStatus,
  DownloadGqlError,
  DownloadUUID
} from '../../downloadsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';
import { DownloadCache } from '../../DownloadCache';

interface DeleteDownloadInput {
  uuid: DownloadUUID;
}

interface DeleteDownloadResponse {
  deleteDownload: {
    status: DeleteDownloadGqlStatus;
    errors: DeleteDownloadError;
  };
}

interface DeleteDownloadError {
  fullMessages: DownloadGqlError;
}

interface DeleteDownloadOptions {
  query: DeleteDownloadGqlQuery;
}

const action = 'deleteDownload';
const pluralScope = DownloadCache.indexCacheKey();

function useDeleteDownload({ query }: DeleteDownloadOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteDownloadInput,
    DeleteDownloadResponse,
    DeleteDownloadError
  >({ action, pluralScope, query });

  return {
    deleteDownloadData: deleteQueryData,
    deleteDownloadError: deleteQueryError,
    deleteDownloadLoading: deleteQueryLoading,
    deleteDownloadErrorMessage: deleteQueryErrorMessage,
    deleteDownload: deleteQuery,
    deleteDownloadReset: deleteQueryReset
  };
}

export default useDeleteDownload;
