import React, { Fragment, ReactNode } from 'react';
import { isIOS } from 'react-device-detect';

import { useAuth } from '../../../auth/hooks/useAuth';
import { usePreserveScroll } from '../../hooks/usePreserveScroll';

import { MenuProvider } from '../../hooks/useMenu';

import { PrimaryMenu } from '../../components/menus/PrimaryMenu';

import { HeadMeta } from '../../../helpers/HeadMeta';
import { HeadTitle } from '../../../helpers/HeadTitle';
import { LoadingSkeleton } from '../../../helpers/LoadingSkeleton';

interface MainAppLayoutProps {
  children: ReactNode;
  onLogoutUser: () => void;
}

function MainAppLayout({ children, onLogoutUser }: MainAppLayoutProps) {
  const auth = useAuth();

  usePreserveScroll();

  return (
    <MenuProvider>
      {isIOS && <HeadMeta />}
      <HeadTitle />
      <div className="relative flex flex-col-reverse sm:flex-row w-full h-full overflow-hidden">
        <Fragment>
          <PrimaryMenu onLogoutUser={onLogoutUser} />
          {auth?.authFetched && auth?.isAuthenticated ? (
            children
          ) : (
            <div
              id="main-section"
              className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
            >
              <LoadingSkeleton loaded={false} />
            </div>
          )}
        </Fragment>
      </div>
    </MenuProvider>
  );
}

export default MainAppLayout;
