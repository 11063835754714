import { ChangeEvent, FocusEvent } from 'react';

import { ID, UUID } from './fields';

export type TrackTimeInItemGqlQuery = string;

export type ItemGqlError = string[] | null;

export interface Item {
  id: ID;
  uuid: UUID;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export type ItemsFilters = Record<string, any>;

export type FetchItemsFilters = Record<string, any>;

export type AfterSubmitFunc = () => void;
// export type AfterCloseFunc = () => void;

// export interface ItemsPaginationInfo {
//   count: number;
//   success: boolean;
// }

// export type Permission = string;
//
// export type Permissions = { [key: string]: string };
//
// export interface Image extends Item {
//   file?: string;
// }

// export interface CurrentUser {
//   id: ID;
//   name: string;
//   get: (field: string) => string;
//   permissions: Permissions;
//   hasPermissions: (action: string, allAction?: string, id?: ID) => boolean;
//   // full_name: string;
//   // tasks_count: number;
//   // google_tag_user_id: string | null;
//   // image: Image | null;
//   locale: Locale;
//   isClient: () => boolean;
//   isEn: () => boolean;
//   isRu: () => boolean;
//   isUk: () => boolean;
// }

export enum FiltersPopoverTypesEnum {
  ACTIVE_FILTERS = 'ACTIVE_FILTERS',
  SAVED_FILTERS = 'SAVED_FILTERS',
  NONE = 'NONE'
}

export type ItemsIndexPageStateType = {
  filtersPopoverType?: FiltersPopoverTypesEnum;
  sidebarItemId?: ID | null;
};

export type ItemStatus =
  | 'definition'
  | 'qa'
  | 'checking'
  | 'incomplete_specification'
  | 'paymentWaiting'
  | 'paid'
  | 'paused'
  | 'implementation'
  | 'acceptance'
  | 'performed'
  | 'canceled';

export type InviteUsersType = {
  uuid: UUID;
  userIds: ID[];
};

export type RemoveItemMembersType = {
  uuid: UUID;
  userIds: ID[];
};

export type AssignUserToItemType = {
  uuid: UUID;
  userId: ID;
};

export type UpdateItemTagIdsType = {
  uuid: ID;
  tagIds: ID[];
};

export type RegisterFormFieldType<InputType> = {
  onBlur: (e: FocusEvent<InputType>) => void;
  onChange: (e: ChangeEvent<InputType>) => void;
  ref: React.Ref<InputType>;
  name: string;
};

export enum PositionStyleEnum {
  absolute = 'absolute',
  fixed = 'fixed'
}
