import { AiSearchKeyType } from '../../keys';

const aiSearchKeys: AiSearchKeyType = {
  aiPoweredCLIPSearch: 'AI-Powered CLIP Search',
  aiPoweredSearch20: 'AI-Powered Search 2.0',
  aiPoweredSearch30: 'AI-Powered Search 3.0',
  aiPoweredSearch: 'AI-Powered Search',
  aiPoweredSearchDetect: 'AI-Powered Search (Detect)',
  findAlternatives: 'Find alternatives',
  findSimilar: 'Find similar',
  imageSearch: 'Image Search',
  objectsFoundInTheSourceImage: 'Objects found in the source image',
  pleaseUploadASinglePhoto:
    'Please upload a single photo of your room, and our AI will detect the furniture pieces and find the most similar products from our 3D library.',
  search: 'Search',
  sourceImage: 'Source image',
  sourceProduct: 'Source product',
  uploadAPhotoOfARoom:
    'Upload a photo of a room, and our AI will find furniture items for you.',
  uploadAPhotoOfYourFurnitureAndWellHelpYouFindMatchingItems:
    "Upload a photo of your furniture, and we'll help you find matching items."
};

export default aiSearchKeys;
