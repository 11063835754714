import React, { Fragment } from 'react';
import compact from 'lodash/compact';

import format from 'date-fns/format';

import { Locale } from '../../types';

import { dateFnsLocales } from '../../utils/dateFnsLocales';

interface DateTimeHelperProps {
  date: string;
  locale?: Locale;
  options?: { withDayOfWeek: boolean };
}

function DateTimeHelper({
  date,
  locale = 'en',
  options = { withDayOfWeek: false }
}: DateTimeHelperProps) {
  const dateObj = new Date(date);
  const localeObj = dateFnsLocales.getLocale(locale);
  const formatOptions = localeObj ? { locale: localeObj } : {};

  return (
    <Fragment>
      {date
        ? compact([
            options.withDayOfWeek
              ? format(dateObj, 'EEEE', formatOptions)
              : null,
            format(dateObj, 'dd MMM yyyy', formatOptions),
            format(dateObj, 'HH:mm', formatOptions)
          ]).join(', ')
        : null}
    </Fragment>
  );
}

export default DateTimeHelper;
