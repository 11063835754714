import { IconProps } from '../types';

function LabelOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      strokeWidth="2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.7 11.2c.2.2.3.5.3.8s-.1.6-.3.8l-5 5.2c-.7.7-1.5 1-2.4 1h-7C4.5 19 3 17.5 3 15.5v-7C3 6.5 4.5 5 6.4 5h7c.9 0 1.8.4 2.4 1.1l4.9 5.1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LabelOutlineIcon;
