import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface SupersetReportsKeyType {
  create: LocalesKey;
  delete: LocalesKey;
  deleteReport: LocalesKey;
  editReport: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  supersetDashboardId: LocalesKey;
  update: LocalesKey;
}

export const supersetReportsKeys = keyPathMirror<
  SupersetReportsKeyType,
  string
>(
  {
    create: null,
    delete: null,
    deleteReport: null,
    editReport: null,
    plural: null,
    singular: null,
    supersetDashboardId: null,
    update: null
  },
  'models.supersetReports.'
);
