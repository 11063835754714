import { ClassName } from '../../types';

interface DropdownDividerProps {
  className?: ClassName;
}

function DropdownDivider({ className }: DropdownDividerProps) {
  return (
    <hr
      className={
        className || 'border-gray-200 dark:border-gray-600 mx-2.5 my-1.5'
      }
    />
  );
}

export default DropdownDivider;
