import { IconProps } from '../types';

function LabelSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7 9.3c.2.2.3.4.3.7 0 .3-.1.5-.3.7l-4.4 4.4c-.6.6-1.3.9-2.1.9H5c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h6.2c.8 0 1.6.3 2.1.9l4.4 4.4z"
      />
    </svg>
  );
}

export default LabelSolidIcon;
