import { IconProps } from '../types';

function UserSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-6 w-6'}
      id={id}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
      />
    </svg>
  );
}

export default UserSolidIcon;
