export default {
  plural: 'Запрошення',
  accept: 'Прийняти',
  acceptAll: 'Прийняти всі',
  acceptSelected: 'Прийняти обрані',
  decline: 'Відхилити',
  declineAll: 'Відхилити всі',
  declineSelected: 'Відхилити обрані',
  search: 'Пошук запрошень за назвою проекту',
  areTouSureYouWantToAcceptAllProjectInvitationsThisActionCannotBeUndone:
    'Ви впевнені, що хочете прийняти всі запрошення в проекти? Цю дію неможливо скасувати.',
  areTouSureYouWantToDeclineAllProjectInvitationsThisActionCannotBeUndone:
    'Ви впевнені, що хочете відхилити всі запрошення в проекти? Цю дію неможливо скасувати.',
  areYouSureYouWantToDeclineProjectInvitationThisActionCannotBeUndone:
    'Ви впевнені, що хочете відхилити запрошення в проект? Цю дію неможливо скасувати.'
};
