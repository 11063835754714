import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { CLEAR_ITEMS_FILTERS } from '../../indexRequestConstants';

function clearItemsFiltersAction(): IndexRequestAction {
  return {
    type: CLEAR_ITEMS_FILTERS
  };
}

export default clearItemsFiltersAction;
