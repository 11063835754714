import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface FormsFieldsKeyType {
  access: LocalesKey;
  account: LocalesKey;
  actionPlural: LocalesKey;
  action: LocalesKey;
  activityDate: LocalesKey;
  additionalEmail1: LocalesKey;
  additionalEmail2: LocalesKey;
  additionalInformation: LocalesKey;
  additionalInfo: LocalesKey;
  additionalDiscount: LocalesKey;
  address: LocalesKey;
  agreedHourlyRate: LocalesKey;
  amount: LocalesKey;
  applicationUacCgi: LocalesKey;
  android: LocalesKey;
  artist: LocalesKey;
  archivizerTeamLocalTime: LocalesKey;
  author: LocalesKey;
  airTableEmail: LocalesKey;
  airTablePassword: LocalesKey;
  airTableApiKey: LocalesKey;
  airTableBaseId: LocalesKey;
  airTableTableName: LocalesKey;
  bff: LocalesKey;
  businessEmail: LocalesKey;
  blockedDate: LocalesKey;
  category: LocalesKey;
  cell: LocalesKey;
  cells: LocalesKey;
  city: LocalesKey;
  cdn: LocalesKey;
  clientPrepayment: LocalesKey;
  clientStatus: LocalesKey;
  clientManagers: LocalesKey;
  clientLocalTime: LocalesKey;
  coldSource: LocalesKey;
  colorTag: LocalesKey;
  confirmPassword: LocalesKey;
  commission: LocalesKey;
  company: LocalesKey;
  companyName: LocalesKey;
  companySize: LocalesKey;
  companyStatus: LocalesKey;
  companyCreator: LocalesKey;
  companyOwner: LocalesKey;
  companyCrm: LocalesKey;
  companyRevenue: LocalesKey;
  companyType: LocalesKey;
  complexity: LocalesKey;
  competencies: LocalesKey;
  contactName: LocalesKey;
  contactPhone: LocalesKey;
  contactEmail: LocalesKey;
  receiver: LocalesKey;
  country: LocalesKey;
  coworking: LocalesKey;
  creditCommission: LocalesKey;
  dateFormat: LocalesKey;
  dateOfSettingTestProject: LocalesKey;
  deleteAccount: LocalesKey;
  hostingCommission: LocalesKey;
  holdRate: LocalesKey;
  team: LocalesKey;
  balance: LocalesKey;
  balanceType: LocalesKey;
  bankDetails: LocalesKey;
  blocked: LocalesKey;
  body: LocalesKey;
  burning: LocalesKey;
  burningOnly: LocalesKey;
  created: LocalesKey;
  createdAt: LocalesKey;
  createdDate: LocalesKey;
  criticalRequirements: LocalesKey;
  currency: LocalesKey;
  currentPassword: LocalesKey;
  currencyExchangeRate: LocalesKey;
  changePassword: LocalesKey;
  companyFinStatus: LocalesKey;
  coRevenueSharer: LocalesKey;
  danger: LocalesKey;
  defaultForNewClients: LocalesKey;
  defaultShare: LocalesKey;
  defaultBillingAddress: LocalesKey;
  defaultPayoneerAccount: LocalesKey;
  description: LocalesKey;
  debtLimit: LocalesKey;
  deferredPaymentDays: LocalesKey;
  discount: LocalesKey;
  dpi: LocalesKey;
  defaultProjectPrice: LocalesKey;
  defaultProjectBudgetLimit: LocalesKey;
  defaultTaskPrice: LocalesKey;
  email: LocalesKey;
  emailAddress: LocalesKey;
  estimateAmount: LocalesKey;
  estimateCost: LocalesKey;
  estimateDate: LocalesKey;
  estimateSendingDate: LocalesKey;
  estimatedByCm: LocalesKey;
  estimatedWorkTime: LocalesKey;
  exclusion: LocalesKey;
  expectedPaymentDate: LocalesKey;
  expectedPaymentAmount: LocalesKey;
  expectedTime: LocalesKey;
  experience: LocalesKey;
  facebook: LocalesKey;
  favorites: LocalesKey;
  feedback: LocalesKey;
  firstName: LocalesKey;
  firstNameUk: LocalesKey;
  firstNameEn: LocalesKey;
  fileExtension: LocalesKey;
  fileFormat: LocalesKey;
  filterName: LocalesKey;
  filterParts: LocalesKey;
  filterToUpdate: LocalesKey;
  financeRole: LocalesKey;
  financeRoles: LocalesKey;
  financeTags: LocalesKey;
  firstPaymentDate: LocalesKey;
  finFirstPaymentDate: LocalesKey;
  format: LocalesKey;
  frontend: LocalesKey;
  fullName: LocalesKey;
  groups: LocalesKey;
  height: LocalesKey;
  hours: LocalesKey;
  hoursMinutes: LocalesKey;
  id: LocalesKey;
  ios: LocalesKey;
  indexedAt: LocalesKey;
  imageUrl: LocalesKey;
  implementationDate: LocalesKey;
  implementedAt: LocalesKey;
  industry: LocalesKey;
  integration: LocalesKey;
  invoiced: LocalesKey;
  isMentor: LocalesKey;
  interfaceLanguage: LocalesKey;
  itn: LocalesKey;
  itemCategory: LocalesKey;
  kind: LocalesKey;
  rateEducationPoint: LocalesKey;
  lastActivityTime: LocalesKey;
  lastActivity: LocalesKey;
  lastPaymentDate: LocalesKey;
  finLastPaymentDate: LocalesKey;
  label: LocalesKey;
  linkedIn: LocalesKey;
  instagram: LocalesKey;
  twitter: LocalesKey;
  leadCreationDate: LocalesKey;
  manualClientStatus: LocalesKey;
  manualStatus: LocalesKey;
  marketingSource: LocalesKey;
  maxPrice: LocalesKey;
  mentor: LocalesKey;
  mentors: LocalesKey;
  message: LocalesKey;
  bodyEn: LocalesKey;
  bodyUk: LocalesKey;
  minPrice: LocalesKey;
  minutes: LocalesKey;
  myProjects: LocalesKey;
  myTasks: LocalesKey;
  name: LocalesKey;
  nanoId: LocalesKey;
  nameEn: LocalesKey;
  nameUk: LocalesKey;
  natureOfLead: LocalesKey;
  none: LocalesKey;
  newFilterName: LocalesKey;
  nda: LocalesKey;
  noGo: LocalesKey;
  notes: LocalesKey;
  newPassword: LocalesKey;
  other: LocalesKey;
  operationHours: LocalesKey;
  partnershipAgreement: LocalesKey;
  paid: LocalesKey;
  paymentType: LocalesKey;
  paymentMethods: LocalesKey;
  passport: LocalesKey;
  payoneerIdLabel: LocalesKey;
  payoneerIdGme: LocalesKey;
  payoneerIdPeGme: LocalesKey;
  payoneerIdAvltd: LocalesKey;
  payoneerIdPeAvltd: LocalesKey;
  password: LocalesKey;
  phone: LocalesKey;
  position: LocalesKey;
  price: LocalesKey;
  privateEntrepreneur: LocalesKey;
  preferableCurrency: LocalesKey;
  prepayment: LocalesKey;
  proficiencyLevel: LocalesKey;
  profilePicture: LocalesKey;
  project: LocalesKey;
  projectManager: LocalesKey;
  projectManagers: LocalesKey;
  providedFullName: LocalesKey;
  providedFullNameEn: LocalesKey;
  providedFullNameUk: LocalesKey;
  priority: LocalesKey;
  qualifications: LocalesKey;
  ratio: LocalesKey;
  rating: LocalesKey;
  realDeclineReasons: LocalesKey;
  received: LocalesKey;
  referer: LocalesKey;
  registrationDate: LocalesKey;
  role: LocalesKey;
  roles: LocalesKey;
  requirements: LocalesKey;
  resolutionDpi: LocalesKey;
  resolutionHeight: LocalesKey;
  resolutionWidth: LocalesKey;
  totalBalance: LocalesKey;
  share: LocalesKey;
  shares: LocalesKey;
  schoolAgreement: LocalesKey;
  status: LocalesKey;
  systemAccount: LocalesKey;
  source: LocalesKey;
  salesManager: LocalesKey;
  section: LocalesKey;
  sourceFilesCount: LocalesKey;
  specializations: LocalesKey;
  state: LocalesKey;
  started: LocalesKey;
  startDate: LocalesKey;
  storageOption: LocalesKey;
  street: LocalesKey;
  subCategories: LocalesKey;
  subject: LocalesKey;
  task: LocalesKey;
  lastName: LocalesKey;
  lastNameEn: LocalesKey;
  lastNameUk: LocalesKey;
  lastUpdate: LocalesKey;
  surname: LocalesKey;
  surnameEn: LocalesKey;
  surnameUk: LocalesKey;
  scopeOfWork: LocalesKey;
  tag: LocalesKey;
  tasksPrice: LocalesKey;
  taskTeam: LocalesKey;
  testProjectFinished: LocalesKey;
  testProjectStarted: LocalesKey;
  testProjectCreatedAt: LocalesKey;
  text: LocalesKey;
  title: LocalesKey;
  timeZone: LocalesKey;
  timeFormat: LocalesKey;
  threeDServices: LocalesKey;
  type: LocalesKey;
  updatedAt: LocalesKey;
  uuid: LocalesKey;
  user: LocalesKey;
  useCustomRating: LocalesKey;
  updated: LocalesKey;
  url: LocalesKey;
  unread: LocalesKey;
  validateOutput: LocalesKey;
  vatNumber: LocalesKey;
  webSite: LocalesKey;
  width: LocalesKey;
  wig: LocalesKey;
  workflowType: LocalesKey;
  zipCode: LocalesKey;
}

export const formsFields = keyPathMirror<FormsFieldsKeyType, string>(
  {
    access: null,
    account: null,
    actionPlural: null,
    action: null,
    activityDate: null,
    additionalEmail1: null,
    additionalEmail2: null,
    additionalInformation: null,
    additionalInfo: null,
    additionalDiscount: null,
    address: null,
    agreedHourlyRate: null,
    amount: null,
    android: null,
    applicationUacCgi: null,
    artist: null,
    archivizerTeamLocalTime: null,
    author: null,
    airTableEmail: null,
    airTablePassword: null,
    airTableApiKey: null,
    airTableBaseId: null,
    airTableTableName: null,
    bankDetails: null,
    bff: null,
    businessEmail: null,
    blockedDate: null,
    category: null,
    cell: null,
    cells: null,
    city: null,
    cdn: null,
    clientPrepayment: null,
    clientStatus: null,
    clientManagers: null,
    clientLocalTime: null,
    coldSource: null,
    colorTag: null,
    confirmPassword: null,
    commission: null,
    company: null,
    companyName: null,
    companySize: null,
    companyStatus: null,
    companyCreator: null,
    companyOwner: null,
    companyCrm: null,
    companyRevenue: null,
    companyType: null,
    complexity: null,
    competencies: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    companyFinStatus: null,
    receiver: null,
    country: null,
    coworking: null,
    creditCommission: null,
    currencyExchangeRate: null,
    defaultShare: null,
    dateFormat: null,
    dateOfSettingTestProject: null,
    deleteAccount: null,
    changePassword: null,
    coRevenueSharer: null,
    hostingCommission: null,
    team: null,
    balance: null,
    balanceType: null,
    blocked: null,
    body: null,
    burning: null,
    burningOnly: null,
    created: null,
    createdAt: null,
    createdDate: null,
    criticalRequirements: null,
    currency: null,
    currentPassword: null,
    danger: null,
    debtLimit: null,
    defaultForNewClients: null,
    defaultBillingAddress: null,
    defaultPayoneerAccount: null,
    description: null,
    deferredPaymentDays: null,
    discount: null,
    dpi: null,
    defaultProjectPrice: null,
    defaultProjectBudgetLimit: null,
    defaultTaskPrice: null,
    email: null,
    emailAddress: null,
    estimateAmount: null,
    estimateCost: null,
    estimateDate: null,
    estimateSendingDate: null,
    estimatedByCm: null,
    estimatedWorkTime: null,
    exclusion: null,
    expectedPaymentDate: null,
    expectedPaymentAmount: null,
    expectedTime: null,
    experience: null,
    facebook: null,
    favorites: null,
    feedback: null,
    fileExtension: null,
    fileFormat: null,
    filterName: null,
    filterParts: null,
    filterToUpdate: null,
    financeRole: null,
    financeRoles: null,
    financeTags: null,
    firstPaymentDate: null,
    finFirstPaymentDate: null,
    finLastPaymentDate: null,
    firstName: null,
    firstNameUk: null,
    firstNameEn: null,
    frontend: null,
    fullName: null,
    format: null,
    groups: null,
    height: null,
    holdRate: null,
    hours: null,
    hoursMinutes: null,
    id: null,
    ios: null,
    indexedAt: null,
    imageUrl: null,
    implementationDate: null,
    implementedAt: null,
    industry: null,
    integration: null,
    invoiced: null,
    isMentor: null,
    interfaceLanguage: null,
    itn: null,
    itemCategory: null,
    kind: null,
    rateEducationPoint: null,
    lastActivityTime: null,
    lastActivity: null,
    lastPaymentDate: null,
    label: null,
    linkedIn: null,
    instagram: null,
    twitter: null,
    leadCreationDate: null,
    manualClientStatus: null,
    manualStatus: null,
    marketingSource: null,
    maxPrice: null,
    mentor: null,
    mentors: null,
    message: null,
    bodyEn: null,
    bodyUk: null,
    minPrice: null,
    minutes: null,
    myProjects: null,
    myTasks: null,
    name: null,
    nanoId: null,
    nameEn: null,
    nameUk: null,
    natureOfLead: null,
    none: null,
    newFilterName: null,
    notes: null,
    lastName: null,
    lastNameEn: null,
    lastNameUk: null,
    lastUpdate: null,
    surname: null,
    surnameEn: null,
    surnameUk: null,
    scopeOfWork: null,
    nda: null,
    noGo: null,
    other: null,
    operationHours: null,
    partnershipAgreement: null,
    paid: null,
    paymentType: null,
    paymentMethods: null,
    privateEntrepreneur: null,
    passport: null,
    payoneerIdLabel: null,
    payoneerIdGme: null,
    payoneerIdPeGme: null,
    payoneerIdAvltd: null,
    payoneerIdPeAvltd: null,
    password: null,
    newPassword: null,
    phone: null,
    position: null,
    price: null,
    preferableCurrency: null,
    prepayment: null,
    proficiencyLevel: null,
    profilePicture: null,
    project: null,
    projectManager: null,
    projectManagers: null,
    providedFullName: null,
    providedFullNameEn: null,
    providedFullNameUk: null,
    priority: null,
    qualifications: null,
    realDeclineReasons: null,
    received: null,
    referer: null,
    registrationDate: null,
    ratio: null,
    rating: null,
    role: null,
    roles: null,
    requirements: null,
    resolutionDpi: null,
    resolutionHeight: null,
    resolutionWidth: null,
    totalBalance: null,
    share: null,
    shares: null,
    schoolAgreement: null,
    salesManager: null,
    section: null,
    systemAccount: null,
    status: null,
    source: null,
    sourceFilesCount: null,
    specializations: null,
    state: null,
    started: null,
    startDate: null,
    storageOption: null,
    street: null,
    subCategories: null,
    subject: null,
    tag: null,
    task: null,
    tasksPrice: null,
    taskTeam: null,
    testProjectFinished: null,
    testProjectStarted: null,
    testProjectCreatedAt: null,
    text: null,
    title: null,
    timeZone: null,
    timeFormat: null,
    threeDServices: null,
    type: null,
    useCustomRating: null,
    updated: null,
    updatedAt: null,
    uuid: null,
    user: null,
    url: null,
    unread: null,
    validateOutput: null,
    vatNumber: null,
    webSite: null,
    width: null,
    wig: null,
    workflowType: null,
    zipCode: null
  },
  'forms.fields.'
);
