import { IconProps } from '../types';

function WithdrawalOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      className={className || 'h-6 w-6'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 8.5V20c0 .6.4 1 1 1h8c.6 0 1-.4 1-1V8.5M12 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm-5 3c1.1 0 2 .9 2 2m6 0c0-1.1.9-2 2-2M5.9 8.5h12.2M7 14H4c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h16c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1h-3"
      />
    </svg>
  );
}

export default WithdrawalOutlineIcon;
