import {
  useCallback,
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';

import { FiltersViewGroups } from '../../../types';

import {
  ExtendedFiltersBaseFilters,
  ExtendedFiltersGroups,
  ExtendedFiltersGeneratedViewGroup,
  ExtendedFiltersContextStateByViewGroup,
  ExtendedFiltersContextChangeStateByViewGroupAction,
  ExtendedFiltersContext
} from './useExtendedFilters.types';

import { useMountEffect } from '../../../common/hooks/useMountEffect';
import { usePreviousValue } from '../../../common/hooks/usePreviousValue';

import { useExtendedFiltersFilters } from './hooks/useExtendedFiltersFilters';
import { useExtendedFiltersChange } from './hooks/useExtendedFiltersChange';

import { extendedFiltersMergeGroups } from './utils/extendedFiltersMergeGroups';
import { generateNanoId } from '../../../utils/generateNanoId';

const ExtendedFiltersContext = createContext<ExtendedFiltersContext>({
  filtersStateByViewGroup: {},
  filtersStateByViewGroupRef: { current: {} },
  changeFiltersStateByViewGroup: () => {
    console.log('error: changeFiltersStateByViewGroup should be initialized');
  }
});

interface ExtendedFiltersProviderProps {
  children: ReactNode;
}

export function ExtendedFiltersProvider({
  children
}: ExtendedFiltersProviderProps) {
  const [filtersStateByViewGroup, setFiltersStateByViewGroup] =
    useState<ExtendedFiltersContextStateByViewGroup>({});

  const filtersStateByViewGroupRef =
    useRef<ExtendedFiltersContextStateByViewGroup>({});

  const changeFiltersStateByViewGroup =
    useCallback<ExtendedFiltersContextChangeStateByViewGroupAction>(
      (viewGroup, filtersState) => {
        setFiltersStateByViewGroup((prevValue) => {
          const newValue = {
            ...prevValue,
            [viewGroup]: filtersState
          };
          filtersStateByViewGroupRef.current = newValue;

          return newValue;
        });
      },
      []
    );

  const extendedFiltersContext = {
    filtersStateByViewGroupRef,
    filtersStateByViewGroup,
    changeFiltersStateByViewGroup
  };

  return (
    <ExtendedFiltersContext.Provider value={extendedFiltersContext}>
      {children}
    </ExtendedFiltersContext.Provider>
  );
}

interface ExtendedFiltersOptions<T extends ExtendedFiltersBaseFilters> {
  viewGroup?: FiltersViewGroups;
  initialPageFilters: T;
  initialTabFilters?: T;
  defaultSettingsFilters?: T;
  initialSettingsFilters?: T;
  initialSelectedFilters?: T;
  currentFilters: T;
  filterItems?: (filters: T) => void;
}

function useExtendedFilters<T extends ExtendedFiltersBaseFilters>({
  viewGroup,
  initialPageFilters,
  initialTabFilters,
  defaultSettingsFilters,
  initialSettingsFilters,
  initialSelectedFilters,
  currentFilters,
  filterItems
}: ExtendedFiltersOptions<T>) {
  const {
    filtersStateByViewGroup,
    filtersStateByViewGroupRef,
    changeFiltersStateByViewGroup
  } = useContext(ExtendedFiltersContext);

  const [generatedViewGroup] = useState<ExtendedFiltersGeneratedViewGroup>(() =>
    generateNanoId()
  );
  const receivedOrGeneratedViewGroup = viewGroup || generatedViewGroup;

  const { pageFilters, tabFilters, settingsFilters, selectedFilters } =
    useExtendedFiltersFilters({
      receivedOrGeneratedViewGroup,
      filtersStateByViewGroup
    });

  const {
    changeFiltersState,
    changePageFilters,
    changeTabFilters,
    changeSettingsFilters,
    changeSettingsFiltersWithoutSave,
    changeSelectedFilters,
    resetSelectedFilters,
    resetSettingsFilters
  } = useExtendedFiltersChange({
    receivedViewGroup: viewGroup,
    filtersStateByViewGroupRef,
    changeFiltersStateByViewGroup,
    receivedOrGeneratedViewGroup,
    defaultSettingsFilters,
    filterItems
  });

  const initializeFilters = useCallback<() => void>(() => {
    const initialFiltersState = pickBy({
      [ExtendedFiltersGroups.PAGE]: initialPageFilters || {},
      [ExtendedFiltersGroups.TAB]: initialTabFilters,
      [ExtendedFiltersGroups.SETTINGS]: initialSettingsFilters,
      [ExtendedFiltersGroups.SELECTED]: initialSelectedFilters
    });
    const resolvedFiltersState = changeFiltersState(initialFiltersState);

    const expectedFilters = extendedFiltersMergeGroups(resolvedFiltersState);

    if (!isEqual(expectedFilters, currentFilters)) {
      filterItems(expectedFilters as T);
    }
  }, [
    currentFilters,
    initialPageFilters,
    initialTabFilters,
    initialSelectedFilters,
    initialSettingsFilters,
    changeFiltersState,
    filterItems
  ]);

  useMountEffect(() => {
    initializeFilters();
  });

  const prevPageFilters = usePreviousValue(initialPageFilters);
  useEffect(() => {
    if (!isEqual(prevPageFilters, initialPageFilters)) {
      changePageFilters(initialPageFilters);
    }
  }, [prevPageFilters, changePageFilters, initialPageFilters]);

  const prevTabFilters = usePreviousValue(initialTabFilters);
  useEffect(() => {
    if (!isEqual(prevTabFilters, initialTabFilters)) {
      changeTabFilters(initialTabFilters);
    }
  }, [prevTabFilters, changeTabFilters, initialTabFilters]);

  const prevSelectedFilters = usePreviousValue(initialSelectedFilters);
  useEffect(() => {
    if (!isEqual(prevSelectedFilters, initialSelectedFilters)) {
      changeSelectedFilters(initialSelectedFilters);
    }
  }, [prevSelectedFilters, changeSelectedFilters, initialSelectedFilters]);

  return {
    pageFilters,
    tabFilters,
    settingsFilters,
    selectedFilters,
    changeTabFilters,
    changeSettingsFilters,
    changeSettingsFiltersWithoutSave,
    changeSelectedFilters,
    resetSelectedFilters,
    resetSettingsFilters
  };
}

export default useExtendedFilters;
