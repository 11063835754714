import React, { memo } from 'react';

import { IconsEnum } from '../../assets/icons/types';

import { AdjustmentsAltSolidIcon } from '../../assets/icons/AdjustmentsAltSolidIcon';
import { AdjustmentsOutlineIcon } from '../../assets/icons/AdjustmentsOutlineIcon';
import { AndroidIcon } from '../../assets/icons/AndroidIcon';
import { AnnotationSolidIcon } from '../../assets/icons/AnnotationSolidIcon';
import { AppleIcon } from '../../assets/icons/AppleIcon';
import { ArchiveBoxXMark } from '../../assets/icons/ArchiveBoxXMark';
import { ArchiveBoxXMarkOutlineIcon } from '../../assets/icons/ArchiveBoxXMarkOutlineIcon';
import { ArrowCircleUpSolidIcon } from '../../assets/icons/ArrowCircleUpSolidIcon';
import { ArrowDownCircleOutlineIcon } from '../../assets/icons/ArrowDownCircleOutlineIcon';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import { ArrowDownTriangleSolidIcon } from '../../assets/icons/ArrowDownTriangleSolidIcon';
import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { ArrowLeftOutlineIcon } from '../../assets/icons/ArrowLeftOutlineIcon';
import { ArrowNarrowDownSolidIcon } from '../../assets/icons/ArrowNarrowDownSolidIcon';
import { ArrowNarrowRightSolidIcon } from '../../assets/icons/ArrowNarrowRightSolidIcon';
import { ArrowNarrowUpOutlineIcon } from '../../assets/icons/ArrowNarrowUpOutlineIcon';
import { ArrowNarrowUpSolidIcon } from '../../assets/icons/ArrowNarrowUpSolidIcon';
import { ArrowPathOutlineIcon } from '../../assets/icons/ArrowPathOutlineIcon';
import { ArrowRightSolidIcon } from '../../assets/icons/ArrowRightSolidIcon';
import { ArrowRightTriangleSolidIcon } from '../../assets/icons/ArrowRightTriangleSolidIcon';
import { ArrowsExpandOutlineIcon } from '../../assets/icons/ArrowsExpandOutlineIcon';
import { ArrowsExpandSolidIcon } from '../../assets/icons/ArrowsExpandSolidIcon';
import { ArrowSmDownIcon } from '../../assets/icons/ArrowSmDownIcon';
import { ArrowSmLeftOutlineIcon } from '../../assets/icons/ArrowSmLeftOutlineIcon';
import { AtSymbolIcon } from '../../assets/icons/AtSymbolIcon';
import { AVIcon } from '../../assets/icons/AVIcon';
import { BanSolidIcon } from '../../assets/icons/BanSolidIcon';
import { BadgeCheckIcon } from '../../assets/icons/BadgeCheckIcon';
import { BellIcon } from '../../assets/icons/BellIcon';
import { BellCrossedSolidIcon } from '../../assets/icons/BellCrossedSolidIcon';
import { BellOutlineIcon } from '../../assets/icons/BellOutlineIcon';
import { BlankIcon } from '../../assets/icons/BlankIcon';
import { BlockIcon } from '../../assets/icons/BlockIcon';
import { BlueBellIcon } from '../../assets/icons/BlueBellIcon';
import { BookOpenSolidIcon } from '../../assets/icons/BookOpenSolidIcon';
import { BriefcaseSolidIcon } from '../../assets/icons/BriefcaseSolidIcon';
import { BurningIcon } from '../../assets/icons/BurningIcon';
import { CalendarOutlineIcon } from '../../assets/icons/CalendarOutlineIcon';
import { CalendarSolidIcon } from '../../assets/icons/CalendarSolidIcon';
import { CashIcon } from '../../assets/icons/CashIcon';
import { CashOutlineIcon } from '../../assets/icons/CashOutlineIcon';
import { CellsSolidIcon } from '../../assets/icons/CellsSolidIcon';
import { ChartBarOutlineIcon } from '../../assets/icons/ChartBarOutlineIcon';
import { ChartBarSquareSolidIcon } from '../../assets/icons/ChartBarSquareSolidIcon';
import { ChartPieIcon } from '../../assets/icons/ChartPieIcon';
import { ChartSquareBarOutlineIcon } from '../../assets/icons/ChartSquareBarOutlineIcon';
import { ChatAltOutlineIcon } from '../../assets/icons/ChatAltOutlineIcon';
import { ChatIcon } from '../../assets/icons/ChatIcon';
import { ChatOutlineIcon } from '../../assets/icons/ChatOutlineIcon';
import { CheckCircleOutlineIcon } from '../../assets/icons/CheckCircleOutlineIcon';
import { CheckCircleSolidIcon } from '../../assets/icons/CheckCircleSolidIcon';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { CheckOutlineIcon } from '../../assets/icons/CheckOutlineIcon';
import { CheckSquareSolidIcon } from '../../assets/icons/CheckSquareSolidIcon';
import { ChevronDoubleLeftIcon } from '../../assets/icons/ChevronDoubleLeftIcon';
import { ChevronDoubleRightIcon } from '../../assets/icons/ChevronDoubleRightIcon';
import { ChevronDownIcon } from '../../assets/icons/ChevronDownIcon';
import { ChevronDownSolidIcon } from '../../assets/icons/ChevronDownSolidIcon';
import { ChevronLeftIcon } from '../../assets/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../assets/icons/ChevronRightIcon';
import { ChevronUpIcon } from '../../assets/icons/ChevronUpIcon';
import { CircleOutlineIcon } from '../../assets/icons/CircleOutlineIcon';
import { CircleSolidIcon } from '../../assets/icons/CircleSolidIcon';
import { ClipboardCheckOutlineIcon } from '../../assets/icons/ClipboardCheckOutlineIcon';
import { ClipboardCheckSolidIcon } from '../../assets/icons/ClipboardCheckSolidIcon';
import { ClipboardDocumentCheckOutlineIcon } from '../../assets/icons/ClipboardDocumentCheckOutlineIcon';
import { ClipboardDocumentCheckSolidIcon } from '../../assets/icons/ClipboardDocumentCheckSolidIcon';
import { ClipboardListOutlineIcon } from '../../assets/icons/ClipboardListOutlineIcon';
import { ClipboardListSolidIcon } from '../../assets/icons/ClipboardListSolidIcon';
import { ClockIcon } from '../../assets/icons/ClockIcon';
import { ClockOutlineIcon } from '../../assets/icons/ClockOutlineIcon';
import { CloudUploadOutlineIcon } from '../../assets/icons/CloudUploadOutlineIcon';
import { CloudUploadSolidIcon } from '../../assets/icons/CloudUploadSolidIcon';
import { CogEmptyIcon } from '../../assets/icons/CogEmptyIcon';
import { CogIcon } from '../../assets/icons/CogIcon';
import { CollectionOutlineIcon } from '../../assets/icons/CollectionOutlineIcon';
import { CollectionSolidIcon } from '../../assets/icons/CollectionSolidIcon';
import { ColorSwatchOutlineIcon } from '../../assets/icons/ColorSwatchOutlineIcon';
import { ColorSwatchSolidIcon } from '../../assets/icons/ColorSwatchSolidIcon';
import { CommandOutlineIcon } from '../../assets/icons/CommandOutlineIcon';
import { ContactSupportIcon } from '../../assets/icons/ContactSupportIcon';
import { CopyIcon } from '../../assets/icons/CopyIcon';
import { CoronaSolidIcon } from '../../assets/icons/CoronaSolidIcon';
import { CreditCardSolidIcon } from '../../assets/icons/CreditCardSolidIcon';
import { CrossedBellIcon } from '../../assets/icons/CrossedBellIcon';
import { CrossIcon } from '../../assets/icons/CrossIcon';
import { CrownSolidIcon } from '../../assets/icons/CrownSolidIcon';
import { CubeOutlineIcon } from '../../assets/icons/CubeOutlineIcon';
import { CubeSolidIcon } from '../../assets/icons/CubeSolidIcon';
import { CubesOutlineIcon } from '../../assets/icons/CubesOutlineIcon';
import { CubeTransparentOutlineIcon } from '../../assets/icons/CubeTransparentOutlineIcon';
import { CubeTransparentSolidIcon } from '../../assets/icons/CubeTransparentSolidIcon';
import { CurrencyDollarSolidIcon } from '../../assets/icons/CurrencyDollarSolidIcon';
import { DatabaseOutlineIcon } from '../../assets/icons/DatabaseOutlineIcon';
import { DocumentArchiveSolidIcon } from '../../assets/icons/DocumentArchiveSolidIcon';
import { DocumentAudioSolidIcon } from '../../assets/icons/DocumentAudioSolidIcon';
import { DocumentDownloadOutlineIcon } from '../../assets/icons/DocumentDownloadOutlineIcon';
import { DocumentDuplicateOutlineIcon } from '../../assets/icons/DocumentDuplicateOutlineIcon';
import { DocumentMediasIcon } from '../../assets/icons/DocumentMediasIcon';
import { DocumentMinusSolidIcon } from '../../assets/icons/DocumentMinusSolidIcon';
import { DocumentOutlineIcon } from '../../assets/icons/DocumentOutlineIcon';
import { DocumentPdfSolidIcon } from '../../assets/icons/DocumentPdfSolidIcon';
import { DocumentReportOutlineIcon } from '../../assets/icons/DocumentReportOutlineIcon';
import { DocumentReportSolidIcon } from '../../assets/icons/DocumentReportSolidIcon';
import { DocumentSolidIcon } from '../../assets/icons/DocumentSolidIcon';
import { DocumentTableOutlineIcon } from '../../assets/icons/DocumentTableOutlineIcon';
import { DocumentTextOutlineIcon } from '../../assets/icons/DocumentTextOutlineIcon';
import { DocumentTextSolidIcon } from '../../assets/icons/DocumentTextSolidIcon';
import { DocumentUploadOutlineIcon } from '../../assets/icons/DocumentUploadOutlineIcon';
import { DocumentUploadSolidIcon } from '../../assets/icons/DocumentUploadSolidIcon';
import { DocumentVideoSolidIcon } from '../../assets/icons/DocumentVideoSolidIcon';
import { DotsHorizontalOutlineIcon } from '../../assets/icons/DotsHorizontalOutlineIcon';
import { DownArrowIcon } from '../../assets/icons/DownArrowIcon';
import { DownloadOutlineIcon } from '../../assets/icons/DownloadOutlineIcon';
import { DownloadSolidIcon } from '../../assets/icons/DownloadSolidIcon';
import { DrawCurveOutlineIcon } from '../../assets/icons/DrawCurveOutlineIcon';
import { ElasticSearchSolidIcon } from '../../assets/icons/ElasticSearchSolidIcon';
import { EmailSolidIcon } from '../../assets/icons/EmailSolidIcon';
import { EmptyStarIcon } from '../../assets/icons/EmptyStarIcon';
import { EmptyStarOffIcon } from '../../assets/icons/EmptyStarOffIcon';
import { EmojiSadSolidIcon } from '../../assets/icons/EmojiSadSolidIcon';
import { EnvelopeIcon } from '../../assets/icons/EnvelopeIcon';
import { ExclamationCircleIcon } from '../../assets/icons/ExclamationCircleIcon';
import { ExclamationIcon } from '../../assets/icons/ExclamationIcon';
import { ExclamationSolidIcon } from '../../assets/icons/ExclamationSolidIcon';
import { ExternalLinkSolidIcon } from '../../assets/icons/ExternalLinkSolidIcon';
import { EyeCrossedIcon } from '../../assets/icons/EyeCrossedIcon';
import { EyeIcon } from '../../assets/icons/EyeIcon';
import { EyeOffOutlineIcon } from '../../assets/icons/EyeOffOutlineIcon';
import { EyeOffSolidIcon } from '../../assets/icons/EyeOffSolidIcon';
import { EyeOutlineIcon } from '../../assets/icons/EyeOutlineIcon';
import { EyeSolidIcon } from '../../assets/icons/EyeSolidIcon';
import { EyeSplashSolidIcon } from '../../assets/icons/EyeSplashSolidIcon';
import { FilledStarIcon } from '../../assets/icons/FilledStarIcon';
import { FilterIcon } from '../../assets/icons/FilterIcon';
import { FilterListSolidIcon } from '../../assets/icons/FilterListSolidIcon';
import { FilterSolidIcon } from '../../assets/icons/FilterSolidIcon';
import { FireOutlineIcon } from '../../assets/icons/FireOutlineIcon';
import { FireSolidIcon } from '../../assets/icons/FireSolidIcon';
import { FlagIcon } from '../../assets/icons/FlagIcon';
import { FlagOffAltOutlineIcon } from '../../assets/icons/FlagOffAltOutlineIcon';
import { FlagOutlineIcon } from '../../assets/icons/FlagOutlineIcon';
import { FolderAltSolidIcon } from '../../assets/icons/FolderAltSolidIcon';
import { FolderCubeSolidIcon } from '../../assets/icons/FolderCubeSolidIcon';
import { FolderDownloadIcon } from '../../assets/icons/FolderDownloadIcon';
import { FolderFileSolidIcon } from '../../assets/icons/FolderFileSolidIcon';
import { FolderImageSolidIcon } from '../../assets/icons/FolderImageSolidIcon';
import { FolderMaterialSolidIcon } from '../../assets/icons/FolderMaterialSolidIcon';
import { FolderOpenOutlineIcon } from '../../assets/icons/FolderOpenOutlineIcon';
import { FolderRulersSolidIcon } from '../../assets/icons/FolderRulersSolidIcon';
import { FolderSolidIcon } from '../../assets/icons/FolderSolidIcon';
import { ForwardIcon } from '../../assets/icons/ForwardIcon';
import { HashOutlineIcon } from '../../assets/icons/HashOutlineIcon';
import { HeartOutlineIcon } from '../../assets/icons/HeartOutlineIcon';
import { HeartSolidIcon } from '../../assets/icons/HeartSolidIcon';
import { HomeIcon } from '../../assets/icons/HomeIcon';
import { HomeSolidIcon } from '../../assets/icons/HomeSolidIcon';
import { IdentificationIcon } from '../../assets/icons/IdentificationIcon';
import { InboxOutlineIcon } from '../../assets/icons/InboxOutlineIcon';
import { InformationCircleOutlineIcon } from '../../assets/icons/InformationCircleOutlineIcon';
import { InformationCircleSolidIcon } from '../../assets/icons/InformationCircleSolidIcon';
import { KeyIcon } from '../../assets/icons/KeyIcon';
import { KeyboardSolidIcon } from '../../assets/icons/KeyboardSolidIcon';
import { LabelOutlineIcon } from '../../assets/icons/LabelOutlineIcon';
import { LabelSolidIcon } from '../../assets/icons/LabelSolidIcon';
import { LightingBoltSolidIcon } from '../../assets/icons/LightingBoltSolidIcon';
import { LinkCrossedAltSolidIcon } from '../../assets/icons/LinkCrossedAltSolidIcon';
import { LinkSolidIcon } from '../../assets/icons/LinkSolidIcon';
import { ListBulletAltSolid } from '../../assets/icons/ListBulletAltSolid';
import { ListTodoSolid } from '../../assets/icons/ListTodoSolid';
import { LocationIcon } from '../../assets/icons/LocationIcon';
import { LockCloseIcon } from '../../assets/icons/LockCloseIcon';
import { LockOpenIcon } from '../../assets/icons/LockOpenIcon';
import { LogoutIcon } from '../../assets/icons/LogoutIcon';
import { MagnifyingGlassCircleSolidIcon } from '../../assets/icons/MagnifyingGlassCircleSolidIcon';
import { MailIcon } from '../../assets/icons/MailIcon';
import { MailOpenIcon } from '../../assets/icons/MailOpenIcon';
import { MailOutlineIcon } from '../../assets/icons/MailOutlineIcon';
import { MarketThriveIcon } from '../../assets/icons/MarketThriveIcon';
import { MaterialOutlineIcon } from '../../assets/icons/MaterialOutlineIcon';
import { MaterialSolidIcon } from '../../assets/icons/MaterialSolidIcon';
import { MenuAltSolidIcon } from '../../assets/icons/MenuAltSolidIcon';
import { MenuOutlineIcon } from '../../assets/icons/MenuOutlineIcon';
import { MenuTreeSolidIcon } from '../../assets/icons/MenuTreeSolidIcon';
import { MinusSmSolidIcon } from '../../assets/icons/MinusSmSolidIcon';
import { MinusSolidIcon } from '../../assets/icons/MinusSolidIcon';
import { NewspaperSolidIcon } from '../../assets/icons/NewspaperSolidIcon';
import { OfficeBuildingOutlineIcon } from '../../assets/icons/OfficeBuildingOutlineIcon';
import { PaperAirplaneSolidIcon } from '../../assets/icons/PaperAirplaneSolidIcon';
import { PaperClipOutlineIcon } from '../../assets/icons/PaperClipOutlineIcon';
import { PaperClipSolidIcon } from '../../assets/icons/PaperClipSolidIcon';
import { PauseOutlineIcon } from '../../assets/icons/PauseOutlineIcon';
import { PauseSolidIcon } from '../../assets/icons/PauseSolidIcon';
import { PencilAltOutlineIcon } from '../../assets/icons/PencilAltOutlineIcon';
import { PencilAltSolidIcon } from '../../assets/icons/PencilAltSolidIcon';
import { PencilIcon } from '../../assets/icons/PencilIcon';
import { PencilOutlineIcon } from '../../assets/icons/PencilOutlineIcon';
import { PhoneIcon } from '../../assets/icons/PhoneIcon';
import { PhoneSolidIcon } from '../../assets/icons/PhoneSolidIcon';
import { PhotoAiSolidIcon } from '../../assets/icons/PhotoAiSolidIcon';
import { PhotoSolidIcon } from '../../assets/icons/PhotoSolidIcon';
import { PhotographOutlineIcon } from '../../assets/icons/PhotographOutlineIcon';
import { PhotographSolidIcon } from '../../assets/icons/PhotographSolidIcon';
import { PinIcon } from '../../assets/icons/PinIcon';
import { PlayOutlineIcon } from '../../assets/icons/PlayOutlineIcon';
import { PlaySolidIcon } from '../../assets/icons/PlaySolidIcon';
import { PlusCircleOutlineIcon } from '../../assets/icons/PlusCircleOutlineIcon';
import { PlusIcon } from '../../assets/icons/PlusIcon';
import { PlusOutlineIcon } from '../../assets/icons/PlusOutlineIcon';
import { PlusSmSolidIcon } from '../../assets/icons/PlusSmSolidIcon';
import { PlusSolidIcon } from '../../assets/icons/PlusSolidIcon';
import { ProjectsIcon } from '../../assets/icons/ProjectsIcon';
import { PulseOutlineIcon } from '../../assets/icons/PulseOutlineIcon';
import { PulseSolidIcon } from '../../assets/icons/PulseSolidIcon';
import { PushpinOutlineIcon } from '../../assets/icons/PushpinOutlineIcon';
import { PushpinOffOutlineIcon } from '../../assets/icons/PushpinOffOutlineIcon';
import { PushpinSolidIcon } from '../../assets/icons/PushpinSolidIcon';
import { QuestionMarkCircleOutlineIcon } from '../../assets/icons/QuestionMarkCircleOutlineIcon';
import { QuestionMarkCircleSolidIcon } from '../../assets/icons/QuestionMarkCircleSolidIcon';
import { ReactIcon } from '../../assets/icons/ReactIcon';
import { ReceiptRefundIcon } from '../../assets/icons/ReceiptRefundIcon';
import { ReceiptRefundOutlineIcon } from '../../assets/icons/ReceiptRefundOutlineIcon';
import { RefreshSolidIcon } from '../../assets/icons/RefreshSolidIcon';
import { ReplyOutlineIcon } from '../../assets/icons/ReplyOutlineIcon';
import { ReplySolidIcon } from '../../assets/icons/ReplySolidIcon';
import { RocketOutlineIcon } from '../../assets/icons/RocketOutlineIcon';
import { RocketSolidIcon } from '../../assets/icons/RocketSolidIcon';
import { RulersOutlineIcon } from '../../assets/icons/RulersOutlineIcon';
import { RulersSolidIcon } from '../../assets/icons/RulersSolidIcon';
import { SearchAiOutlineIcon } from '../../assets/icons/SearchAiOutlineIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { SearchOutlineIcon } from '../../assets/icons/SearchOutlineIcon';
import { SelectorIcon } from '../../assets/icons/SelectorIcon';
import { ServerSolidIcon } from '../../assets/icons/ServerSolidIcon';
import { ShieldCheckOutlineIcon } from '../../assets/icons/ShieldCheckOutlineIcon';
import { ShieldCheckSolidIcon } from '../../assets/icons/ShieldCheckSolidIcon';
import { ShieldExclamationIcon } from '../../assets/icons/ShieldExclamationIcon';
import { SmashingCGIIcon } from '../../assets/icons/SmashingCGIIcon';
import { SmileIcon } from '../../assets/icons/SmileIcon';
import { SortAscIcon } from '../../assets/icons/SortAscIcon';
import { SortDescIcon } from '../../assets/icons/SortDescIcon';
import { SparklesSolidIcon } from '../../assets/icons/SparklesSolidIcon';
import { Square3Stack3DIcon } from '../../assets/icons/Square3Stack3DIcon';
import { Square3Stack3DOutlineIcon } from '../../assets/icons/Square3Stack3DOutlineIcon';
import { SquareEmptySolidIcon } from '../../assets/icons/SquareEmptySolidIcon';
import { StarsMultipleSolidIcon } from '../../assets/icons/StarsMultipleSolidIcon';
import { StopOutlineIcon } from '../../assets/icons/StopOutlineIcon';
import { StopSolidIcon } from '../../assets/icons/StopSolidIcon';
import { SwitchHorizontalSolidIcon } from '../../assets/icons/SwitchHorizontalSolidIcon';
import { TableSolidIcon } from '../../assets/icons/TableSolidIcon';
import { TagOutlineIcon } from '../../assets/icons/TagOutlineIcon';
import { TemplateSolidIcon } from '../../assets/icons/TemplateSolidIcon';
import { TicketSolidIcon } from '../../assets/icons/TicketSolidIcon';
import { TopArrowIcon } from '../../assets/icons/TopArrowIcon';
import { TransferIncomingSolidIcon } from '../../assets/icons/TransferIncomingSolidIcon';
import { TransferOutgoingSolidIcon } from '../../assets/icons/TransferOutgoingSolidIcon';
import { TranslateIcon } from '../../assets/icons/TranslateIcon';
import { TrashOutlineIcon } from '../../assets/icons/TrashOutlineIcon';
import { TrashSolidIcon } from '../../assets/icons/TrashSolidIcon';
import { TrendingDownSolidIcon } from '../../assets/icons/TrendingDownSolidIcon';
import { TrendingUpSolidIcon } from '../../assets/icons/TrendingUpSolidIcon';
import { UnitsMetricCmSolidIcon } from '../../assets/icons/UnitsMetricCmSolidIcon';
import { UnitsMetricInSolidIcon } from '../../assets/icons/UnitsMetricInSolidIcon';
import { UploadOutlineIcon } from '../../assets/icons/UploadOutlineIcon';
import { UploadSolidIcon } from '../../assets/icons/UploadSolidIcon';
import { UserAddOutlineIcon } from '../../assets/icons/UserAddOutlineIcon';
import { UserAddSolidIcon } from '../../assets/icons/UserAddSolidIcon';
import { UserCircleIcon } from '../../assets/icons/UserCircleIcon';
import { UserCircleSolidIcon } from '../../assets/icons/UserCircleSolidIcon';
import { UserGroupIcon } from '../../assets/icons/UserGroupIcon';
import { UserGroupSolidIcon } from '../../assets/icons/UserGroupSolidIcon';
import { UserOrganizationIcon } from '../../assets/icons/UserOrganizationIcon';
import { UserSolidIcon } from '../../assets/icons/UserSolidIcon';
import { UsersSolidIcon } from '../../assets/icons/UsersSolidIcon';
import { UserSubtractOutlineIcon } from '../../assets/icons/UserSubtractOutlineIcon';
import { ViewGridIcon } from '../../assets/icons/ViewGridIcon';
import { ViewGridSmIcon } from '../../assets/icons/ViewGridSmIcon';
import { ViewListIcon } from '../../assets/icons/ViewListIcon';
import { ViewListSolidIcon } from '../../assets/icons/ViewListSolidIcon';
import { ViewListUserSolidIcon } from '../../assets/icons/ViewListUserSolidIcon';
import { VraySolidIcon } from '../../assets/icons/VraySolidIcon';
import { WhiteboardSolidIcon } from '../../assets/icons/WhiteboardSolidIcon';
import { WithdrawalOutlineIcon } from '../../assets/icons/WithdrawalOutlineIcon';
import { WrenchScrewdriverOutlineIcon } from '../../assets/icons/WrenchScrewdriverOutlineIcon';
import { XCircleIcon } from '../../assets/icons/XCircleIcon';
import { XCircleOutlineIcon } from '../../assets/icons/XCircleOutlineIcon';
import { XIcon } from '../../assets/icons/XIcon';
import { XOutlineIcon } from '../../assets/icons/XOutlineIcon';
import { ZoomInOutlineIcon } from '../../assets/icons/ZoomInOutlineIcon';

interface IconProps {
  id?: string;
  className?: string;
  icon: IconsEnum;
  strokeWidth?: string;
}

function Icon({ className, icon, id, strokeWidth }: IconProps) {
  switch (icon) {
    case IconsEnum.ADJUSTMENTS_ALT_SOLID:
      return <AdjustmentsAltSolidIcon className={className} id={id} />;
    case IconsEnum.ADJUSTMENTS_OUTLINE:
      return <AdjustmentsOutlineIcon className={className} id={id} />;
    case IconsEnum.ANDROID:
      return <AndroidIcon className={className} id={id} />;
    case IconsEnum.ANNOTATION_SOLID:
      return <AnnotationSolidIcon className={className} id={id} />;
    case IconsEnum.APPLE:
      return <AppleIcon className={className} id={id} />;
    case IconsEnum.ARCHIVE_BOX_X_MARK_OUTLINE:
      return <ArchiveBoxXMarkOutlineIcon className={className} id={id} />;
    case IconsEnum.ARCHIVE_BOX_X_MARK:
      return <ArchiveBoxXMark className={className} id={id} />;
    case IconsEnum.ARROW_CIRCLE_UP_SOLID:
      return <ArrowCircleUpSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_DOWN_CIRCLE_OUTLINE:
      return <ArrowDownCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROW_DOWN_TRIANGLE_SOLID:
      return <ArrowDownTriangleSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_DOWN:
      return <ArrowDownIcon className={className} id={id} />;
    case IconsEnum.ARROW_LEFT_OUTLINE:
      return <ArrowLeftOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROW_LEFT:
      return <ArrowLeftIcon className={className} id={id} />;
    case IconsEnum.ARROW_NARROW_DOWN_SOLID:
      return <ArrowNarrowDownSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_NARROW_RIGHT_SOLID:
      return <ArrowNarrowRightSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_NARROW_UP_OUTLINE:
      return <ArrowNarrowUpOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROW_NARROW_UP_SOLID:
      return <ArrowNarrowUpSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_PATH_OUTLINE:
      return <ArrowPathOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROW_RIGHT_SOLID:
      return <ArrowRightSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_RIGHT_TRIANGLE_SOLID:
      return <ArrowRightTriangleSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_SM_DOWN:
      return <ArrowSmDownIcon className={className} id={id} />;
    case IconsEnum.ARROW_SM_LEFT_OUTLINE:
      return <ArrowSmLeftOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROWS_EXPAND_OUTLINE:
      return <ArrowsExpandOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROWS_EXPAND_SOLID:
      return <ArrowsExpandSolidIcon className={className} id={id} />;
    case IconsEnum.AT_SYMBOL:
      return <AtSymbolIcon className={className} id={id} />;
    case IconsEnum.AV:
      return <AVIcon className={className} id={id} />;
    case IconsEnum.BAN_SOLID:
      return <BanSolidIcon className={className} id={id} />;
    case IconsEnum.BADGE_CHECK:
      return <BadgeCheckIcon className={className} id={id} />;
    case IconsEnum.BELL_OUTLINE:
      return <BellOutlineIcon className={className} id={id} />;
    case IconsEnum.BELL:
      return <BellIcon className={className} id={id} />;
    case IconsEnum.BELL_CROSSED_SOLID:
      return <BellCrossedSolidIcon className={className} id={id} />;
    case IconsEnum.BLANK:
      return <BlankIcon className={className} id={id} />;
    case IconsEnum.BLOCK:
      return <BlockIcon className={className} id={id} />;
    case IconsEnum.BLUE_BELL:
      return <BlueBellIcon className={className} id={id} />;
    case IconsEnum.BOOK_OPEN_SOLID:
      return <BookOpenSolidIcon className={className} id={id} />;
    case IconsEnum.BRIEFCASE_SOLID:
      return <BriefcaseSolidIcon className={className} id={id} />;
    case IconsEnum.BURNING:
      return <BurningIcon className={className} id={id} />;
    case IconsEnum.CALENDAR_OUTLINE:
      return <CalendarOutlineIcon className={className} id={id} />;
    case IconsEnum.CALENDAR_SOLID:
      return <CalendarSolidIcon className={className} id={id} />;
    case IconsEnum.CASH_OUTLINE:
      return <CashOutlineIcon className={className} id={id} />;
    case IconsEnum.CASH:
      return <CashIcon className={className} id={id} />;
    case IconsEnum.CELLS_SOLID:
      return <CellsSolidIcon className={className} id={id} />;
    case IconsEnum.CHART_BAR_SQUARE_SOLID:
      return <ChartBarSquareSolidIcon className={className} id={id} />;
    case IconsEnum.CHART_BAR:
      return <ChartBarOutlineIcon className={className} id={id} />;
    case IconsEnum.CHART_PIE:
      return <ChartPieIcon className={className} id={id} />;
    case IconsEnum.CHART_SQUARE_BAR:
      return <ChartSquareBarOutlineIcon className={className} id={id} />;
    case IconsEnum.CHAT_ALT:
      return <ChatAltOutlineIcon className={className} id={id} />;
    case IconsEnum.CHAT_OUTLINE:
      return <ChatOutlineIcon className={className} id={id} />;
    case IconsEnum.CHAT:
      return <ChatIcon className={className} id={id} />;
    case IconsEnum.CHECK_CIRCLE_OUTLINE:
      return <CheckCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.CHECK_CIRCLE_SOLID:
      return <CheckCircleSolidIcon className={className} id={id} />;
    case IconsEnum.CHECK_OUTLINE:
      return <CheckOutlineIcon className={className} id={id} />;
    case IconsEnum.CHECK_SQUARE_SOLID:
      return <CheckSquareSolidIcon className={className} id={id} />;
    case IconsEnum.CHECK:
      return <CheckIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_DOUBLE_LEFT:
      return <ChevronDoubleLeftIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_DOUBLE_RIGHT:
      return <ChevronDoubleRightIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_DOWN_SOLID:
      return <ChevronDownSolidIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_DOWN:
      return <ChevronDownIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_LEFT:
      return <ChevronLeftIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_RIGHT:
      return <ChevronRightIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_UP:
      return <ChevronUpIcon className={className} id={id} />;
    case IconsEnum.CIRCLE_OUTLINE:
      return <CircleOutlineIcon className={className} id={id} />;
    case IconsEnum.CIRCLE_SOLID:
      return <CircleSolidIcon className={className} id={id} />;
    case IconsEnum.CLIPBOARD_CHECK_OUTLINE:
      return <ClipboardCheckOutlineIcon className={className} id={id} />;
    case IconsEnum.CLIPBOARD_CHECK_SOLID:
      return <ClipboardCheckSolidIcon className={className} id={id} />;
    case IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE:
      return (
        <ClipboardDocumentCheckOutlineIcon className={className} id={id} />
      );
    case IconsEnum.CLIPBOARD_DOCUMENT_CHECK_SOLID:
      return <ClipboardDocumentCheckSolidIcon className={className} id={id} />;
    case IconsEnum.CLIPBOARD_LIST_OUTLINE:
      return <ClipboardListOutlineIcon className={className} id={id} />;
    case IconsEnum.CLIPBOARD_LIST_SOLID:
      return <ClipboardListSolidIcon className={className} id={id} />;
    case IconsEnum.CLOCK_OUTLINE:
      return <ClockOutlineIcon className={className} id={id} />;
    case IconsEnum.CLOCK:
      return <ClockIcon className={className} id={id} />;
    case IconsEnum.CLOUD_UPLOAD_OUTLINE:
      return <CloudUploadOutlineIcon className={className} id={id} />;
    case IconsEnum.CLOUD_UPLOAD_SOLID:
      return <CloudUploadSolidIcon className={className} id={id} />;
    case IconsEnum.COG_EMPTY:
      return <CogEmptyIcon className={className} id={id} />;
    case IconsEnum.COG:
      return <CogIcon className={className} id={id} />;
    case IconsEnum.COLLECTION_OUTLINE:
      return <CollectionOutlineIcon className={className} id={id} />;
    case IconsEnum.COLLECTION_SOLID:
      return <CollectionSolidIcon className={className} id={id} />;
    case IconsEnum.COLOR_SWATCH_OUTLINE:
      return <ColorSwatchOutlineIcon className={className} id={id} />;
    case IconsEnum.COLOR_SWATCH_SOLID:
      return <ColorSwatchSolidIcon className={className} id={id} />;
    case IconsEnum.COMMAND_OUTLINE:
      return <CommandOutlineIcon className={className} id={id} />;
    case IconsEnum.CONTACT_SUPPORT:
      return <ContactSupportIcon className={className} id={id} />;
    case IconsEnum.COPY:
      return <CopyIcon className={className} id={id} />;
    case IconsEnum.CORONA_SOLID:
      return <CoronaSolidIcon className={className} id={id} />;
    case IconsEnum.CREDIT_CARD_SOLID:
      return <CreditCardSolidIcon className={className} id={id} />;
    case IconsEnum.CROSS:
      return <CrossIcon className={className} id={id} />;
    case IconsEnum.CROSSED_BELL:
      return <CrossedBellIcon className={className} id={id} />;
    case IconsEnum.CROWN_SOLID:
      return <CrownSolidIcon className={className} id={id} />;
    case IconsEnum.CUBE_OUTLINE:
      return <CubeOutlineIcon className={className} id={id} />;
    case IconsEnum.CUBE_SOLID:
      return <CubeSolidIcon className={className} id={id} />;
    case IconsEnum.CUBE_TRANSPARENT_OUTLINE:
      return <CubeTransparentOutlineIcon className={className} id={id} />;
    case IconsEnum.CUBE_TRANSPARENT_SOLID:
      return <CubeTransparentSolidIcon className={className} id={id} />;
    case IconsEnum.CUBES_OUTLINE:
      return <CubesOutlineIcon className={className} id={id} />;
    case IconsEnum.CURRENCY_DOLLAR_SOLID:
      return <CurrencyDollarSolidIcon className={className} id={id} />;
    case IconsEnum.DATABASE_OUTLINE:
      return <DatabaseOutlineIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_ARCHIVE_SOLID:
      return <DocumentArchiveSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_AUDIO_SOLID:
      return <DocumentAudioSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_DOWNLOAD_OUTLINE:
      return <DocumentDownloadOutlineIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_DUPLICATE_OUTLINE:
      return (
        <DocumentDuplicateOutlineIcon
          className={className}
          id={id}
          strokeWidth={strokeWidth}
        />
      );
    case IconsEnum.DOCUMENT_MEDIAS:
      return <DocumentMediasIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_MINUS_SOLID:
      return <DocumentMinusSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_OUTLINE:
      return (
        <DocumentOutlineIcon
          className={className}
          id={id}
          strokeWidth={strokeWidth}
        />
      );
    case IconsEnum.DOCUMENT_PDF_SOLID:
      return <DocumentPdfSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_REPORT_OUTLINE:
      return <DocumentReportOutlineIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_REPORT_SOLID:
      return <DocumentReportSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_SOLID:
      return <DocumentSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_TABLE_OUTLINE:
      return (
        <DocumentTableOutlineIcon
          className={className}
          id={id}
          strokeWidth={strokeWidth}
        />
      );
    case IconsEnum.DOCUMENT_TEXT_OUTLINE:
      return (
        <DocumentTextOutlineIcon
          className={className}
          id={id}
          strokeWidth={strokeWidth}
        />
      );
    case IconsEnum.DOCUMENT_TEXT_SOLID:
      return <DocumentTextSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_UPLOAD_OUTLINE:
      return <DocumentUploadOutlineIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_UPLOAD_SOLID:
      return <DocumentUploadSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_VIDEO_SOLID:
      return <DocumentVideoSolidIcon className={className} id={id} />;
    case IconsEnum.DOTS_HORIZONTAL_OUTLINE:
      return <DotsHorizontalOutlineIcon className={className} id={id} />;
    case IconsEnum.DOWN_ARROW:
      return <DownArrowIcon className={className} id={id} />;
    case IconsEnum.DOWNLOAD_OUTLINE:
      return <DownloadOutlineIcon className={className} id={id} />;
    case IconsEnum.DOWNLOAD_SOLID:
      return <DownloadSolidIcon className={className} id={id} />;
    case IconsEnum.DRAW_CURVE_OUTLINE:
      return <DrawCurveOutlineIcon className={className} id={id} />;
    case IconsEnum.ELASTIC_SEARCH_SOLID:
      return <ElasticSearchSolidIcon className={className} id={id} />;
    case IconsEnum.EMAIL_SOLID:
      return <EmailSolidIcon className={className} id={id} />;
    case IconsEnum.EMPTY_STAR:
      return <EmptyStarIcon className={className} id={id} />;
    case IconsEnum.EMPTY_STAR_OFF:
      return <EmptyStarOffIcon className={className} id={id} />;
    case IconsEnum.EMOJI_SAD_SOLID:
      return <EmojiSadSolidIcon className={className} id={id} />;
    case IconsEnum.ENVELOPE:
      return <EnvelopeIcon className={className} id={id} />;
    case IconsEnum.EXCLAMATION_CIRCLE:
      return <ExclamationCircleIcon className={className} id={id} />;
    case IconsEnum.EXCLAMATION_SOLID:
      return <ExclamationSolidIcon className={className} id={id} />;
    case IconsEnum.EXCLAMATION:
      return <ExclamationIcon className={className} id={id} />;
    case IconsEnum.EXTERNAL_LINK_SOLID:
      return <ExternalLinkSolidIcon className={className} id={id} />;
    case IconsEnum.EYE_CROSSED:
      return <EyeCrossedIcon className={className} id={id} />;
    case IconsEnum.EYE_OFF_SOLID:
      return <EyeOffSolidIcon className={className} id={id} />;
    case IconsEnum.EYE_OFF_OUTLINE:
      return <EyeOffOutlineIcon className={className} id={id} />;
    case IconsEnum.EYE_OUTLINE:
      return <EyeOutlineIcon className={className} id={id} />;
    case IconsEnum.EYE_SOLID:
      return <EyeSolidIcon className={className} id={id} />;
    case IconsEnum.EYE_SPLASH_SOLID:
      return <EyeSplashSolidIcon className={className} id={id} />;
    case IconsEnum.EYE:
      return <EyeIcon className={className} id={id} />;
    case IconsEnum.FILLED_STAR:
      return <FilledStarIcon className={className} id={id} />;
    case IconsEnum.FILTER_LIST_SOLID:
      return <FilterListSolidIcon className={className} id={id} />;
    case IconsEnum.FILTER_SOLID:
      return <FilterSolidIcon className={className} id={id} />;
    case IconsEnum.FILTER:
      return <FilterIcon className={className} id={id} />;
    case IconsEnum.FIRE_OUTLINE:
      return <FireOutlineIcon className={className} id={id} />;
    case IconsEnum.FIRE_SOLID:
      return <FireSolidIcon className={className} id={id} />;
    case IconsEnum.FLAG:
      return <FlagIcon className={className} id={id} />;
    case IconsEnum.FLAG_OFF_ALT_OUTLINE:
      return <FlagOffAltOutlineIcon className={className} id={id} />;
    case IconsEnum.FLAG_OUTLINE:
      return <FlagOutlineIcon className={className} id={id} />;
    case IconsEnum.FOLDER_ALT_SOLID:
      return <FolderAltSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_CUBE_SOLID:
      return <FolderCubeSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_DOWNLOAD:
      return <FolderDownloadIcon className={className} id={id} />;
    case IconsEnum.FOLDER_FILE_SOLID:
      return <FolderFileSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_IMAGE_SOLID:
      return <FolderImageSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_MATERIAL_SOLID:
      return <FolderMaterialSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_OPEN_OUTLINE:
      return <FolderOpenOutlineIcon className={className} id={id} />;
    case IconsEnum.FOLDER_RULERS_SOLID:
      return <FolderRulersSolidIcon className={className} id={id} />;
    case IconsEnum.FOLDER_SOLID:
      return <FolderSolidIcon className={className} id={id} />;
    case IconsEnum.FORWARD:
      return <ForwardIcon className={className} id={id} />;
    case IconsEnum.HASH_OUTLINE:
      return <HashOutlineIcon className={className} id={id} />;
    case IconsEnum.HEART_OUTLINE:
      return <HeartOutlineIcon className={className} id={id} />;
    case IconsEnum.HEART_SOLID:
      return <HeartSolidIcon className={className} id={id} />;
    case IconsEnum.HOME_SOLID:
      return <HomeSolidIcon className={className} id={id} />;
    case IconsEnum.HOME:
      return <HomeIcon className={className} id={id} />;
    case IconsEnum.IDENTIFICATION:
      return <IdentificationIcon className={className} id={id} />;
    case IconsEnum.INBOX_OUTLINE:
      return <InboxOutlineIcon className={className} id={id} />;
    case IconsEnum.INFORMATION_CIRCLE_OUTLINE:
      return <InformationCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.INFORMATION_CIRCLE_SOLID:
      return <InformationCircleSolidIcon className={className} id={id} />;
    case IconsEnum.KEY:
      return <KeyIcon className={className} id={id} />;
    case IconsEnum.KEYBOARD_SOLID:
      return <KeyboardSolidIcon className={className} id={id} />;
    case IconsEnum.LABEL_OUTLINE:
      return <LabelOutlineIcon className={className} id={id} />;
    case IconsEnum.LABEL_SOLID:
      return <LabelSolidIcon className={className} id={id} />;
    case IconsEnum.LIGHTING_BOLT_SOLID:
      return <LightingBoltSolidIcon className={className} id={id} />;
    case IconsEnum.LINK_CROSSED_ALT_SOLID:
      return <LinkCrossedAltSolidIcon className={className} id={id} />;
    case IconsEnum.LINK_SOLID:
      return <LinkSolidIcon className={className} id={id} />;
    case IconsEnum.LIST_BULLET_ALT:
      return <ListBulletAltSolid className={className} id={id} />;
    case IconsEnum.LIST_TODO_SOLID:
      return <ListTodoSolid className={className} id={id} />;
    case IconsEnum.LOCATION:
      return <LocationIcon className={className} id={id} />;
    case IconsEnum.LOCK_CLOSE:
      return <LockCloseIcon className={className} id={id} />;
    case IconsEnum.LOCK_OPEN:
      return <LockOpenIcon className={className} id={id} />;
    case IconsEnum.LOGOUT:
      return <LogoutIcon className={className} id={id} />;
    case IconsEnum.MAGNIFYING_GLASS_CIRCLE_SOLID:
      return <MagnifyingGlassCircleSolidIcon className={className} id={id} />;
    case IconsEnum.MAIL_OPEN:
      return <MailOpenIcon className={className} id={id} />;
    case IconsEnum.MAIL_OUTLINE:
      return <MailOutlineIcon className={className} id={id} />;
    case IconsEnum.MARKET_THRIVE:
      return <MarketThriveIcon className={className} id={id} />;
    case IconsEnum.MAIL:
      return <MailIcon className={className} id={id} />;
    case IconsEnum.MATERIAL_OUTLINE:
      return <MaterialOutlineIcon className={className} id={id} />;
    case IconsEnum.MATERIAL_SOLID:
      return <MaterialSolidIcon className={className} id={id} />;
    case IconsEnum.MENU_ALT_SOLID:
      return <MenuAltSolidIcon className={className} id={id} />;
    case IconsEnum.MENU_OUTLINE:
      return <MenuOutlineIcon className={className} id={id} />;
    case IconsEnum.MENU_TREE_SOLID:
      return <MenuTreeSolidIcon className={className} id={id} />;
    case IconsEnum.MINUS_SM_SOLID:
      return <MinusSmSolidIcon className={className} id={id} />;
    case IconsEnum.MINUS_SOLID:
      return <MinusSolidIcon className={className} id={id} />;
    case IconsEnum.NEWSPAPER_SOLID:
      return <NewspaperSolidIcon className={className} id={id} />;
    case IconsEnum.OFFICE_BUILDING_OUTLINE:
      return <OfficeBuildingOutlineIcon className={className} id={id} />;
    case IconsEnum.PAPER_AIRPLANE_SOLID:
      return <PaperAirplaneSolidIcon className={className} id={id} />;
    case IconsEnum.PAPER_CLIP_OUTLINE:
      return <PaperClipOutlineIcon className={className} id={id} />;
    case IconsEnum.PAPER_CLIP_SOLID:
      return <PaperClipSolidIcon className={className} id={id} />;
    case IconsEnum.PAUSE_OUTLINE:
      return <PauseOutlineIcon className={className} id={id} />;
    case IconsEnum.PAUSE_SOLID:
      return <PauseSolidIcon className={className} id={id} />;
    case IconsEnum.PENCIL_ALT_OUTLINE:
      return <PencilAltOutlineIcon className={className} id={id} />;
    case IconsEnum.PENCIL_ALT_SOLID:
      return <PencilAltSolidIcon className={className} id={id} />;
    case IconsEnum.PENCIL_OUTLINE:
      return <PencilOutlineIcon className={className} id={id} />;
    case IconsEnum.PENCIL:
      return <PencilIcon className={className} id={id} />;
    case IconsEnum.PHONE_SOLID:
      return <PhoneSolidIcon className={className} id={id} />;
    case IconsEnum.PHONE:
      return <PhoneIcon className={className} id={id} />;
    case IconsEnum.PHOTO_AI_SOLID:
      return <PhotoAiSolidIcon className={className} id={id} />;
    case IconsEnum.PHOTO_SOLID:
      return <PhotoSolidIcon className={className} id={id} />;
    case IconsEnum.PHOTOGRAPH_OUTLINE:
      return <PhotographOutlineIcon className={className} id={id} />;
    case IconsEnum.PHOTOGRAPH_SOLID:
      return <PhotographSolidIcon className={className} id={id} />;
    case IconsEnum.PIN:
      return <PinIcon className={className} id={id} />;
    case IconsEnum.PLAY_OUTLINE:
      return <PlayOutlineIcon className={className} id={id} />;
    case IconsEnum.PLAY_SOLID:
      return <PlaySolidIcon className={className} id={id} />;
    case IconsEnum.PLUS_CIRCLE_OUTLINE:
      return <PlusCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.PLUS_OUTLINE:
      return <PlusOutlineIcon className={className} id={id} />;
    case IconsEnum.PLUS_SM_SOLID:
      return <PlusSmSolidIcon className={className} id={id} />;
    case IconsEnum.PLUS_SOLID:
      return <PlusSolidIcon className={className} id={id} />;
    case IconsEnum.PLUS:
      return <PlusIcon className={className} id={id} />;
    case IconsEnum.PROJECTS:
      return <ProjectsIcon className={className} id={id} />;
    case IconsEnum.PULSE_OUTLINE:
      return <PulseOutlineIcon className={className} id={id} />;
    case IconsEnum.PULSE_SOLID:
      return <PulseSolidIcon className={className} id={id} />;
    case IconsEnum.PUSHPIN_OUTLINE:
      return <PushpinOutlineIcon className={className} id={id} />;
    case IconsEnum.PUSHPIN_OFF_OUTLINE:
      return <PushpinOffOutlineIcon className={className} id={id} />;
    case IconsEnum.PUSHPIN_SOLID:
      return <PushpinSolidIcon className={className} id={id} />;
    case IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE:
      return <QuestionMarkCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.QUESTION_MARK_CIRCLE_SOLID:
      return <QuestionMarkCircleSolidIcon className={className} id={id} />;
    case IconsEnum.REACT:
      return <ReactIcon className={className} id={id} />;
    case IconsEnum.RECEIPT_REFUND_OUTLINE:
      return <ReceiptRefundOutlineIcon className={className} id={id} />;
    case IconsEnum.RECEIPT_REFUND:
      return <ReceiptRefundIcon className={className} id={id} />;
    case IconsEnum.REFRESH_SOLID:
      return <RefreshSolidIcon className={className} id={id} />;
    case IconsEnum.REPLY_OUTLINE:
      return <ReplyOutlineIcon className={className} id={id} />;
    case IconsEnum.REPLY_SOLID:
      return <ReplySolidIcon className={className} id={id} />;
    case IconsEnum.ROCKET_OUTLINE:
      return <RocketOutlineIcon className={className} id={id} />;
    case IconsEnum.ROCKET_SOLID:
      return <RocketSolidIcon className={className} id={id} />;
    case IconsEnum.RULERS_OUTLINE:
      return <RulersOutlineIcon className={className} id={id} />;
    case IconsEnum.RULERS_SOLID:
      return <RulersSolidIcon className={className} id={id} />;
    case IconsEnum.SEARCH_AI_OUTLINE:
      return <SearchAiOutlineIcon className={className} id={id} />;
    case IconsEnum.SEARCH:
      return <SearchIcon className={className} id={id} />;
    case IconsEnum.SEARCH_OUTLINE:
      return <SearchOutlineIcon className={className} id={id} />;
    case IconsEnum.SELECTOR:
      return <SelectorIcon className={className} id={id} />;
    case IconsEnum.SERVER_SOLID:
      return <ServerSolidIcon className={className} id={id} />;
    case IconsEnum.SHIELD_CHECK_OUTLINE:
      return <ShieldCheckOutlineIcon className={className} id={id} />;
    case IconsEnum.SHIELD_CHECK_SOLID:
      return <ShieldCheckSolidIcon className={className} id={id} />;
    case IconsEnum.SHIELD_EXCLAMATION:
      return <ShieldExclamationIcon className={className} id={id} />;
    case IconsEnum.SMASHING_CGI:
      return <SmashingCGIIcon className={className} id={id} />;
    case IconsEnum.SMILE:
      return <SmileIcon className={className} id={id} />;
    case IconsEnum.SORT_ASC:
      return <SortAscIcon className={className} id={id} />;
    case IconsEnum.SORT_DESC:
      return <SortDescIcon className={className} id={id} />;
    case IconsEnum.SPARKLES_SOLID:
      return <SparklesSolidIcon className={className} id={id} />;
    case IconsEnum.SQUARE_3_STACK_3D_OUTLINE:
      return <Square3Stack3DOutlineIcon className={className} id={id} />;
    case IconsEnum.SQUARE_EMPTY_SOLID:
      return <SquareEmptySolidIcon className={className} id={id} />;
    case IconsEnum.SQUARE_3_STACK_3D:
      return <Square3Stack3DIcon className={className} id={id} />;
    case IconsEnum.STARS_MULTIPLE_SOLID:
      return <StarsMultipleSolidIcon className={className} id={id} />;
    case IconsEnum.STOP_OUTLINE:
      return <StopOutlineIcon className={className} id={id} />;
    case IconsEnum.STOP_SOLID:
      return <StopSolidIcon className={className} id={id} />;
    case IconsEnum.SWITCH_HORIZONTAL_SOLID:
      return <SwitchHorizontalSolidIcon className={className} id={id} />;
    case IconsEnum.TABLE_SOLID:
      return <TableSolidIcon className={className} id={id} />;
    case IconsEnum.TAG_OUTLINE:
      return <TagOutlineIcon className={className} id={id} />;
    case IconsEnum.TEMPLATE_SOLID:
      return <TemplateSolidIcon className={className} id={id} />;
    case IconsEnum.TICKET_SOLID:
      return <TicketSolidIcon className={className} id={id} />;
    case IconsEnum.TOP_ARROW:
      return <TopArrowIcon className={className} id={id} />;
    case IconsEnum.TRANSFER_INCOMING_SOLID:
      return <TransferIncomingSolidIcon className={className} id={id} />;
    case IconsEnum.TRANSFER_OUTGOING_SOLID:
      return <TransferOutgoingSolidIcon className={className} id={id} />;
    case IconsEnum.TRANSLATE:
      return <TranslateIcon className={className} id={id} />;
    case IconsEnum.TRASH_OUTLINE:
      return <TrashOutlineIcon className={className} id={id} />;
    case IconsEnum.TRASH_SOLID:
      return <TrashSolidIcon className={className} id={id} />;
    case IconsEnum.TRENDING_DOWN_SOLID:
      return <TrendingDownSolidIcon className={className} id={id} />;
    case IconsEnum.TRENDING_UP_SOLID:
      return <TrendingUpSolidIcon className={className} id={id} />;
    case IconsEnum.UNITS_METRIC_CM_SOLID:
      return <UnitsMetricCmSolidIcon className={className} id={id} />;
    case IconsEnum.UNITS_METRIC_IN_SOLID:
      return <UnitsMetricInSolidIcon className={className} id={id} />;
    case IconsEnum.UPLOAD_OUTLINE:
      return <UploadOutlineIcon className={className} id={id} />;
    case IconsEnum.UPLOAD_SOLID:
      return <UploadSolidIcon className={className} id={id} />;
    case IconsEnum.USER_ADD_OUTLINE:
      return <UserAddOutlineIcon className={className} id={id} />;
    case IconsEnum.USER_ADD_SOLID:
      return <UserAddSolidIcon className={className} id={id} />;
    case IconsEnum.USER_CIRCLE_SOLID:
      return <UserCircleSolidIcon className={className} id={id} />;
    case IconsEnum.USER_CIRCLE:
      return <UserCircleIcon className={className} id={id} />;
    case IconsEnum.USER_GROUP_SOLID:
      return <UserGroupSolidIcon className={className} id={id} />;
    case IconsEnum.USER_GROUP:
      return <UserGroupIcon className={className} id={id} />;
    case IconsEnum.USER_ORGANIZATION:
      return <UserOrganizationIcon className={className} id={id} />;
    case IconsEnum.USER_SOLID:
      return <UserSolidIcon className={className} id={id} />;
    case IconsEnum.USER_SUBTRACT_OUTLINE:
      return <UserSubtractOutlineIcon className={className} id={id} />;
    case IconsEnum.USERS_SOLID:
      return <UsersSolidIcon className={className} id={id} />;
    case IconsEnum.VIEW_GRID:
      return <ViewGridIcon className={className} id={id} />;
    case IconsEnum.VIEW_GRID_SM:
      return <ViewGridSmIcon className={className} id={id} />;
    case IconsEnum.VIEW_LIST_SOLID:
      return <ViewListSolidIcon className={className} id={id} />;
    case IconsEnum.VIEW_LIST_USER_SOLID:
      return <ViewListUserSolidIcon className={className} id={id} />;
    case IconsEnum.VIEW_LIST:
      return <ViewListIcon className={className} id={id} />;
    case IconsEnum.VRAY_SOLID:
      return <VraySolidIcon className={className} id={id} />;
    case IconsEnum.WHITEBOARD_SOLID:
      return <WhiteboardSolidIcon className={className} id={id} />;
    case IconsEnum.WITHDRAWAL_OUTLINE:
      return <WithdrawalOutlineIcon className={className} id={id} />;
    case IconsEnum.WRENCH_SCREWDRIVER_OUTLINE:
      return <WrenchScrewdriverOutlineIcon className={className} id={id} />;
    case IconsEnum.X_CIRCLE_OUTLINE:
      return <XCircleOutlineIcon className={className} id={id} />;
    case IconsEnum.X_CIRCLE:
      return <XCircleIcon className={className} id={id} />;
    case IconsEnum.X_OUTLINE:
      return <XOutlineIcon className={className} id={id} />;
    case IconsEnum.X:
      return <XIcon className={className} id={id} />;
    case IconsEnum.ZOOM_IN_OUTLINE:
      return <ZoomInOutlineIcon className={className} id={id} />;
    default:
      return null;
  }
}

export default memo<IconProps>(Icon);
