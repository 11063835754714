import startsWith from 'lodash/startsWith';

import { I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { PaymentsSecondaryMenuItems } from '../../CompactPaymentsSecondaryMenu.types';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';
import { AccountPath } from '../../../../../../accounts/AccountPath';
import { PaymentPath } from '../../../../../../payments/PaymentPath';
import { JournalRecordPath } from '../../../../../../journalRecords/JournalRecordPath';
import { SmartContractPath } from '../../../../../../smartContracts/SmartContractPath';

import { InvoicesPermissions } from '../../../../../../invoices/invoicesConstants';
import { AccountsPermissions } from '../../../../../../accounts/accountsConstants';
import { JournalRecordsPermissions } from '../../../../../../journalRecords/journalRecordsConstants';
import { SmartContractsPermissions } from '../../../../../../smartContracts/smartContractsConstants';
import { invoicesKeys, menuKeys } from '../../../../../../../locales/keys';

export const paymentsMenuLinks = (permissions: {
  invoices: string[];
  performerInvoices: string[];
  generalLedgers: string[];
  smartContracts: string[];
}) => [
  {
    name: PaymentsSecondaryMenuItems.INVOICES,
    href: InvoicePath.paymentsInvoices(),
    i18nText: invoicesKeys.plural,
    activeTest: (pathname: string) =>
      startsWith(pathname, InvoicePath.paymentsInvoices()),
    permissions: permissions.invoices
  },
  {
    name: PaymentsSecondaryMenuItems.PERFORMER_INVOICES,
    href: InvoicePath.paymentsContractorInvoicesCurrentFilter(),
    i18nText: invoicesKeys.performerInvoices,
    activeTest: (pathname: string) =>
      startsWith(pathname, InvoicePath.paymentsContractorInvoices()),
    permissions: permissions.performerInvoices
  },
  {
    name: PaymentsSecondaryMenuItems.GENERAL_LEDGERS,
    href: AccountPath.paymentsBalanceSheet(),
    i18nText: menuKeys.links.generalLedger,
    activeTest: (pathname: string) =>
      startsWith(pathname, AccountPath.paymentsBalanceSheet()) ||
      startsWith(pathname, JournalRecordPath.paymentsJournalRecords()),
    permissions: permissions.generalLedgers
  },
  {
    name: PaymentsSecondaryMenuItems.SMART_CONTRACTS,
    href: PaymentPath.smartContracts(),
    i18nText: menuKeys.links.smartContracts,
    activeTest: (pathname: string) =>
      startsWith(pathname, SmartContractPath.paymentsSmartContracts()),
    permissions: permissions.smartContracts
  }
];

export interface CompactPaymentsSecondaryMenuLinksConfigItem {
  action:
    | InvoicesPermissions
    | AccountsPermissions
    | JournalRecordsPermissions
    | SmartContractsPermissions;
  active: boolean;
  count?: number;
  href: string;
  i18nText: I18nText;
  icon: IconsEnum;
  visible?: boolean;
}

export interface CompactPaymentsSecondaryMenuLinksConfig {
  invoices: CompactPaymentsSecondaryMenuLinksConfigItem[];
  performerInvoices: CompactPaymentsSecondaryMenuLinksConfigItem[];
  generalLedgers: CompactPaymentsSecondaryMenuLinksConfigItem[];
  smartContracts: CompactPaymentsSecondaryMenuLinksConfigItem[];
}
