import { IconProps } from '../types';

function PushpinOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-4 w-4'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.6 13.6L16 15c.4.4.4 1 0 1.4l-1.4 1.4c-.4.4-1 .4-1.4 0l-7.1-7.1c-.4-.4-.4-1 0-1.4L7.5 8c.4-.4 1-.4 1.4 0l1.4 1.4 4.2-2.8-.7-.7c-.4-.4-.4-1 0-1.4L15.3 3c.4-.4 1-.4 1.4 0L21 7.3c.4.4.4 1 0 1.4l-1.4 1.4c-.4.4-1 .4-1.4 0l-.7-.7c-.1 0-2.9 4.2-2.9 4.2zM9.7 14.3L4 20"
      />
    </svg>
  );
}

export default PushpinOutlineIcon;
