import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface RevenueShareItemsKeyType {
  addShare: LocalesKey;
  areYouSureYouWantToDisableThisRevenueShare: LocalesKey;
  areYouSureYouWantToEnableThisRevenueShare: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  endDate: LocalesKey;
  incoming: LocalesKey;
  moveTo: LocalesKey;
  name: LocalesKey;
  outgoing: LocalesKey;
  past: LocalesKey;
  plural: LocalesKey;
  search: LocalesKey;
  select: LocalesKey;
  selectShare: LocalesKey;
  selectedUserHasNoShares: LocalesKey;
  share: LocalesKey;
  single: LocalesKey;
  startDate: LocalesKey;
  update: LocalesKey;
  willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow: LocalesKey;
  usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate: LocalesKey;
}

export const revenueShareItemsKeys = keyPathMirror<
  RevenueShareItemsKeyType,
  string
>(
  {
    addShare: null,
    areYouSureYouWantToDisableThisRevenueShare: null,
    areYouSureYouWantToEnableThisRevenueShare: null,
    create: null,
    delete: null,
    endDate: null,
    incoming: null,
    moveTo: null,
    name: null,
    outgoing: null,
    past: null,
    plural: null,
    search: null,
    select: null,
    selectShare: null,
    selectedUserHasNoShares: null,
    share: null,
    single: null,
    startDate: null,
    update: null,
    willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow:
      null,
    usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate:
      null
  },
  'models.revenueShareItems.'
);
