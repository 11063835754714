import { AccountBalancesKeyType } from '../../keys';

const accountBalancesKeys: AccountBalancesKeyType = {
  amount: 'Amount',
  balance: 'Balance',
  prevBalance: 'Prev_Balance',
  date: 'Date',
  accountBalance: 'Account Balance'
};

export default accountBalancesKeys;
