import { useCallback } from 'react';
import {
  useForm,
  FieldValues,
  Path,
  SubmitHandler,
  SubmitErrorHandler,
  UnpackNestedValue,
  DefaultValues
} from 'react-hook-form';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactHookFormMode } from './useReactHookForm.types';

import { raiseError } from '../../../../../utils/raiseError';

interface ReactHookFormOptions<FormDataType> {
  defaultValues: DefaultValues<FormDataType>;
  isModalForm?: boolean;
  serverValidatedFields?: Path<FormDataType>[];
  schema?: AnyObjectSchema;
  mode?: ReactHookFormMode;
}

interface SubmitReactHookFormOptions<FormDataType> {
  onSubmit: SubmitHandler<FormDataType>;
  onClientValidationError?: SubmitErrorHandler<FormDataType>;
  onServerError?: (error: unknown) => void;
  dirtyFieldsOnly?: boolean;
}

function useReactHookForm<FormDataType extends FieldValues>({
  defaultValues,
  isModalForm = false,
  schema,
  mode
}: ReactHookFormOptions<FormDataType>) {
  const {
    control,
    formState: { dirtyFields, isDirty, errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    setFocus,
    watch,
    trigger
  } = useForm<FormDataType>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
    mode
  });

  const handleSubmitReactHookForm = useCallback(
    ({
      onSubmit,
      onClientValidationError,
      onServerError,
      dirtyFieldsOnly = true
    }: SubmitReactHookFormOptions<FormDataType>) =>
      handleSubmit(
        async (data) => {
          try {
            if (dirtyFieldsOnly) {
              return await onSubmit?.(
                pick(data, keys(dirtyFields)) as UnpackNestedValue<FormDataType>
              );
            }

            return await onSubmit?.(data);
          } catch (error) {
            keys(error).forEach((errorKey: Path<FormDataType>) => {
              if (errorKey === 'fullMessages') {
                return;
              }

              if (isArray(error?.[errorKey])) {
                setError(errorKey, {
                  type: 'server',
                  message: error?.[errorKey]?.join(', ')
                });
              }
            });

            onServerError?.(error);

            if (isModalForm) {
              raiseError('Server validation error');
            }
          }
        },
        (errors) => {
          onClientValidationError?.(errors);

          if (isModalForm) {
            raiseError('Client validation error');
          }
        }
      ),
    [handleSubmit, dirtyFields, isModalForm, setError]
  );

  return {
    control,
    dirtyFields,
    errors,
    getValues,
    handleSubmitReactHookForm,
    isDirty,
    isValid,
    register,
    resetForm: reset,
    setError,
    setValue,
    setFocus,
    watch,
    trigger
  };
}

export default useReactHookForm;
