import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MenuKeyType {
  contactSupport: LocalesKey;
  hide: LocalesKey;
  keepOpen: LocalesKey;
  links: {
    all: LocalesKey;
    access: LocalesKey;
    accounts: LocalesKey;
    accountingTransactions: LocalesKey;
    active: LocalesKey;
    allInvoices: LocalesKey;
    bills: LocalesKey;
    blocked: LocalesKey;
    chatGPTPrompts: LocalesKey;
    clients: LocalesKey;
    emailTemplates: LocalesKey;
    favorite: LocalesKey;
    finance: LocalesKey;
    generalLedger: LocalesKey;
    invoices: LocalesKey;
    itemTags: LocalesKey;
    roles: LocalesKey;
    pages: LocalesKey;
    permissions: LocalesKey;
    people: LocalesKey;
    sections: LocalesKey;
    products: LocalesKey;
    projects: LocalesKey;
    tasks: LocalesKey;
    transactionsImports: LocalesKey;
    content: LocalesKey;
    reports: LocalesKey;
    reportSections: LocalesKey;
    sentMessages: LocalesKey;
    smartContracts: LocalesKey;
    stock: LocalesKey;
    messages: LocalesKey;
    my: LocalesKey;
    companies: LocalesKey;
    defaultItemTypes: LocalesKey;
    itemTypes: LocalesKey;
    itemCategories: LocalesKey;
    avBillingInfos: LocalesKey;
    depositInvoices: LocalesKey;
    workers: LocalesKey;
    smartContractFees: LocalesKey;
    unassigned: LocalesKey;
  };
  tabs: {
    dashboard: LocalesKey;
    notifications: LocalesKey;
    tasksAndProjects: LocalesKey;
    workspace: LocalesKey;
    workspaceBeta: LocalesKey;
    stock3d: LocalesKey;
    users: LocalesKey;
    workers: LocalesKey;
    people: LocalesKey;
    clients: LocalesKey;
    admin: LocalesKey;
    payments: LocalesKey;
    library: LocalesKey;
    myLibrary: LocalesKey;
    reports: LocalesKey;
  };
}

export const menuKeys = keyPathMirror<MenuKeyType, string>(
  {
    contactSupport: null,
    hide: null,
    keepOpen: null,
    links: {
      all: null,
      access: null,
      accounts: null,
      accountingTransactions: null,
      active: null,
      allInvoices: null,
      bills: null,
      blocked: null,
      chatGPTPrompts: null,
      clients: null,
      emailTemplates: null,
      favorite: null,
      finance: null,
      generalLedger: null,
      invoices: null,
      itemTags: null,
      roles: null,
      pages: null,
      permissions: null,
      people: null,
      sections: null,
      products: null,
      projects: null,
      tasks: null,
      transactionsImports: null,
      content: null,
      reports: null,
      reportSections: null,
      sentMessages: null,
      smartContracts: null,
      stock: null,
      messages: null,
      my: null,
      companies: null,
      defaultItemTypes: null,
      itemTypes: null,
      itemCategories: null,
      avBillingInfos: null,
      depositInvoices: null,
      workers: null,
      smartContractFees: null,
      unassigned: null
    },
    tabs: {
      dashboard: null,
      notifications: null,
      tasksAndProjects: null,
      workspace: null,
      workspaceBeta: null,
      stock3d: null,
      users: null,
      workers: null,
      people: null,
      clients: null,
      admin: null,
      payments: null,
      library: null,
      myLibrary: null,
      reports: null
    }
  },
  'models.menu.'
);
