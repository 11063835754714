import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ItemCategoriesKeyType {
  singular: LocalesKey;
  plural: LocalesKey;
  search: LocalesKey;
  name: LocalesKey;
  categoryName: LocalesKey;
  create: LocalesKey;
  update: LocalesKey;
  delete: LocalesKey;
  selectCategory: LocalesKey;
}

export const itemCategoriesKeys = keyPathMirror<ItemCategoriesKeyType, string>(
  {
    singular: null,
    plural: null,
    search: null,
    name: null,
    categoryName: null,
    create: null,
    update: null,
    delete: null,
    selectCategory: null
  },
  'models.itemCategories.'
);
