import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface AccountsKeyType {
  areYouSureYouWantToCancelInvitation: LocalesKey;
  cancelInvitation: LocalesKey;
  email: LocalesKey;
  expired: LocalesKey;
  expiryDate: LocalesKey;
  invitations: LocalesKey;
  sentBy: LocalesKey;
  sent: LocalesKey;
}

export const teamInvitationsKeys = keyPathMirror<AccountsKeyType, string>(
  {
    areYouSureYouWantToCancelInvitation: null,
    cancelInvitation: null,
    email: null,
    expired: null,
    expiryDate: null,
    invitations: null,
    sentBy: null,
    sent: null
  },
  'models.teamInvitations.'
);
