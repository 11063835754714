import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { ChatGptPromptUUID } from './chatGptPromptsTypes';

const basePath = '/chat-gpt-prompts';

export class ChatGptPromptPath {
  static index() {
    return basePath;
  }

  static show(chatGptPromptUuid: ChatGptPromptUUID) {
    return `${basePath}/${chatGptPromptUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
