import { IconProps } from '../types';

function RulersSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M7 18H3.8c-1 0-1.8-.8-1.8-1.8V8h5v2H4v1h3v2H5v1h2v2H4v1h3v1zM8 8v10h8.2c1 0 1.8-.8 1.8-1.8V8H8zm8.2-6H3.8C2.8 2 2 2.8 2 3.8V7h2V4h1v3h2V5h1v2h2V4h1v3h2V5h1v2h2V4h1v3h1V3.8c0-1-.8-1.8-1.8-1.8z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default RulersSolidIcon;
