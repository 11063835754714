import React, { Fragment, ReactNode, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cl from 'classnames';
import isFunction from 'lodash/isFunction';

import { IconsEnum } from '../../../assets/icons/types';
import {
  SimpleModalCommonProps,
  SimpleModalRenderPropArgs,
  SimpleModalRenderButtonsPropArgs
} from './SimpleModal.types';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';
import SimpleModalButtons from './components/SimpleModalButtons/SimpleModalButtons';

import { SimpleModalWrapper } from './helpers/SimpleModalWrapper';
import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';

import { words } from '../../../locales/keys';

interface SimpleModalChildrenProps {
  children: ReactNode | ((args: SimpleModalRenderPropArgs) => ReactNode);
  childrenButtons?: ReactNode;
  renderButtons?: (args: SimpleModalRenderButtonsPropArgs) => ReactNode;
}

interface SimpleModalDefaultProps {
  handleSubmit?: () => void;
  hideModal: () => void;
  isOpen: boolean;
}

type SimpleModalProps = SimpleModalDefaultProps &
  SimpleModalCommonProps &
  SimpleModalChildrenProps;

function SimpleModal({
  children,
  disabled,
  handleSubmit,
  hideModal,
  i18nCancelText = words.cancel,
  i18nSubmitText = words.save,
  i18nCloseText = words.close,
  submitIcon,
  submitClass,
  buttonsContainerClassName,
  submitIconClassName,
  i18nTitle,
  title,
  id,
  isLoading,
  isOpen = false,
  addModalClassName,
  modalIcon,
  modalIconClassName,
  submitDisabled,
  submitButtonColor = 'blue',
  withoutCancelButton = false,
  withoutButtons = false,
  withCloseButton = false,
  withoutSubmitButton = false,
  childrenClassName,
  modalSize = 'xl',
  allowPropagation,
  onCancel,
  closeOnCancel,
  cancelButtonPosition,
  withoutInitialFocus,
  childrenButtons,
  renderButtons
}: SimpleModalProps) {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as={SimpleModalWrapper}
        allowPropagation={allowPropagation}
        className={cl('fixed z-20 inset-0', { 'max-w-': modalSize === 'full' })}
        id={id}
        initialFocus={withoutInitialFocus ? divRef : submitButtonRef}
        open={isOpen}
        data-archi-modal
        onClose={hideModal}
      >
        <div
          className="relative flex items-end sm:items-center justify-center h-full pt-8 sm:p-4 sm:pb-8"
          ref={divRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={cl(
                'fixed inset-0 bg-gray-400 bg-opacity-80 dark:bg-gray-950 dark:bg-opacity-80 transition-opacity',
                { 'z-0': modalSize === 'full' }
              )}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cl(
                'z-10 flex flex-col bg-white dark:bg-gray-850 border dark:border-gray-800 border-transparent rounded-t-3xl sm:rounded-3xl shadow-xl',
                {
                  'max-h-full w-full': modalSize === 'full',
                  'max-h-full w-full sm:max-w-xl': modalSize === 'xl',
                  'max-h-full w-full sm:max-w-xs': modalSize === 'xs',
                  'max-h-full w-full sm:max-w-sm': modalSize === 'sm',
                  'max-h-full w-full sm:max-w-3xl': modalSize === '3xl',
                  'max-h-full w-full sm:max-w-4xl': modalSize === '4xl',
                  'max-h-full w-full sm:max-w-md': modalSize === 'md'
                },
                addModalClassName
              )}
            >
              <div className="flex-shrink px-4">
                {i18nTitle || title ? (
                  <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700 py-4">
                    <Fragment>
                      {modalIcon ? (
                        <Icon className={modalIconClassName} icon={modalIcon} />
                      ) : null}
                      <Dialog.Title
                        as="h3"
                        className={cl(
                          'text-base sm:text-lg text-center flex-1 truncate',
                          {
                            'pl-8': !modalIcon
                          }
                        )}
                      >
                        {i18nTitle ? <Translate id={i18nTitle} /> : title}
                      </Dialog.Title>
                      <PureIconButtonHelper
                        className="-mr-2 -my-2 ml-2 dark:hover:bg-gray-700 dark:hover:text-gray-200 dark:text-gray-500 hover:bg-gray-200 hover:text-gray-600 p-2 rounded-full text-gray-400"
                        icon={IconsEnum.X}
                        onClick={hideModal}
                      />
                    </Fragment>
                  </div>
                ) : null}
              </div>

              <div className={childrenClassName || 'p-4'}>
                {typeof children === 'function'
                  ? children({ isOpen, hideModal })
                  : children}
              </div>

              {isFunction(renderButtons)
                ? renderButtons({ handleSubmit, hideModal })
                : null}

              {withoutButtons || isFunction(renderButtons) ? null : (
                <SimpleModalButtons
                  disabled={disabled}
                  handleSubmit={handleSubmit}
                  i18nCancelText={i18nCancelText}
                  i18nSubmitText={i18nSubmitText}
                  i18nCloseText={i18nCloseText}
                  submitIcon={submitIcon}
                  containerClassName={buttonsContainerClassName}
                  submitClass={submitClass}
                  submitIconClassName={submitIconClassName}
                  submitDisabled={submitDisabled}
                  submitButtonColor={submitButtonColor}
                  cancelButtonPosition={cancelButtonPosition}
                  withoutCancelButton={withoutCancelButton}
                  withCloseButton={withCloseButton}
                  withoutSubmitButton={withoutSubmitButton}
                  isLoading={isLoading}
                  onCancel={onCancel}
                  closeOnCancel={closeOnCancel}
                  hideModal={hideModal}
                  ref={submitButtonRef}
                  childrenButtons={childrenButtons}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SimpleModal;
