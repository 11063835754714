import { IconProps } from '../types';

function ViewListUserSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 4c0-.6.4-1 1-1h12c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1zm0 4c0-.6.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1zm0 4c0-.6.4-1 1-1h3c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1zm0 4c0-.6.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1zm10-4c1.4 0 2.5-1.1 2.5-2.5S14.4 7 13 7s-2.5 1.1-2.5 2.5S11.6 12 13 12zm-5 5c0-2.8 2.2-5 5-5s5 2.2 5 5H8z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ViewListUserSolidIcon;
