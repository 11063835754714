import { IconProps } from '../types';

function CircleOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-4 w-4'}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle fillRule="evenodd" cx="12" cy="12" r="6" />
    </svg>
  );
}

export default CircleOutlineIcon;
