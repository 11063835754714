import { NextRouter } from 'next/router';
import startsWith from 'lodash/startsWith';

import { TeamPath } from '../../../main/teams/TeamPath';

function isCompanyViewRoute(router: NextRouter): boolean {
  return startsWith(router.pathname, TeamPath.companyPathname());
}

export default isCompanyViewRoute;
