import { IconProps } from '../types';

function DocumentVideoSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M15.4 6L12 2.6c-.4-.4-.9-.6-1.4-.6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7.4c0-.5-.2-1-.6-1.4zM7 14V8l6 3-6 3z"></path>
    </svg>
  );
}

export default DocumentVideoSolidIcon;
