import { AccountTypeNanoID } from '../accountTypes/accountTypesTypes';
import { FetchFinJournalRecordsFilters } from './journalRecordsTypes';

const paymentsJournalRecordsBasePath = '/payments/transactions';

export class JournalRecordPath {
  static paymentsJournalRecords() {
    return paymentsJournalRecordsBasePath;
  }

  static paymentsJournalRecordsAccountType(
    accountTypeNanoId: AccountTypeNanoID
  ) {
    return `${paymentsJournalRecordsBasePath}/filters/account-types/${accountTypeNanoId}`;
  }

  static paymentsJournalRecordsAccountTypeWithFilters(
    accountTypeNanoId: AccountTypeNanoID,
    filters: FetchFinJournalRecordsFilters
  ) {
    return `${paymentsJournalRecordsBasePath}/filters/account-types/${accountTypeNanoId}?filters=${JSON.stringify(
      filters
    )}`;
  }

  static accountingTransactions() {
    return `/accounting-transactions`;
  }
}
