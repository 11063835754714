export default {
  downloadExcel: 'Скачать документ excel со списком оценок',
  plural: 'Оценки',
  set: 'Набор оценок',
  singular: 'Оценка',
  no: 'Нет оценок',
  fields: {
    accuracy: 'Точность',
    communication: 'Коммуникация',
    timing: 'Тайминг',
    quality: 'Качество',
    taskError: 'Ошибка задачи',
    projectError: 'Ошибка проекта',
    profileError: 'Ошибка профиля',
    briefError: 'Ошибка брифа',
    estimateError: 'Ошибка оценки',
    styleError: 'Стилистическая ошибка',
    burningTasks: 'Горящие задачи',
    respondRate: 'Частота ответов',
    estimateRate: 'Оценка стоимости',
    qualityAssurance: 'Обеспечение качества',
    textureLightingCamera: 'Текстура/Освещение/Камера',
    compositionDetailsStorytelling: 'Композиция/Детали/Сюжет'
  }
};
