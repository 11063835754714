import React from 'react';
import cl from 'classnames';

import { ClassName, I18nText } from '../../../../../../types';
import { IconsEnum } from '../../../../../../assets/icons/types';
import { PrimaryNavTabsEnum } from '../../../../../hooks/useMenu/types';

import { useMenu } from '../../../../../hooks/useMenu';

import { NextPureTooltipIconLinkHelper } from '../../../../../../helpers/links/NextPureTooltipIconLinkHelper';

interface PrimaryNavTabProps {
  addClassName?: ClassName;
  href: string;
  icon: IconsEnum;
  tab: PrimaryNavTabsEnum;
  tooltipI18nText: I18nText;
}

function PrimaryNavTab({
  addClassName,
  href,
  icon,
  tab,
  tooltipI18nText
}: PrimaryNavTabProps) {
  const { currentPathTab } = useMenu();

  const active = tab === currentPathTab;

  return (
    <NextPureTooltipIconLinkHelper
      className={cl(
        'inline-flex flex-shrink-0 items-center justify-center h-full sm:h-14 flex-grow sm:w-14 sm:rounded-lg sm:flex-grow-0 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 stroke-1.75',
        active
          ? 'text-blue-500 bg-blue-500 bg-opacity-20 dark:text-blue-400'
          : 'hover:bg-gray-200 dark:hover:bg-gray-700',
        addClassName
      )}
      href={href}
      iconClassName="h-6 w-6 sm:h-7 sm:w-7"
      icon={icon}
      tooltipSingleton
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default PrimaryNavTab;
