import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface PagesKeyType {
  plural: LocalesKey;
  search: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  view: LocalesKey;
}

export const pagesKeys = keyPathMirror<PagesKeyType, string>(
  {
    plural: null,
    search: null,
    create: null,
    delete: null,
    view: null
  },
  'models.pages.'
);
