import { ItemCategoriesKeyType } from '../../keys/models/itemCategories';

const itemCategoriesKeys: ItemCategoriesKeyType = {
  singular: 'Item category',
  plural: 'Item categories',
  search: 'Search item categories',
  name: 'Name category',
  categoryName: 'Category name',
  create: 'Create item category',
  update: 'Update item category',
  delete: 'Delete item category',
  selectCategory: 'Select item category'
};

export default itemCategoriesKeys;
