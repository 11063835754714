import { IconsEnum } from '../../../../../assets/icons/types';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { UserPath } from '../../../../../main/users/UserPath';

import { paymentsKeys } from '../../../../../locales/keys';

interface UserMenuProfilePaymentsLinkProps {
  closeMenus: () => void;
}

function UserMenuProfilePaymentsLink({
  closeMenus
}: UserMenuProfilePaymentsLinkProps) {
  return (
    <NextPureLinkHelper
      href={UserPath.payments()}
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm items-center"
      icon={IconsEnum.CASH}
      iconClassName="mr-3 h-5 w-5"
      i18nText={paymentsKeys.plural}
      onClick={closeMenus}
    />
  );
}

export default UserMenuProfilePaymentsLink;
