import { useCallback, useMemo } from 'react';

import { ReactQueryModalOptions } from './useReactQueryModal.types';

import { useReactQueryState } from '../../../../main/common/hooks/base/reactQuery/useReactQueryState';

const MODAL_CACHE_KEY = 'modal';

function useReactQueryModal({
  modalEnum,
  onClose,
  onOpen
}: ReactQueryModalOptions) {
  const { setValue, value } = useReactQueryState(MODAL_CACHE_KEY, null);

  const isOpen = useMemo<boolean>(
    () => value === modalEnum,
    [value, modalEnum]
  );

  const showModal = useCallback<() => void>(() => {
    setValue(modalEnum);
    onOpen && onOpen();
  }, [setValue, modalEnum, onOpen]);

  const hideModal = useCallback<() => void>(() => {
    setValue(null);
    onClose?.();
  }, [onClose, setValue]);

  return {
    isOpen,
    showModal,
    hideModal
  };
}

export default useReactQueryModal;
