import React, { useCallback } from 'react';
import cl from 'classnames';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';

import { AddFundsModalButtonDataBillingInfo } from '../../../../AddFundsModalButton.types';
import { ClassName } from '../../../../../../../../../types';

import { useTranslate } from '../../../../../../../../../common/hooks/useTranslate';

import { formsFields, words } from '../../../../../../../../../locales/keys';

interface AddFundsModalButtonBillingInfoSelectItemProps {
  addClassName?: ClassName;
  billingInfo: AddFundsModalButtonDataBillingInfo;
  selected: boolean;
  onBillingInfoSelect: (
    billingInfo: AddFundsModalButtonDataBillingInfo
  ) => void;
  withPaymentMethod?: boolean;
  withAvBillingAddress?: boolean;
}

function AddFundsModalButtonBillingInfoSelectItem({
  addClassName,
  billingInfo,
  selected,
  onBillingInfoSelect,
  withPaymentMethod = false,
  withAvBillingAddress = false
}: AddFundsModalButtonBillingInfoSelectItemProps) {
  const handleSelect = useCallback<() => void>(() => {
    onBillingInfoSelect(billingInfo);
  }, [billingInfo, onBillingInfoSelect]);

  const { t } = useTranslate();

  const companyInfo = compact([
    billingInfo?.companyName,
    `${billingInfo?.firstName || ''} ${billingInfo?.lastName || ''}`.trim(),
    billingInfo?.contactPhone,
    billingInfo?.contactEmail
  ]).join(', ');

  const addressInfo = compact([
    billingInfo?.address,
    billingInfo?.city,
    billingInfo?.state,
    billingInfo?.country,
    billingInfo?.zipCode
  ]).join(', ');

  const vatNumberInfo =
    billingInfo?.vatNumber &&
    `${t(formsFields.vatNumber)}: ${billingInfo?.vatNumber}`;

  const paymentMethodInfo =
    withPaymentMethod &&
    `${t(words.paymentMethod)}: ${capitalize(billingInfo?.paymentMethod)}`;

  const receiverInfo =
    withAvBillingAddress &&
    `${t(words.receiver)}: ${billingInfo?.avBillingInfo?.name}`;

  return (
    <label
      className={cl(
        'relative border p-4 flex cursor-pointer focus:outline-none',
        addClassName,
        selected
          ? 'bg-blue-50 border-blue-200 dark:border-blue-600 dark:bg-blue-500/10 z-10'
          : 'border-gray-200 dark:border-gray-600'
      )}
    >
      <input
        type="radio"
        checked={selected}
        className="basic-radio checked:text-blue-600"
        onChange={handleSelect}
      />
      <span className="ml-3 flex flex-col">
        <span className="block text-sm font-medium text-gray-900 dark:text-gray-200">
          {addressInfo}
        </span>

        <span
          className={cl(
            'block text-sm',
            selected
              ? 'text-blue-700 dark:text-blue-300'
              : 'text-gray-500 dark:text-gray-400'
          )}
        >
          {companyInfo}
        </span>

        {vatNumberInfo && (
          <span
            className={cl(
              'block text-sm',
              selected
                ? 'text-blue-700 dark:text-blue-300'
                : 'text-gray-500 dark:text-gray-400'
            )}
          >
            {vatNumberInfo}
          </span>
        )}

        {receiverInfo && (
          <span
            className={cl(
              'block text-sm',
              selected
                ? 'text-blue-700 dark:text-blue-300'
                : 'text-gray-500 dark:text-gray-400'
            )}
          >
            {receiverInfo}
          </span>
        )}

        {withPaymentMethod && (
          <span
            className={cl(
              'block text-sm',
              selected
                ? 'text-blue-700 dark:text-blue-300'
                : 'text-gray-500 dark:text-gray-400'
            )}
          >
            {paymentMethodInfo}
          </span>
        )}
      </span>
    </label>
  );
}

export default AddFundsModalButtonBillingInfoSelectItem;
