import { IconsEnum } from '../../../../../assets/icons/types';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { UserPath } from '../../../../../main/users/UserPath';
import { SingleUserPath } from '../../../../../main/users/SingleUserPath';

import { words } from '../../../../../locales/keys';

interface UserMenuProfileLinkProps {
  linkType: 'profile' | 'newProfile';
  closeMenus: () => void;
}

function UserMenuProfileLink({
  linkType,
  closeMenus
}: UserMenuProfileLinkProps) {
  return (
    <NextPureLinkHelper
      href={
        linkType === 'profile' ? UserPath.profile() : SingleUserPath.profile()
      }
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm items-center"
      icon={IconsEnum.USER_CIRCLE_SOLID}
      iconClassName="mr-3 h-5 w-5"
      i18nText={words.profile}
      onClick={closeMenus}
    />
  );
}

export default UserMenuProfileLink;
