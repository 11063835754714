import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TodoItemsKeyType {
  checkAll: LocalesKey;
  createTodoList: LocalesKey;
  editTodoList: LocalesKey;
  plural: LocalesKey;
  uncheckAll: LocalesKey;
  enterListTitle: LocalesKey;
}

export const todoItemsKeys = keyPathMirror<TodoItemsKeyType, string>(
  {
    checkAll: null,
    createTodoList: null,
    editTodoList: null,
    plural: null,
    uncheckAll: null,
    enterListTitle: null
  },
  'models.todoItems.'
);
