import { IconProps } from '../types';

function SmashingCGIIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      aria-hidden="true"
      className={className || 'h-8 w-8'}
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      enableBackground="new 0 0 256 256"
      xmlSpace="preserve"
    >
      <polygon points="206.7,45.4 95.4,109.2 82.6,101.8 193.9,38 128,0 17.1,64 17.1,192 49.3,210.6 160.6,146.8 173.4,154.2 62.1,218 128,256 238.9,192 238.9,64" />
    </svg>
  );
}

export default SmashingCGIIcon;
