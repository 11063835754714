import { FinanceAddressesKeyType } from '../../keys';

const financeAddresses: FinanceAddressesKeyType = {
  addAddress: 'Add address',
  addBillingAddress: 'Add billing address',
  deleteBillingAddress: 'Delete billing address',
  selectState: 'Select state',
  selectCountry: 'Select country',
  updateBillingAddress: 'Update billing address'
};

export default financeAddresses;
