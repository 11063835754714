import { gql } from 'graphql-request';

export const FETCH_TASK_MEMBER_INVITES_TOTAL_COUNT_QUERY = gql`
  query TaskMemberInvitesTotalCountQuery(
    $taskMemberInvitesFilters: TaskMemberInvitesFilters
  ) {
    taskMemberInvites(filters: $taskMemberInvitesFilters) {
      paginationInfo {
        totalCount
      }
    }
  }
`;
