import { memo, useCallback, useEffect } from 'react';

interface PopoverCloseOnEscProps {
  closePopover: () => void;
}

const PopoverCloseOnEsc = ({ closePopover }: PopoverCloseOnEscProps) => {
  const handlePress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closePopover();
      }
    },
    [closePopover]
  );

  useEffect(() => {
    window.addEventListener('keydown', handlePress);
    return () => {
      window.removeEventListener('keydown', handlePress);
    };
  }, [handlePress]);

  return null;
};

export default memo(PopoverCloseOnEsc);
