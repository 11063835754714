import { TeamNanoID } from '../../../main/teams/teamsTypes';
import { PrimaryNavTabsEnum } from '../../hooks/useMenu/types';

function getNavMenuTabPath(
  tab: PrimaryNavTabsEnum,
  companyNanoId?: TeamNanoID
) {
  return companyNanoId ? `${tab}-${companyNanoId}-path` : `${tab}-path`;
}

export default getNavMenuTabPath;
