import { IconProps } from '../types';

function ArrowDownTriangleSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-5 w-5'}
      fill="currentColor"
      id={id}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" d="M10 14 L4 8 L16 8 Z" />
    </svg>
  );
}

export default ArrowDownTriangleSolidIcon;
