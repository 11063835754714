import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberHelperProps {
  value: number;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
}

function NumberHelper({
  value,
  prefix,
  suffix,
  thousandSeparator = true,
  decimalScale = 2,
  fixedDecimalScale = true
}: NumberHelperProps) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      suffix={suffix ? ` ${suffix}` : null}
    />
  );
}

export default NumberHelper;
