import React, { ForwardedRef, useCallback } from 'react';

import { I18nText } from '../../../types';

import { Translate } from '../../Translate';

type PureRefButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureRefButtonHelperDefaultProps {
  id?: string;
  blurOnMouseLeave?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: PureRefButtonHelperOnClick;
  onMouseEnter?: () => void;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
}

interface PureRefButtonHelperWithI18nProps {
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureRefButtonHelperWithTextProps {
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureRefButtonHelperProps = PureRefButtonHelperDefaultProps &
  (PureRefButtonHelperWithI18nProps | PureRefButtonHelperWithTextProps);

const PureRefButtonHelper = React.forwardRef<
  HTMLButtonElement,
  PureRefButtonHelperProps
>(
  (
    componentProps: PureRefButtonHelperProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      id,
      blurOnMouseLeave,
      className,
      disabled,
      onClick,
      onMouseEnter,
      onPointerDown,
      i18nTextClassName,
      i18nText,
      text
    } = componentProps;
    const handleClick = useCallback<PureRefButtonHelperOnClick>(
      (e) => {
        e.preventDefault();
        onClick?.(e);
      },
      [onClick]
    );

    const handleMouseLeave = useCallback(
      (event) => (event.target as HTMLButtonElement)?.blur(),
      []
    );

    return (
      <button
        id={id}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
        onPointerDown={onPointerDown}
        ref={ref}
        type="button"
      >
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
      </button>
    );
  }
);

PureRefButtonHelper.displayName = 'PureRefButtonHelper';

export default PureRefButtonHelper;
