export default {
  create: 'Створити дозвіл',
  plural: 'Дозволи',
  pluralDowncase: 'дозволи',
  new: 'Новий дозвіл',
  no: 'Дозволи відсутні',
  delete: 'Видалити дозвіл',
  scopes: {
    index: 'Індекс',
    create: 'Створення',
    read: 'Читання',
    update: 'Оновлення',
    delete: 'Видалення',
    download: 'Завантаження'
  },
  search: 'Шукати доступи'
};
