import React from 'react';

import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { itemsKeys, words } from '../../../../../../../locales/keys';

interface AddFundsModalButtonBillingInfoInfoProps {
  amount: number;
}

function AddFundsModalButtonBillingInfoInfo({
  amount
}: AddFundsModalButtonBillingInfoInfoProps) {
  return (
    <table className="w-full font-mono">
      <thead>
        <tr className="border-b text-gray-600 dark:text-gray-400 border-gray-200 dark:border-gray-600">
          <th className="text-xs font-semibold py-2 text-left pr-2 w-1/2">
            <div className="flex items-center gap-1.5">
              <span>
                <Translate id={itemsKeys.name} />
              </span>
            </div>
          </th>
          <th className="text-xs font-semibold py-2 text-left px-2">
            <Translate id={itemsKeys.price} />
          </th>
          <th className="text-xs font-semibold py-2 text-right px-2 w-px">
            <Translate id={itemsKeys.qty} />
          </th>
          <th className="text-xs font-semibold py-2 text-right pl-2">
            <Translate id={itemsKeys.subTotal} />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="font-bold text-xs py-1 bg-gray-50 dark:bg-gray-850"
            colSpan={4}
          >
            <Translate id={itemsKeys.rendering} />
          </td>
        </tr>
        <tr className="group align-top">
          <td className="text-xs pt-2 pb-2 text-left pr-0.5 sm:pr-2 break-wrap">
            <Translate id={itemsKeys.threeDRenderingServicesPrepayment} />
          </td>
          <td className="text-xs pt-2 pb-2 text-left px-2">
            <MoneyHelper value={amount} />
          </td>
          <td className="text-xs pt-2 pb-2 text-right pl-2 relative pr-0 w-0">
            <span>{1}</span>
          </td>
          <td className="text-xs pt-2 pb-2 text-right pl-2">
            <span>
              <MoneyHelper value={amount} />
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td colSpan={2} className="text-xs pt-0.5 pb-2 text-left px-2">
            <Translate id={words.tax} />
          </td>
          <td className="text-xs text-right pl-2 pt-0.5 pb-2">
            <MoneyHelper value={0} />
          </td>
        </tr>
        <tr>
          <td />
          <td
            colSpan={2}
            className="sm:text-lg font-semibold text-left px-2 py-2 border-t border-gray-200 dark:border-gray-600"
          >
            <Translate id={words.amountDue} />
          </td>
          <td className="sm:text-lg text-right pl-0.5 sm:pl-2 font-semibold border-t border-gray-200 dark:border-gray-600 py-1.5">
            <div className="inline-block py-0.5 px-0.5 sm:px-2 bg-yellow-100 dark:bg-yellow-800 rounded-md">
              <MoneyHelper value={amount} />
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default AddFundsModalButtonBillingInfoInfo;
