import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SettingsKeyType {
  finance: LocalesKey;
  plural: LocalesKey;
  general: LocalesKey;
  system: LocalesKey;
  notification: LocalesKey;
  delete: LocalesKey;
  deleteAccount: LocalesKey;
}

export const settingsKeys = keyPathMirror<SettingsKeyType, string>(
  {
    finance: null,
    plural: null,
    general: null,
    system: null,
    notification: null,
    delete: null,
    deleteAccount: null
  },
  'models.settings.'
);
