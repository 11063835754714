import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

export interface FetchProfileCategoriesQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  localizedName: CategoryLocalizedName;
}

export const FETCH_PROFILE_CATEGORIES_QUERY = gql`
  query UserProfileCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        localizedName
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
