import { ReactNode, useCallback } from 'react';
import { useToasts, AppearanceTypes } from 'react-toast-notifications';

import { I18nText } from '../../../types';

import { useTranslate } from '../useTranslate';

type ToastNotificationMessage = ReactNode | string;
type I18nToastNotificationMessage = I18nText;

interface ToastNotificationOptions {
  appearance?: AppearanceTypes;
  autoDismiss?: boolean;
  message?: ToastNotificationMessage;
  i18nMessage?: I18nToastNotificationMessage;
}

function useToastNotification(options: ToastNotificationOptions = {}) {
  const {
    message,
    i18nMessage,
    appearance = 'success',
    autoDismiss = true
  } = options;

  const { addToast } = useToasts();
  const { t } = useTranslate();

  const showToastNotification = useCallback<
    (
      toastMessage?: ToastNotificationMessage,
      toastAppearance?: AppearanceTypes
    ) => void
  >(
    (toastMessage, toastAppearance) =>
      addToast(toastMessage || message, {
        appearance: toastAppearance || appearance,
        autoDismiss
      }),
    [message, appearance, autoDismiss, addToast]
  );

  const showToastI18nNotification = useCallback<
    (
      i18nToastMessage?: I18nToastNotificationMessage,
      toastAppearance?: AppearanceTypes
    ) => void
  >(
    (i18nToastMessage, toastAppearance) =>
      addToast(t(i18nToastMessage || i18nMessage), {
        appearance: toastAppearance || appearance,
        autoDismiss
      }),
    [t, i18nMessage, appearance, autoDismiss, addToast]
  );

  return { showToastNotification, showToastI18nNotification };
}

export default useToastNotification;
