import { IconProps } from '../types';

function ChevronDownIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      viewBox="0 0 24 24"
      strokeWidth="2"
      fill="none"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default ChevronDownIcon;
