import React, { memo, useCallback } from 'react';

import { UserMenuTypes } from './UserMenu.types';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useMenu } from '../../hooks/useMenu';

import { UserMenuAvatar } from './components/UserMenuAvatar';
import { UserMenuDashboardLink } from './components/UserMenuDashboardLink';
import { UserMenuLogoutButton } from './components/UserMenuLogoutButton';
import { UserMenuProfileLink } from './components/UserMenuProfileLink';
import { UserMenuSettingsLink } from './components/UserMenuSettingsLink';
import { UserMenuProfileInfo } from './components/UserMenuProfileInfo';
import { UserFinMenuProfileInfo } from './components/UserFinMenuProfileInfo';
import { UserMenuProfilePaymentsLink } from './components/UserMenuProfilePaymentsLink';
import { UserMenuCompanyProfileLink } from './components/UserMenuCompanyProfileLink';

import { UserTermsModalButton } from '../modalButtons/UserTermsModalButton';

import { CheckPermissions } from '../../../helpers/CheckPermissions';
import { DropdownDivider } from '../../../helpers/DropdownDivider';
import { PopoverWithPortal, usePopoverState } from '../../../helpers/Popover';
import { PopoverPlacement } from '../../../helpers/Popover/popoverConstants';
import { RefButtonHelper } from '../../../helpers/buttons/RefButtonHelper';

import { UsersPermissions } from '../../../main/users/usersConstants';
import { AppPermissions } from '../../appConstants';
import { words } from '../../../locales/keys';
import { IconsEnum } from '../../../assets/icons/types';

interface UserMenuProps {
  type: UserMenuTypes;
  onLogoutUser: () => void;
  userNanoId: UserNanoID;
}

function UserMenu({ type, userNanoId, onLogoutUser }: UserMenuProps) {
  const { closeSecondaryMenu } = useMenu();

  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverState();

  const closeMenus = useCallback(() => {
    closeSecondaryMenu();
    closePopover();
  }, [closeSecondaryMenu, closePopover]);

  return (
    <>
      <RefButtonHelper
        className="flex mx-auto relative rounded-full text-sm text-white"
        ref={referencePopoverCallbackRef}
        onClick={togglePopover}
      >
        <span className="sr-only">Open user menu</span>
        <UserMenuAvatar type={type} />
      </RefButtonHelper>

      {isPopoverOpen ? (
        <PopoverWithPortal
          referenceElement={referencePopoverElement}
          placement={
            type === UserMenuTypes.MOBILE
              ? PopoverPlacement.TOP_END
              : PopoverPlacement.RIGHT_END
          }
          withoutCalculateMaxHeight
          closePopover={closePopover}
          distanceOffset={6}
          className="min-w-48 z-20 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
        >
          <CheckPermissions action={UsersPermissions.READ_USER_MENU_USER_INFO}>
            <UserMenuProfileInfo userNanoId={userNanoId} />
            <DropdownDivider />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_FIN_MENU_USER_INFO}
          >
            <UserFinMenuProfileInfo userNanoId={userNanoId} />
            <DropdownDivider />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_DASHBOARD_LINK}
          >
            <UserMenuDashboardLink closeMenus={closeMenus} />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_PROFILE_LINK}
          >
            <UserMenuProfileLink closeMenus={closeMenus} linkType="profile" />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_NEW_PROFILE_LINK}
          >
            <UserMenuProfileLink
              closeMenus={closeMenus}
              linkType="newProfile"
            />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_FIN_PROFILE_LINK}
          >
            <UserMenuCompanyProfileLink
              closeMenus={closeMenus}
              i18nText={words.profile}
              icon={IconsEnum.USER_CIRCLE_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_COMPANY_PROFILE_LINK}
          >
            <UserMenuCompanyProfileLink closeMenus={closeMenus} />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_PROFILE_PAYMENTS_LINK}
          >
            <UserMenuProfilePaymentsLink closeMenus={closeMenus} />
          </CheckPermissions>
          <CheckPermissions
            action={UsersPermissions.READ_USER_MENU_PROFILE_SETTINGS_LINK}
          >
            <UserMenuSettingsLink closeMenus={closeMenus} />
          </CheckPermissions>

          <CheckPermissions action={AppPermissions.READ_TERMS_MODAL_BUTTON}>
            <DropdownDivider className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5 sm:hidden" />
            <UserTermsModalButton
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap sm:hidden"
              i18nText={words.terms}
              iconClassName="mr-3 h-5 w-5"
              icon={IconsEnum.INFORMATION_CIRCLE_SOLID}
            />
          </CheckPermissions>

          <DropdownDivider />
          <UserMenuLogoutButton
            closeMenus={closeMenus}
            onLogout={onLogoutUser}
          />
        </PopoverWithPortal>
      ) : null}
    </>
  );
}

export default memo<UserMenuProps>(UserMenu);
