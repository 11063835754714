import { FetchCurrentUserCacheKey } from '../../auth/authTypes';

import {
  FetchUserProfileCacheKey,
  UserClientStatusesEnum,
  UserID,
  UserNanoID
} from './usersTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TeamID } from '../teams/teamsTypes';
import { ProjectNanoID } from '../projects/projectsTypes';
import { TaskNanoID } from '../tasks/tasksTypes';

type UsersType = 'clients' | 'workers' | null;

export class UserCache {
  static currentUserCacheKey() {
    return 'currentUser' as FetchCurrentUserCacheKey;
  }

  static indexCacheKey() {
    return 'users' as FetchUserProfileCacheKey;
  }

  static cellsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-cells`;
  }

  static groupsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-groups`;
  }

  static selectedUserSelectFieldCacheKey(fieldName: string) {
    return `user-select-field-${fieldName}`;
  }

  static usersFilterCacheKey(filterName: string) {
    return `users-filter-${filterName}` as FetchUserProfileCacheKey;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `users-filtered-${searchFilterNanoId}`;
  }

  static filteredWorkersCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `workers-filtered-${searchFilterNanoId}`;
  }

  static filesAttachmentsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-files-attachments`;
  }

  static imagesAttachmentsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-images-attachments`;
  }

  static profileFilesCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-files-attachments`;
  }

  static profileImagesCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-images-attachments`;
  }

  static allRecentlySelectedUsersCacheKey() {
    return `recently-selected-users`;
  }

  static recentlySelectedUsersCacheKey(usersType: UsersType) {
    return `recently-selected-users-${usersType}`;
  }

  static usersSearchCacheKey(usersType: UsersType) {
    return `users-search-${usersType}`;
  }

  static teamUsersSearchCacheKey(teamId: TeamID) {
    return `team-users-${teamId}-search`;
  }

  static usersMessagesByProject(projectNanoId: ProjectNanoID) {
    return `users-messages-by-project-${projectNanoId}`;
  }

  static usersMessagesByTask(taskNanoId: TaskNanoID) {
    return `users-messages-by-task-${taskNanoId}`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `users-select-${fieldName}` as FetchUserProfileCacheKey;
  }

  static defaultSelectCacheKey(fieldName: string) {
    return `default-users-select-${fieldName}` as FetchUserProfileCacheKey;
  }

  static itemsWithUsersCacheKey() {
    return `items-with-users`;
  }

  static showCacheKey() {
    return 'user' as FetchUserProfileCacheKey;
  }

  static showMenuCacheKey() {
    return 'user-menu' as FetchUserProfileCacheKey;
  }

  static showQueryKey(userNanoId: UserNanoID) {
    return ['user', userNanoId];
  }

  static showRedirectCacheKey(userId: UserID | UserNanoID) {
    return `user-${userId}-redirect`;
  }

  static showProfileSlowFieldsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-profile-slow-fields`;
  }

  static showUserTeamsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-teams`;
  }

  static showFinanceCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-finance`;
  }

  static showPendingPaymentsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-pending-payments`;
  }

  static transactionsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-transactions`;
  }

  static unpaidBillsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-unpaid-bills`;
  }

  static clientsCacheKey() {
    return 'clients';
  }

  static clientsFavoriteCacheKey() {
    return 'clients-favorite';
  }

  static clientsStatusCacheKey(clientStatus: UserClientStatusesEnum) {
    return `clients-status-${clientStatus}`;
  }

  static filteredClientsCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `clients-filtered-${searchFilterNanoId}`;
  }

  static clientsFilterCacheKey() {
    return 'clientsFilter';
  }

  static selectFieldCacheKey() {
    return 'select-field-users';
  }

  static selectFieldSelectedUsersCacheKey() {
    return 'select-field-users-selected-users';
  }

  static clientManagersMultiSelectFieldCacheKey() {
    return 'client-managers-multi-select-field-users';
  }

  static projectManagersMultiSelectFieldCacheKey() {
    return 'project-managers-multi-select-field-users';
  }

  static clientsMultiSelectFieldCacheKey() {
    return 'clients-multi-select-field-users';
  }

  static selectedClientsMultiSelectFieldCacheKey(fieldName: string) {
    return `clients-multi-select-field-users-${fieldName}`;
  }

  static workersCacheKey() {
    return 'workers';
  }

  static workersBlockedCacheKey() {
    return 'workers-blocked';
  }

  static workersFavoriteCacheKey() {
    return 'workers-favorite';
  }

  static workersActiveCacheKey() {
    return 'workers-active';
  }

  static userDescriptionCacheKey() {
    return `user-description`;
  }

  static userAirTableCacheKey() {
    return `user-air-table`;
  }

  static userTermsAcceptedAtCacheKey() {
    return `user-terms-accepted-at`;
  }

  static userPersonalizationCacheKey() {
    return `user-personalization`;
  }

  static userSettingsCacheKey() {
    return `user-settings`;
  }

  static userDocumentsCacheKey() {
    return `user-documents`;
  }

  static userDashboardKey() {
    return `user-dashboard`;
  }

  static userContactInfoCacheKey() {
    return `user-contact-info`;
  }
}
