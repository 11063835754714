import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TagsKeyType {
  plural: LocalesKey;
  singular: LocalesKey;
  select: LocalesKey;
  change: LocalesKey;
  customTags: LocalesKey;
  removeAllOtherTags: LocalesKey;
}

export const tagsKeys = keyPathMirror<TagsKeyType, string>(
  {
    plural: null,
    singular: null,
    select: null,
    change: null,
    customTags: null,
    removeAllOtherTags: null
  },
  'models.tags.'
);
