import React from 'react';
import omit from 'lodash/omit';

import { SimpleModalWrapperProps } from './SimpleModalWrapper.types';

const SimpleModalWrapper = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    SimpleModalWrapperProps
>((props, ref) => {
  const { allowPropagation, ...divProps } = props;
  return (
    <div
      {...(allowPropagation ? omit(divProps, 'onClick') : divProps)}
      ref={ref}
    />
  );
});

SimpleModalWrapper.displayName = 'SimpleModalWrapper';

export default SimpleModalWrapper;
