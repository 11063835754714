import { MaterialsKeyType } from '../../keys';

const materialsKeys: MaterialsKeyType = {
  // add: 'Add material to library',
  addMaterial: 'Add material',
  all: 'All materials',
  attach: 'Attach materials',
  attachMaterial: 'Attach material',
  attachSelected: 'Attach selected materials',
  categories: {
    stone: 'Stone',
    ceramics: 'Ceramics',
    fabric: 'Fabric',
    glass: 'Glass',
    metal: 'Metal',
    wood: 'Wood'
  },
  category: 'Material category',
  deleteMaterial: 'Delete material',
  deleteWarning:
    'Are you sure you want to delete the material? This action cannot be undone.',
  download: 'Download material',
  editMaterial: 'Edit material',
  my: 'My Materials',
  name: 'Material name',
  oneOrMoreSkuWasNotFoundInMaterialLibrary:
    'One or more SKU was not found in material library',
  plural: 'Materials',
  search: 'Search materials',
  searchMaterialsByName: 'Search materials by name',
  selected: 'Selected materials',
  singular: 'Material',
  threeDStock: '3D Stock Materials',
  viewAll: 'View all materials'
};

export default materialsKeys;
