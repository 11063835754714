import compact from 'lodash/compact';
import map from 'lodash/map';

import { StaticImageSrcSetData } from '../../StaticImageHelper.types';

function generateStaticImageSrcSet(data: StaticImageSrcSetData): string {
  return compact(
    map(data, (version, resolution) => {
      return `${version} ${resolution}`;
    })
  ).join(', ');
}

export default generateStaticImageSrcSet;
