import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface EmailTemplatesKeyType {
  plural: LocalesKey;
  description: LocalesKey;
  single: LocalesKey;
  search: LocalesKey;
  subject: LocalesKey;
  subjectUk: LocalesKey;
  subjectEn: LocalesKey;
  create: LocalesKey;
  key: LocalesKey;
  remove: LocalesKey;
  edit: LocalesKey;
  view: LocalesKey;
}

export const emailTemplatesKeys = keyPathMirror<EmailTemplatesKeyType, string>(
  {
    plural: null,
    single: null,
    description: null,
    search: null,
    subject: null,
    subjectUk: null,
    subjectEn: null,
    create: null,
    key: null,
    edit: null,
    remove: null,
    view: null
  },
  'models.emailTemplates.'
);
