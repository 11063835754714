import React from 'react';

import { IconProps } from '../types';

function ElasticSearchSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <path d="M198.9,181.1c0-10.4,0-14.4,0-14.4s0.4-16.6-13.1-16.6s-15.5,0-15.5,0v15.9h7.8c0,0,5.8,0.2,5.8,7.6s0,15.3,0,15.3s1,9.3,11.7,9.3c0,0-11.8,0.7-11.8,11.6c0,10.9,0,12.8,0,12.8s0.7,8.3-6.7,8.3c-7.3,0-109.9,0-109.9,0s-6.1-0.2-6.1-8.8c0-8.6,0-86,0-86s0.2-11.8-12.2-11.8c0,0,12.3-0.9,12.3-10.3c0-9.5,0-36.4,0-36.4s0.7-6.7,6.6-6.7s6.7,0.1,6.7,0.1V55.5H58.1c0,0-11.8,0-11.8,15.6c0,15.6,0,36.1,0,36.1s-0.2,10-9.9,10v14.2h4.4c0,0,5.4-0.3,5.4,6.4s0,92.7,0,92.7S45.6,246,60,246c14.4,0,126,0,126,0s12.8-0.9,12.8-15.5c0-14.7,0-16.5,0-16.5s-0.1-9.1,10-9.1v-13.7C208.7,191.2,198.9,191.4,198.9,181.1z" />
      <path d="M100.5,139.6L75,184.2l0,0l-6.7,11.6c0,0-1.9,3.1,10.8,10.4c12.7,7.3,15.1,4.5,15.1,4.5l1.3-2.4l0,0l34.7-60.4l1.2-2c6,1.8,12.5,2.7,19.1,2.7c26.8,0,50-15.4,61.3-37.9c4.9-9.5,7.7-20.2,7.7-31.6c0-27.3-15.9-50.9-39.1-61.9c-9.2-4.6-19.5-7.1-30.4-7.1c-37.8,0-68.5,30.7-68.5,68.5c0,7.8,1.3,15.2,3.7,22.2c3.8,11.9,10.8,22.5,19.9,30.6L100.5,139.6C100.5,139.6,100.5,139.6,100.5,139.6z M101.7,79.2c0-27.3,22.1-49.5,49.5-49.5c27.3,0,49.5,22.1,49.5,49.5c0,27.3-22.1,49.5-49.5,49.5C123.8,128.7,101.7,106.6,101.7,79.2z" />
    </svg>
  );
}

export default ElasticSearchSolidIcon;
