export default {
  areYouSureYouWantToCancelInvitation:
    'Are you sure you want to cancel invitation?',
  cancelInvitation: 'Cancel invitation',
  email: 'Email',
  expired: 'Expired',
  expiryDate: 'Expiry date',
  invitations: 'Invitations',
  sentBy: 'Sent by',
  sent: 'Sent'
};
