export const INITIAL_FILTERS = {};
export const INITIAL_PAGE = 1;
export const INITIAL_SORT = ['CREATED_AT_DESC'];
export const INITIAL_LIMIT = 24;

export const CHANGE_ITEMS_FILTERS = 'CHANGE_ITEMS_FILTERS';
export const CLEAR_ITEMS_FILTERS = 'CLEAR_ITEMS_FILTERS';
export const FILTER_ITEMS = 'FILTER_ITEMS';
export const LIMIT_ITEMS = 'LIMIT_ITEMS';
export const PAGINATE_ITEMS = 'PAGINATE_ITEMS';
export const SORT_ITEMS = 'SORT_ITEMS';
