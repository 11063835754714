import { teamsKeys } from '../../locales/keys';
import { AvBillingInfoPaymentMethods } from '../avBillingInfos/avBillingInfosTypes';
import { InvoicePaymentMethods } from '../invoices/invoicesTypes';
import { FetchBillingInfosSortTypes } from './billingInfosTypes';

export const INITIAL_BILLING_INFOS_FILTERS = {};
export const INITIAL_BILLING_INFOS_PAGE = 1;
export const INITIAL_BILLING_INFOS_SORT = [
  FetchBillingInfosSortTypes.CREATED_AT_DESC
];
export const INITIAL_BILLING_INFOS_LIMIT = 50;

export const usaCountryName = 'USA';

export enum BillingInfosPermissions {
  READ_CREATE_BILLING_INFO_MODAL_RECIEVER_FIELD = 'read_create_billing_info_modal_reciever_field',
  READ_UPDATE_BILLING_INFO_MODAL_RECIEVER_FIELD = 'read_update_billing_info_modal_reciever_field'
}

export const paymentMethods = [
  {
    label: teamsKeys.wire,
    value: InvoicePaymentMethods.WIRE
  },
  {
    label: teamsKeys.card,
    value: InvoicePaymentMethods.CARD
  }
];

export const avPaymentMethods = {
  [InvoicePaymentMethods.WIRE]: [AvBillingInfoPaymentMethods.WIRE],
  [InvoicePaymentMethods.CARD]: [
    AvBillingInfoPaymentMethods.PAYONEER,
    AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT,
    AvBillingInfoPaymentMethods.STRIPE,
    AvBillingInfoPaymentMethods.FONDY,
    AvBillingInfoPaymentMethods.PAYPAL
  ]
};
