import { gql } from 'graphql-request';
import { FeedBackUUID } from '../feedBacksTypes';

export interface FeedBackQueryResponse {
  uuid: FeedBackUUID;
}

export const CREATE_FEEDBACK_QUERY = gql`
  mutation createFeedBack(
    $name: String
    $email: String
    $subject: String
    $body: String
  ) {
    createFeedBack(
      input: { name: $name, email: $email, subject: $subject, body: $body }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
