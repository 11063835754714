import { FetchTeamsSortItem, TeamClientStatusesEnum } from './teamsTypes';

import { teamsKeys } from '../../locales/keys';
import { Currencies, PayoneerAccountTypes } from '../../types';
import { CompanyNanoID } from '../companies/companiesTypes';
import { PayoneerV4PayoutProgramKeys } from '../payoneerV4Payouts/payoneerV4PayoutsTypes';

export const enum TeamTabs {
  ARTISTS = 'Artists',
  AUTO_CHECK_IN = 'Auto check-in',
  SC_TEMPLATE = 'Smart contract template',
  EVENT_DISPATCHER = 'Event dispatcher',
  ITEM_TYPES = 'Item types',
  MEMBERS = 'Members',
  PROFILE = 'Profile',
  DASHBOARD = 'Dashboard',
  REVENUE_SHARE = 'Revenue share',
  FILES = 'Files',
  RECORDS = 'Records',
  REPORTS = 'Reports',
  SUBBOOKS = 'Subbooks'
}

export const enum TeamReportsTabs {
  TIME_BY_DATE = 'time_by_date',
  TASKS_WITHOUT_SOURCE_FILES = 'tasks_without_source_files'
}

export const enum TeamSectionsTabs {
  BASIC_INFO = 'Basic info',
  MARKETING_DATA = 'Marketing data',
  REQUIREMENTS = 'Requirements',
  PERSONALIZATION = 'Personalization',
  STATUS = 'Status',
  BILLING_INFO = 'Billing info',
  SMART_CONTRACT_SETTINGS = 'Smart contract settings',
  TERMS = 'Terms',
  SETTINGS = 'Settings',
  DESCRIPTION = 'Description',
  DOCUMENTS = 'Documents',
  AIR_TABLE = 'AirTable'
}

export const teamClientI18nTextStatuses = {
  [TeamClientStatusesEnum.ACTIVE]: teamsKeys.statuses.active,
  [TeamClientStatusesEnum.DECLINED]: teamsKeys.statuses.declined,
  [TeamClientStatusesEnum.NEW]: teamsKeys.statuses.new,
  [TeamClientStatusesEnum.NO_PAYMENTS]: teamsKeys.statuses.noPayments,
  [TeamClientStatusesEnum.PAUSE]: teamsKeys.statuses.paused,
  [TeamClientStatusesEnum.REAL_DECLINE]: teamsKeys.statuses.realDecline
};

export const teamTextPayoneerAccountTypes = {
  [PayoneerAccountTypes.PAYONEER_ID]: 'Payoneer GME',
  [PayoneerAccountTypes.PAYONEER_ID_PE]: 'Payoneer PE GME',
  [PayoneerAccountTypes.PAYONEER_ID_AVLTD]: 'Payoneer AV Ltd',
  [PayoneerAccountTypes.PAYONEER_ID_PE_AVLTD]: 'Payoneer PE AV Ltd'
};

export const teamPayoutPayoneerAccountTypes = {
  [PayoneerAccountTypes.PAYONEER_ID]:
    PayoneerV4PayoutProgramKeys.MASS_PAYOUTS_1,
  [PayoneerAccountTypes.PAYONEER_ID_PE]:
    PayoneerV4PayoutProgramKeys.MASS_PAYOUTS_2,
  [PayoneerAccountTypes.PAYONEER_ID_AVLTD]:
    PayoneerV4PayoutProgramKeys.MASS_PAYOUTS_3,
  [PayoneerAccountTypes.PAYONEER_ID_PE_AVLTD]:
    PayoneerV4PayoutProgramKeys.MASS_PAYOUTS_4
};

export type CompanyCurrencyStateType = {
  currency: Currencies;
};

export const defaultCompanyCurrencyState: CompanyCurrencyStateType = {
  currency: Currencies.USD
};
export const companyCurrencyLocalStorageKey = (nanoId: CompanyNanoID) =>
  `company-currency-${nanoId}`;

export const companyPaymentsMenuGeneralLedgerKey = (nanoId: CompanyNanoID) =>
  `company-${nanoId}-payments-menu-general-ledgers`;

export const companyPaymentsProformaCheckedItemsKey =
  'company-payments-proforma-chacked-items';

export const INITIAL_TEAMS_FILTERS = {};
export const INITIAL_TEAMS_PAGE = 1;
export const INITIAL_TEAMS_SORT = [FetchTeamsSortItem.CREATED_AT_DESC];
export const INITIAL_TEAMS_LIMIT = 24;

export const teamUsaCountryId = '609';

export const enum TeamsPermissions {
  READ_COMPANY_PROFILE_LINK = 'read_company_profile_link',
  READ_COMPANY_PROFILE_COEFFICIENT = 'read_company_profile_coefficient',
  READ_COMPANY_PROFILE_STATUS = 'read_company_profile_status',
  READ_COMPANY_PROFILE_FIN_STATUS = 'read_company_profile_fin_status',
  READ_COMPANY_PROFILE_NDA = 'read_company_profile_nda',
  READ_SELF_COMPANY_PROFILE_TAB = 'read_self_company_profile_tab',
  READ_OTHER_COMPANY_PROFILE_TAB = 'read_other_company_profile_tab',
  READ_SELF_COMPANY_DASHBOARD_TAB = 'read_self_company_dashboard_tab',
  READ_OTHER_COMPANY_DASHBOARD_TAB = 'read_other_company_dashboard_tab',
  READ_SELF_COMPANY_MEMBERS_TAB = 'read_self_company_members_tab',
  READ_OTHER_COMPANY_MEMBERS_TAB = 'read_other_company_members_tab',
  READ_SELF_COMPANY_ARTISTS_TAB = 'read_self_company_artists_tab',
  READ_OTHER_COMPANY_ARTISTS_TAB = 'read_other_company_artists_tab',
  READ_SELF_COMPANY_ITEM_TYPES_TAB = 'read_self_company_item_types_tab',
  READ_OTHER_COMPANY_ITEM_TYPES_TAB = 'read_other_company_item_types_tab',
  READ_SELF_COMPANY_REVENUE_SHARE_ITEMS_TAB = 'read_self_company_revenue_share_items_tab',
  READ_OTHER_COMPANY_REVENUE_SHARE_ITEMS_TAB = 'read_other_company_revenue_share_items_tab',
  READ_SELF_COMPANY_FILES_TAB = 'read_self_company_files_tab',
  READ_OTHER_COMPANY_FILES_TAB = 'read_other_company_files_tab',
  READ_SELF_CLIENT_COMPANY_FILES_TAB = 'read_self_client_company_files_tab',
  READ_OTHER_CLIENT_COMPANY_FILES_TAB = 'read_other_client_company_files_tab',
  READ_SELF_COMPANY_RECORDS_TAB = 'read_self_company_records_tab',
  READ_OTHER_COMPANY_RECORDS_TAB = 'read_other_company_records_tab',
  READ_SELF_CLIENT_COMPANY_RECORDS_TAB = 'read_self_client_company_records_tab',
  READ_OTHER_CLIENT_COMPANY_RECORDS_TAB = 'read_other_client_company_records_tab',
  READ_SELF_COMPANY_REPORTS_TAB = 'read_self_company_reports_tab',
  READ_OTHER_COMPANY_REPORTS_TAB = 'read_other_company_reports_tab',
  READ_SELF_COMPANY_SUBBOOKS_TAB = 'read_self_company_subbooks_tab',
  READ_OTHER_COMPANY_SUBBOOKS_TAB = 'read_other_company_subbooks_tab',
  READ_SELF_COMPANY_AUTO_CHECK_IN_TAB = 'read_self_company_auto_check_in_tab',
  READ_OTHER_COMPANY_AUTO_CHECK_IN_TAB = 'read_other_company_auto_check_in_tab',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATES_TAB = 'read_self_company_smart_contract_templates_tab',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATES_TAB = 'read_other_company_smart_contract_templates_tab',
  READ_SELF_COMPANY_EVENT_DISPATCHER_TAB = 'read_self_company_event_dispatcher_tab',
  READ_OTHER_COMPANY_EVENT_DISPATCHER_TAB = 'read_other_company_event_dispatcher_tab',
  READ_SELF_COMPANY_EVENT_DISPATCHER_ADD_EVENT_BUTTON = 'read_self_company_event_dispatcher_add_event_button',
  READ_OTHER_COMPANY_EVENT_DISPATCHER_ADD_EVENT_BUTTON = 'read_other_company_event_dispatcher_add_event_button',
  READ_COMPANIES_UPSALER_ID_FILTER = 'read_companies_upsaler_id_filter',
  READ_COMPANIES_SEARCH_FILTERS_POPOVER = 'read_companies_search_filters_popover',
  READ_PERFORMERS_CREATED_AT_FILTER = 'read_performers_created_at_filter',
  READ_PERFORMERS_GROUP_ID_FILTER = 'read_performers_group_id_filter',
  READ_PERFORMERS_POSITION_ID_FILTER = 'read_performers_position_id_filter',
  READ_PERFORMERS_SPECIALIZATION_ID_FILTER = 'read_performers_specialization_id_filter',
  READ_PERFORMERS_QUALIFICATION_ID_FILTER = 'read_performers_qualification_id_filter',
  READ_PERFORMERS_FINANCE_TAG_ID_FILTER = 'read_performers_finance_tag_id_filter',
  READ_PERFORMERS_MENTOR_ID_FILTER = 'read_performers_mentor_id_filter',
  READ_PERFORMERS_PROJECT_MANAGER_ID_FILTER = 'read_performers_project_manager_id_filter',
  READ_PERFORMERS_FINANCE_ROLE_ID_FILTER = 'read_performers_finance_role_id_filter',
  READ_PERFORMERS_SHARE_FILTER = 'read_performers_share_filter',
  READ_PERFORMERS_REVENUE_FILTER = 'read_performers_revenue_filter',
  READ_PERFORMERS_WORK_EXPERIENCE_FILTER = 'read_performers_work_experience_filter',
  READ_PERFORMERS_SEARCH_FILTERS_POPOVER = 'read_performers_search_filters_popover',
  READ_SELF_COMPANY_PROFILE_INDEX_PAGE = 'read_self_company_profile_index_page',
  READ_OTHER_COMPANY_PROFILE_INDEX_PAGE = 'read_other_company_profile_index_page',
  READ_SELF_COMPANY_PROFILE_OWNER_RESUME_PAGE = 'read_self_company_profile_owner_resume_page',
  READ_OTHER_COMPANY_PROFILE_OWNER_RESUME_PAGE = 'read_other_company_profile_owner_resume_page',
  READ_SELF_COMPANY_PROFILE_OWNER_DESCRIPTION_PAGE = 'read_self_company_profile_owner_description_page',
  READ_OTHER_COMPANY_PROFILE_OWNER_DESCRIPTION_PAGE = 'read_other_company_profile_owner_description_page',
  READ_SELF_COMPANY_ITEM_TYPES_INDEX_PAGE = 'read_self_company_item_types_index_page',
  READ_OTHER_COMPANY_ITEM_TYPES_INDEX_PAGE = 'read_other_company_item_types_index_page',
  READ_SELF_COMPANY_PROFILE_MARKETING_DATA_PAGE = 'read_self_company_profile_marketing_data_page',
  READ_OTHER_COMPANY_PROFILE_MARKETING_DATA_PAGE = 'read_other_company_profile_marketing_data_page',
  READ_SELF_COMPANY_PROFILE_SMART_CONTRACT_SETTINGS_PAGE = 'read_self_company_profile_smart_contract_settings_page',
  READ_OTHER_COMPANY_PROFILE_SMART_CONTRACT_SETTINGS_PAGE = 'read_other_company_profile_smart_contract_settings_page',
  READ_SELF_COMPANY_PROFILE_REQUIREMENTS_PAGE = 'read_self_company_profile_requirements_page',
  READ_OTHER_COMPANY_PROFILE_REQUIREMENTS_PAGE = 'read_other_company_profile_requirements_page',
  READ_COMPANY_PROFILE_DESCRIPTION_PAGE = 'read_company_profile_description_page',
  READ_SELF_COMPANY_PROFILE_STATUS_PAGE = 'read_self_company_profile_status_page',
  READ_OTHER_COMPANY_PROFILE_STATUS_PAGE = 'read_other_company_profile_status_page',
  READ_COMPANY_PAYMENTS_INVOICES_INVOICE_PAGE = 'read_company_payments_invoices_invoice_page',
  READ_COMPANY_PROFILE_FINANCE_INFO_PAGE = 'read_company_profile_finance_info_page',
  READ_SELF_COMPANY_ARTISTS_PAGE = 'read_self_company_artists_page',
  READ_OTHER_COMPANY_ARTISTS_PAGE = 'read_other_company_artists_page',
  READ_COMPANY_ITEM_TYPES_PAGE = 'read_company_item_types_page',
  READ_SELF_COMPANY_PROFILE_PERMISSIONS_PAGE = 'read_self_company_profile_permissions_page',
  READ_OTHER_COMPANY_PROFILE_PERMISSIONS_PAGE = 'read_other_company_profile_permissions_page',
  READ_SELF_COMPANY_PROFILE_SETTINGS_PAGE = 'read_self_company_profile_settings_page',
  READ_OTHER_COMPANY_PROFILE_SETTINGS_PAGE = 'read_other_company_profile_settings_page',
  READ_SELF_COMPANY_PROFILE_PERSONALIZATION_PAGE = 'read_self_company_profile_personalization_page',
  READ_OTHER_COMPANY_PROFILE_PERSONALIZATION_PAGE = 'read_other_company_profile_personalization_page',
  READ_SELF_COMPANY_PROFILE_OWNER_DOCUMENTS_PAGE = 'read_self_company_profile_owner_documents_page',
  READ_OTHER_COMPANY_PROFILE_OWNER_DOCUMENTS_PAGE = 'read_other_company_profile_owner_documents_page',
  READ_SELF_COMPANY_PROFILE_OWNER_AIR_TABLE_PAGE = 'read_self_company_profile_owner_air_table_page',
  READ_OTHER_COMPANY_PROFILE_OWNER_AIR_TABLE_PAGE = 'read_other_company_profile_owner_air_table_page',
  READ_SELF_COMPANY_PROFILE_TERMS_PAGE = 'read_self_company_profile_terms_page',
  READ_OTHER_COMPANY_PROFILE_TERMS_PAGE = 'read_other_company_profile_terms_page',
  READ_SELF_COMPANY_PROFILE_BILLING_INFO_PAGE = 'read_self_company_profile_billing_info_page',
  READ_OTHER_COMPANY_PROFILE_BILLING_INFO_PAGE = 'read_other_company_profile_billing_info_page',
  READ_SELF_COMPANY_DASHBOARD_PAGE = 'read_self_company_dashboard_page',
  READ_OTHER_COMPANY_DASHBOARD_PAGE = 'read_other_company_dashboard_page',
  READ_SELF_COMPANY_RECORDS_PAGE = 'read_self_company_records_page',
  READ_OTHER_COMPANY_RECORDS_PAGE = 'read_other_company_records_page',
  READ_SELF_CLIENT_COMPANY_RECORDS_PAGE = 'read_self_client_company_records_page',
  READ_OTHER_CLIENT_COMPANY_RECORDS_PAGE = 'read_other_client_company_records_page',
  READ_SELF_COMPANY_REPORTS_PAGE = 'read_self_company_reports_page',
  READ_OTHER_COMPANY_REPORTS_PAGE = 'read_other_company_reports_page',
  READ_SELF_COMPANY_TASKS_WITHOUT_SOURCE_FILES_PAGE = 'read_self_company_tasks_without_source_files_page',
  READ_OTHER_COMPANY_TASKS_WITHOUT_SOURCE_FILES_PAGE = 'read_other_company_tasks_without_source_files_page',
  READ_SELF_COMPANY_FILES_PAGE = 'read_self_company_files_page',
  READ_OTHER_COMPANY_FILES_PAGE = 'read_other_company_files_page',
  READ_SELF_CLIENT_COMPANY_FILES_PAGE = 'read_self_client_company_files_page',
  READ_OTHER_CLIENT_COMPANY_FILES_PAGE = 'read_other_client_company_files_page',
  READ_COMPANY_SETTINGS_PAGE = 'read_company_settings_page',
  READ_SELF_COMPANY_MEMBERS_PAGE = 'read_self_company_members_page',
  READ_OTHER_COMPANY_MEMBERS_PAGE = 'read_other_company_members_page',
  READ_SELF_COMPANY_MEMBERS_PAGE_INVITATIONS = 'read_self_company_members_page_invitations',
  READ_OTHER_COMPANY_MEMBERS_PAGE_INVITATIONS = 'read_other_company_members_page_invitations',
  READ_SELF_COMPANY_PAYMENTS_BALANCE_SHEET_PAGE = 'read_self_company_payments_balance_sheet_page',
  READ_OTHER_COMPANY_PAYMENTS_BALANCE_SHEET_PAGE = 'read_other_company_payments_balance_sheet_page',
  READ_SELF_COMPANY_PAYMENTS_BALANCE_SHEET_RECALCULATE_BALANCES_BUTTON = 'read_self_company_payments_balance_sheet_recalculate_balances_button',
  READ_OTHER_COMPANY_PAYMENTS_BALANCE_SHEET_RECALCULATE_BALANCES_BUTTON = 'read_other_company_payments_balance_sheet_recalculate_balances_button',
  READ_SELF_COMPANY_PAYMENTS_CURRENT_CONTRACTOR_INVOICES_PAGE = 'read_self_company_payments_current_contractor_invoices_page',
  READ_OTHER_COMPANY_PAYMENTS_CURRENT_CONTRACTOR_INVOICES_PAGE = 'read_other_company_payments_current_contractor_invoices_page',
  READ_SELF_COMPANY_PAYMENTS_OPEN_CONTRACTOR_INVOICES_PAGE = 'read_self_company_payments_open_contractor_invoices_page',
  READ_OTHER_COMPANY_PAYMENTS_OPEN_CONTRACTOR_INVOICES_PAGE = 'read_other_company_payments_open_contractor_invoices_page',
  READ_SELF_COMPANY_PAYMENTS_SENT_CONTRACTOR_INVOICES_PAGE = 'read_self_company_payments_sent_contractor_invoices_page',
  READ_OTHER_COMPANY_PAYMENTS_SENT_CONTRACTOR_INVOICES_PAGE = 'read_other_company_payments_sent_contractor_invoices_page',
  READ_SELF_COMPANY_PAYMENTS_OVERDUE_CONTRACTOR_INVOICES_PAGE = 'read_self_company_payments_overdue_contractor_invoices_page',
  READ_OTHER_COMPANY_PAYMENTS_OVERDUE_CONTRACTOR_INVOICES_PAGE = 'read_other_company_payments_overdue_contractor_invoices_page',
  READ_SELF_COMPANY_PAYMENTS_CONTRACTOR_INVOICES_PAGE = 'read_self_company_payments_contractor_invoices_page',
  READ_OTHER_COMPANY_PAYMENTS_CONTRACTOR_INVOICES_PAGE = 'read_other_company_payments_contractor_invoices_page',
  READ_COMPANY_PAYMENTS_JOURNAL_RECORDS_PAGE = 'read_company_payments_journal_records_page',
  READ_COMPANY_PAYMENTS_SMART_CONTRACTS_PAGE = 'read_company_payments_smart_contracts_page',
  READ_COMPANY_PAYMENTS_SMART_CONTRACTS_DRAFT_FILTER_PAGE = 'read_company_payments_smart_contracts_draft_filter_page',
  READ_COMPANY_PAYMENTS_SMART_CONTRACTS_CANCELED_FILTER_PAGE = 'read_company_payments_smart_contracts_canceled_filter_page',
  READ_SELF_COMPANY_PROJECTS_PAGE = 'read_self_company_projects_page',
  READ_OTHER_COMPANY_PROJECTS_PAGE = 'read_other_company_projects_page',
  READ_SELF_COMPANY_TASKS_PAGE = 'read_self_company_tasks_page',
  READ_OTHER_COMPANY_TASKS_PAGE = 'read_other_company_tasks_page',
  READ_SELF_COMPANY_TASKS_RESULTS_PAGE = 'read_self_company_tasks_results_page',
  READ_OTHER_COMPANY_TASKS_RESULTS_PAGE = 'read_other_company_tasks_results_page',
  READ_SELF_COMPANY_MESSAGES_PAGE = 'read_self_company_messages_page',
  READ_OTHER_COMPANY_MESSAGES_PAGE = 'read_other_company_messages_page',
  READ_SELF_COMPANY_USER_PROJECTS_PAGE = 'read_self_company_user_projects_page',
  READ_OTHER_COMPANY_USER_PROJECTS_PAGE = 'read_other_company_user_projects_page',
  READ_SELF_COMPANY_USER_TASKS_PAGE = 'read_self_company_user_tasks_page',
  READ_OTHER_COMPANY_USER_TASKS_PAGE = 'read_other_company_user_tasks_page',
  READ_SELF_COMPANY_USER_TASKS_RESULTS_PAGE = 'read_self_company_user_tasks_results_page',
  READ_OTHER_COMPANY_USER_TASKS_RESULTS_PAGE = 'read_other_company_user_tasks_results_page',
  READ_SELF_COMPANY_USER_MESSAGES_PAGE = 'read_self_company_user_messages_page',
  READ_OTHER_COMPANY_USER_MESSAGES_PAGE = 'read_other_company_user_messages_page',
  READ_SELF_COMPANY_LIBRARY_PAGE = 'read_self_company_library_page',
  READ_OTHER_COMPANY_LIBRARY_PAGE = 'read_other_company_library_page',
  READ_SELF_COMPANY_LIBRARY_MATERIALS_NAV = 'read_self_company_library_materials_nav',
  READ_OTHER_COMPANY_LIBRARY_MATERIALS_NAV = 'read_other_company_library_materials_nav',
  READ_SELF_COMPANY_LIBRARY_PRODUCTS_PAGE = 'read_self_company_library_products_page',
  READ_OTHER_COMPANY_LIBRARY_PRODUCTS_PAGE = 'read_other_company_library_products_page',
  READ_SELF_COMPANY_LIBRARY_LIFESTYLES_PAGE = 'read_self_company_library_lifestyles_page',
  READ_OTHER_COMPANY_LIBRARY_LIFESTYLES_PAGE = 'read_other_company_library_lifestyles_page',
  READ_SELF_COMPANY_LIBRARY_MATERIALS_PAGE = 'read_self_company_library_materials_page',
  READ_OTHER_COMPANY_LIBRARY_MATERIALS_PAGE = 'read_other_company_library_materials_page',
  READ_COMPANY_LIBRARY_PREMIUM_LIFESTYLES_LINK = 'read_company_library_premium_lifestyles_link',
  READ_SELF_COMPANY_SUBBOOKS_PAGE = 'read_self_company_subbooks_page',
  READ_OTHER_COMPANY_SUBBOOKS_PAGE = 'read_other_company_subbooks_page',
  READ_SELF_COMPANY_AUTO_CHECK_IN_PAGE = 'read_self_company_auto_check_in_page',
  READ_OTHER_COMPANY_AUTO_CHECK_IN_PAGE = 'read_other_company_auto_check_in_page',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_PAGE = 'read_self_company_smart_contract_template_page',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_PAGE = 'read_other_company_smart_contract_template_page',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_SHARES_PAGE = 'read_self_company_smart_contract_template_shares_page',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_SHARES_PAGE = 'read_other_company_smart_contract_template_shares_page',
  READ_SELF_COMPANY_EVENT_DISPATCHER_PAGE = 'read_self_company_event_dispatcher_page',
  READ_OTHER_COMPANY_EVENT_DISPATCHER_PAGE = 'read_other_company_event_dispatcher_page',
  READ_SELF_COMPANY_EVENT_DISPATCHER_DELETE_BUTTON = 'read_self_company_event_dispatcher_delete_button',
  READ_OTHER_COMPANY_EVENT_DISPATCHER_DELETE_BUTTON = 'read_other_company_event_dispatcher_delete_button',
  READ_SELF_COMPANY_AUTO_CHECK_IN_PAGE_FOLLOWERS_TAB = 'read_self_company_auto_check_in_page_followers_tab',
  READ_OTHER_COMPANY_AUTO_CHECK_IN_PAGE_FOLLOWERS_TAB = 'read_other_company_auto_check_in_page_followers_tab',
  READ_SELF_COMPANY_AUTO_CHECK_IN_PAGE_LEADERS_TAB = 'read_self_company_auto_check_in_page_leaders_tab',
  READ_OTHER_COMPANY_AUTO_CHECK_IN_PAGE_LEADERS_TAB = 'read_other_company_auto_check_in_page_leaders_tab',
  READ_SELF_COMPANY_AUTO_CHECK_IN_PAGE_CLIENTS_TAB = 'read_self_company_auto_check_in_page_clients_tab',
  READ_OTHER_COMPANY_AUTO_CHECK_IN_PAGE_CLIENTS_TAB = 'read_other_company_auto_check_in_page_clients_tab',
  READ_COMPANIES_INDEX_PAGE = 'read_companies_index_page',
  READ_COMPANIES_MY_COMPANIES_PAGE = 'read_companies_my_companies_page',
  READ_COMPANIES_UNASSIGNED_PAGE = 'read_companies_unassigned_page',
  READ_PERFORMERS_INDEX_PAGE = 'read_performers_index_page',
  READ_PERFORMERS_TAT_PAGE = 'read_performers_tat_page',
  READ_PERFORMERS_BLOCKED_PAGE = 'read_performers_blocked_page',
  READ_PERFORMERS_UNASSIGNED_PAGE = 'read_performers_unassigned_page',
  READ_PERFORMERS_MY_PERFORMERS_PAGE = 'read_performers_my_performers_page',
  READ_COMPANIES_MENU_INDEX_LINK = 'read_companies_menu_index_link',
  READ_COMPANIES_MENU_MY_COMPANIES_LINK = 'read_companies_menu_my_companies_link',
  READ_COMPANIES_MENU_FAVORITE_LINK = 'read_companies_menu_favorite_link',
  READ_COMPANIES_MENU_UNASSIGNED_LINK = 'read_companies_menu_unassigned_link',
  READ_PERFORMERS_MENU_INDEX_LINK = 'read_performers_menu_index_link',
  READ_PERFORMERS_MENU_UNASSIGNED_LINK = 'read_performers_menu_unassigned_link',
  READ_PERFORMERS_MENU_FAVORITE_LINK = 'read_performers_menu_favorite_link',
  READ_PERFORMERS_MENU_MY_PERFORMERS_LINK = 'read_performers_menu_my_performers_link',
  READ_PERFORMERS_MENU_TAT_LINK = 'read_performers_menu_tat_link',
  READ_PERFORMERS_MENU_BLOCKED_LINK = 'read_performers_menu_blocked_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_BALANCE_SHEET_LINK = 'read_self_company_payments_menu_balance_sheet_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_BALANCE_SHEET_LINK = 'read_other_company_payments_menu_balance_sheet_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_CURRENT_CONTRACTOR_INVOICES_LINK = 'read_self_company_payments_menu_current_contractor_invoices_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_CURRENT_CONTRACTOR_INVOICES_LINK = 'read_other_company_payments_menu_current_contractor_invoices_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_OPEN_CONTRACTOR_INVOICES_LINK = 'read_self_company_payments_menu_open_contractor_invoices_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_OPEN_CONTRACTOR_INVOICES_LINK = 'read_other_company_payments_menu_open_contractor_invoices_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_SENT_CONTRACTOR_INVOICES_LINK = 'read_self_company_payments_menu_sent_contractor_invoices_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_SENT_CONTRACTOR_INVOICES_LINK = 'read_other_company_payments_menu_sent_contractor_invoices_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_OVERDUE_CONTRACTOR_INVOICES_LINK = 'read_self_company_payments_menu_overdue_contractor_invoices_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_OVERDUE_CONTRACTOR_INVOICES_LINK = 'read_other_company_payments_menu_overdue_contractor_invoices_link',
  READ_SELF_COMPANY_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK = 'read_self_company_payments_menu_contractor_invoices_link',
  READ_OTHER_COMPANY_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK = 'read_other_company_payments_menu_contractor_invoices_link',
  READ_COMPANY_PAYMENTS_MENU_JOURNAL_RECORDS_LINK = 'read_company_payments_menu_journal_records_link',
  READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_LINK = 'read_company_payments_menu_smart_contracts_link',
  READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_DRAFT_LINK = 'read_company_payments_menu_smart_contracts_draft_link',
  READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_CANCELED_LINK = 'read_company_payments_menu_smart_contracts_canceled_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_LINK = 'read_company_payments_menu_invoices_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_SENT_LINK = 'read_company_payments_menu_invoices_sent_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_DRAFT_LINK = 'read_company_payments_menu_invoices_draft_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_PAID_LINK = 'read_company_payments_menu_invoices_paid_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_CANCELED_LINK = 'read_company_payments_menu_invoices_canceled_link',
  READ_COMPANY_PAYMENTS_MENU_INVOICES_REFUNDS_LINK = 'read_company_payments_menu_invoices_refunds_link',
  READ_COMPANY_PAYMENTS_MENU_GENERAL_LEDGERS = 'read_company_payments_menu_general_ledgers',
  CREATE_COMPANY_PAYMENTS_MENU_GENERAL_LEDGERS = 'create_company_payments_menu_general_ledgers',
  READ_SELF_COMPANY_WORKSPACE_MENU_PROJECTS_LINK = 'read_self_company_workspace_menu_projects_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_PROJECTS_LINK = 'read_other_company_workspace_menu_projects_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_PROJECTS_FAVORITE_LINK = 'read_self_company_workspace_menu_projects_favorite_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_PROJECTS_FAVORITE_LINK = 'read_other_company_workspace_menu_projects_favorite_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_PROJECTS_BURNING_LINK = 'read_self_company_workspace_menu_projects_burning_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_PROJECTS_BURNING_LINK = 'read_other_company_workspace_menu_projects_burning_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_TASKS_LINK = 'read_self_company_workspace_menu_tasks_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_TASKS_LINK = 'read_other_company_workspace_menu_tasks_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_TASKS_FAVORITE_LINK = 'read_self_company_workspace_menu_tasks_favorite_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_TASKS_FAVORITE_LINK = 'read_other_company_workspace_menu_tasks_favorite_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_TASKS_BURNING_LINK = 'read_self_company_workspace_menu_tasks_burning_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_TASKS_BURNING_LINK = 'read_other_company_workspace_menu_tasks_burning_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_TASKS_RESULTS_LINK = 'read_self_company_workspace_menu_tasks_results_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_TASKS_RESULTS_LINK = 'read_other_company_workspace_menu_tasks_results_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_MESSAGES_LINK = 'read_self_company_workspace_menu_messages_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_MESSAGES_LINK = 'read_other_company_workspace_menu_messages_link',
  READ_SELF_COMPANY_WORKSPACE_MENU_MESSAGES_NEW_LINK = 'read_self_company_workspace_menu_messages_new_link',
  READ_OTHER_COMPANY_WORKSPACE_MENU_MESSAGES_NEW_LINK = 'read_other_company_workspace_menu_messages_new_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_PROJECTS_LINK = 'read_self_company_user_workspace_menu_projects_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_PROJECTS_LINK = 'read_other_company_user_workspace_menu_projects_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_PROJECTS_FAVORITE_LINK = 'read_self_company_user_workspace_menu_projects_favorite_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_PROJECTS_FAVORITE_LINK = 'read_other_company_user_workspace_menu_projects_favorite_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_PROJECTS_BURNING_LINK = 'read_self_company_user_workspace_menu_projects_burning_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_PROJECTS_BURNING_LINK = 'read_other_company_user_workspace_menu_projects_burning_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_TASKS_LINK = 'read_self_company_user_workspace_menu_tasks_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_TASKS_LINK = 'read_other_company_user_workspace_menu_tasks_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_TASKS_FAVORITE_LINK = 'read_self_company_user_workspace_menu_tasks_favorite_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_TASKS_FAVORITE_LINK = 'read_other_company_user_workspace_menu_tasks_favorite_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_TASKS_BURNING_LINK = 'read_self_company_user_workspace_menu_tasks_burning_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_TASKS_BURNING_LINK = 'read_other_company_user_workspace_menu_tasks_burning_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_TASKS_RESULTS_LINK = 'read_self_company_user_workspace_menu_tasks_results_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_TASKS_RESULTS_LINK = 'read_other_company_user_workspace_menu_tasks_results_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_MESSAGES_LINK = 'read_self_company_user_workspace_menu_messages_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_MESSAGES_LINK = 'read_other_company_user_workspace_menu_messages_link',
  READ_SELF_COMPANY_USER_WORKSPACE_MENU_MESSAGES_NEW_LINK = 'read_self_company_user_workspace_menu_messages_new_link',
  READ_OTHER_COMPANY_USER_WORKSPACE_MENU_MESSAGES_NEW_LINK = 'read_other_company_user_workspace_menu_messages_new_link',
  READ_SELF_COMPANY_MENU_CREATE_PROJECT_IN_TEAM_LINK = 'read_self_company_menu_create_project_in_team_link',
  READ_OTHER_COMPANY_MENU_CREATE_PROJECT_IN_TEAM_LINK = 'read_other_company_menu_create_project_in_team_link',
  READ_SELF_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON = 'read_self_company_menu_invite_new_users_modal_button',
  READ_OTHER_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON = 'read_other_company_menu_invite_new_users_modal_button',
  READ_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON_IF_OWNER = 'read_company_menu_invite_new_users_modal_button_if_owner',
  READ_SELF_COMPANY_CREATE_SUBBOOK_MODAL_BUTTON = 'read_self_company_create_subbook_modal_button',
  READ_OTHER_COMPANY_CREATE_SUBBOOK_MODAL_BUTTON = 'read_other_company_create_subbook_modal_button',
  READ_SELF_COMPANY_CREATE_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_self_company_create_sc_auto_check_in_rule_modal_button',
  READ_OTHER_COMPANY_CREATE_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_other_company_create_sc_auto_check_in_rule_modal_button',
  READ_SELF_COMPANY_CREATE_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_self_company_create_company_smart_contract_template_modal_button',
  READ_OTHER_COMPANY_CREATE_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_other_company_create_company_smart_contract_template_modal_button',
  READ_SELF_COMPANY_EDIT_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_self_company_edit_company_smart_contract_template_modal_button',
  READ_OTHER_COMPANY_EDIT_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_other_company_edit_company_smart_contract_template_modal_button',
  READ_SELF_COMPANY_EDIT_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_self_company_edit_sc_auto_check_in_rule_modal_button',
  READ_OTHER_COMPANY_EDIT_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_other_company_edit_sc_auto_check_in_rule_modal_button',
  READ_SELF_COMPANY_DELETE_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_self_company_delete_sc_auto_check_in_rule_modal_button',
  READ_OTHER_COMPANY_DELETE_SC_AUTO_CHECK_IN_RULE_MODAL_BUTTON = 'read_other_company_delete_sc_auto_check_in_rule_modal_button',
  READ_SELF_COMPANY_DELETE_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_self_company_delete_company_smart_contract_template_modal_button',
  READ_OTHER_COMPANY_DELETE_COMPANY_SMART_CONTRACT_TEMPLATE_MODAL_BUTTON = 'read_other_company_delete_company_smart_contract_template_modal_button',
  READ_SELF_COMPANY_MAKE_DEFAULT_COMPANY_SMART_CONTRACT_TEMPLATE_BUTTON = 'read_self_company_make_default_company_smart_contract_template_button',
  READ_OTHER_COMPANY_MAKE_DEFAULT_COMPANY_SMART_CONTRACT_TEMPLATE_BUTTON = 'read_other_company_make_default_company_smart_contract_template_button',
  READ_SELF_COMPANY_UPDATE_SUBBOOK_DEFAULT_SHARE_FIELD = 'read_self_company_update_subbook_default_share_field',
  READ_OTHER_COMPANY_UPDATE_SUBBOOK_DEFAULT_SHARE_FIELD = 'read_other_company_update_subbook_default_share_field',
  CHANGE_SELF_COMPANY_UPDATE_SUBBOOK_DEFAULT_SHARE_FIELD = 'change_self_company_update_subbook_default_share_field',
  CHANGE_OTHER_COMPANY_UPDATE_SUBBOOK_DEFAULT_SHARE_FIELD = 'change_other_company_update_subbook_default_share_field',
  READ_SELF_COMPANY_UPDATE_SUBBOOK_INVOICED_FIELD = 'read_self_company_update_subbook_invoiced_field',
  READ_OTHER_COMPANY_UPDATE_SUBBOOK_INVOICED_FIELD = 'read_other_company_update_subbook_invoiced_field',
  READ_SELF_COMPANY_EDIT_SUBBOOK_MODAL_BUTTON = 'read_self_company_edit_subbook_modal_button',
  READ_OTHER_COMPANY_EDIT_SUBBOOK_MODAL_BUTTON = 'read_other_company_edit_subbook_modal_button',
  READ_SELF_PAYMENTS_INVOICE_VAT_NOTE = 'read_self_payments_invoice_vat_note',
  READ_OTHER_PAYMENTS_INVOICE_VAT_NOTE = 'read_other_payments_invoice_vat_note',
  READ_PAYMENTS_INVOICES_OPEN = 'read_payments_invoices_open_filter_menu',
  READ_PAYMENTS_INVOICES_SENT = 'read_payments_invoices_sent_filter_menu',
  READ_PAYMENTS_INVOICES_DRAFT = 'read_payments_invoices_draft_filter_menu',
  READ_PAYMENTS_INVOICES_PAID = 'read_payments_invoices_paid_filter_menu',
  READ_PAYMENTS_INVOICES_CANCELED = 'read_payments_invoices_canceled_filter_menu',
  READ_PAYMENTS_INVOICES_REFUNDS = 'read_payments_invoices_refunds_filter_menu',
  READ_PAYMENTS_INVOICES_PROFORMA = 'read_payments_invoices_proforma_filter_menu',
  READ_PAYMENTS_INVOICES_PROFORMA_INVOICE_PAGE = 'read_payments_invoices_proforma_invoice_page',
  READ_COMPANIES_TABLE_SELECTED_FIELD = 'read_companies_table_selected_field',
  READ_COMPANIES_TABLE_TEAM_FIELD = 'read_companies_table_team_field',
  READ_COMPANIES_TABLE_GENERAL_LEDGER_ID_FIELD = 'read_companies_table_general_ledger_id_field',
  READ_COMPANIES_TABLE_BALANCE_FIELD = 'read_companies_table_balance_field',
  READ_COMPANIES_TABLE_DEBT_FIELD = 'read_companies_table_debt_field',
  READ_COMPANIES_TABLE_SIGMA_PAYMENTS_FIELD = 'read_companies_table_sigma_payments_field',
  READ_COMPANIES_TABLE_LAST_PAYMENT_DATE_FIELD = 'read_companies_table_last_payment_date_field',
  READ_COMPANIES_TABLE_FIN_LAST_PAYMENT_DATE_FIELD = 'read_companies_table_fin_last_payment_date_field',
  READ_COMPANIES_TABLE_LAST_ACTIVITY_DATE_FIELD = 'read_companies_table_last_activity_date_field',
  READ_COMPANIES_TABLE_CATEGORY_FIELD = 'read_companies_table_category_field',
  READ_COMPANIES_TABLE_SUB_CATEGORIES_FIELD = 'read_companies_table_sub_categories_field',
  READ_COMPANIES_TABLE_TAGS_FIELD = 'read_companies_table_tags_field',
  READ_COMPANIES_TABLE_CLIENT_MANAGER_FIELD = 'read_companies_table_client_manager_field',
  READ_COMPANIES_TABLE_STATUS_FIELD = 'read_companies_table_status_field',
  READ_COMPANIES_TABLE_FIN_STATUS_FIELD = 'read_companies_table_fin_status_field',
  READ_COMPANIES_TABLE_FAVORITE_FIELD = 'read_companies_table_favorite_field',
  READ_PERFORMERS_TABLE_SELECTED_FIELD = 'read_performers_table_selected_field',
  READ_PERFORMERS_TABLE_FAVORITE_FIELD = 'read_performers_table_favorite_field',
  READ_PERFORMERS_TABLE_NAME_FIELD = 'read_performers_table_name_field',
  READ_PERFORMERS_TABLE_OWNER_ID_FIELD = 'read_performers_table_owner_id_field',
  READ_PERFORMERS_TABLE_GENERAL_LEDGER_ID_FIELD = 'read_performers_table_general_ledger_id_field',
  READ_PERFORMERS_TABLE_PAYONEER_PAYOUTS_BLOCKED_FIELD = 'read_performers_table_payoneer_payouts_blocked_field',
  CHANGE_PERFORMERS_TABLE_PAYONEER_PAYOUTS_BLOCKED_FIELD = 'change_performers_table_payoneer_payouts_blocked_field',
  READ_PERFORMERS_TABLE_EMAIL_FIELD = 'read_performers_table_email_field',
  READ_PERFORMERS_TABLE_BLOCKED_FIELD = 'read_performers_table_blocked_field',
  READ_PERFORMERS_TABLE_REVENUE_FIELD = 'read_performers_table_revenue_field',
  READ_PERFORMERS_TABLE_SHARES_FIELD = 'read_performers_table_shares_field',
  READ_PERFORMERS_TABLE_GROUPS_FIELD = 'read_performers_table_groups_field',
  READ_PERFORMERS_TABLE_POSITION_FIELD = 'read_performers_table_position_field',
  READ_PERFORMERS_TABLE_FINANCE_TAGS_FIELD = 'read_performers_table_finance_tags_field',
  READ_PERFORMERS_TABLE_PROJECT_MANAGER_FIELD = 'read_performers_table_project_manager_field',
  READ_PERFORMERS_TABLE_MENTOR_FIELD = 'read_performers_table_mentor_field',
  READ_PERFORMERS_TABLE_WORK_EXPERIENCE_FIELD = 'read_performers_table_work_experience_field',
  READ_PERFORMERS_TABLE_QUALIFICATIONS_FIELD = 'read_performers_table_qualifications_field',
  READ_PERFORMERS_TABLE_FINANCE_ROLE_FIELD = 'read_performers_table_finance_role_field',
  READ_PERFORMERS_TABLE_DROPDOWN_FIELD = 'read_performers_table_dropdown_field',
  READ_PERFORMERS_TABLE_DROPDOWN_ADD_RECORD_MODAL_BUTTON = 'read_performers_table_dropdown_add_record_modal_button',
  READ_PERFORMERS_TAT_TABLE_DROPDOWN_FIELD = 'read_performers_tat_table_dropdown_field',
  READ_PERFORMERS_TAT_TABLE_DROPDOWN_ADD_RECORD_MODAL_BUTTON = 'read_performers_tat_table_dropdown_add_record_modal_button',
  READ_NEW_COMPANY_BUTTON = 'read_new_company_button',
  CREATE_COMPANY_DESCRIPTION = 'create_company_description',
  READ_COMPANIES_MENU_STATUS_NEW_LINK = 'read_companies_menu_status_new_link',
  READ_COMPANIES_MENU_STATUS_ACTIVE_LINK = 'read_companies_menu_status_active_link',
  READ_COMPANIES_MENU_STATUS_PAUSED_LINK = 'read_companies_menu_status_paused_link',
  READ_COMPANIES_MENU_STATUS_DECLINED_LINK = 'read_companies_menu_status_declined_link',
  READ_COMPANIES_MENU_STATUS_REAL_DECLINE_LINK = 'read_companies_menu_status_real_decline_link',
  READ_COMPANIES_MENU_STATUS_NO_PAYMENTS_LINK = 'read_companies_menu_status_no_payments_link',
  READ_COMPANIES_MENU_STATUS_NEW_LINK_COUNT = 'read_companies_menu_status_new_link_count',
  READ_COMPANIES_MENU_STATUS_ACTIVE_LINK_COUNT = 'read_companies_menu_status_active_link_count',
  READ_COMPANIES_MENU_STATUS_PAUSED_LINK_COUNT = 'read_companies_menu_status_paused_link_count',
  READ_COMPANIES_MENU_STATUS_DECLINED_LINK_COUNT = 'read_companies_menu_status_declined_link_count',
  READ_COMPANIES_MENU_STATUS_REAL_DECLINE_LINK_COUNT = 'read_companies_menu_status_real_decline_link_count',
  READ_COMPANIES_MENU_STATUS_NO_PAYMENTS_LINK_COUNT = 'read_companies_menu_status_no_payments_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_NEW_LINK = 'read_companies_menu_fin_status_new_link',
  READ_COMPANIES_MENU_FIN_STATUS_ACTIVE_LINK = 'read_companies_menu_fin_status_active_link',
  READ_COMPANIES_MENU_FIN_STATUS_PAUSED_LINK = 'read_companies_menu_fin_status_paused_link',
  READ_COMPANIES_MENU_FIN_STATUS_DECLINED_LINK = 'read_companies_menu_fin_status_declined_link',
  READ_COMPANIES_MENU_FIN_STATUS_REAL_DECLINE_LINK = 'read_companies_menu_fin_status_real_decline_link',
  READ_COMPANIES_MENU_FIN_STATUS_NO_PAYMENTS_LINK = 'read_companies_menu_fin_status_no_payments_link',
  READ_COMPANIES_MENU_FIN_STATUS_NEW_LINK_COUNT = 'read_companies_menu_fin_status_new_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_ACTIVE_LINK_COUNT = 'read_companies_menu_fin_status_active_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_PAUSED_LINK_COUNT = 'read_companies_menu_fin_status_paused_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_DECLINED_LINK_COUNT = 'read_companies_menu_fin_status_declined_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_REAL_DECLINE_LINK_COUNT = 'read_companies_menu_fin_status_real_decline_link_count',
  READ_COMPANIES_MENU_FIN_STATUS_NO_PAYMENTS_LINK_COUNT = 'read_companies_menu_fin_status_no_payments_link_count',
  READ_COMPANIES_SAVED_FILTERS_MENU = 'read_companies_saved_filters_menu',
  READ_PERFORMERS_SAVED_FILTERS_MENU = 'read_performers_saved_filters_menu',
  READ_COMPANY_MEMBERS_TABLE = 'read_company_members_table',
  READ_COMPANY_ARTISTS_TABLE = 'read_company_artists_table',
  READ_COMPANY_IMAGE_FIELD = 'read_company_image_field',
  READ_PROFORMA_DOWNLOAD_INVOICES_BUTTON = 'read_proforma_download_invoices_button',
  READ_PROFORMA_UPLOAD_INVOICES_MODAL_BUTTON = 'read_proforma_upload_invoices_modal_button',
  CHANGE_COMPANY_IMAGE_FIELD = 'change_company_image_field',
  READ_COMPANY_NAME_FIELD = 'read_company_name_field',
  READ_COMPANY_CREATOR_FIELD = 'read_company_creator_field',
  CHANGE_COMPANY_NAME_FIELD = 'change_company_name_field',
  READ_COMPANY_EMAIL_FIELD = 'read_company_email_field',
  CHANGE_COMPANY_EMAIL_FIELD = 'change_company_email_field',
  READ_COMPANY_STATUS_FIELD = 'read_company_status_field',
  CHANGE_COMPANY_STATUS_FIELD = 'change_company_status_field',
  READ_COMPANY_TYPE_FIELD = 'read_company_type_field',
  CHANGE_COMPANY_TYPE_FIELD = 'change_company_type_field',
  READ_COMPANY_FILES_STORAGE_FIELD = 'read_company_files_storage_field',
  CHANGE_COMPANY_FILES_STORAGE_FIELD = 'change_company_files_storage_field',
  READ_COMPANY_AUTO_DEBT_REPAYMENT_FIELD = 'read_company_auto_debt_repayment_field',
  CHANGE_COMPANY_AUTO_DEBT_REPAYMENT_FIELD = 'change_company_auto_debt_repayment_field',
  READ_COMPANY_AUTO_PAYMENT_SC_FIELD = 'read_company_auto_payment_sc_field',
  CHANGE_COMPANY_AUTO_PAYMENT_SC_FIELD = 'change_company_auto_payment_sc_field',
  READ_COMPANY_AUTO_CONTRACTOR_PAYMENT_FIELD = 'read_company_auto_contractor_payment_field',
  CHANGE_COMPANY_AUTO_CONTRACTOR_PAYMENT_FIELD = 'change_company_auto_contractor_payment_field',
  READ_COMPANY_COUNTRY_FIELD = 'read_company_country_field',
  CHANGE_COMPANY_COUNTRY_FIELD = 'change_company_country_field',
  READ_COMPANY_STATE_FIELD = 'read_company_state_field',
  CHANGE_COMPANY_STATE_FIELD = 'change_company_state_field',
  READ_COMPANY_SITE_FIELD = 'read_company_site_field',
  CHANGE_COMPANY_SITE_FIELD = 'change_company_site_field',
  READ_COMPANY_ADDRESS_FIELD = 'read_company_address_field',
  CHANGE_COMPANY_ADDRESS_FIELD = 'change_company_address_field',
  READ_COMPANY_ZIP_CODE_FIELD = 'read_company_zip_code_field',
  CHANGE_COMPANY_ZIP_CODE_FIELD = 'change_company_zip_code_field',
  READ_COMPANY_FACEBOOK_FIELD = 'read_company_facebook_field',
  CHANGE_COMPANY_FACEBOOK_FIELD = 'change_company_facebook_field',
  READ_COMPANY_LINKED_IN_FIELD = 'read_company_linked_in_field',
  CHANGE_COMPANY_LINKED_IN_FIELD = 'change_company_linked_in_field',
  READ_COMPANY_TWITTER_FIELD = 'read_company_twitter_field',
  CHANGE_COMPANY_TWITTER_FIELD = 'change_company_twitter_field',
  READ_COMPANY_INSTAGRAM_FIELD = 'read_company_instagram_field',
  CHANGE_COMPANY_INSTAGRAM_FIELD = 'change_company_instagram_field',
  READ_COMPANY_SOCIAL_NETWORKS_FIELD = 'read_company_social_networks_field',
  CHANGE_COMPANY_SOCIAL_NETWORKS_FIELD = 'change_company_social_networks_field',
  READ_COMPANY_CITY_FIELD = 'read_company_city_field',
  CHANGE_COMPANY_CITY_FIELD = 'change_company_city_field',
  READ_COMPANY_CREATED_AT_FIELD = 'read_company_created_at_field',
  READ_COMPANY_UPDATED_AT_FIELD = 'read_company_updated_at_field',
  READ_COMPANY_BLOCKED_AT_FIELD = 'read_company_blocked_at_field',
  READ_COMPANY_LEAD_CREATION_DATE_FIELD = 'read_company_lead_creation_date_field',
  CHANGE_COMPANY_LEAD_CREATION_DATE_FIELD = 'change_company_lead_creation_date_field',
  READ_COMPANY_NDA_FIELD = 'read_company_nda_field',
  READ_COMPANY_NDA_FILE_ID_FIELD = 'read_company_nda_file_id_field',
  CHANGE_COMPANY_NDA_FILE_ID_FIELD = 'change_company_nda_file_id_field',
  READ_COMPANY_VENDOR_FORM_ID_FIELD = 'read_company_vendor_form_id_field',
  CHANGE_COMPANY_VENDOR_FORM_ID_FIELD = 'change_company_vendor_form_id_field',
  READ_COMPANY_AGREEMENT_ID_FIELD = 'read_company_agreement_id_field',
  CHANGE_COMPANY_AGREEMENT_ID_FIELD = 'change_company_agreement_id_field',
  CHANGE_COMPANY_NDA_FIELD = 'change_company_nda_field',
  READ_COMPANY_TERMS_FIELD = 'read_company_terms_field',
  CHANGE_COMPANY_TERMS_FIELD = 'change_company_terms_field',
  READ_COMPANY_DESCRIPTION_FIELD = 'read_company_description_field',
  CHANGE_COMPANY_DESCRIPTION_FIELD = 'change_company_description_field',
  READ_COMPANY_FIRST_PAYMENT_DATE_FIELD = 'read_company_first_payment_date_field',
  READ_COMPANY_FIN_FIRST_PAYMENT_DATE_FIELD = 'read_company_fin_first_payment_date_field',
  READ_COMPANY_FIN_LAST_PAYMENT_DATE_FIELD = 'read_company_fin_last_payment_date_field',
  READ_COMPANY_LAST_PAYMENT_DATE_FIELD = 'read_company_last_payment_date_field',
  READ_COMPANY_DISCOUNT_FIELD = 'read_company_discount_field',
  CHANGE_COMPANY_DISCOUNT_FIELD = 'change_company_discount_field',
  READ_COMPANY_ADDITION_DISCOUNT_FIELD = 'read_company_addition_discount_field',
  CHANGE_COMPANY_ADDITION_DISCOUNT_FIELD = 'change_company_addition_discount_field',
  READ_COMPANY_CURRENCY_FIELD = 'read_company_currency_field',
  CHANGE_COMPANY_CURRENCY_FIELD = 'change_company_currency_field',
  READ_COMPANY_TAGS_FIELD = 'read_company_tags_field',
  CHANGE_COMPANY_TAGS_FIELD = 'change_company_tags_field',
  READ_COMPANY_UPSALERS_FIELD = 'read_company_upsalers_field',
  CHANGE_COMPANY_UPSALERS_FIELD = 'change_company_upsalers_field',
  READ_COMPANY_SALES_MANAGERS_FIELD = 'read_company_sales_managers_field',
  CHANGE_COMPANY_SALES_MANAGERS_FIELD = 'change_company_sales_managers_field',
  READ_COMPANY_REFERER_FIELD = 'read_company_referer_field',
  CHANGE_COMPANY_REFERER_FIELD = 'change_company_referer_field',
  READ_COMPANY_BUSINESS_EMAIL_FIELD = 'read_company_business_email_field',
  CHANGE_COMPANY_BUSINESS_EMAIL_FIELD = 'change_company_business_email_field',
  READ_COMPANY_MARKETING_SOURCE_FIELD = 'read_company_marketing_source_field',
  CHANGE_COMPANY_MARKETING_SOURCE_FIELD = 'change_company_marketing_source_field',
  READ_COMPANY_COLD_SOURCE_FIELD = 'read_company_cold_source_field',
  CHANGE_COMPANY_COLD_SOURCE_FIELD = 'change_company_cold_source_field',
  READ_COMPANY_CATEGORY_FIELD = 'read_company_category_field',
  CHANGE_COMPANY_CATEGORY_FIELD = 'change_company_category_field',
  READ_COMPANY_COMPANY_SIZE_FIELD = 'read_company_company_size_field',
  CHANGE_COMPANY_COMPANY_SIZE_FIELD = 'change_company_company_size_field',
  READ_COMPANY_COMPANY_REVENUE_FIELD = 'read_company_company_revenue_field',
  CHANGE_COMPANY_COMPANY_REVENUE_FIELD = 'change_company_company_revenue_field',
  READ_COMPANY_SUB_CATEGORIES_FIELD = 'read_company_sub_categories_field',
  CHANGE_COMPANY_SUB_CATEGORIES_FIELD = 'change_company_sub_categories_field',
  READ_COMPANY_NATURE_OF_LEAD_FIELD = 'read_company_nature_of_lead_field',
  CHANGE_COMPANY_NATURE_OF_LEAD_FIELD = 'change_company_nature_of_lead_field',
  READ_COMPANY_ROLES_FIELD = 'read_company_roles_field',
  CHANGE_COMPANY_ROLES_FIELD = 'change_company_roles_field',
  READ_COMPANY_USER_ID_FIELD = 'read_company_user_id_field',
  CHANGE_COMPANY_USER_ID_FIELD = 'change_company_user_id_field',
  READ_COMPANY_VALIDATE_OUTPUT_FIELD = 'read_company_validate_output_field',
  CHANGE_COMPANY_VALIDATE_OUTPUT_FIELD = 'change_company_validate_output_field',
  READ_COMPANY_COMPANY_CRM_FIELD = 'read_company_company_crm_field',
  CHANGE_COMPANY_COMPANY_CRM_FIELD = 'change_company_company_crm_field',
  READ_COMPANY_CLIENT_STATUS_FIELD = 'read_company_client_status_field',
  READ_COMPANY_FIN_CLIENT_STATUS_FIELD = 'read_company_fin_client_status_field',
  READ_COMPANY_MANUAL_TEAM_STATUS_FIELD = 'read_company_manual_team_status_field',
  CHANGE_COMPANY_MANUAL_TEAM_STATUS_FIELD = 'change_company_manual_team_status_field',
  READ_COMPANY_REAL_DECLINE_REASONS_FIELD = 'read_company_real_decline_reasons_field',
  CHANGE_COMPANY_REAL_DECLINE_REASONS_FIELD = 'change_company_real_decline_reasons_field',
  READ_COMPANY_LOCALE_FIELD = 'read_company_locale_field',
  CHANGE_COMPANY_LOCALE_FIELD = 'change_company_locale_field',
  READ_COMPANY_TIME_ZONE_FIELD = 'read_company_time_zone_field',
  CHANGE_COMPANY_TIME_ZONE_FIELD = 'change_company_time_zone_field',
  READ_COMPANY_TIME_FORMAT_FIELD = 'read_company_time_format_field',
  CHANGE_COMPANY_TIME_FORMAT_FIELD = 'change_company_time_format_field',
  READ_COMPANY_DATE_FORMAT_FIELD = 'read_company_date_format_field',
  CHANGE_COMPANY_DATE_FORMAT_FIELD = 'change_company_date_format_field',
  READ_COMPANY_OPERATION_HOURS_FIELD = 'read_company_operation_hours_field',
  CHANGE_COMPANY_OPERATION_HOURS_FIELD = 'change_company_operation_hours_field',
  READ_COMPANY_PAYMENT_METHODS_FIELD = 'read_company_payment_methods_field',
  CHANGE_COMPANY_PAYMENT_METHODS_FIELD = 'change_company_payment_methods_field',
  READ_COMPANY_VIEW_PER_TASK_FIELD = 'read_company_view_per_task_field',
  CHANGE_COMPANY_VIEW_PER_TASK_FIELD = 'change_company_view_per_task_field',
  READ_COMPANY_REVISIONS_FIELD = 'read_company_revisions_field',
  CHANGE_COMPANY_REVISIONS_FIELD = 'change_company_revisions_field',
  READ_COMPANY_HOURLY_RATE_FIELD = 'read_company_hourly_rate_field',
  CHANGE_COMPANY_HOURLY_RATE_FIELD = 'change_company_hourly_rate_field',
  READ_COMPANY_WORKFLOW_TYPE_FIELD = 'read_company_workflow_type_field',
  CHANGE_COMPANY_WORKFLOW_TYPE_FIELD = 'change_company_workflow_type_field',
  READ_COMPANY_COMPLEXITY_FIELD = 'read_company_complexity_field',
  CHANGE_COMPANY_COMPLEXITY_FIELD = 'change_company_complexity_field',
  READ_COMPANY_SCOPE_OF_WORK_FIELD = 'read_company_scope_of_work_field',
  CHANGE_COMPANY_SCOPE_OF_WORK_FIELD = 'change_company_scope_of_work_field',
  READ_COMPANY_DELIVERY_TIME_FIELD = 'read_company_delivery_time_field',
  CHANGE_COMPANY_DELIVERY_TIME_FIELD = 'change_company_delivery_time_field',
  READ_COMPANY_RESPOND_TIME_FIELD = 'read_company_respond_time_field',
  CHANGE_COMPANY_RESPOND_TIME_FIELD = 'change_company_respond_time_field',
  READ_COMPANY_PRIORITY_FIELD = 'read_company_priority_field',
  CHANGE_COMPANY_PRIORITY_FIELD = 'change_company_priority_field',
  READ_COMPANY_SOURCE_FIELD = 'read_company_source_field',
  CHANGE_COMPANY_SOURCE_FIELD = 'change_company_source_field',
  READ_COMPANY_HOLD_RATE_FIELD = 'read_company_hold_rate_field',
  CHANGE_COMPANY_HOLD_RATE_FIELD = 'change_company_hold_rate_field',
  READ_COMPANY_DEBT_LIMIT_FIELD = 'read_company_debt_limit_field',
  CHANGE_COMPANY_DEBT_LIMIT_FIELD = 'change_company_debt_limit_field',
  READ_COMPANY_ACCOUNT_RECEIVABLES_FIELD = 'read_company_account_receivables_field',
  CHANGE_COMPANY_ACCOUNT_RECEIVABLES_FIELD = 'change_company_account_receivables_field',
  CHANGE_COMPANY_ACCOUNT_RECEIVABLES_FIELD_FIFTY_PERCENT_OPTION = 'change_company_account_receivables_field_fifty_percent_option',
  READ_COMPANY_DEFERRED_PAYMENT_FIELD = 'read_company_deferred_payment_field',
  CHANGE_COMPANY_DEFERRED_PAYMENT_FIELD = 'change_company_deferred_payment_field',
  READ_COMPANY_TRANSACTION_FEE_FIELD = 'read_company_transaction_fee_field',
  CHANGE_COMPANY_TRANSACTION_FEE_FIELD = 'change_company_transaction_fee_field',
  READ_COMPANY_PROVISION_FOR_BAD_DEBT_FIELD = 'read_company_provision_for_bad_debt_field',
  CHANGE_COMPANY_PROVISION_FOR_BAD_DEBT_FIELD = 'change_company_provision_for_bad_debt_field',
  READ_COMPANY_PROVISION_FOR_REVISION_FIELD = 'read_company_provision_for_revision_field',
  CHANGE_COMPANY_PROVISION_FOR_REVISION_FIELD = 'change_company_provision_for_revision_field',
  READ_CHANGE_COMPANY_OWNER_MODAL_BUTTON = 'read_change_company_owner_modal_button',
  READ_CHANGE_COMPANY_OWNER_MODAL_BUTTON_IF_OWNER = 'read_change_company_owner_modal_button_if_owner',
  READ_SELF_COMPANY_INVITE_NEW_USERS_MODAL_BUTTON = 'read_self_company_invite_new_users_modal_button',
  READ_OTHER_COMPANY_INVITE_NEW_USERS_MODAL_BUTTON = 'read_other_company_invite_new_users_modal_button',
  READ_COMPANY_INVITE_NEW_USERS_MODAL_BUTTON_IF_OWNER = 'read_company_invite_new_users_modal_button_if_owner',
  READ_MERGE_COMPANIES_MODAL_BUTTON = 'read_merge_companies_modal_button',
  READ_COMPANY_ARTISTS_UPDATE_TEAM_CONTRACTORS_MODAL_BUTTON = 'read_company_artists_update_team_contractors_modal_button',
  READ_COMPANY_ITEM_TYPES_TABLE_SELECTED_FIELD = 'read_company_item_types_table_selected_field',
  READ_COMPANY_ITEM_TYPES_TABLE_ID_FIELD = 'read_company_item_types_table_id_field',
  READ_COMPANY_ITEM_TYPES_TABLE_NAME_FIELD = 'read_company_item_types_table_name_field',
  READ_COMPANY_ITEM_TYPES_TABLE_USER_FIELD = 'read_company_item_types_table_user_field',
  READ_COMPANY_ITEM_TYPES_TABLE_CREATED_AT_FIELD = 'read_company_item_types_table_created_at_field',
  READ_COMPANY_ITEM_TYPES_TABLE_ITEM_CATEGORY_FIELD = 'read_company_item_types_table_item_category_field',
  READ_COMPANY_ITEM_TYPES_TABLE_ITEM_TAG_FIELD = 'read_company_item_types_table_item_tag_field',
  READ_COMPANY_ITEM_TYPES_TABLE_PRICE_FIELD = 'read_company_item_types_table_price_field',
  READ_COMPANY_ITEM_TYPES_TABLE_DROPDOWN_FIELD = 'read_company_item_types_table_dropdown_field',
  READ_SELF_COMPANY_PAYMENTS_ACCOUNTS_ASSETS_TABLE = 'read_self_company_payments_accounts_assets_table',
  READ_OTHER_COMPANY_PAYMENTS_ACCOUNTS_ASSETS_TABLE = 'read_other_company_payments_accounts_assets_table',
  READ_SELF_COMPANY_PAYMENTS_ACCOUNTS_EQUITY_TABLE = 'read_self_company_payments_accounts_equity_table',
  READ_OTHER_COMPANY_PAYMENTS_ACCOUNTS_EQUITY_TABLE = 'read_other_company_payments_accounts_equity_table',
  READ_SELF_COMPANY_PAYMENTS_ACCOUNTS_LIABILITY_TABLE = 'read_self_company_payments_accounts_liability_table',
  READ_OTHER_COMPANY_PAYMENTS_ACCOUNTS_LIABILITY_TABLE = 'read_other_company_payments_accounts_liability_table',
  READ_SELF_COMPANY_PAYMENTS_ACCOUNTS_PROVISIONS_TABLE = 'read_self_company_payments_accounts_provisions_table',
  READ_OTHER_COMPANY_PAYMENTS_ACCOUNTS_PROVISIONS_TABLE = 'read_other_company_payments_accounts_provisions_table',
  READ_SELF_COMPANY_PAYMENTS_BALANCE_SHEET_INCOME_STATEMENT_SECTION = 'read_self_company_payments_balance_sheet_income_statement_section',
  READ_OTHER_COMPANY_PAYMENTS_BALANCE_SHEET_INCOME_STATEMENT_SECTION = 'read_other_company_payments_balance_sheet_income_statement_section',
  READ_COMPANY_ITEM_TYPES_DELETE_ITEM_TYPE_BUTTON = 'read_company_item_types_delete_item_type_button',
  READ_COMPANY_ITEM_TYPES_UPDATE_ITEM_TYPE_BUTTON = 'read_company_item_types_update_item_type_button',
  READ_COMPANY_ITEM_TYPES_CREATE_ITEM_TYPE_BUTTON = 'read_company_item_types_create_item_type_button',
  READ_COMPANY_ITEM_TYPES_DOWNLOAD_ITEM_TYPES_BUTTON = 'read_company_item_types_download_item_types_button',
  READ_COMPANY_ITEM_TYPES_IMPORT_ITEM_TYPES_MODAL_BUTTON = 'read_company_item_types_import_item_types_modal_button',
  READ_COMPANY_ITEM_TYPES_BATCH_DUPLICATE_BUTTON = 'read_company_item_types_batch_duplicate_button',
  READ_COMPANY_ITEM_TYPES_BATCH_DELETE_BUTTON = 'read_company_item_types_batch_delete_button',
  READ_SELF_COMPANY_BILLING_INFO_FIELD = 'read_self_company_billing_info_field',
  READ_OTHER_COMPANY_BILLING_INFO_FIELD = 'read_other_company_billing_info_field',
  READ_SELF_COMPANY_BILLING_INFO_RECEIVER_FIELD = 'read_self_company_billing_info_receiver_field',
  READ_OTHER_COMPANY_BILLING_INFO_RECEIVER_FIELD = 'read_other_company_billing_info_receiver_field',
  READ_SELF_CREATE_BILLING_INFO_BUTTON = 'read_self_create_billing_info_button',
  READ_OTHER_CREATE_BILLING_INFO_BUTTON = 'read_other_create_billing_info_button',
  READ_SELF_UPDATE_BILLING_INFO_BUTTON = 'read_self_update_billing_info_button',
  READ_OTHER_UPDATE_BILLING_INFO_BUTTON = 'read_other_update_billing_info_button',
  READ_SELF_COPY_BILLING_INFO_BUTTON = 'read_self_copy_billing_info_button',
  READ_OTHER_COPY_BILLING_INFO_BUTTON = 'read_other_copy_billing_info_button',
  READ_SELF_DELETE_BILLING_INFO_BUTTON = 'read_self_delete_billing_info_button',
  READ_SELF_FIN_DELETE_BILLING_INFO_BUTTON = 'read_self_fin_delete_billing_info_button',
  READ_OTHER_DELETE_BILLING_INFO_BUTTON = 'read_other_delete_billing_info_button',
  READ_OTHER_FIN_DELETE_BILLING_INFO_BUTTON = 'read_other_fin_delete_billing_info_button',
  READ_SELF_COMPANY_CUSTOM_FIELDS = 'read_self_company_custom_fields',
  READ_OTHER_COMPANY_CUSTOM_FIELDS = 'read_other_company_custom_fields',
  READ_SELF_COMPANY_CUSTOM_FIELDS_CREATE_BUTTON = 'read_self_company_custom_fields_create_button',
  READ_OTHER_COMPANY_CUSTOM_FIELDS_CREATE_BUTTON = 'read_other_company_custom_fields_create_button',
  READ_SELF_COMPANY_CUSTOM_FIELDS_UPDATE_BUTTON = 'read_self_company_custom_fields_update_button',
  READ_OTHER_COMPANY_CUSTOM_FIELDS_UPDATE_BUTTON = 'read_other_company_custom_fields_update_button',
  READ_SELF_COMPANY_CUSTOM_FIELDS_DELETE_BUTTON = 'read_self_company_custom_fields_delete_button',
  READ_OTHER_COMPANY_CUSTOM_FIELDS_DELETE_BUTTON = 'read_other_company_custom_fields_delete_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_CANCEL_BUTTON = 'read_self_company_payments_invoice_cancel_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_REFUND_BUTTON = 'read_self_company_payments_invoice_refund_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_CHARGEBACK_BUTTON = 'read_self_company_payments_invoice_chargeback_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_CHARGEBACK_BUTTON = 'read_other_company_payments_invoice_chargeback_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_APPROVE_BUTTON = 'read_self_company_payments_invoice_approve_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_CONFIRM_PAYMENT_BUTTON = 'read_self_company_payments_invoice_confirm_payment_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_PAY_INTERNAL_BUTTON = 'read_self_company_payments_invoice_pay_internal_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_UNCOLLECTIBLE_BUTTON = 'read_self_company_payments_invoice_uncollectible_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_SEND_TO_EMAIL_BUTTON = 'read_self_company_payments_invoice_send_to_email_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_DOWNLOAD_BUTTON = 'read_self_company_payments_invoice_download_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_RECEIPT_BUTTON = 'read_self_company_payments_invoice_receipt_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_SUBSCRIBE_BUTTON = 'read_self_company_payments_invoice_subscribe_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_PAY_BY_CARD_BUTTON = 'read_self_company_payments_invoice_pay_by_card_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_PAY_STRIPE_LINK = 'read_self_company_payments_invoice_pay_stripe_link',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_SEND_DRAFT_BUTTON = 'read_self_company_payments_invoice_send_draft_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_CANCEL_BUTTON = 'read_other_company_payments_invoice_cancel_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_REFUND_BUTTON = 'read_other_company_payments_invoice_refund_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_APPROVE_BUTTON = 'read_other_company_payments_invoice_approve_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_CONFIRM_PAYMENT_BUTTON = 'read_other_company_payments_invoice_confirm_payment_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_PAY_INTERNAL_BUTTON = 'read_other_company_payments_invoice_pay_internal_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_UNCOLLECTIBLE_BUTTON = 'read_other_company_payments_invoice_uncollectible_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_SEND_TO_EMAIL_BUTTON = 'read_other_company_payments_invoice_send_to_email_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_DOWNLOAD_BUTTON = 'read_other_company_payments_invoice_download_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_RECEIPT_BUTTON = 'read_other_company_payments_invoice_receipt_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_SUBSCRIBE_BUTTON = 'read_other_company_payments_invoice_subscribe_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_PAY_BY_CARD_BUTTON = 'read_other_company_payments_invoice_pay_by_card_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_PAY_STRIPE_LINK = 'read_other_company_payments_invoice_pay_stripe_link',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_SEND_DRAFT_BUTTON = 'read_other_company_payments_invoice_send_draft_button',
  READ_SELF_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'read_self_payments_invoice_header_custom_fields',
  READ_OTHER_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'read_other_payments_invoice_header_custom_fields',
  CREATE_SELF_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'create_self_payments_invoice_header_custom_fields',
  CREATE_OTHER_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'create_other_payments_invoice_header_custom_fields',
  UPDATE_SELF_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'update_self_payments_invoice_header_custom_fields',
  UPDATE_OTHER_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'update_other_payments_invoice_header_custom_fields',
  DELETE_SELF_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'delete_self_payments_invoice_header_custom_fields',
  DELETE_OTHER_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'delete_other_payments_invoice_header_custom_fields',
  READ_SELF_PAYMENTS_INVOICE_AV_BILLING_INFO = 'read_self_payments_invoice_av_billing_info',
  READ_OTHER_PAYMENTS_INVOICE_AV_BILLING_INFO = 'read_other_payments_invoice_av_billing_info',
  READ_SELF_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'read_self_payments_invoice_av_billing_info_custom_fields',
  READ_OTHER_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'read_other_payments_invoice_av_billing_info_custom_fields',
  CREATE_SELF_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'create_self_payments_invoice_av_billing_info_custom_fields',
  CREATE_OTHER_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'create_other_payments_invoice_av_billing_info_custom_fields',
  UPDATE_SELF_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'update_self_payments_invoice_av_billing_info_custom_fields',
  UPDATE_OTHER_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'update_other_payments_invoice_av_billing_info_custom_fields',
  DELETE_SELF_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'delete_self_payments_invoice_av_billing_info_custom_fields',
  DELETE_OTHER_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'delete_other_payments_invoice_av_billing_info_custom_fields',
  READ_SELF_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'read_self_payments_invoice_billing_info_custom_fields',
  READ_OTHER_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'read_other_payments_invoice_billing_info_custom_fields',
  CREATE_SELF_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'create_self_payments_invoice_billing_info_custom_fields',
  CREATE_OTHER_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'create_other_payments_invoice_billing_info_custom_fields',
  UPDATE_SELF_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'update_self_payments_invoice_billing_info_custom_fields',
  UPDATE_OTHER_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'update_other_payments_invoice_billing_info_custom_fields',
  DELETE_SELF_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'delete_self_payments_invoice_billing_info_custom_fields',
  DELETE_OTHER_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'delete_other_payments_invoice_billing_info_custom_fields',
  READ_SELF_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'read_self_payments_invoice_terms_and_notes_custom_fields',
  READ_OTHER_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'read_other_payments_invoice_terms_and_notes_custom_fields',
  CREATE_SELF_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'create_self_payments_invoice_terms_and_notes_custom_fields',
  CREATE_OTHER_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'create_other_payments_invoice_terms_and_notes_custom_fields',
  UPDATE_SELF_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'update_self_payments_invoice_terms_and_notes_custom_fields',
  UPDATE_OTHER_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'update_other_payments_invoice_terms_and_notes_custom_fields',
  DELETE_SELF_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'delete_self_payments_invoice_terms_and_notes_custom_fields',
  DELETE_OTHER_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'delete_other_payments_invoice_terms_and_notes_custom_fields',
  READ_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'read_self_payments_invoice_amount_due_custom_fields',
  READ_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'read_other_payments_invoice_amount_due_custom_fields',
  CREATE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'create_self_payments_invoice_amount_due_custom_fields',
  CREATE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'create_other_payments_invoice_amount_due_custom_fields',
  UPDATE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'update_self_payments_invoice_amount_due_custom_fields',
  UPDATE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'update_other_payments_invoice_amount_due_custom_fields',
  DELETE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'delete_self_payments_invoice_amount_due_custom_fields',
  DELETE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'delete_other_payments_invoice_amount_due_custom_fields',
  READ_SELF_PAYMENTS_INVOICE_BILLING_INFO = 'read_self_payments_invoice_billing_info',
  READ_OTHER_PAYMENTS_INVOICE_BILLING_INFO = 'read_other_payments_invoice_billing_info',
  READ_SELF_PAYMENTS_INVOICE_TEAM_UPSALERS = 'read_self_payments_invoice_team_upsalers',
  READ_OTHER_PAYMENTS_INVOICE_TEAM_UPSALERS = 'read_other_payments_invoice_team_upsalers',
  READ_SELF_PAYMENTS_INVOICE_ITEMS = 'read_self_payments_invoice_items',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS = 'read_other_payments_invoice_items',
  READ_SELF_PAYMENTS_INVOICE_NOTES = 'read_self_payments_invoice_notes',
  READ_OTHER_PAYMENTS_INVOICE_NOTES = 'read_other_payments_invoice_notes',
  READ_SELF_PAYMENTS_INVOICE_TERMS = 'read_self_payments_invoice_terms',
  READ_OTHER_PAYMENTS_INVOICE_TERMS = 'read_other_payments_invoice_terms',
  READ_SELF_PAYMENTS_INVOICE_NAME = 'read_self_payments_invoice_name',
  READ_OTHER_PAYMENTS_INVOICE_NAME = 'read_other_payments_invoice_name',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_SEND_BUTTON = 'read_self_company_payments_invoice_send_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_SEND_BUTTON = 'read_other_company_payments_invoice_send_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_VOID_BUTTON = 'read_self_company_payments_invoice_void_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_VOID_BUTTON = 'read_other_company_payments_invoice_void_button',
  READ_SELF_COMPANY_PAYMENTS_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON = 'read_self_company_payments_invoice_cancel_smart_contracts_button',
  READ_OTHER_COMPANY_PAYMENTS_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON = 'read_other_company_payments_invoice_cancel_smart_contracts_button',
  READ_SELF_PAYMENTS_RECEIPT_BLOCK = 'read_self_payments_receipt_block',
  READ_OTHER_PAYMENTS_RECEIPT_BLOCK = 'read_other_payments_receipt_block',
  READ_SELF_MAKE_DEFAULT_BILLING_INFO_BUTTON = 'read_self_make_default_billing_info_button',
  READ_OTHER_MAKE_DEFAULT_BILLING_INFO_BUTTON = 'read_other_make_default_billing_info_button',
  READ_SELF_COMPANY_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON = 'read_self_company_payments_menu_withdraw_modal_button',
  READ_OTHER_COMPANY_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON = 'read_other_company_payments_menu_withdraw_modal_button',
  READ_SELF_COMPANY_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON_BALANCE = 'read_self_company_payments_menu_withdraw_modal_button_balance',
  READ_OTHER_COMPANY_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON_BALANCE = 'read_other_company_payments_menu_withdraw_modal_button_balance',
  READ_COMPANY_PAYMENTS_MENU_PROFORMA_LINK = 'read_company_payments_menu_proforma_link',
  READ_SELF_COMPANY_REVENUE_SHARE_ITEMS_PAGE = 'read_self_company_revenue_share_items_page',
  READ_OTHER_COMPANY_REVENUE_SHARE_ITEMS_PAGE = 'read_other_company_revenue_share_items_page',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_MY_ITEMS_ONLY_FIELD = 'read_self_payments_invoice_items_my_items_only_field',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_MY_ITEMS_ONLY_FIELD = 'read_other_payments_invoice_items_my_items_only_field',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_FIELD = 'read_self_payments_invoice_items_group_by_field',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_FIELD = 'read_other_payments_invoice_items_group_by_field',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_PROJECTS_OPTION = 'read_self_payments_invoice_items_group_by_projects_option',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_PROJECTS_OPTION = 'read_other_payments_invoice_items_group_by_projects_option',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_TASKS_OPTION = 'read_self_payments_invoice_items_group_by_tasks_option',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_TASKS_OPTION = 'read_other_payments_invoice_items_group_by_tasks_option',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEMS_OPTION = 'read_self_payments_invoice_items_group_by_items_option',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEMS_OPTION = 'read_other_payments_invoice_items_group_by_items_option',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_CATEGORIES_OPTION = 'read_self_payments_invoice_items_group_by_item_categories_option',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_CATEGORIES_OPTION = 'read_other_payments_invoice_items_group_by_item_categories_option',
  READ_SELF_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_TAGS_OPTION = 'read_self_payments_invoice_items_group_by_item_tags_option',
  READ_OTHER_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_TAGS_OPTION = 'read_other_payments_invoice_items_group_by_item_tags_option',
  READ_SELF_PAYMENTS_INVOICES_LIST_CHECKBOX = 'read_self_payments_invoices_list_checkbox',
  READ_OTHER_PAYMENTS_INVOICES_LIST_CHECKBOX = 'read_other_payments_invoices_list_checkbox',
  READ_SELF_PAYMENTS_INVOICE_PAYMENT_METHOD = 'read_self_payments_invoice_payment_method',
  READ_OTHER_PAYMENTS_INVOICE_PAYMENT_METHOD = 'read_other_payments_invoice_payment_method',
  READ_WORKER_COMPANY_MEMBERS_LINK = 'read_worker_company_members_link',
  READ_CLIENT_COMPANY_MEMBERS_LINK = 'read_client_company_members_link',
  READ_SELF_PAYMENTS_INVOICE_CREATE_INVOICE_BUTTON = 'read_self_payments_invoice_create_invoice_button',
  READ_OTHER_PAYMENTS_INVOICE_CREATE_INVOICE_BUTTON = 'read_other_payments_invoice_create_invoice_button',
  READ_SELF_PAYMENTS_INVOICE_PAYMENTS = 'read_self_payments_invoice_payments',
  READ_OTHER_PAYMENTS_INVOICE_PAYMENTS = 'read_other_payments_invoice_payments',
  READ_SELF_PAYMENTS_INVOICE_CURRENCY = 'read_self_payments_invoice_currency',
  READ_OTHER_PAYMENTS_INVOICE_CURRENCY = 'read_other_payments_invoice_currency',
  READ_SELF_PAYMENTS_INVOICE_CURRENCY_CHANGE_BUTTON = 'read_self_payments_invoice_currency_change_button',
  READ_OTHER_PAYMENTS_INVOICE_CURRENCY_CHANGE_BUTTON = 'read_other_payments_invoice_currency_change_button',
  READ_COMPANY_SELECT_OWNER_EMAIL = 'read_company_select_owner_email',
  CHANGE_SELF_PAYMENTS_INVOICE_DUE_DATE = 'change_self_payments_invoice_due_date',
  CHANGE_OTHER_PAYMENTS_INVOICE_DUE_DATE = 'change_other_payments_invoice_due_date',
  CHANGE_SELF_PAYMENTS_INVOICE_ISSUE_DATE = 'change_self_payments_invoice_issue_date',
  CHANGE_OTHER_PAYMENTS_INVOICE_ISSUE_DATE = 'change_other_payments_invoice_issue_date',
  READ_PERFORMERS_PAYONEER_PAYOUTS_BLOCKED_FILTER = 'read_performers_payoneer_payouts_blocked_filter',
  READ_COMPANY_PROJECT_PROFORMA_NEW = 'read_company_project_proforma_new',
  READ_COMPANY_PAYMENTS_PROFORMA_NEW = 'read_company_payments_proforma_new',
  READ_SELF_COMPANY_CREATE_RECORD_BUTTON = 'read_self_company_create_record_button',
  READ_OTHER_COMPANY_CREATE_RECORD_BUTTON = 'read_other_company_create_record_button',
  CHANGE_SELF_COMPANY_CREATE_RECORD_MENTIONED_USER_IDS = 'change_self_company_create_record_mentioned_user_ids',
  CHANGE_OTHER_COMPANY_CREATE_RECORD_MENTIONED_USER_IDS = 'change_other_company_create_record_mentioned_user_ids',
  CHANGE_SELF_COMPANY_RECORD_CLIENT_ID = 'change_self_company_record_client_id',
  CHANGE_OTHER_COMPANY_RECORD_CLIENT_ID = 'change_other_company_record_client_id',
  CHANGE_SELF_COMPANY_RECORD_OPERATION_TYPE = 'change_self_company_record_operation_type',
  CHANGE_OTHER_COMPANY_RECORD_OPERATION_TYPE = 'change_other_company_record_operation_type',
  CHANGE_SELF_COMPANY_RECORD_SUBJECT = 'change_self_company_record_subject',
  CHANGE_OTHER_COMPANY_RECORD_SUBJECT = 'change_other_company_record_subject',
  CHANGE_SELF_COMPANY_RECORD_BODY = 'change_self_company_record_body',
  CHANGE_OTHER_COMPANY_RECORD_BODY = 'change_other_company_record_body',
  CHANGE_SELF_COMPANY_RECORD_DATE = 'change_self_company_record_date',
  CHANGE_OTHER_COMPANY_RECORD_DATE = 'change_other_company_record_date',
  CHANGE_SELF_COMPANY_RECORD_COMPLETE_DATETIME = 'change_self_company_record_complete_datetime',
  CHANGE_OTHER_COMPANY_RECORD_COMPLETE_DATETIME = 'change_other_company_record_complete_datetime',
  CHANGE_SELF_COMPANY_RECORD_STATE = 'change_self_company_record_state',
  CHANGE_OTHER_COMPANY_RECORD_STATE = 'change_other_company_record_state',
  CHANGE_SELF_COMPANY_RECORD_FILE_ATTACHMENT_IDS = 'change_self_company_record_file_attachment_ids',
  CHANGE_OTHER_COMPANY_RECORD_FILE_ATTACHMENT_IDS = 'change_other_company_record_file_attachment_ids',
  CHANGE_SELF_COMPANY_RECORD_ADD_TO_COMPANY_CHECKBOX = 'change_self_company_record_add_to_company_checkbox',
  CHANGE_OTHER_COMPANY_RECORD_ADD_TO_COMPANY_CHECKBOX = 'change_other_company_record_add_to_company_checkbox',
  CHANGE_SELF_COMPANY_RECORD_CREATE_COMPANY_RECORD_CHECKBOX = 'change_self_company_record_create_company_record_checkbox',
  CHANGE_OTHER_COMPANY_RECORD_CREATE_COMPANY_RECORD_CHECKBOX = 'change_other_company_record_create_company_record_checkbox',
  READ_SELF_COMPANY_CREATE_MY_RECORD_BUTTON = 'read_self_company_create_my_record_button',
  READ_OTHER_COMPANY_CREATE_MY_RECORD_BUTTON = 'read_other_company_create_my_record_button',
  READ_SELF_COMPANY_UPDATE_RECORD_BUTTON = 'read_self_company_update_record_button',
  READ_OTHER_COMPANY_UPDATE_RECORD_BUTTON = 'read_other_company_update_record_button',

  READ_SELF_COMPANY_AUTO_CHECK_IN_RULE_ADD_ITERATION_BUTTON = 'read_self_company_auto_check_in_rule_add_iteration_button',
  READ_SELF_COMPANY_AUTO_CHECK_IN_RULE_ADD_SHARE_BUTTON = 'read_self_company_auto_check_in_rule_add_share_button',
  READ_SELF_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_self_company_auto_check_in_rule_final_iteration_add_share_button',
  READ_SELF_COMPANY_AUTO_CHECK_IN_RULE_SHARES = 'read_self_company_auto_check_in_rule_shares',
  READ_SELF_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_SHARES = 'read_self_company_auto_check_in_rule_final_iteration_shares',

  READ_CLIENT_COMPANY_AUTO_CHECK_IN_RULE_ADD_ITERATION_BUTTON = 'read_client_company_auto_check_in_rule_add_iteration_button',
  READ_CLIENT_COMPANY_AUTO_CHECK_IN_RULE_ADD_SHARE_BUTTON = 'read_client_company_auto_check_in_rule_add_share_button',
  READ_CLIENT_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_client_company_auto_check_in_rule_final_iteration_add_share_button',
  READ_CLIENT_COMPANY_AUTO_CHECK_IN_RULE_SHARES = 'read_client_company_auto_check_in_rule_shares',
  READ_CLIENT_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_SHARES = 'read_client_company_auto_check_in_rule_final_iteration_shares',

  READ_WORKER_COMPANY_AUTO_CHECK_IN_RULE_ADD_ITERATION_BUTTON = 'read_worker_company_auto_check_in_rule_add_iteration_button',
  READ_WORKER_COMPANY_AUTO_CHECK_IN_RULE_ADD_SHARE_BUTTON = 'read_worker_company_auto_check_in_rule_add_share_button',
  READ_WORKER_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_worker_company_auto_check_in_rule_final_iteration_add_share_button',
  READ_WORKER_COMPANY_AUTO_CHECK_IN_RULE_SHARES = 'read_worker_company_auto_check_in_rule_shares',
  READ_WORKER_COMPANY_AUTO_CHECK_IN_RULE_FINAL_ITERATION_SHARES = 'read_worker_company_auto_check_in_rule_final_iteration_shares',
  READ_PROFORMA_NEW_OLD_PROFORMA_BUTTON = 'read_proforma_new_old_proforma_button',

  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_ADD_ITERATION_BUTTON = 'read_self_company_smart_contract_template_add_iteration_button',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_ADD_SHARE_BUTTON = 'read_self_company_smart_contract_template_add_share_button',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_self_company_smart_contract_template_final_iteration_add_share_button',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_SHARES = 'read_self_company_smart_contract_template_shares',
  READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATE_FINAL_ITERATION_SHARES = 'read_self_company_smart_contract_template_final_iteration_shares',

  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_ADD_ITERATION_BUTTON = 'read_other_company_smart_contract_template_add_iteration_button',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_ADD_SHARE_BUTTON = 'read_other_company_smart_contract_template_add_share_button',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_other_company_smart_contract_template_final_iteration_add_share_button',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_SHARES = 'read_other_company_smart_contract_template_shares',
  READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATE_FINAL_ITERATION_SHARES = 'read_other_company_smart_contract_template_final_iteration_shares',

  READ_COMPANY_SYSTEM_COMPANY_EVENT_TEMPLATE = 'read_company_system_company_event_template'
}
