import React, { ReactNode } from 'react';

import { useAuth } from '../../../auth/hooks/useAuth';

import { AuthAppLayout } from '../AuthAppLayout';
import { LoadingSkeleton } from '../../../helpers/LoadingSkeleton';

interface AuthAppLayoutWithCurrentUserProps {
  children: ReactNode;
}

function AuthAppLayoutWithCurrentUser({
  children
}: AuthAppLayoutWithCurrentUserProps) {
  const auth = useAuth();

  return (
    <AuthAppLayout>
      {auth?.authFetched && auth?.isAuthenticated ? (
        children
      ) : (
        <div
          id="main-section"
          className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
        >
          <LoadingSkeleton loaded={false} />
        </div>
      )}
    </AuthAppLayout>
  );
}

export default AuthAppLayoutWithCurrentUser;
