import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';

import {
  UpdateItemGqlQuery,
  UpdateItemCacheKeys
} from '../../../../../../types';

import { updateItem } from '../baseActions/updateItem';

import {
  ErrorType,
  parseRequestError
} from '../../../../../../utils/parseRequestError';

interface UpdateQueryOptions<UpdateQueryInput, ItemType> {
  action: string;
  query: UpdateItemGqlQuery;
  /**
   * @deprecated You should use cacheKeys instead
   */
  pluralScope?: string;
  /**
   * @deprecated You should use cacheKeys instead
   */
  scope?: string;
  cacheKeys?: UpdateItemCacheKeys;
  onOptimisticUpdate?: (input: UpdateQueryInput) => null | (() => void);
  onSuccess?: (responseRecord: ItemType) => void;
  refetchInactive?: boolean;
}

interface UpdateQueryContext {
  rollback?: () => void;
}

function useUpdateQuery<
  UpdateQueryInput,
  UpdateQueryResponse,
  UpdateQueryError extends ErrorType,
  ItemType
>({
  action,
  pluralScope,
  scope,
  query,
  cacheKeys,
  onOptimisticUpdate,
  onSuccess,
  refetchInactive = false
}: UpdateQueryOptions<UpdateQueryInput, ItemType>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateQueryResponse,
    UpdateQueryError,
    UpdateQueryInput,
    UpdateQueryContext
  >(
    (queryInput) =>
      updateItem<UpdateQueryInput, UpdateQueryResponse>({
        action,
        query,
        queryInput
      }),
    {
      onMutate: (input) => {
        const rollback = onOptimisticUpdate?.(input) || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSuccess: (response) => {
        onSuccess?.(response?.[action]?.record);
      },
      onSettled: async (response) => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey, { refetchInactive })
          );
        }

        if (pluralScope) {
          await queryClient.invalidateQueries(pluralScope);
        }

        if (scope && response?.[action]?.recordNanoId) {
          await queryClient.invalidateQueries([
            scope,
            response?.[action]?.recordNanoId
          ]);
        }

        if (scope && response?.[action]?.recordUuid) {
          await queryClient.invalidateQueries([
            scope,
            response?.[action]?.recordUuid
          ]);
        }
      }
    }
  );

  return {
    updateQueryData: data,
    updateQueryError: error,
    updateQueryLoading: isLoading,
    updateQueryErrorMessage: parseRequestError(error),
    updateQuery: mutateAsync,
    updateQueryReset: reset
  };
}

export default useUpdateQuery;
