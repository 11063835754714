import React, { ReactNode, useCallback } from 'react';

import { I18nText } from '../../../../types';

import { IconsEnum } from '../../../../assets/icons/types';
import { PureIconButtonHelperDefaultProps } from '../../../buttons/PureIconButtonHelper/PureIconButtonHelper';

import { ButtonHelper } from '../../../buttons/ButtonHelper';
import { PureIconButtonHelper } from '../../../buttons/PureIconButtonHelper';
import { Translate } from '../../../Translate';

interface TabButtonProps extends PureIconButtonHelperDefaultProps {
  badge?: ReactNode;
  data: string;
  i18nText: I18nText;
  icon: IconsEnum | null;
  onTabChange: (data: string) => void;
}

function TabButton({
  badge,
  className,
  data,
  disabled,
  i18nText,
  icon,
  onTabChange
}: TabButtonProps) {
  const handleOnClick = useCallback(
    () => onTabChange(data),
    [data, onTabChange]
  );

  if (badge) {
    return (
      <ButtonHelper
        blurOnMouseLeave
        className={className}
        disabled={disabled}
        onClick={handleOnClick}
      >
        <Translate id={i18nText} />
        {badge}
      </ButtonHelper>
    );
  }

  return (
    <PureIconButtonHelper
      blurOnMouseLeave
      className={className}
      disabled={disabled}
      i18nText={i18nText}
      icon={icon}
      onClick={handleOnClick}
    />
  );
}

export default TabButton;
