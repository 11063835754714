import { BillingInfoNanoID } from './billingInfosTypes';

const baseUrl = 'billing_infos';

export class BillingInfoBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static delete(billingInfoNanoID: BillingInfoNanoID) {
    return `${baseUrl}/${billingInfoNanoID}`;
  }

  static update(billingInfoNanoID: BillingInfoNanoID) {
    return `${baseUrl}/${billingInfoNanoID}`;
  }

  static makeDefault(billingInfoNanoID: BillingInfoNanoID) {
    return `${baseUrl}/${billingInfoNanoID}/make_default`;
  }

  static makeWireDefault(billingInfoNanoID: BillingInfoNanoID) {
    return `${baseUrl}/${billingInfoNanoID}/make_wire_default`;
  }

  static makeCardDefault(billingInfoNanoID: BillingInfoNanoID) {
    return `${baseUrl}/${billingInfoNanoID}/make_card_default`;
  }
}
