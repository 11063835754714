const paymentsSmartContractsBasePath = '/payments/smart-contracts';

export class SmartContractPath {
  static paymentsSmartContracts() {
    return paymentsSmartContractsBasePath;
  }

  static paymentsSmartContractsDraftFilter() {
    return `${paymentsSmartContractsBasePath}/filters/draft`;
  }

  static paymentsSmartContractsCanceledFilter() {
    return `${paymentsSmartContractsBasePath}/filters/canceled`;
  }
}
