import { AppCrmNames } from '../../../../types';

import { StaticImageHelper } from '../../../../helpers/StaticImageHelper';

import archivizerLogoColorSolidWithTextBlack from '../../../../assets/images/img/archivizer-logo_color-solid-with-text-black.svg';
import archivizerLogoColorSolidWithTextWhite from '../../../../assets/images/img/archivizer-logo_color-solid-with-text-white.svg';
import marketThriveLogo from '../../../../assets/images/img/mt_crm_long1.svg';

import { APP_CRM_NAME } from '../../../../config';

function AuthHeader() {
  return (
    <div className="h-16 flex items-center px-8 py-4 border-b dark:border-gray-700 bg-gray-100 dark:bg-gray-850">
      {APP_CRM_NAME === AppCrmNames.MARKET_THRIVE ? (
        <StaticImageHelper
          className="h-12 block dark:bg-white"
          src={marketThriveLogo}
          alt="MarketThrive logo"
        />
      ) : (
        <>
          <StaticImageHelper
            className="h-8 hidden dark:block"
            src={archivizerLogoColorSolidWithTextWhite}
            alt="Archivizer logo"
          />

          <StaticImageHelper
            className="h-8 block dark:hidden"
            src={archivizerLogoColorSolidWithTextBlack}
            alt="Archivizer logo"
          />
        </>
      )}
    </div>
  );
}

export default AuthHeader;
