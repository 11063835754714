import { components, MultiValueProps } from 'react-select';
import has from 'lodash/has';

import { MultiSelectExtendedDataType } from '../../types';

import { ValueImage } from '../ValueImage';

function MultiValue({
  children,
  ...props
}: MultiValueProps<MultiSelectExtendedDataType>) {
  return (
    <components.MultiValue {...props}>
      {has(props.data, 'image') ? (
        <div className="flex items-center relative">
          <ValueImage
            image={props.data.image}
            label={props.data.label as string}
            className="absolute left-0 rounded-full h-6 w-6"
          />

          <span className="pl-8">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.MultiValue>
  );
}

export default MultiValue;
