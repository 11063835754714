import { FetchCategoriesSortTypes } from './categoriesTypes';

export const INITIAL_CATEGORIES_FILTERS = {};
export const INITIAL_CATEGORIES_PAGE = 1;
export const INITIAL_CATEGORIES_SORT = [
  FetchCategoriesSortTypes.CREATED_AT_DESC
];
export const INITIAL_CATEGORIES_LIMIT = 50;

export enum CategoriesPermissions {
  READ_CATEGORIES_INDEX_PAGE = 'read_categories_index_page',
  READ_CATEGORIES_SEARCH_FILTERS_POPOVER = 'read_categories_search_filters_popover'
}
