import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import { formatters } from './services';

import en from '../../locales/en';
import ru from '../../locales/ru';
import uk from '../../locales/uk';
import each from 'lodash/each';

const EN_LANG = 'en';

const DEFAULT_LANG = EN_LANG;

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      ru: {
        translation: ru
      },
      uk: {
        translation: uk
      }
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['htmlTag'],
      caches: []
    }
  });

each(formatters, (formatter, key) => {
  i18n.services.formatter.add(key, formatter);
});

export default i18n;
