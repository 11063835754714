import React, { createContext, ReactNode, useContext } from 'react';

import { TeamNanoID } from '../../../main/teams/teamsTypes';

interface ThirdPersonViewContextType {
  isThirdPersonView: boolean;
  companyNanoId: TeamNanoID | null;
}

const ThirdPersonViewContext = createContext<ThirdPersonViewContextType>({
  isThirdPersonView: false,
  companyNanoId: null
});

interface ThirdPersonViewProviderProps {
  children: ReactNode;
  companyNanoId: TeamNanoID;
}

export function ThirdPersonViewProvider({
  children,
  companyNanoId
}: ThirdPersonViewProviderProps) {
  const isThirdPersonView = !!companyNanoId;

  const thirdPersonViewContext = {
    isThirdPersonView,
    companyNanoId
  };

  return (
    <ThirdPersonViewContext.Provider value={thirdPersonViewContext}>
      {children}
    </ThirdPersonViewContext.Provider>
  );
}

export const useThirdPersonView = () => {
  return useContext(ThirdPersonViewContext);
};
