import { IconProps } from '../types';

function RocketSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M17.7 2c-3.2-.1-6.7 1.6-9 4.1-2.1.1-4.2.9-5.8 2.5-.1.1-.1.2 0 .3 0 .1.1.2.2.2l2.6.4-.3.4c-.1.1-.1.3 0 .5l4.3 4.3c.1.1.1.1.2.1s.2 0 .2-.1l.4-.3.4 2.6c0 .1.1.2.2.3h.1c.1 0 .2 0 .3-.1 1.5-1.5 2.4-3.6 2.4-5.8 2.5-2.4 4.2-6 4.1-9.1 0-.2-.2-.3-.3-.3zm-3.1 5.8c-.3.3-.8.5-1.2.5s-.9-.2-1.2-.5c-.6-.6-.6-1.7 0-2.4.6-.6 1.7-.6 2.4 0s.6 1.7 0 2.4zM3.8 13.3c-.7.7-1.7 3.9-1.8 4.3 0 .1 0 .2.1.3.1.1.1.1.2.1h.1c.4-.1 3.6-1.1 4.3-1.8.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0z" />
    </svg>
  );
}

export default RocketSolidIcon;
