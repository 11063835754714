import { gql } from 'graphql-request';

export const FETCH_MESSAGES_TOTAL_COUNT_QUERY = gql`
  query MessagesTotalCountQuery($messagesFilters: MessagesFilters) {
    messages(filters: $messagesFilters) {
      paginationInfo {
        totalCount
      }
    }
  }
`;
