import { IconProps } from '../types';

function ViewListIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-6 w-6'}
      fill="none"
      id={id}
      stroke="currentColor"
      strokeWidth={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 6h16M4 10h16M4 14h16M4 18h16"
      />
    </svg>
  );
}

export default ViewListIcon;
