export default {
  account: 'Account',
  accountingTransactions: 'Accounting Transactions',
  accountType: 'Account type',
  accountTypeChartNumber: 'Account Type Chart Number',
  accountTypeChartNumbers: 'Account Type Chart Numbers',
  itcId: 'Itc id',
  incomeStatementRecordType: 'Income statement record type',
  amount: 'Amount',
  sumSide: 'Sum side',
  balance: 'Balance',
  credit: 'Credit',
  date: 'Date',
  debit: 'Debit',
  event: 'Event',
  id: 'Id',
  journalEntryId: 'Journal Entry Id',
  journalRecordId: 'Journal Record Id',
  side: 'Side',
  invoice: 'Invoice',
  plural: 'Journal records',
  import: 'Import',
  importTransactions: 'Import transactions',
  incomeStatementPeriod: 'Income statement period',
  searchTransactions: 'Search transactions',
  searchByCompanyNanoId: 'Search by Company NanoId',
  searchTransactionById: 'Search Transaction by id',
  searchByInvoiceId: 'Search by Invoice id',
  searchByAccountTypeNanoId: 'Search by account type NanoId',
  searchByTaskId: 'Search by Task id',
  searchByProjectId: 'Search by Project Id',
  searchByIncomeStatementRecordType: 'Search by income statement record type',
  searchByUserNanoId: 'Search by User NanoId',
  selectAccountTypeChartNumbers: 'Select Account Type Chart Numbers',
  transactions: 'Transactions',
  transactionId: 'Transaction Id',
  transactionsImports: 'Transactions Imports',
  transactionEventName: 'Event Name',
  transactionsPeriod: 'Transactions period'
};
