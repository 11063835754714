import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface LifestylesKeyType {
  addLifestyle: LocalesKey;
  all: LocalesKey;
  associatedAssets: LocalesKey;
  associatedScenes: LocalesKey;
  attach: LocalesKey;
  attachLifestyle: LocalesKey;
  attachSelected: LocalesKey;
  categories: {
    bedroom: LocalesKey;
    commercialAreas: LocalesKey;
    dining: LocalesKey;
    exteriors: LocalesKey;
    livingApartments: LocalesKey;
    sanitaryFacilities: LocalesKey;
    transitAreas: LocalesKey;
  };
  category: LocalesKey;
  deleteLifestyle: LocalesKey;
  deleteWarning: LocalesKey;
  download: LocalesKey;
  editLifestyle: LocalesKey;
  my: LocalesKey;
  name: LocalesKey;
  plural: LocalesKey;
  projectName: LocalesKey;
  search: LocalesKey;
  selected: LocalesKey;
  singular: LocalesKey;
  threeDStock: LocalesKey;
  timeHours: LocalesKey;
  type: LocalesKey;
  viewAll: LocalesKey;
}

export const lifestylesKeys = keyPathMirror<LifestylesKeyType, string>(
  {
    addLifestyle: null,
    all: null,
    associatedAssets: null,
    associatedScenes: null,
    attach: null,
    attachLifestyle: null,
    attachSelected: null,
    categories: {
      bedroom: null,
      commercialAreas: null,
      dining: null,
      exteriors: null,
      livingApartments: null,
      sanitaryFacilities: null,
      transitAreas: null
    },
    category: null,
    deleteLifestyle: null,
    deleteWarning: null,
    download: null,
    editLifestyle: null,
    my: null,
    name: null,
    plural: null,
    projectName: null,
    search: null,
    selected: null,
    singular: null,
    threeDStock: null,
    timeHours: null,
    type: null,
    viewAll: null
  },
  'models.lifestyles.'
);
