import startsWith from 'lodash/startsWith';

import { TeamNanoID } from '../../../../teams/teamsTypes';
import { CompactCompanyLibraryMenuItems } from './CompactCompanyLibraryMenu.types';
import { PrimaryNavTabTooltipItem } from '../../../../../app/components/menus/PrimaryNav/helpers/PrimaryNavTooltipMenuTab/PrimaryNavTooltipMenuTab.types';

import { CommonPermissions } from '../../../commonConstants';
import { TeamPath } from '../../../../teams/TeamPath';

import {
  lifestylesKeys,
  materialsKeys,
  productsKeys
} from '../../../../../locales/keys';

export const selfCompanyLibraryMenuLinks: PrimaryNavTabTooltipItem[] = [
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.currentCompanyLibraryProducts()),
    name: CompactCompanyLibraryMenuItems.PRODUCTS,
    href: TeamPath.currentCompanyLibraryProducts(),
    i18nText: productsKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_PRODUCTS_LINK
    ]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.currentCompanyLibraryLifestyles()),
    name: CompactCompanyLibraryMenuItems.LIFESTYLES,
    href: TeamPath.currentCompanyLibraryLifestyles(),
    i18nText: lifestylesKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_LIFESTYLES_LINK
    ]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.currentCompanyLibraryMaterials()),
    name: CompactCompanyLibraryMenuItems.MATERIALS,
    href: TeamPath.currentCompanyLibraryMaterials(),
    i18nText: materialsKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_MATERIALS_LINK
    ]
  }
];

export const companyLibraryMenuLinks = (
  companyNanoId: TeamNanoID
): PrimaryNavTabTooltipItem[] => [
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.companyLibraryProducts(companyNanoId)),
    name: CompactCompanyLibraryMenuItems.PRODUCTS,
    href: TeamPath.companyLibraryProducts(companyNanoId),
    i18nText: productsKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_PRODUCTS_LINK
    ]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.companyLibraryLifestyles(companyNanoId)),
    name: CompactCompanyLibraryMenuItems.LIFESTYLES,
    href: TeamPath.companyLibraryLifestyles(companyNanoId),
    i18nText: lifestylesKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_LIFESTYLES_LINK
    ]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.companyLibraryMaterials(companyNanoId)),
    name: CompactCompanyLibraryMenuItems.MATERIALS,
    href: TeamPath.companyLibraryMaterials(companyNanoId),
    i18nText: materialsKeys.plural,
    permissions: [
      CommonPermissions.READ_COMPACT_SELF_COMPANY_LIBRARY_MENU_MATERIALS_LINK
    ]
  }
];
