import React, { memo, Fragment } from 'react';

import { I18nText } from '../../types';

import { useTranslate } from '../../common/hooks/useTranslate';

interface TranslateProps extends Record<string, unknown> {
  id: I18nText;
}

function Translate({ id, ...options }: TranslateProps) {
  const { t } = useTranslate();

  if (id) {
    return <Fragment>{t(id, options)}</Fragment>;
  }

  return null;
}

export default memo<TranslateProps>(Translate);
