import compact from 'lodash/compact';

import { ExtendedFiltersGroups } from '../../useExtendedFilters.types';

import { mergeFiltersByPriority } from '../mergeFiltersByPriority';

function extendedFiltersMergeGroups<T>(
  filtersState?: Partial<Record<ExtendedFiltersGroups, T>>
): T {
  return mergeFiltersByPriority(
    compact([
      filtersState?.[ExtendedFiltersGroups.PAGE],
      filtersState?.[ExtendedFiltersGroups.TAB],
      filtersState?.[ExtendedFiltersGroups.SETTINGS],
      filtersState?.[ExtendedFiltersGroups.SELECTED]
    ])
  );
}

export default extendedFiltersMergeGroups;
