import { AccountNanoID } from './accountsTypes';

const basePath = '/accounts';
const paymentsBalanceSheetBasePath = '/payments/balance-sheet';

export class AccountPath {
  static show(accountNanoId: AccountNanoID) {
    return `${basePath}/${accountNanoId}`;
  }

  static paymentsBalanceSheet() {
    return paymentsBalanceSheetBasePath;
  }

  static accountBalances(accountNanoId: AccountNanoID) {
    return `${basePath}/${accountNanoId}/account-balances`;
  }
}
