import { useCallback, useState } from 'react';

import { PageNanoID } from '../../../../main/pages/pagesTypes';

import { UserTermsModalBody } from './components/UserTermsModalBody';

import { SimpleModalButton } from '../../../../helpers/buttons/SimpleModalButton';
import { TabsHelper } from '../../../../helpers/TabsHelper';

import { words } from '../../../../locales/keys';
import {
  PAYMENTS_PAGE_NANOID,
  PRIVACY_PAGE_NANOID,
  REFUND_PAGE_NANOID
} from '../../../../config';
import { ClassName, I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

const enum TabsEnum {
  PAYMENT_TERMS = 'paymentTerms',
  TERMS_OF_SERVICE = 'termsOfService',
  PRIVACY_POLICY = 'privacyPolicy'
}

const tabs = [
  {
    value: TabsEnum.PAYMENT_TERMS,
    i18nText: words.paymentTerms,
    icon: null,
    tooltipI18nText: words.paymentTerms
  },
  {
    value: TabsEnum.TERMS_OF_SERVICE,
    i18nText: words.termsOfService,
    icon: null,
    tooltipI18nText: words.termsOfService
  },
  {
    value: TabsEnum.PRIVACY_POLICY,
    i18nText: words.privacyPolicy,
    icon: null,
    tooltipI18nText: words.privacyPolicy
  }
];

const tabPage = {
  [TabsEnum.PAYMENT_TERMS]: PAYMENTS_PAGE_NANOID,
  [TabsEnum.PRIVACY_POLICY]: PRIVACY_PAGE_NANOID,
  [TabsEnum.TERMS_OF_SERVICE]: REFUND_PAGE_NANOID
};

interface UserTermsModalButtonProps {
  i18nText: I18nText;
  className?: ClassName;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function UserTermsModalButton({
  i18nText,
  className,
  i18nTextClassName,
  icon,
  iconClassName
}: UserTermsModalButtonProps) {
  const [openedTab, setOpenedTab] = useState<TabsEnum>(TabsEnum.PAYMENT_TERMS);

  const handleTabChange = useCallback<(tab: TabsEnum) => void>(
    (tab) => setOpenedTab(tab),
    [setOpenedTab]
  );

  const resetOpenedTab = useCallback<() => void>(() => {
    if (openedTab !== TabsEnum.PAYMENT_TERMS) {
      setOpenedTab(TabsEnum.PAYMENT_TERMS);
    }
  }, [openedTab]);

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      i18nTitle={words.terms}
      icon={icon}
      iconClassName={iconClassName}
      withCloseButton
      withoutCancelButton
      withoutSubmitButton
      onClose={resetOpenedTab}
      withoutInitialFocus
    >
      <div className="space-y-6 mt-2">
        <TabsHelper
          data={tabs}
          defaultTab={tabs[0].value}
          onChange={handleTabChange}
          withoutPermissions
        />
        <UserTermsModalBody pageNanoId={tabPage[openedTab] as PageNanoID} />
      </div>
    </SimpleModalButton>
  );
}

export default UserTermsModalButton;
