import { useMemo } from 'react';
import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';
import mapValues from 'lodash/mapValues';

import { CompactPaymentsSecondaryMenuLinksConfig } from './useCompactCompanyPaymentsSecondaryMenu.types';
import { CompanyNanoID } from '../../../../../../companies/companiesTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { getPaymentsActiveMenu } from '../../../../../../common/components/menus/CompactPaymentsSecondaryMenu/utils/getPaymentsActiveMenu';
import { TeamPath } from '../../../../../TeamPath';

import {
  accountsKeys,
  invoicesKeys,
  journalRecordsKeys,
  smartContractsKeys,
  words
} from '../../../../../../../locales/keys';
import { TeamsPermissions } from '../../../../../teamsConstants';
import map from 'lodash/map';

interface CompactCompanyPaymentsSecondaryMenuOptions {
  companyNanoId: CompanyNanoID;
  isSelf?: boolean;
}

function useCompactCompanyPaymentsSecondaryMenu({
  companyNanoId,
  isSelf
}: CompactCompanyPaymentsSecondaryMenuOptions) {
  const { pathname, asPath } = useRouter();

  const config = useMemo<CompactPaymentsSecondaryMenuLinksConfig>(
    () => ({
      invoices: [
        {
          action: TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_LINK,
          active:
            pathname === TeamPath.companyPaymentsInvoices(companyNanoId) ||
            pathname ===
              `${TeamPath.companyPaymentsInvoices(
                '[slug]' as CompanyNanoID
              )}/[invoiceSlug]`,
          href: TeamPath.companyPaymentsInvoices(companyNanoId),
          i18nText: invoicesKeys.allInvoices,
          icon: IconsEnum.VIEW_LIST
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_SENT_LINK,
          active: startsWith(
            asPath,
            TeamPath.companyPaymentsInvoicesSentFilter(companyNanoId)
          ),
          href: TeamPath.companyPaymentsInvoicesSentFilter(companyNanoId),
          i18nText: words.sent,
          icon: IconsEnum.TICKET_SOLID
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_DRAFT_LINK,
          active: startsWith(
            asPath,
            TeamPath.companyPaymentsInvoicesDraftFilter(companyNanoId)
          ),
          href: TeamPath.companyPaymentsInvoicesDraftFilter(companyNanoId),
          i18nText: words.draft,
          icon: IconsEnum.PENCIL_ALT_SOLID
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_PAID_LINK,
          active: startsWith(
            asPath,
            TeamPath.companyPaymentsInvoicesPaidFilter(companyNanoId)
          ),
          href: TeamPath.companyPaymentsInvoicesPaidFilter(companyNanoId),
          i18nText: words.paid,
          icon: IconsEnum.CHECK
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_CANCELED_LINK,
          active: startsWith(
            asPath,
            TeamPath.companyPaymentsInvoicesCanceledFilter(companyNanoId)
          ),
          href: TeamPath.companyPaymentsInvoicesCanceledFilter(companyNanoId),
          i18nText: words.cancel,
          icon: IconsEnum.RECEIPT_REFUND
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_INVOICES_REFUNDS_LINK,
          active: startsWith(
            asPath,
            TeamPath.companyPaymentsInvoicesRefundsFilter(companyNanoId)
          ),
          href: TeamPath.companyPaymentsInvoicesRefundsFilter(companyNanoId),
          i18nText: words.refunds,
          icon: IconsEnum.ARCHIVE_BOX_X_MARK
        }
      ],
      performerInvoices: [
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_CURRENT_CONTRACTOR_INVOICES_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_CURRENT_CONTRACTOR_INVOICES_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsContractorInvoicesCurrent(companyNanoId),
          href: TeamPath.companyPaymentsContractorInvoicesCurrent(
            companyNanoId
          ),
          i18nText: words.current,
          icon: IconsEnum.EXCLAMATION_CIRCLE
        },
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_OPEN_CONTRACTOR_INVOICES_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_OPEN_CONTRACTOR_INVOICES_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsContractorInvoicesOpen(companyNanoId),
          href: TeamPath.companyPaymentsContractorInvoicesOpen(companyNanoId),
          i18nText: words.open,
          icon: IconsEnum.BOOK_OPEN_SOLID
        },
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_SENT_CONTRACTOR_INVOICES_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_SENT_CONTRACTOR_INVOICES_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsContractorInvoicesSent(companyNanoId),
          href: TeamPath.companyPaymentsContractorInvoicesSent(companyNanoId),
          i18nText: words.sent,
          icon: IconsEnum.TICKET_SOLID
        },
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_OVERDUE_CONTRACTOR_INVOICES_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_OVERDUE_CONTRACTOR_INVOICES_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsContractorInvoicesOverdue(companyNanoId),
          href: TeamPath.companyPaymentsContractorInvoicesOverdue(
            companyNanoId
          ),
          i18nText: words.overdue,
          icon: IconsEnum.FIRE_SOLID
        },
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsContractorInvoices(companyNanoId),
          href: TeamPath.companyPaymentsContractorInvoices(companyNanoId),
          i18nText: invoicesKeys.allInvoices,
          icon: IconsEnum.VIEW_LIST
        }
      ],
      generalLedgers: [
        {
          action: isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_PAYMENTS_MENU_BALANCE_SHEET_LINK
            : TeamsPermissions.READ_OTHER_COMPANY_PAYMENTS_MENU_BALANCE_SHEET_LINK,
          active:
            asPath === TeamPath.companyPaymentsBalanceSheet(companyNanoId),
          href: TeamPath.companyPaymentsBalanceSheet(companyNanoId),
          i18nText: accountsKeys.balanceSheet,
          icon: IconsEnum.LIST_BULLET_ALT
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_JOURNAL_RECORDS_LINK,
          active:
            asPath === TeamPath.companyPaymentsTransactions(companyNanoId),
          href: TeamPath.companyPaymentsTransactions(companyNanoId),
          i18nText: journalRecordsKeys.transactions,
          icon: IconsEnum.SWITCH_HORIZONTAL_SOLID
        }
      ],
      smartContracts: [
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_LINK,
          active:
            asPath === TeamPath.companyPaymentsSmartContracts(companyNanoId),
          href: TeamPath.companyPaymentsSmartContracts(companyNanoId),
          i18nText: smartContractsKeys.plural,
          icon: IconsEnum.VIEW_LIST_SOLID
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_DRAFT_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsSmartContractsDraftFilter(companyNanoId),
          href: TeamPath.companyPaymentsSmartContractsDraftFilter(
            companyNanoId
          ),
          i18nText: words.draft,
          icon: IconsEnum.PENCIL_ALT_SOLID
        },
        {
          action:
            TeamsPermissions.READ_COMPANY_PAYMENTS_MENU_SMART_CONTRACTS_CANCELED_LINK,
          active:
            asPath ===
            TeamPath.companyPaymentsSmartContractsCanceledFilter(companyNanoId),
          href: TeamPath.companyPaymentsSmartContractsCanceledFilter(
            companyNanoId
          ),
          i18nText: words.canceled,
          icon: IconsEnum.ARCHIVE_BOX_X_MARK
        }
      ]
    }),
    [asPath, companyNanoId, isSelf, pathname]
  );

  const activeMenu = getPaymentsActiveMenu(pathname);

  return {
    activeMenu,
    config,
    permissions: mapValues(config, (item) => map(item, 'action'))
  };
}

export default useCompactCompanyPaymentsSecondaryMenu;
