import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
  useEffect
} from 'react';
import { useRouter } from 'next/router';
import isBoolean from 'lodash/isBoolean';

import { PrimaryNavTabsEnum } from './types';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useThirdPersonView } from '../useThirdPersonView';

import { getCurrentPathTab } from './utils/getCurrentPathTab';
import { LocalForage } from '../../../utils/LocalForage';

const MenuContext = createContext({
  currentPathTab: null as PrimaryNavTabsEnum | null,
  isSecondaryMenuOpen: false,
  isSecondaryMenuKeepOpen: true,
  hasSecondarySidebar: true,
  openSecondaryMenu: () => {
    console.log('error: openSecondaryMenu should be initialized');
  },
  closeSecondaryMenu: () => {
    console.log('error: closeSecondaryMenu should be initialized');
  },
  toggleSecondaryMenu: () => {
    console.log('error: toggleSecondaryMenu should be initialized');
  },
  toggleKeepSecondaryMenu: () => {
    console.log('error: toggleKeepSecondaryMenu should be initialized');
  },
  onMountSecondarySidebar: () => {
    console.log('error: onMountSecondarySidebar should be initialized');
  },
  onUnmountSecondarySidebar: () => {
    console.log('error: onUnmountSecondarySidebar should be initialized');
  }
});

interface MenuProviderProps {
  children: ReactNode;
  userNanoId?: UserNanoID;
}

type SecondaryMenuKeepOpenType = boolean;

const localForageKey = 'secondary-menu-keep-open';

export function MenuProvider({ children, userNanoId }: MenuProviderProps) {
  const { asPath } = useRouter();
  const { companyNanoId } = useThirdPersonView();

  const currentPathTab: PrimaryNavTabsEnum | null = getCurrentPathTab({
    pathname: asPath,
    companyViewCompanyNanoId: companyNanoId,
    companyViewUserNanoId: userNanoId
  });

  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] =
    useState<boolean>(false);
  const openSecondaryMenu = useCallback<() => void>(() => {
    setIsSecondaryMenuOpen(true);
  }, [setIsSecondaryMenuOpen]);
  const closeSecondaryMenu = useCallback<() => void>(() => {
    setIsSecondaryMenuOpen(false);
  }, [setIsSecondaryMenuOpen]);
  const toggleSecondaryMenu = useCallback<() => void>(() => {
    setIsSecondaryMenuOpen((prev) => !prev);
  }, [setIsSecondaryMenuOpen]);

  const [isSecondaryMenuKeepOpen, setIsSecondaryMenuKeepOpen] =
    useState<SecondaryMenuKeepOpenType>(true);

  useEffect(() => {
    LocalForage.getItem<SecondaryMenuKeepOpenType>(localForageKey).then(
      (value) => {
        if (isBoolean(value)) {
          setIsSecondaryMenuKeepOpen(value);
        }
      }
    );
  }, []);

  const toggleKeepSecondaryMenu = useCallback<() => void>(() => {
    setIsSecondaryMenuKeepOpen((prev) => {
      if (prev) {
        closeSecondaryMenu();
        LocalForage.setItem<SecondaryMenuKeepOpenType>(localForageKey, false);
      } else if (!prev) {
        LocalForage.setItem<SecondaryMenuKeepOpenType>(localForageKey, true);
      }
      return !prev;
    });
  }, [setIsSecondaryMenuKeepOpen, closeSecondaryMenu]);

  const [hasSecondarySidebar, setHasSecondarySidebar] = useState(false);
  const onMountSecondarySidebar = useCallback<() => void>(() => {
    setHasSecondarySidebar(true);
  }, [setHasSecondarySidebar]);
  const onUnmountSecondarySidebar = useCallback<() => void>(() => {
    setHasSecondarySidebar(false);
  }, [setHasSecondarySidebar]);

  const authContext = {
    currentPathTab,
    isSecondaryMenuOpen,
    isSecondaryMenuKeepOpen,
    hasSecondarySidebar,
    openSecondaryMenu,
    closeSecondaryMenu,
    toggleSecondaryMenu,
    toggleKeepSecondaryMenu,
    onMountSecondarySidebar,
    onUnmountSecondarySidebar
  };

  return (
    <MenuContext.Provider value={authContext}>{children}</MenuContext.Provider>
  );
}

export const useMenu = () => {
  return useContext(MenuContext);
};
