import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

interface StringsType {
  acceptTaskResult: LocalesKey;
  addYourCommentHere: LocalesKey;
  accordingToTheRulesForRoundingContractorInvoiceAmountsUpTo001TheAmountMayDifferFromThePaidByAFewCents: LocalesKey;
  alreadyAdded: LocalesKey;
  applyToAll: LocalesKey;
  approveTaskResult: LocalesKey;
  areYouSureThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToBlockThisAccount: LocalesKey;
  areYouSureYouWantToBlockThisCompany: LocalesKey;
  areYouSureYouWantToCancelTheTask: LocalesKey;
  areYouSureYouWantToClearAllFormFields: LocalesKey;
  areYouSureYouWantToClearSelected: LocalesKey;
  areYouSureYouWantToCreateMissingWhiteboardShapes: LocalesKey;
  areYouSureYouWantToDeletTheBillingAddress: LocalesKey;
  areYouSureYouWantToDeleteAccount: LocalesKey;
  areYouSureYouWantToDeleteThisAccount: LocalesKey;
  areYouSureYouWantToDeleteThisThread: LocalesKey;
  areYouSureYouWantToDeleteThisTemplate: LocalesKey;
  areYouSureYouWantToDeleteThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: LocalesKey;
  areYouSureYouWantToDeleteThisTransactionImports: LocalesKey;
  areYouSureYouWantToDeletTheField: LocalesKey;
  areYouSureYouWantToRemoveThisUserFromCompany: LocalesKey;
  areYouSureYouWantToRemoveItemsFromDraft: LocalesKey;
  areYouSureYouWantToUnblockThisAccount: LocalesKey;
  areYouSureYouWantToReplaceActiveWhiteboardWithBackup: LocalesKey;
  areYouSureYouWantToDeleteSavedFilterThisActionCannotBeUndone: LocalesKey;
  atLeastOneTaskMustBeEntered: LocalesKey;
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: LocalesKey;
  byRatingTasksYouAcceptTaskResultsForTheClient: LocalesKey;
  changeVisibility: LocalesKey;
  clearFilters: LocalesKey;
  convertFromMarkdown: LocalesKey;
  deleteThread: LocalesKey;
  dragAndDropFilesOr: LocalesKey;
  dropTheFilesHere: LocalesKey;
  errorLoadingImage: LocalesKey;
  fileIsUploading: LocalesKey;
  filterByDate: LocalesKey;
  helpWithTextFormatting: LocalesKey;
  hiddenAnd: LocalesKey;
  hereIsTheExampleOfWhatYoullGet: LocalesKey;
  learnAboutItemTypes: LocalesKey;
  letsGetToKnowEachOther: LocalesKey;
  iConfirmThatIHaveReadAndAgreeTo: LocalesKey;
  inviteYourTeamMembers: LocalesKey;
  ifYouAreUnsureWhoToInviteNowYouCanSkip: LocalesKey;
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly: LocalesKey;
  indicateHowMuchDoYouAgreeWithTheFollowingStatement: LocalesKey;
  iHaveReadAndAgreeToTheUpdatedInformation: LocalesKey;
  linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc: LocalesKey;
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: LocalesKey;
  orChangeTableSettingsToAlwaysShowHiddenElements: LocalesKey;
  pleaseClickOnStarsToRateTheResult: LocalesKey;
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly: LocalesKey;
  pleaseProvideYourAddress: LocalesKey;
  pleaseRateResults: LocalesKey;
  pleaseRateTaskRusult: LocalesKey;
  pleaseRateTheTask: LocalesKey;
  pleaseWaitYourDownloadWillBeReadyInSeveralMinutes: LocalesKey;
  pleaseAdjustYourFiltersAndTryAgainOr: LocalesKey;
  previewTextFormatting: LocalesKey;
  selectInvoiceFromTheList: LocalesKey;
  sendEmailNotification: LocalesKey;
  sendToWhiteboard: LocalesKey;
  noItemsMatchYourSearchCriteria: LocalesKey;
  startTypingToSearchForClients: LocalesKey;
  textFormattingHelp: LocalesKey;
  thePaymentHasBeenAcceptedForProcessingUsually: LocalesKey;
  thePaymentHasBeenCanceled: LocalesKey;
  theTaskResultHasBeenApproved: LocalesKey;
  toSetUpTheInterfaceForTheRightWorkflow: LocalesKey;
  thereAreNoNonValidTasks: LocalesKey;
  thereAreNoValidTasks: LocalesKey;
  thisActionWillAffect: LocalesKey;
  thisCommentWouldBeVisibleOnlyInTimeline: LocalesKey;
  thereIsNoBillingInfoInTheCompany: LocalesKey;
  updateCost: LocalesKey;
  updateDueDate: LocalesKey;
  weAreOnline: LocalesKey;
  weAreReadyToHelp: LocalesKey;
  whiteboardDataHasBeenUpdatedPleaseReloadThePage: LocalesKey;
  writeHereWarning: LocalesKey;
  youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed: LocalesKey;
  youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask: LocalesKey;
  youWillBeRedirectedToThePaymentsPageIn: LocalesKey;
  youWillBeRedirectedToTheProjectPageIn: LocalesKey;
  letsCreateYourFirstProject: LocalesKey;
  letsDiveIn: LocalesKey;
  startByOutliningYourProjectVisionBe: LocalesKey;
  ourExpertTeamWillWorkCloselyWith: LocalesKey;
  hereIsABriefOfOurServicesAndPricesNo: LocalesKey;
  unsureClickStartProjectAnd: LocalesKey;
  asTeamMemberOf: LocalesKey;
  generateApplicationToken1: LocalesKey;
  generateApplicationToken2: LocalesKey;
  refreshAccessToken: LocalesKey;
  reverseChargeForServices: LocalesKey;
  yourPaymentIsBeingProcessedBeAdvisedThat: LocalesKey;
  uploadASinglePhotoOfTheRoomAndOurAIWill: LocalesKey;
  selectTheObjectYouWantToSearchInOur3DLibrary: LocalesKey;
  theFunctionalityIsNotAvailableForSearchWithAI: LocalesKey;
  uploadAPhotoOfARoomSelectACategory: LocalesKey;
}

export const stringsKeys = keyPathMirror<StringsType, string>(
  {
    acceptTaskResult: null,
    addYourCommentHere: null,
    accordingToTheRulesForRoundingContractorInvoiceAmountsUpTo001TheAmountMayDifferFromThePaidByAFewCents:
      null,
    alreadyAdded: null,
    applyToAll: null,
    approveTaskResult: null,
    areYouSureThisActionCannotBeUndone: null,
    areYouSureYouWantToBlockThisAccount: null,
    areYouSureYouWantToBlockThisCompany: null,
    areYouSureYouWantToCancelTheTask: null,
    areYouSureYouWantToClearAllFormFields: null,
    areYouSureYouWantToClearSelected: null,
    areYouSureYouWantToCreateMissingWhiteboardShapes: null,
    areYouSureYouWantToDeletTheBillingAddress: null,
    areYouSureYouWantToDeleteAccount: null,
    areYouSureYouWantToDeleteThisAccount: null,
    areYouSureYouWantToDeleteThisActionCannotBeUndone: null,
    areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone: null,
    areYouSureYouWantToDeleteThisTransactionImports: null,
    areYouSureYouWantToDeleteThisThread: null,
    areYouSureYouWantToDeleteThisTemplate: null,
    areYouSureYouWantToDeletTheField: null,
    areYouSureYouWantToRemoveThisUserFromCompany: null,
    areYouSureYouWantToRemoveItemsFromDraft: null,
    areYouSureYouWantToUnblockThisAccount: null,
    areYouSureYouWantToReplaceActiveWhiteboardWithBackup: null,
    areYouSureYouWantToDeleteSavedFilterThisActionCannotBeUndone: null,
    atLeastOneTaskMustBeEntered: null,
    beforeYouAcceptTheTaskWeLikeToRemindYouThat: null,
    byRatingTasksYouAcceptTaskResultsForTheClient: null,
    changeVisibility: null,
    clearFilters: null,
    convertFromMarkdown: null,
    deleteThread: null,
    dragAndDropFilesOr: null,
    dropTheFilesHere: null,
    errorLoadingImage: null,
    fileIsUploading: null,
    filterByDate: null,
    helpWithTextFormatting: null,
    hiddenAnd: null,
    hereIsTheExampleOfWhatYoullGet: null,
    iConfirmThatIHaveReadAndAgreeTo: null,
    inviteYourTeamMembers: null,
    learnAboutItemTypes: null,
    letsGetToKnowEachOther: null,
    ifYouAreUnsureWhoToInviteNowYouCanSkip: null,
    ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
      null,
    indicateHowMuchDoYouAgreeWithTheFollowingStatement: null,
    iHaveReadAndAgreeToTheUpdatedInformation: null,
    linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc:
      null,
    onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt:
      null,
    orChangeTableSettingsToAlwaysShowHiddenElements: null,
    pleaseClickOnStarsToRateTheResult: null,
    pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
      null,
    pleaseProvideYourAddress: null,
    pleaseRateResults: null,
    pleaseRateTaskRusult: null,
    pleaseRateTheTask: null,
    pleaseWaitYourDownloadWillBeReadyInSeveralMinutes: null,
    previewTextFormatting: null,
    pleaseAdjustYourFiltersAndTryAgainOr: null,
    selectInvoiceFromTheList: null,
    sendEmailNotification: null,
    sendToWhiteboard: null,
    noItemsMatchYourSearchCriteria: null,
    startTypingToSearchForClients: null,
    textFormattingHelp: null,
    thePaymentHasBeenAcceptedForProcessingUsually: null,
    thePaymentHasBeenCanceled: null,
    theTaskResultHasBeenApproved: null,
    toSetUpTheInterfaceForTheRightWorkflow: null,
    thereAreNoNonValidTasks: null,
    thereAreNoValidTasks: null,
    thisActionWillAffect: null,
    thisCommentWouldBeVisibleOnlyInTimeline: null,
    thereIsNoBillingInfoInTheCompany: null,
    updateCost: null,
    updateDueDate: null,
    weAreOnline: null,
    weAreReadyToHelp: null,
    whiteboardDataHasBeenUpdatedPleaseReloadThePage: null,
    writeHereWarning: null,
    youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed:
      null,
    youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask:
      null,
    youWillBeRedirectedToThePaymentsPageIn: null,
    youWillBeRedirectedToTheProjectPageIn: null,
    letsCreateYourFirstProject: null,
    letsDiveIn: null,
    startByOutliningYourProjectVisionBe: null,
    ourExpertTeamWillWorkCloselyWith: null,
    hereIsABriefOfOurServicesAndPricesNo: null,
    unsureClickStartProjectAnd: null,
    asTeamMemberOf: null,
    generateApplicationToken1: null,
    generateApplicationToken2: null,
    refreshAccessToken: null,
    reverseChargeForServices: null,
    yourPaymentIsBeingProcessedBeAdvisedThat: null,
    uploadASinglePhotoOfTheRoomAndOurAIWill: null,
    selectTheObjectYouWantToSearchInOur3DLibrary: null,
    theFunctionalityIsNotAvailableForSearchWithAI: null,
    uploadAPhotoOfARoomSelectACategory: null
  },
  'strings.'
);
