import { IconProps } from '../types';

function CheckSquareSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M5 3c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5zm8.7 5.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L9 10.6 7.7 9.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2c.4.4 1 .4 1.4 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CheckSquareSolidIcon;
