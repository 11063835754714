import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useMainScroll } from '../useMainScroll';

function usePreserveScroll() {
  const router = useRouter();

  const { mainScrollElement, saveScrollPosition, setIsNavigateBack } =
    useMainScroll();

  useEffect(() => {
    router.beforePopState(() => {
      setIsNavigateBack(true);

      return true;
    });

    const onRouteChangeStart = () => {
      saveScrollPosition(mainScrollElement?.scrollTop);
    };

    const onRouteChangeComplete = () => {
      setIsNavigateBack(false);
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router, setIsNavigateBack, mainScrollElement, saveScrollPosition]);
}

export default usePreserveScroll;
