import React from 'react';

import { TeamNanoID } from '../../../../../main/teams/teamsTypes';
import { useTeamShowPage } from '../../../../../main/teams/hooks/useTeamShowPage';

import { TeamAvatar } from '../../../../../main/teams/helpers/TeamAvatar';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';

import { TeamPath } from '../../../../../main/teams/TeamPath';

interface CompanyViewHeadCompanyProps {
  companyNanoId: TeamNanoID;
}

function CompanyViewHeadCompany({
  companyNanoId
}: CompanyViewHeadCompanyProps) {
  const { team, teamFetched, teamIsPlaceholderData } = useTeamShowPage({
    teamNanoId: companyNanoId
  });

  return (
    <NextLinkHelper
      className="rounded hover:bg-white/20 pr-2.5 flex gap-2 items-center focus:ring-base focus:ring-0"
      href={TeamPath.companyProfile(companyNanoId)}
    >
      <LoadingSkeleton
        loaded={teamFetched || teamIsPlaceholderData}
        count={1}
        className="m-0"
        skeletonClassName="flex h-5 w-5 2xl:h-8 2xl:w-8"
      >
        {team ? (
          <TeamAvatar
            className="h-5 w-5 2xl:h-8 2xl:w-8 rounded object-cover"
            team={team}
          />
        ) : null}
      </LoadingSkeleton>
      <LoadingSkeleton
        loaded={teamFetched || teamIsPlaceholderData}
        count={1}
        className="m-0"
      >
        <div className="font-medium">{team?.name}</div>
      </LoadingSkeleton>
    </NextLinkHelper>
  );
}

export default CompanyViewHeadCompany;
