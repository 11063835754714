import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { EmailTemplateUUID } from './emailTemplatesTypes';

const basePath = '/email-templates';

export class EmailTemplatePath {
  static index() {
    return basePath;
  }

  static show(emailTemplateUuid: EmailTemplateUUID) {
    return `${basePath}/${emailTemplateUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
