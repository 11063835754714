import { UserNanoID } from '../users/usersTypes';

export class TaskMemberInviteCache {
  static indexCacheKey() {
    return 'taskMemberInvites';
  }

  static showCacheKey() {
    return 'taskMemberInvite';
  }

  static totalCountCacheKey() {
    return 'taskMemberInvitesTotalCount';
  }

  static userTaskMemberInvitesCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-task-member-invites`;
  }
}
