import { gql } from 'graphql-request';

export const FETCH_PROJECT_MEMBER_INVITES_TOTAL_COUNT_QUERY = gql`
  query ProjectMemberInvitesTotalCountQuery(
    $projectMemberInvitesFilters: ProjectMemberInvitesFilters
  ) {
    projectMemberInvites(filters: $projectMemberInvitesFilters) {
      paginationInfo {
        totalCount
      }
    }
  }
`;
