import React, { ReactNode } from 'react';

import { AuthHeader } from '../../../auth/components/headers/AuthHeader';

import { HeadTitle } from '../../../helpers/HeadTitle';

interface AuthAppLayoutProps {
  children: ReactNode;
}

function AuthAppLayout({ children }: AuthAppLayoutProps) {
  return (
    <>
      <HeadTitle />
      <div className="h-full flex flex-col overflow-y-auto">
        <AuthHeader />
        {children}
      </div>
    </>
  );
}

export default AuthAppLayout;
