import { IconProps } from '../types';

function StarsMultipleSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5 shrink-0'}
    >
      <path d="M10 8L7.5 9.6l.8-2.8L6 4.9l2.9-.2L10 2l1.1 2.7 2.9.2-2.3 1.9.8 2.8L10 8zm-1.5 9l-.8-2.8 2.3-1.9-2.9-.2L6 9.4l-1.1 2.7-2.9.2 2.3 1.9-.8 2.8L6 15.4 8.5 17zm8 0l-.8-2.8 2.3-1.9-2.9-.2L14 9.4l-1.1 2.7-2.9.2 2.3 1.9-.8 2.8 2.5-1.6 2.5 1.6z" />
    </svg>
  );
}

export default StarsMultipleSolidIcon;
