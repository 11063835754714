export default {
  plural: 'Приглашения',
  accept: 'Принять',
  acceptAll: 'Принять все',
  acceptSelected: 'Принять выбранные',
  decline: 'Отклонить',
  declineAll: 'Отклонить все',
  declineSelected: 'Отклонить выбранные',
  search: 'Поиск приглашений по названию задачи',
  areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone:
    'Вы уверены, что хотите принять все приглашения в задачи? Это действие не может быть отменено.',
  areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone:
    'Вы уверены, что хотите отклонить все приглашения в задачи? Это действие не может быть отменено.',
  areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone:
    'Вы уверены, что хотите отклонить приглашение в задачу? Это действие не может быть отменено.'
};
