import { IconProps } from '../types';

import { TopArrowIcon } from '../TopArrowIcon';

function DownArrowIcon({ className, id }: IconProps) {
  return (
    <TopArrowIcon
      id={id}
      className={className || 'h-5 w-5 text-red-500 transform rotate-180'}
    />
  );
}

export default DownArrowIcon;
