import React, { Fragment, memo, useMemo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import { TeamNanoID } from '../../../../main/teams/teamsTypes';
import { UserNanoID } from '../../../../main/users/usersTypes';

import { PrimaryNavTabsEnum } from '../../../hooks/useMenu/types';

import { useTeamShowPage } from '../../../../main/teams/hooks/useTeamShowPage';
import {
  companyPaymentsMenuLinks,
  useCompactCompanyPaymentsSecondaryMenu
} from '../../../../main/teams/components/menus/CompactCompanyPaymentsSecondaryMenu/hooks/useCompactCompanyPaymentsSecondaryMenu';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { LoadingPrimaryMenuSkeleton } from '../../../../helpers/LoadingPrimaryMenuSkeleton';
import { PrimaryNavTab } from '../PrimaryNav/helpers/PrimaryNavTab';
import { PrimaryNavTooltipMenuTab } from '../PrimaryNav/helpers/PrimaryNavTooltipMenuTab';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TeamPath } from '../../../../main/teams/TeamPath';

import { AppPermissions } from '../../../appConstants';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import { menuKeys } from '../../../../locales/keys';
import { companyLibraryMenuLinks } from '../../../../main/common/components/menus/CompactCompanyLibraryMenu/compactCompanyLibraryMenuConstants';

interface CompanyViewPrimaryNavProps {
  companyNanoId: TeamNanoID;
  userNanoId?: UserNanoID;
}

function CompanyViewPrimaryNav({
  companyNanoId,
  userNanoId
}: CompanyViewPrimaryNavProps) {
  const { isSelf, isSelfLoading } = useTeamShowPage({
    teamNanoId: companyNanoId
  });

  const { permissions: companyPaymentsPermissions } =
    useCompactCompanyPaymentsSecondaryMenu({
      companyNanoId,
      isSelf
    });

  const memorizedCompanyLibraryMenuLinks = useMemo(
    () => companyLibraryMenuLinks(companyNanoId),
    [companyNanoId]
  );

  return (
    <TooltipSingletonSourceWrapper placement={TooltipPlacement.RIGHT} withArrow>
      <LoadingPrimaryMenuSkeleton loaded={!isSelfLoading}>
        <CheckPermissions
          action={AppPermissions.READ_COMPANY_VIEW_MENU_DASHBOARD_TAB}
        >
          <PrimaryNavTab
            href={
              userNanoId
                ? TeamPath.companyUserProfile(companyNanoId, userNanoId)
                : TeamPath.companyProfile(companyNanoId)
            }
            icon={IconsEnum.HOME}
            tooltipI18nText={menuKeys.tabs.dashboard}
            tab={PrimaryNavTabsEnum.COMPANY_VIEW_DASHBOARD}
          />
        </CheckPermissions>

        {userNanoId ? (
          <CheckPermissions
            action={
              isSelf
                ? AppPermissions.READ_SELF_COMPANY_USER_VIEW_MENU_WORKSPACE_TAB
                : AppPermissions.READ_OTHER_COMPANY_USER_VIEW_MENU_WORKSPACE_TAB
            }
          >
            <PrimaryNavTab
              href={TeamPath.companyUserProjects(companyNanoId, userNanoId)}
              icon={IconsEnum.PROJECTS}
              tooltipI18nText={menuKeys.tabs.workspace}
              tab={PrimaryNavTabsEnum.COMPANY_VIEW_WORKSPACE}
            />
          </CheckPermissions>
        ) : (
          <Fragment>
            <CheckPermissions
              action={
                isSelf
                  ? AppPermissions.READ_SELF_COMPANY_VIEW_MENU_WORKSPACE_TAB
                  : AppPermissions.READ_OTHER_COMPANY_VIEW_MENU_WORKSPACE_TAB
              }
            >
              <PrimaryNavTab
                href={TeamPath.companyProjects(companyNanoId)}
                icon={IconsEnum.PROJECTS}
                tooltipI18nText={menuKeys.tabs.workspace}
                tab={PrimaryNavTabsEnum.COMPANY_VIEW_WORKSPACE}
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                isSelf
                  ? AppPermissions.READ_SELF_COMPANY_VIEW_MENU_LIBRARY_TAB
                  : AppPermissions.READ_OTHER_COMPANY_VIEW_MENU_LIBRARY_TAB
              }
            >
              <PrimaryNavTooltipMenuTab
                companyNanoId={companyNanoId}
                href={TeamPath.companyLibraryProducts(companyNanoId)}
                icon={IconsEnum.CUBE_OUTLINE}
                tab={PrimaryNavTabsEnum.COMPANY_VIEW_LIBRARY}
                tooltipI18nText={menuKeys.tabs.myLibrary}
                tooltipLinks={memorizedCompanyLibraryMenuLinks}
              />
            </CheckPermissions>

            <CheckPermissions
              action={AppPermissions.READ_COMPANY_VIEW_MENU_PAYMENTS_TAB}
            >
              <PrimaryNavTooltipMenuTab
                companyNanoId={companyNanoId}
                href={TeamPath.companyPaymentsInvoices(companyNanoId)}
                icon={IconsEnum.CASH_OUTLINE}
                tooltipI18nText={menuKeys.tabs.payments}
                tab={PrimaryNavTabsEnum.COMPANY_VIEW_PAYMENTS}
                tooltipLinks={companyPaymentsMenuLinks(
                  companyNanoId,
                  companyPaymentsPermissions
                )}
              />
            </CheckPermissions>
          </Fragment>
        )}
      </LoadingPrimaryMenuSkeleton>
    </TooltipSingletonSourceWrapper>
  );
}

export default memo(CompanyViewPrimaryNav);
