import { useMemo } from 'react';

import { FiltersViewGroups } from '../../../../../types';

import {
  ExtendedFiltersBaseFilters,
  ExtendedFiltersGroups
} from '../../useExtendedFilters.types';

import { useLocalStorage } from '../../../../../common/hooks/useLocalStorage';

import { extendedFiltersMergeGroups } from '../../utils/extendedFiltersMergeGroups';
import { ExtendedFiltersSettingsStorage } from '../../utils/ExtendedFiltersSettingsStorage';

interface ExtendedFiltersInitialFiltersOptions<
  T extends ExtendedFiltersBaseFilters
> {
  filtersViewGroup: FiltersViewGroups;
  initialPageFilters: T;
  initialTabFilters?: T;
  initialSelectedFilters?: T;
  defaultSettingsFilters?: T;
}

function useExtendedFiltersInitialFilters<
  T extends ExtendedFiltersBaseFilters
>({
  filtersViewGroup,
  initialPageFilters,
  initialTabFilters,
  initialSelectedFilters,
  defaultSettingsFilters
}: ExtendedFiltersInitialFiltersOptions<T>) {
  const [initialSettingsFilters] = useLocalStorage<T>(
    ExtendedFiltersSettingsStorage.getKeyByFiltersViewGroup(filtersViewGroup),
    defaultSettingsFilters
  );

  const extendedInitialFilters = useMemo<T>(() => {
    return extendedFiltersMergeGroups({
      [ExtendedFiltersGroups.PAGE]: initialPageFilters,
      [ExtendedFiltersGroups.TAB]: initialTabFilters,
      [ExtendedFiltersGroups.SETTINGS]: initialSettingsFilters,
      [ExtendedFiltersGroups.SELECTED]: initialSelectedFilters
    });
  }, [
    initialPageFilters,
    initialTabFilters,
    initialSettingsFilters,
    initialSelectedFilters
  ]);

  return {
    extendedInitialFilters,
    initialSettingsFilters
  };
}

export default useExtendedFiltersInitialFilters;
