import { PreviewModelsKeyType } from '../../keys';

const previewModelsKeys: PreviewModelsKeyType = {
  clickOnModel: 'Click on model',
  clickOutsideModel: 'Click outside model',
  dragWithOneFinder: 'Drag with one finger',
  dragWithTwoFingers: 'Drag with two fingers',
  fitToScreen: 'Fit to screen',
  focus: 'Focus',
  lmbMouseMove: 'LMB + Mouse move',
  mouseWheel: 'Mouse wheel',
  pan: 'Pan',
  pinchZoomGesture: 'Pinch/Zoom gesture',
  rmbMouseMove: 'RMB + Mouse move',
  rotate: 'Rotate',
  tapOnModel: 'Tap on model',
  tapOutsideModel: 'Tap outside model',
  zoom: 'Zoom'
};

export default previewModelsKeys;
