import React, {
  ChangeEventHandler,
  useState,
  useCallback,
  CSSProperties
} from 'react';

import { FieldIcons } from '../../../../../../../types';

function autosizeInput(input: HTMLInputElement): string {
  const pl = parseInt(getComputedStyle(input).paddingLeft, 10);
  const pr = parseInt(getComputedStyle(input).paddingRight, 10);
  return `calc(${pl + pr + 2}px + ${input.value.length || 1}ch)`;
}

function getInputStyles(input: HTMLInputElement | null): CSSProperties {
  if (!input) {
    return {
      width: 'calc(74px + 1ch)'
    };
  }
  return {
    width: autosizeInput(input)
  };
}

interface AddFundsModalButtonAmountInputProps {
  value: number;
  onChange?: (value: number) => void;
}

function AddFundsModalButtonAmountInput({
  value,
  onChange
}: AddFundsModalButtonAmountInputProps) {
  const [inputElement, setInputElement] = useState<HTMLInputElement>(null);

  const handleAmountChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const parsedValue = parseInt(e.target.value);
      onChange?.(isFinite(parsedValue) && parsedValue >= 0 ? parsedValue : 0);
    },
    [onChange]
  );

  return (
    <label className="flex justify-center items-center -mb-4 p-2 focus-within:ring-4 ring-blue-300 cursor-text focus-within:hover:bg-transparent dark:focus-within:hover:bg-transparent rounded-md">
      <span className="text-3xl font-mono font-semibold leading-normal">
        {FieldIcons.DOLLAR}
      </span>
      <input
        ref={setInputElement}
        type="text"
        value={value || ''}
        className="bg-transparent border-0 -ml-8 -mr-9 px-9 leading-normal text-3xl font-mono font-semibold p-0 w-auto focus:ring-0"
        min={0}
        placeholder="0"
        style={getInputStyles(inputElement)}
        disabled={!onChange}
        onChange={handleAmountChange}
      />
    </label>
  );
}

export default AddFundsModalButtonAmountInput;
