import { ReactNode } from 'react';

import Tippy, { useSingleton } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { TooltipSingletonContext } from '../contexts';

import {
  followCursorPlugin,
  defaultDelay,
  defaultDuration,
  defaultOffset,
  defaultTouch,
  TooltipPlacement
} from '../tooltipsConstants';

interface TooltipSingletonSourceWrapperProps {
  children: ReactNode;
  withArrow?: boolean;
  placement?: TooltipPlacement;
  delay?: number | [number | null, number | null];
  duration?: number | [number | null, number | null];
  interactive?: boolean;
  followCursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  offset?: [number, number];
}

function TooltipSingletonSourceWrapper({
  children,
  withArrow = false,
  placement = TooltipPlacement.BOTTOM,
  interactive = false,
  followCursor,
  offset = defaultOffset
}: TooltipSingletonSourceWrapperProps) {
  const [source, target] = useSingleton();

  return (
    <TooltipSingletonContext.Provider value={target}>
      {children}
      <Tippy
        singleton={source}
        interactive={interactive}
        placement={placement}
        arrow={withArrow}
        delay={defaultDelay}
        duration={defaultDuration}
        offset={offset}
        plugins={followCursor ? followCursorPlugin : undefined}
        touch={defaultTouch}
        {...(followCursor ? { followCursor } : {})}
      />
    </TooltipSingletonContext.Provider>
  );
}

export default TooltipSingletonSourceWrapper;
