import { IconProps } from '../types';

function MarketThriveIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      aria-hidden="true"
      className={className || 'h-14 w-14'}
      stroke="none"
      viewBox="0 0 720 720"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        fill="#766f65"
        d="M315,553a24,24,0,1,1-24,24A24,24,0,0,1,315,553Zm-7,40V560l24,16.472Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M570,635.264l9-118.105h35L639,595l24-77.841h35l9,118.105H680l-5-79.138-25,79.138H628l-26-79.138-5,79.138H570Z"
      />
      <path
        fillRule="evenodd"
        fill="#bfbfbf"
        d="M12,447.3L48,80.749H81L208,394,335,80.749h32L404,447.3H377L345,129,207.727,453,71,129.561,40,447.3H12Z"
      />
      <path
        fillRule="evenodd"
        fill="#0070c0"
        d="M506,447.3V173.255H421V80.749H713v92.506H626V447.3H506Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M105,528C54.029,497.848,4.4,527.682,4,576s52.1,84.092,104,45c-4.587-10.369-6.929-14.226-10-20-12.457,13.027-66,23.277-66-25s56.412-36.643,65-24C99.822,544.089,103.236,534.89,105,528Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M455.88,528c-50.971-30.152-100.6-.318-101,48s52.1,84.092,104,45c-4.587-10.369-6.929-14.226-10-20-12.457,13.027-66,23.277-66-25s56.412-36.643,65-24C450.7,544.089,454.116,534.89,455.88,528Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M221,536c-41.734-42.884-110-15.173-110,40s65.45,83.623,114,41V568H174v21h24c0,4.534.28,17.527,0,17-3,9.742-59,18.932-59-30s55.265-41.257,65-24C211.067,544.588,216.49,540.144,221,536Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M244.075,516.675h27.85V635.037h-27.85V516.675Z"
      />
      <path
        fillRule="evenodd"
        fill="#000000"
        d="M472,635.264V516.675h41c21.9,0,45,9.09,45,36.325s-23,33-23,33l30,49.264H533L508,590h-9v45.264H472ZM499,569h18c2.929,0,13-2.807,13-16s-11.026-14-14-14H499v30Z"
      />
    </svg>
  );
}

export default MarketThriveIcon;
