import map from 'lodash/map';
import includes from 'lodash/includes';

import { UserNanoID } from '../../../users/usersTypes';

import { IsSelfCompanyCompany } from './isSelfCompany.types';

interface IsSelfCompanyOptions {
  company: IsSelfCompanyCompany;
  userNanoId: UserNanoID;
}

function isSelfCompany({ company, userNanoId }: IsSelfCompanyOptions) {
  if (!company || !userNanoId) {
    return false;
  }

  if (company?.owner?.nanoId === userNanoId) {
    return true;
  }

  const memberNanoIds = map(company?.users, 'nanoId');
  if (includes(memberNanoIds, userNanoId)) {
    return true;
  }

  const contractorNanoIds = map(company?.contractors, 'nanoId');
  if (includes(contractorNanoIds, userNanoId)) {
    return true;
  }

  const upsalerIds = map(company?.upsalers, 'nanoId');
  if (includes(upsalerIds, userNanoId)) {
    return true;
  }

  const salesManagerIds = map(company?.salesManagers, 'nanoId');
  if (includes(salesManagerIds, userNanoId)) {
    return true;
  }

  return false;
}

export default isSelfCompany;
