import { PageNanoID } from './pagesTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

const basePath = '/pages';

export class PagePath {
  static index() {
    return basePath;
  }

  static show(pageNanoID: PageNanoID) {
    return `${basePath}/${pageNanoID}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
