import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProjectNotificationsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
}

export const projectNotificationsKeys = keyPathMirror<
  ProjectNotificationsKeyType,
  string
>(
  {
    list: null,
    plural: null,
    singular: null
  },
  'models.projectNotifications.'
);
