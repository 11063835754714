import { gql } from 'graphql-request';
import {
  AvBillingInfoAddress,
  AvBillingInfoBankDetails,
  AvBillingInfoCity,
  AvBillingInfoCompanyName,
  AvBillingInfoContactEmail,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoCountry,
  AvBillingInfoCreatedAt,
  AvBillingInfoDefaultAt,
  AvBillingInfoDisabledAt,
  AvBillingInfoExcludedCountries,
  AvBillingInfoID,
  AvBillingInfoName,
  AvBillingInfoNanoID,
  AvBillingInfoNotes,
  AvBillingInfoState,
  AvBillingInfoUUID,
  AvBillingInfoUpdatedAt,
  AvBillingInfoVatNumber,
  AvBillingInfoZipCode
} from '../avBillingInfosTypes';

export interface FetchAvBillingInfosQueryResponse {
  id: AvBillingInfoID;
  nanoId: AvBillingInfoNanoID;
  uuid: AvBillingInfoUUID;
  name: AvBillingInfoName;
  companyName: AvBillingInfoCompanyName;
  contactName: AvBillingInfoContactName;
  contactPhone: AvBillingInfoContactPhone;
  contactEmail: AvBillingInfoContactEmail;
  country: AvBillingInfoCountry;
  disabledAt: AvBillingInfoDisabledAt;
  state: AvBillingInfoState;
  city: AvBillingInfoCity;
  address: AvBillingInfoAddress;
  zipCode: AvBillingInfoZipCode;
  createdAt: AvBillingInfoCreatedAt;
  updatedAt: AvBillingInfoUpdatedAt;
  defaultAt: AvBillingInfoDefaultAt;
  notes: AvBillingInfoNotes;
  bankDetails: AvBillingInfoBankDetails;
  excludedCountries: AvBillingInfoExcludedCountries;
  vatNumber?: AvBillingInfoVatNumber;
}

export const FETCH_AV_BILLING_INFOS_QUERY = gql`
  query AvBillingInfos(
    $filters: AvBillingInfosFilters
    $sort: [AvBillingInfosSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    avBillingInfos(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      appVersion
      nodes {
        address
        bankDetails
        city
        companyName
        contactEmail
        contactName
        contactPhone
        country
        createdAt
        defaultAt
        disabledAt
        excludedCountries
        id
        name
        nanoId
        notes
        paymentMethod
        state
        vatNumber
        updatedAt
        uuid
        zipCode
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
