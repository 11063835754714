import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface PermissionsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  name: LocalesKey;
  search: LocalesKey;
  view: LocalesKey;
  permissionName: LocalesKey;
  scopes: {
    index: LocalesKey;
    create: LocalesKey;
    read: LocalesKey;
    update: LocalesKey;
    delete: LocalesKey;
    download: LocalesKey;
  };
}

export const permissionsKeys = keyPathMirror<PermissionsKeyType, string>(
  {
    list: null,
    plural: null,
    create: null,
    delete: null,
    name: null,
    search: null,
    view: null,
    permissionName: null,
    scopes: {
      index: null,
      create: null,
      read: null,
      update: null,
      delete: null,
      download: null
    }
  },
  'models.permissions.'
);
