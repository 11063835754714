import { CompanyID, CompanyNanoID } from '../companies/companiesTypes';
import { UserID } from '../users/usersTypes';

export class GeneralLedgerCache {
  static index() {
    return `general-ledgers-index`;
  }

  static showCacheKey() {
    return `general-ledger`;
  }

  static companySubbooksGeneralLedgersCacheKey(companyNanoId: CompanyNanoID) {
    return `company-${companyNanoId}-subbooks-general-ledgers`;
  }

  static companyGeneralLedgersCacheKey(
    companyNanoId: CompanyNanoID | CompanyID
  ) {
    return `company-${companyNanoId}-general-ledgers`;
  }

  static companyDefaultGeneralLedgersCacheKey(
    companyNanoId: CompanyNanoID | CompanyID
  ) {
    return `company-${companyNanoId}-default-general-ledgers`;
  }

  static userGeneralLedgersCacheKey(userId: UserID) {
    return `user-${userId}-general-ledgers`;
  }
}
