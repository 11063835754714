import React from 'react';

import { InvoicePaymentMethods } from '../../../../../invoicesTypes';

import { AddFundsModalButtonPaymentMethodSelectItem } from './components/AddFundsModalButtonPaymentMethodSelectItem';

import { Translate } from '../../../../../../../helpers/Translate';

import { invoicesKeys } from '../../../../../../../locales/keys';

interface AddFundsModalButtonPaymentMethodSelectProps {
  method: InvoicePaymentMethods | null;
  onChange: (method: InvoicePaymentMethods) => void;
}

function AddFundsModalButtonPaymentMethodSelect({
  method,
  onChange
}: AddFundsModalButtonPaymentMethodSelectProps) {
  return (
    <div>
      <fieldset>
        <legend className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          <Translate id={invoicesKeys.paymentMethod} />
        </legend>
        <div className="rounded-md -space-y-px">
          <AddFundsModalButtonPaymentMethodSelectItem
            active={method === InvoicePaymentMethods.WIRE}
            method={InvoicePaymentMethods.WIRE}
            labelI18nText={invoicesKeys.wire}
            descriptionI18nText={invoicesKeys.messages.wireMethods}
            rounded="top"
            onChange={onChange}
          />
          <AddFundsModalButtonPaymentMethodSelectItem
            active={method === InvoicePaymentMethods.CARD}
            method={InvoicePaymentMethods.CARD}
            labelI18nText={invoicesKeys.creditCard}
            descriptionI18nText={invoicesKeys.messages.creditCardMethods}
            rounded="bottom"
            onChange={onChange}
          />
        </div>
      </fieldset>
    </div>
  );
}

export default AddFundsModalButtonPaymentMethodSelect;
