export const enum ModalsEnum {
  TASKS_FILTER_MODAL = 'tasks_filter_modal',
  DOWNLOADS_MANAGER_MODAL = 'downloads_manager_modal',
  WHITEBOARD_SEND_IMAGE_TO_TASK_MODAL = 'whiteboard_send_image_to_task_modal'
}

export interface ReactQueryModalOptions {
  modalEnum: ModalsEnum;
  onClose?: () => void;
  onOpen?: () => void;
}
