import cl from 'classnames';

import { UserMenuTypes } from '../../UserMenu.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth/useAuth';

import { UserAvatar } from '../../../../../main/common/helpers/UserAvatar';

interface UserMenuAvatarProps {
  type: UserMenuTypes;
}

function UserMenuAvatar({ type }: UserMenuAvatarProps) {
  const currentUser = useCurrentUser();

  return (
    <UserAvatar
      className={cl(
        'rounded-full',
        type === UserMenuTypes.MOBILE ? 'h-8 w-8' : 'h-10 w-10'
      )}
      user={currentUser}
    />
  );
}

export default UserMenuAvatar;
