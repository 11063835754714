import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  AssociationNanoIDsFilter,
  BooleanFilter,
  CreatedAt,
  DateType,
  DeleteItemCacheKeys,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSerializer,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IDFilter,
  ItemGqlError,
  NanoID,
  NanoIDFilter,
  NanoIdFilterType,
  IdFilterType,
  StringArrayFilter,
  UpdatedAt,
  UpdateItemGqlStatus,
  UUID
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { CompetencyName } from '../competencies/competenciesTypes';
import {
  InvoiceAmount,
  InvoiceClientId,
  InvoiceClientStatus,
  InvoiceCreatedAt,
  InvoiceGeneralLedgerGeneralLedgerType,
  InvoiceGeneralLedgerID,
  InvoiceGeneralLedgerName,
  InvoiceID,
  InvoiceInvoiceType,
  InvoiceNanoID,
  InvoiceParentInvoiceID,
  InvoiceParentInvoiceNanoID,
  InvoicePaymentMethod,
  InvoicePrepayment,
  InvoicePrimaryInvoiceID,
  InvoiceReturnReasons,
  InvoiceSelectedCurrency,
  InvoiceStatus,
  InvoiceTotal,
  InvoiceUUID,
  InvoiceUpdatedAt
} from '../invoices/invoicesTypes';
import {
  ItemDescription,
  ItemID,
  ItemItemCategoryName,
  ItemItemTypeName,
  ItemNanoID,
  ItemPrepayment,
  ItemPrice,
  ItemShowVoidButton,
  ItemUnbindedAt,
  ItemUpdatedAt,
  ItemUUID,
  ItemViewPrice,
  ItemViewPriceCurrency
} from '../items/itemsTypes';
import {
  ProjectCreatedAt,
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectUUID
} from '../projects/projectsTypes';
import {
  TaskCreatedAt,
  TaskID,
  TaskName,
  TaskNanoID,
  TaskOwnerFullName,
  TaskOwnerID,
  TaskOwnerNanoID,
  TaskUUID
} from '../tasks/tasksTypes';
import { UserCompetencyID } from '../userCompetencies/userCompetenciesTypes';
import {
  UserBlocked,
  UserClient,
  UserCurrentTeamNanoID,
  UserFullName,
  UserID,
  UserImageFile,
  UserName,
  UserNanoID,
  UserSurname,
  UserUUID
} from '../users/usersTypes';
import {
  CompanyGeneralLedgerID,
  CompanyID,
  CompanyName,
  CompanyNanoID
} from '../companies/companiesTypes';
import { TextFilterType } from '../../types';
import { CompanySmartContractTemplateName } from '../companySmartContractTemplates/companySmartContractTemplatesTypes';
import {
  SmartContractShareGeneralLedgerCompanyID,
  SmartContractShareGeneralLedgerCompanyImageFile,
  SmartContractShareGeneralLedgerCompanyName,
  SmartContractShareGeneralLedgerCompanyNanoID,
  SmartContractShareGeneralLedgerID,
  SmartContractShareGeneralLedgerName,
  SmartContractShareID,
  SmartContractShareStatus
} from '../smartContractShares/smartContractSharesTypes';
import { GeneralLedgerCompanyAutoPaymentScAt } from '../generalLedgers/generalLedgersTypes';

export type SmartContractID = ID;
export type SmartContractUUID = UUID;
export type SmartContractNanoID = NanoID;
export type SmartContractCreatedAt = CreatedAt;
export type SmartContractUpdatedAt = UpdatedAt;
export type SmartContractOpenedAt = DateType;
export type SmartContractDoneAt = DateType;
export type SmartContractCanceledAt = DateType;
export type SmartContractInternalAt = DateType;
export type SmartContractDueDate = DateType;
export type SmartContractStatus = SmartContractStatuses;
export type SmartContractShareStrategy = SmartContractShareStrategies;
export type SmartContractShareStrategyValue = number;
export type SmartContractSmartContractType = SmartContractSmartContractTypes;

export type SmartContractShowUnbindButton = boolean;
export type SmartContractDisabledUnbindButton = boolean;
export type SmartContractShowTransactionFeeButton = boolean;
export type SmartContractDisabledTransactionFeeButton = boolean;
export type SmartContractShowProvisionForBadDebtButton = boolean;
export type SmartContractDisabledProvisionForBadDebtButton = boolean;
export type SmartContractShowProvisionForRevisionButton = boolean;
export type SmartContractDisabledProvisionForRevisionButton = boolean;
export type SmartContractShowAvShareSchemeButton = boolean;
export type SmartContractDisabledAvShareSchemeButton = boolean;
export type SmartContractShowAddItemButton = boolean;
export type SmartContractDisabledAddItemButton = boolean;
export type SmartContractShowMoveItemButton = boolean;
export type SmartContractDisabledMoveItemButton = boolean;
export type SmartContractShowChangeUserShareButton = boolean;
export type SmartContractDisabledChangeUserShareButton = boolean;
export type SmartContractShowRemoveFromContractButton = boolean;
export type SmartContractDisabledRemoveFromContractButton = boolean;
export type SmartContractShowCancelButton = boolean;
export type SmartContractDisabledCancelButton = boolean;
export type SmartContractShowCheckInButton = boolean;
export type SmartContractDisabledCheckInButton = boolean;
export type SmartContractShowRefundContractorsInvoiceButton = boolean;
export type SmartContractDisabledRefundContractorsInvoiceButton = boolean;
export type SmartContractShowPayFromPfrContractorsInvoiceButton = boolean;
export type SmartContractDisabledPayFromPfrContractorsInvoiceButton = boolean;
export type SmartContractShowPayFromAvContractorsInvoiceButton = boolean;
export type SmartContractDisabledPayFromAvContractorsInvoiceButton = boolean;
export type SmartContractShowPayFromPfbdContractorsInvoiceButton = boolean;
export type SmartContractDisabledPayFromPfbdContractorsInvoiceButton = boolean;
export type SmartContractShowInternalSmartContractButton = boolean;
export type SmartContractDisabledInternalSmartContractButton = boolean;
export type SmartContractShowAutomaticPaymentButton = boolean;

export type SmartContractTaskOwnerID = TaskOwnerID;
export type SmartContractTaskOwnerNanoID = TaskOwnerNanoID;
export type SmartContractTaskOwnerFullName = TaskOwnerFullName;

export type SmartContractTaskID = TaskID;
export type SmartContractTaskUUID = TaskUUID;
export type SmartContractTaskName = TaskName;
export type SmartContractTaskNanoID = TaskNanoID;
export type SmartContractTaskProjectID = ProjectID;
export type SmartContractTaskCreatedAt = TaskCreatedAt;
export type SmartContractTaskProjectUUID = ProjectUUID;
export type SmartContractTaskProjectNanoID = ProjectNanoID;
export type SmartContractTaskProjectName = ProjectName;
export type SmartContractTaskProjectCreatedAt = ProjectCreatedAt;
export type SmartContractTaskProjectCompanyID = CompanyID;
export type SmartContractTaskProjectCompanyNanoID = CompanyNanoID;
export type SmartContractTaskProjectCompanyName = CompanyName;
export type SmartContractTaskProjectCompanyAutoPaymentScAt =
  GeneralLedgerCompanyAutoPaymentScAt;
export type SmartContractTaskDisabledAcceptButton = boolean;
export type SmartContractTaskDisabledCancelButton = boolean;
export type SmartContractTaskDisabledContinueButton = boolean;
export type SmartContractTaskDisabledDestroyButton = boolean;
export type SmartContractTaskDisabledRestoreButton = boolean;
export type SmartContractTaskDisabledStartButton = boolean;
export type SmartContractTaskShowAcceptButton = boolean;
export type SmartContractTaskShowCancelButton = boolean;
export type SmartContractTaskShowContinueButton = boolean;
export type SmartContractTaskShowDestroyButton = boolean;
export type SmartContractTaskShowRestoreButton = boolean;
export type SmartContractTaskShowStartButton = boolean;

export type SmartContractCompanyID = CompanyID;
export type SmartContractCompanyNanoID = CompanyNanoID;
export type SmartContractCompanyName = CompanyName;
export type SmartContractCompanyGeneralLedgerID = CompanyGeneralLedgerID;
export type SmartContractCompanyAutoPaymentScAt =
  GeneralLedgerCompanyAutoPaymentScAt;

export type SmartContractCompanySmartContractTemplateName =
  CompanySmartContractTemplateName;

export type SmartContractInvoiceID = InvoiceID;
export type SmartContractInvoiceNanoID = InvoiceNanoID;
export type SmartContractInvoiceUUID = InvoiceUUID;
export type SmartContractInvoiceAmount = InvoiceAmount;
export type SmartContractInvoiceSelectedCurrency = InvoiceSelectedCurrency;
export type SmartContractInvoiceInvoiceType = InvoiceInvoiceType;
export type SmartContractInvoiceReturnReason = InvoiceReturnReasons;
export type SmartContractInvoiceStatus = InvoiceStatus;
export type SmartContractInvoiceTotal = InvoiceTotal;
export type SmartContractInvoicePaymentMethod = InvoicePaymentMethod;
export type SmartContractInvoicePrimaryInvoiceID = InvoicePrimaryInvoiceID;
export type SmartContractInvoicePrepayment = InvoicePrepayment;
export type SmartContractInvoiceClientId = InvoiceClientId;
export type SmartContractInvoiceClientStatus = InvoiceClientStatus;
export type SmartContractInvoiceParentInvoiceID = InvoiceParentInvoiceID;
export type SmartContractInvoiceGeneralLedgerID = InvoiceGeneralLedgerID;
export type SmartContractInvoiceGeneralLedgerName = InvoiceGeneralLedgerName;
export type SmartContractInvoiceGeneralLedgerGeneralLedgerType =
  InvoiceGeneralLedgerGeneralLedgerType;

export type SmartContractItemID = ItemID;
export type SmartContractItemNanoID = ItemNanoID;
export type SmartContractItemUUID = ItemUUID;
export type SmartContractItemPrice = ItemPrice;
export type SmartContractItemPrepayment = ItemPrepayment;
export type SmartContractItemItemTypeName = ItemItemTypeName;
export type SmartContractItemItemCategoryName = ItemItemCategoryName;
export type SmartContractItemDescription = ItemDescription;
export type SmartContractItemUnbindedAt = ItemUnbindedAt;
export type SmartContractItemShowVoidButton = ItemShowVoidButton;
export type SmartContractItemUpdatedAt = ItemUpdatedAt;
export type SmartContractItemViewPrice = ItemViewPrice;
export type SmartContractItemViewPriceCurrency = ItemViewPriceCurrency;

export type SmartContractItemInvoiceID = InvoiceID;
export type SmartContractItemInvoiceParentInvoiceID = InvoiceParentInvoiceID;
export type SmartContractItemInvoiceParentInvoiceNanoID =
  InvoiceParentInvoiceNanoID;
export type SmartContractItemInvoiceStatus = InvoiceStatus;
export type SmartContractItemInvoiceInvoiceType = InvoiceInvoiceType;
export type SmartContractItemInvoiceUUID = InvoiceUUID;
export type SmartContractItemInvoiceNanoID = InvoiceNanoID;
export type SmartContractItemInvoiceCreatedAt = InvoiceCreatedAt;
export type SmartContractItemInvoiceUpdatedAt = InvoiceUpdatedAt;
export type SmartContractItemInvoiceAmount = InvoiceAmount;
export type SmartContractItemInvoiceReturnReason = InvoiceReturnReasons;

export type SmartContractRuleID = ID;
export type SmartContractRuleUUID = UUID;
export type SmartContractRuleNanoID = NanoID;
export type SmartContractRuleName = string;
export type SmartContractRuleDescription = string;
export type SmartContractRuleRuleType = SmartContractRuleRuleTypes;
export type SmartContractRulePresenceType = SmartContractRulePresenceTypes;
export type SmartContractRuleCreatedAt = CreatedAt;
export type SmartContractRuleUpdatedAt = UpdatedAt;
export type SmartContractRuleValue = number;
export type SmartContractRuleShareOverrideAt = string;
export type SmartContractRuleFeeType = SmartContractRuleFeeTypes;
export type SmartContractRuleCompetencyName = CompetencyName;

export type SmartContractSmartContractShareID = SmartContractShareID;
export type SmartContractSmartContractShareStatus = SmartContractShareStatus;
export type SmartContractSmartContractShareGeneralLedgerID =
  SmartContractShareGeneralLedgerID;
export type SmartContractSmartContractShareGeneralLedgerName =
  SmartContractShareGeneralLedgerName;
export type SmartContractSmartContractShareGeneralLedgerCompanyID =
  SmartContractShareGeneralLedgerCompanyID;
export type SmartContractSmartContractShareGeneralLedgerCompanyNanoID =
  SmartContractShareGeneralLedgerCompanyNanoID;
export type SmartContractSmartContractShareGeneralLedgerCompanyName =
  SmartContractShareGeneralLedgerCompanyName;
export type SmartContractSmartContractShareGeneralLedgerCompanyImageFile =
  SmartContractShareGeneralLedgerCompanyImageFile;

export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerID = UserID;
export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerNanoID =
  UserNanoID;
export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerBlocked =
  UserBlocked;
export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerClient =
  UserClient;
export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerFullName =
  UserFullName;
export type SmartContractSmartContractShareGeneralLedgerCompanyOwnerImageFile =
  UserImageFile;

export type SmartContractRuleCompetencyContractorID = UserID;
export type SmartContractRuleCompetencyContractorUUID = UserUUID;
export type SmartContractRuleCompetencyContractorNanoID = UserNanoID;
export type SmartContractRuleCompetencyContractorName = UserName;
export type SmartContractRuleCompetencyContractorSurName = UserSurname;
export type SmartContractRuleCompetencyContractorFullName = UserFullName;
export type SmartContractRuleCompetencyContractorCurrentTeamNanoID =
  UserCurrentTeamNanoID;
export type SmartContractRuleCompetencyContractorImageFile = UserImageFile;

export type SmartContractRuleContractorCompetencyID = UserCompetencyID;

export type SmartContractContractorsAmountContractorCompetencyID =
  UserCompetencyID;
export type SmartContractContractorsAmountAmount = string;

export type CheckInSmartContractRulesCacheKeys = UpdateItemsCacheKeys;

export type FetchSmartContractsCacheKey = FetchItemsCacheKey;

export type UpdateSmartContractsCacheKeys = UpdateItemsCacheKeys;

export type DeleteSmartContractRuleCacheKeys = DeleteItemCacheKeys;

export type SmartContractCompanyContractorNanoIDFilter = NanoIDFilter;
export type SmartContractRuleCompetencyContractorNanoIDFilter = NanoIDFilter;
export type SmartContractCompanyNanoIDFilter = NanoIDFilter;
export type SmartContractParentInvoiceNanoIdFilter = AssociationNanoIDsFilter;
export type SmartContractInvoiceNanoIDFilter = NanoIDFilter;
export type SmartContractProjectNanoIDFilter = NanoIDFilter;
export type SmartContractStatusFilter = StringArrayFilter;
export type SmartContractTaskNanoIDFilter = NanoIDFilter;
export type SmartContractWithoutDoneFilter = BooleanFilter;
export type SmartContractWithoutParentInvoicesFilter = BooleanFilter;
export type SmartContractInvoiceIDFilter = IDFilter | IDFilter[];

export const enum FetchSmartContractsFilterFields {
  COMPANY_CONTRACTOR_NANO_ID = 'companyContractorNanoId',
  COMPANY_NANO_ID = 'companyNanoId',
  PARENT_INVOICE_NANO_ID = 'parentInvoiceNanoId',
  PROJECT_NANO_ID = 'projectNanoId',
  STATUS = 'status',
  TASK_NANO_ID = 'taskNanoId',
  WITHOUT_DONE = 'withoutDone'
}

export interface FetchSmartContractsFilters {
  companyContractorNanoId?: SmartContractCompanyContractorNanoIDFilter;
  companyNanoId?: SmartContractCompanyNanoIDFilter;
  projectNanoId?: SmartContractProjectNanoIDFilter;
  status?: SmartContractStatusFilter;
  taskNanoId?: SmartContractTaskNanoIDFilter;
  invoiceNanoId?: SmartContractInvoiceNanoIDFilter;
  invoiceId?: SmartContractInvoiceIDFilter;
  parentInvoiceNanoId?: SmartContractParentInvoiceNanoIdFilter;
  withoutDone?: SmartContractWithoutDoneFilter;
  withoutParentInvoices?: SmartContractWithoutParentInvoicesFilter;
  smartContractRuleCompetencyContractorNanoId?: SmartContractRuleCompetencyContractorNanoIDFilter;
}

export interface FetchFinSmartContractsFilters {
  taskNanoId?: NanoIdFilterType;
  companyNanoId?: NanoIdFilterType;
  generalLedgerId?: IdFilterType;
  projectNanoId?: NanoIdFilterType;
  companyContractorNanoId?: NanoIdFilterType;
  parentInvoiceNanoId?: NanoIdFilterType;
  status?: TextFilterType;
  withoutParentInvoices?: SmartContractWithoutParentInvoicesFilter;
  smartContractRuleCompetencyContractorNanoId?: NanoIdFilterType;
  withoutDone?: SmartContractWithoutDoneFilter;
}

export const enum FetchSmartContractsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export const enum SmartContractStatuses {
  DRAFT = 'draft',
  OPEN = 'open',
  CANCELED = 'canceled',
  DONE = 'done'
}

export const enum SmartContractSmartContractTypes {
  NONE = 'none',
  RULES = 'rules',
  SHARES = 'shares'
}

export const enum SmartContractRuleRuleTypes {
  FEE = 'fee',
  CONTRACTOR = 'contractor',
  MINIMUM_SHARE = 'minimum_share'
}

export const enum SmartContractRuleFeeTypes {
  BAD_DEBT = 'bad_debt',
  REVISION = 'revision',
  HOSTING = 'hosting',
  TRANSACTION_FEE = 'transaction_fee',
  HOLD_RATE = 'hold_rate',
  // SMART_CONTRACT_FEE deprecated
  SMART_CONTRACT_FEE = 'smart_contract_fee'
}

export const enum SmartContractRuleAllocationTypes {
  VARIABLE = 'variable',
  FIXED = 'fixed'
}

export const enum SmartContractRulePresenceTypes {
  ENABLED = 'enabled',
  DRAFT = 'draft',
  TRASH = 'trash'
}

export const enum SmartContractShareStrategies {
  NET = 'net',
  RESIDUAL = 'residual',
  FIXED = 'fixed'
}

export enum SmartContractRuleFields {
  CONTRACTOR_COMPETENCY_ID = 'contractorCompetencyId'
}

export type SmartContractStatusFilterType = {
  eq?: SmartContractStatuses;
  in?: SmartContractStatuses[];
  notEq?: SmartContractStatuses;
  notIn?: SmartContractStatuses[];
};

export type FetchSmartContractsSort = FetchItemsSort;
export type FetchSmartContractsSortSmartContracts = FetchItemsSortItems;
export type FetchSmartContractsPage = FetchItemsPage;
export type FetchSmartContractsLimit = FetchItemsLimit;
export type FetchSmartContractsSerializer = FetchItemsSerializer;
export type FetchSmartContractsErrorMessage = FetchItemsErrorMessage;
export type FetchSmartContractsFetched = FetchItemsFetched;
export type FetchSmartContractFetched = FetchItemFetched;
export type FetchSmartContractsIsPlaceholderData = FetchItemsIsPlaceholderData;
// export type FetchSmartContractIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchSmartContractsPaginateSmartContracts = FetchItemsPaginateItems;
export type FetchSmartContractsPrefetchSmartContracts = FetchItemsPrefetchItems;
export type FetchSmartContractsTotalCount = FetchItemsTotalCount;
export type FetchSmartContractsFilterSmartContracts =
  FetchItemsFilterItems<FetchSmartContractsFilters>;

export type ChangeSmartContractsFiltersFunc = (
  changedFilters: FetchSmartContractsFilters,
  removedFilters?: string[]
) => void;

export type ChangeFinSmartContractsFiltersFunc = (
  changedFilters: FetchFinSmartContractsFilters,
  removedFilters?: string[]
) => void;

export type FetchSmartContractsCheckedAll = CheckedAll;
export type FetchSmartContractsOnCheckAll = OnCheckAll;
export type FetchSmartContractsChecked = Checked;
export type FetchSmartContractsCheckedHash = CheckedHashItem;
export type FetchSmartContractsOnSetCheckedIds = OnSetCheckedIds;

export type CheckInToSmartContractRuleGqlStatus = UpdateItemGqlStatus;
export type CheckInToSmartContractRuleGqlError = ItemGqlError;
export type CheckInToSmartContractRuleGqlErrors =
  CheckInToSmartContractRuleGqlError[];

export type FetchSmartContractsGqlQuery = FetchItemsGqlQuery;

export type FetchSmartContractCacheKey = FetchItemCacheKey;
export type FetchSmartContractGqlQuery = FetchItemGqlQuery;
