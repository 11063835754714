import { gql } from 'graphql-request';

import {
  UserCreatedAt,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserLocale,
  UserNanoID,
  UserPermissionsAction,
  UserUUID,
  UserClient,
  UserCurrentTeamID,
  UserCurrentTeamUUID,
  UserCurrentTeamNanoID,
  UserRoleName
} from '../../main/users/usersTypes';

export interface FetchCurrentUserQueryResponse {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  locale: UserLocale;
  createdAt: UserCreatedAt;
  client: UserClient;
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  };
  currentTeam: {
    id: UserCurrentTeamID;
    uuid: UserCurrentTeamUUID;
    nanoId: UserCurrentTeamNanoID;
  };
  frontendActions: { [action: UserPermissionsAction]: true };
  roles: {
    name: UserRoleName;
  }[];
}

export const FETCH_CURRENT_USER = gql`
  query FetchCurrentUser {
    currentUser {
      id
      uuid
      nanoId
      fullName
      locale
      createdAt
      client
      image {
        uuid
        file
      }
      currentTeam {
        id
        uuid
        nanoId
      }
      frontendActions
      roles {
        name
      }
    }
  }
`;
