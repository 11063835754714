import { ReactNode, useContext } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { TooltipSingletonContext } from '../contexts';

import { Translate } from '../../Translate';

interface TooltipSingletonTargetBaseProps<T> {
  referenceElement: T;
}

interface TooltipSingletonTargetWithI18nProps {
  tooltipI18nText: string;
  tooltipText?: never;
  children?: never;
}

interface TooltipSingletonTargetWithTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
  children?: never;
}

interface TooltipSingletonTargetWithChildrenProps {
  tooltipI18nText?: never;
  tooltipText?: never;
  children: ReactNode;
}

function TooltipSingletonTarget<T extends HTMLElement | null>({
  referenceElement,
  tooltipI18nText,
  tooltipText,
  children
}: TooltipSingletonTargetBaseProps<T> &
  (
    | TooltipSingletonTargetWithI18nProps
    | TooltipSingletonTargetWithTextProps
    | TooltipSingletonTargetWithChildrenProps
  )) {
  const singletonTarget = useContext(TooltipSingletonContext);

  if (!tooltipI18nText && !tooltipText && !children) {
    return null;
  }

  return (
    <Tippy
      singleton={singletonTarget}
      reference={referenceElement}
      content={
        tooltipI18nText ? (
          <Translate id={tooltipI18nText} />
        ) : (
          tooltipText || children
        )
      }
    />
  );
}

export default TooltipSingletonTarget;
