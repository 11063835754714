export default {
  plural: 'Категорії',
  seeAll: 'Подивитися всі категорії',
  seeLess: 'Бачити менше категорій',
  singular: 'Категорія',
  new: 'Нова категорія',
  'add...': 'Додати категорію',
  'select...': 'Обрати категорію...',
  no: 'Категорії відсутні',
  styles: {
    plural: 'Стилі',
    singular: 'Стиль',
    'select...': 'Обрати стиль...'
  },
  materials: {
    plural: 'Матеріали',
    singular: 'Матеріал',
    'select...': 'Обрати матеріал...'
  },
  brands: {
    plural: 'Виробники',
    search: 'Шукати виробників',
    singular: 'Виробник',
    'add...': 'Додати виробника...',
    'select...': 'Обрати виробника...'
  },
  gammas: {
    singular: 'Гама',
    'select...': 'Обрати гаму...'
  },
  groups: {
    plural: 'Групи',
    singular: 'Група',
    'select...': 'Обрати групу...'
  },
  tones: {
    singular: 'Тон'
    // 'select...': 'Select tone...'
  },
  colors: {
    plural: 'Кольори',
    singular: 'Колір',
    'select...': 'Обрати колір...'
  },
  mainColors: {
    plural: 'Основні кольори',
    singular: 'Основний колір',
    'select...': 'Обрати основний колір...'
  },
  accentColors: {
    plural: 'Кольори акценту',
    singular: 'Колір акценту',
    'select...': 'Обрати колір акценту...'
  },
  authors: {
    plural: 'Автори',
    singular: 'Автор',
    select: 'Обрати автора'
  },
  performers: {
    plural: 'Автори',
    singular: 'Автор',
    select: 'Обрати автора',
    'select...': 'Обрати автора...'
  },
  projects: {
    plural: 'Проєкти',
    singular: 'Проєкт',
    select: 'Обрати проєкт',
    'select...': 'Обрати проєкт...'
  },
  manufacturers: {
    singular: 'Виробник',
    plural: 'Виробники'
  },
  fileVersionTags: {
    plural: 'Мітки версії файлу',
    singular: 'Мітка версії файлу',
    'select...': 'Обрати мітку версії файлу...'
  },
  collections: {
    plural: 'Колекції',
    singular: 'Колекція',
    'select...': 'Обрати колекцію...'
  },
  mistakeTypes: {
    plural: 'Типи помилок',
    singular: 'Тип помилки',
    select: 'Обрати тип помилки',
    'select...': 'Обрати тип помилки...'
  },
  statuses: {
    plural: 'Статуси',
    singular: 'Статус',
    select: 'Обрати статус',
    'select...': 'Обрати статус...'
  }
};
