import { InvoiceNanoID, InvoiceID } from './invoicesTypes';

const basePath = '/invoices';
const paymentsContractorInvoicesPath = '/payments/performer-invoices';
const depositInvoicesPath = '/deposit-invoices';
const paymentsPath = '/payments';
const paymentsInvoicesPath = '/payments/invoices';

export class InvoicePath {
  static index() {
    return basePath;
  }

  static show(invoiceNanoId: InvoiceNanoID) {
    return `${basePath}/${invoiceNanoId}`;
  }

  static showById(invoiceId: InvoiceID) {
    return `${basePath}/${invoiceId}`;
  }

  static depositInvoices() {
    return depositInvoicesPath;
  }

  static payments() {
    return paymentsPath;
  }

  static paymentsInvoices() {
    return paymentsInvoicesPath;
  }

  static paymentsContractorInvoices() {
    return paymentsContractorInvoicesPath;
  }

  static paymentsContractorInvoicesCurrentFilter() {
    return `${paymentsContractorInvoicesPath}/filters/current`;
  }

  static paymentsContractorInvoicesOverdueFilter() {
    return `${paymentsContractorInvoicesPath}/filters/overdue`;
  }

  static paymentsContractorInvoicesOpenFilter() {
    return `${paymentsContractorInvoicesPath}/filters/open`;
  }

  static paymentsContractorInvoicesSentFilter() {
    return `${paymentsContractorInvoicesPath}/filters/sent`;
  }

  static paymentsInvoicesFilter(filterSlug: string) {
    return `${paymentsInvoicesPath}/filters/${filterSlug}`;
  }

  static paymentsInvoicesOpenFilter() {
    return `${paymentsInvoicesPath}/filters/open`;
  }

  static paymentsInvoice(invoiceNanoId: InvoiceNanoID | InvoiceID) {
    return `${paymentsInvoicesPath}/${invoiceNanoId}`;
  }

  static paymentsInvoicesSentFilter() {
    return `${paymentsInvoicesPath}/filters/sent`;
  }

  static paymentsInvoicesDraftFilter() {
    return `${paymentsInvoicesPath}/filters/draft`;
  }

  static paymentsInvoicesPaidFilter() {
    return `${paymentsInvoicesPath}/filters/paid`;
  }

  static paymentsInvoicesCanceledFilter() {
    return `${paymentsInvoicesPath}/filters/canceled`;
  }

  static paymentsInvoicesRefundsFilter() {
    return `${paymentsInvoicesPath}/filters/refund`;
  }
}
