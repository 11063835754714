import { useCallback, useRef } from 'react';

interface UsePrimaryMenuHoverOptions {
  openSecondaryMenu: () => void;
}

function usePrimaryMenuHover({
  openSecondaryMenu
}: UsePrimaryMenuHoverOptions) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      openSecondaryMenu();
      timeoutRef.current = null;
    }, 350);
  }, [openSecondaryMenu, timeoutRef]);

  const handleMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [timeoutRef]);

  return {
    handleMouseEnter,
    handleMouseLeave
  };
}

export default usePrimaryMenuHover;
