import {
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemsCacheKey,
  ItemGqlError,
  TrackTimeInItemGqlQuery,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus
} from '../../../types';
import {
  UpdateItemsGqlError,
  UpdateItemsGqlQuery,
  UpdateItemsGqlStatus
} from '../../../types/updateItemsTypes';
import {
  CreateItemsGqlQuery,
  CreateItemsGqlStatus
} from '../../../types/createItemsTypes';

export * from './projectsFields';
export * from './fetchProjectsTypes';

export type CreateProjectGqlQuery = CreateItemGqlQuery;
export type CreateProjectInTeamGqlQuery = CreateItemGqlQuery;
export type CreateProjectsInTeamGqlQuery = CreateItemsGqlQuery;
export type UpdateProjectGqlQuery = UpdateItemGqlQuery;
export type DeleteProjectGqlQuery = DeleteItemGqlQuery;
export type DownloadProjectResultsGqlQuery = UpdateItemGqlQuery;
export type TrackTimeInProjectGqlQuery = TrackTimeInItemGqlQuery;
export type SubmitFileAttachmentsToProjectGqlQuery = CreateItemGqlQuery;

export type CreateProjectGqlStatus = CreateItemGqlStatus;
export type CreateProjectInTeamGqlStatus = CreateItemGqlStatus;
export type CreateProjectsInTeamGqlStatus = CreateItemsGqlStatus;
export type UpdateProjectGqlStatus = UpdateItemGqlStatus;
export type DeleteProjectGqlStatus = DeleteItemGqlStatus;
export type DownloadProjectResultsGqlStatus = UpdateItemGqlStatus;
export type SubmitFileAttachmentsToProjectGqlStatus = CreateItemGqlStatus;
export type ProjectGqlError = ItemGqlError;

export type inviteUsersToProjectsGqlQuery = UpdateItemsGqlQuery;
export type inviteUsersToProjectsGqlStatus = UpdateItemsGqlStatus;
export type inviteUsersToProjectsGqlError = UpdateItemsGqlError;

export type CreateProjectsBillsGqlStatus = CreateItemGqlStatus;
export type CreateProjectsBillsGqlError = ItemGqlError;
export type CreateProjectsBillsGqlQuery = CreateItemGqlQuery;
export type CreateProjectsBillsCacheKey = FetchItemsCacheKey;
export type CreateProjectsBillsCacheKeys = CreateProjectsBillsCacheKey[];
