export const VERSION = '1.6.24';

export const APP_CRM_NAME = process.env.NEXT_PUBLIC_APP_CRM_NAME;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const TEST_GRAPHQL_API_URI =
  process.env.NEXT_PUBLIC_TEST_GRAPHQL_API_URI ||
  process.env.NEXT_PUBLIC_DEVELOP_API_URI;

export const GRAPHQL_API_URI = process.env.NEXT_PUBLIC_GRAPHQL_API_URI;
export const GRAPHQL_READ_API_URI =
  process.env.NEXT_PUBLIC_GRAPHQL_READ_API_URI ||
  process.env.NEXT_PUBLIC_GRAPHQL_API_URI;
export const GRAPHQL_READ_FIN_API_URI =
  process.env.NEXT_PUBLIC_GRAPHQL_READ_FIN_API_URI;
export const UPLOADS_API_URI = process.env.NEXT_PUBLIC_UPLOADS_API_URI;
export const MARKETING_API_URL = process.env.NEXT_PUBLIC_MARKETING_API_URL;

export const ZAPIER_ZOHO_HOOK_URL =
  process.env.NEXT_PUBLIC_ZAPIER_ZOHO_HOOK_URL;

export const ZAPIER_ZOHO_REGISTER_WORKER_HOOK_URL =
  process.env.NEXT_PUBLIC_ZAPIER_ZOHO_REGISTER_WORKER_HOOK_URL;

export const FILE_ATTACHMENTS_API_URI =
  process.env.NEXT_PUBLIC_FILE_ATTACHMENTS_API_URI;

export const FILES_API_URI =
  process.env.NEXT_PUBLIC_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_files`;
export const IMAGES_API_URI =
  process.env.NEXT_PUBLIC_IMAGES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_images`;
export const MAX_FILES_API_URI =
  process.env.NEXT_PUBLIC_MAX_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_max_files`;
export const SOURCE_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_source_files`;
export const CLIENT_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_client_files`;
export const FILE_IMPORTS_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_file_imports`;
export const TEXTURE_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_texture_files`;

export const NEW_PRODUCTS_API_URI = `${process.env.NEXT_PUBLIC_NEW_API_URI}/products`;

export const STATIC_API_URI = process.env.NEXT_PUBLIC_STATIC_API_URI;
export const PROXY_STATIC_TARGET = process.env.NEXT_PUBLIC_PROXY_STATIC_TARGET;

export const SHOW_DEBUG = process.env.NEXT_PUBLIC_SHOW_DEBUG === 'true';
export const USE_CONSOLE = process.env.NEXT_PUBLIC_USE_CONSOLE;

export const PRIVACY_PAGE_NANOID = process.env.NEXT_PUBLIC_PRIVACY_PAGE_NANOID;
export const PRIVACY_POLICY_PAGE_NANOID =
  process.env.NEXT_PUBLIC_PRIVACY_POLICY_PAGE_NANOID;
export const SERVICE_AGREEMENT_PAGE_NANOID =
  process.env.NEXT_PUBLIC_SERVICE_AGREEMENT_PAGE_NANOID;
export const PAYMENTS_PAGE_NANOID =
  process.env.NEXT_PUBLIC_PAYMENTS_PAGE_NANOID;
export const REFUND_PAGE_NANOID = process.env.NEXT_PUBLIC_REFUND_PAGE_NANOID;
export const HELP_PAGE_NANOID = process.env.NEXT_PUBLIC_HELP_PAGE_NANOID;
export const NOTIFICATION_PAGE_NANOID =
  process.env.NEXT_PUBLIC_NOTIFICATION_PAGE_NANOID;
export const WELCOME_PAGE_NANOID = process.env.NEXT_PUBLIC_WELCOME_PAGE_NANOID;
export const LEGAL_INFORMATION_UPDATE_PAGE_NANOID =
  process.env.NEXT_PUBLIC_LEGAL_INFORMATION_UPDATE_PAGE_NANOID;
export const ARCHIVIZIER_TEAM_TIME_ZONE =
  process.env.NEXT_PUBLIC_ARCHIVIZIER_TEAM_TIME_ZONE;
export const WELCOME_INFO_PAGE_NANOID =
  process.env.NEXT_PUBLIC_WELCOME_INFO_PAGE_NANOID;
export const MARKDOWN_HELP_PAGE_NANOID =
  process.env.NEXT_PUBLIC_MARKDOWN_HELP_PAGE_NANOID;
export const ITEM_TYPES_HELP_PAGE_NANOID =
  process.env.NEXT_PUBLIC_ITEM_TYPES_HELP_PAGE_NANOID;

export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID;
export const GTM_DATA_LAYER_NAME = 'UserDataLayer';

export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const HOTJAR_SV = process.env.NEXT_PUBLIC_HOTJAR_SV;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const SUPABASE_BIT_API_PATH = '/api/supabase/bit';

export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY;
export const CRM_SMASHING_CGI_URL =
  process.env.NEXT_PUBLIC_CRM_SMASHING_CGI_URL;

export const REST_API_PROXY_PATH = '/api/rest';

export const BFF_API_PATH = '/api/bff';

export const SUPERSET_DOMAIN = process.env.NEXT_PUBLIC_SUPERSET_DOMAIN;

export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

export const SHOW_PROJECT_TOUR = process.env.NEXT_PUBLIC_SHOW_PROJECT_TOUR;

export const SHOW_PROJECT_TOUR_USER_CREATED_AT =
  process.env.NEXT_PUBLIC_SHOW_PROJECT_TOUR_USER_CREATED_AT;

export const CHAT_GPT_COMPLETIONS_MODEL =
  process.env.NEXT_PUBLIC_CHAT_GPT_COMPLETIONS_MODEL;
