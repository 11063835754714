import React, { Fragment, ReactNode } from 'react';

import { useCurrentUser } from '../../auth/hooks/useAuth/useAuth';

interface CheckPermissionsDefaultProps {
  children: ReactNode;
}

interface CheckPermissionsWithActionProps {
  action: string;
  actions?: never;
}

interface CheckPermissionsWithActionsProps {
  action?: never;
  actions: string[];
}

type CheckPermissionsProps = CheckPermissionsDefaultProps &
  (CheckPermissionsWithActionProps | CheckPermissionsWithActionsProps);

function CheckPermissions({
  action,
  actions,
  children
}: CheckPermissionsProps) {
  const currentUser = useCurrentUser();

  if (action && currentUser.hasPermissions(action)) {
    return <Fragment>{children}</Fragment>;
  }

  if (actions && actions.some((a) => currentUser.hasPermissions(a))) {
    return <Fragment>{children}</Fragment>;
  }

  return <Fragment />;
}

export default CheckPermissions;
