import React from 'react';

import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import {
  AddFundsModalButtonSteps,
  AddFundsModalButtonData,
  AddFundsModalButtonWireBillingInfoStepAction,
  AddFundsModalButtonPaymentMethodAndAmountStepAction,
  AddFundsModalButtonStepBackAction,
  AddFundsModalButtonCloseAction
} from '../../AddFundsModalButton.types';

import {
  FetchTeamAddFundsQueryResponse,
  FETCH_TEAM_ADD_FUNDS_QUERY
} from '../../../../../../teams/queries/fetchTeamAddFunds.query';

import { useCompanyCashAccount } from '../../../../../../accounts/hooks/useCompanyCashAccount';
import { useFinTeam } from '../../../../../../teams/hooks/useFinTeam';

import { AddFundsModalButtonPaymentMethodAndAmountStep } from '../AddFundsModalButtonPaymentMethodAndAmountStep';
import { AddFundsModalButtonWireBillingInfoStep } from '../AddFundsModalButtonWireBillingInfoStep';
import { AddFundsModalButtonCardBillingInfoAndCardStep } from '../AddFundsModalButtonCardBillingInfoAndCardStep';
import { AddFundsModalButtonWireInvoiceInfoStep } from '../AddFundsModalButtonWireInvoiceInfoStep';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { TeamCache } from '../../../../../../teams/TeamCache';

interface AddFundsModalButtonContentProps {
  companyNanoId: TeamNanoID;
  addFundsData: AddFundsModalButtonData;
  addFundsStep: AddFundsModalButtonSteps;
  onPaymentMethodAndAmountStep: AddFundsModalButtonPaymentMethodAndAmountStepAction;
  onWireBillingInfoStep: AddFundsModalButtonWireBillingInfoStepAction;
  onStepBack: AddFundsModalButtonStepBackAction;

  onClose: AddFundsModalButtonCloseAction;
}

function AddFundsModalButtonContent({
  companyNanoId,
  addFundsData,
  addFundsStep,
  onPaymentMethodAndAmountStep,
  onWireBillingInfoStep,
  onStepBack,
  onClose
}: AddFundsModalButtonContentProps) {
  const {
    companyCashAccount,
    companyCashAccountErrorMessage,
    companyCashAccountFetched
  } = useCompanyCashAccount({
    companyNanoId
  });
  const { team, teamError, teamFetched } =
    useFinTeam<FetchTeamAddFundsQueryResponse>({
      cacheKey: TeamCache.showCompanyAddFundsCacheKey(),
      query: FETCH_TEAM_ADD_FUNDS_QUERY,
      uuid: companyNanoId
    });

  const companyCashBalance = companyCashAccount?.balance || 0;

  return (
    <LoadingSkeleton loaded={companyCashAccountFetched && teamFetched}>
      <AlertMessage message={companyCashAccountErrorMessage || teamError} />
      {addFundsStep === AddFundsModalButtonSteps.PAYMENT_METHOD_AND_AMOUNT ? (
        <AddFundsModalButtonPaymentMethodAndAmountStep
          companyCashBalance={companyCashBalance}
          companyPreferredPaymentMethod={team?.preferredPaymentMethod}
          addFundsData={addFundsData}
          onPaymentMethodAndAmountStep={onPaymentMethodAndAmountStep}
          onClose={onClose}
        />
      ) : null}
      {addFundsStep === AddFundsModalButtonSteps.WIRE_BILLING_INFO ? (
        <AddFundsModalButtonWireBillingInfoStep
          companyCashBalance={companyCashBalance}
          companyNanoId={companyNanoId}
          companyDeferredPayment={team?.deferredPayment}
          companyTerms={team?.terms}
          addFundsData={addFundsData}
          onStepBack={onStepBack}
          onWireBillingInfoStep={onWireBillingInfoStep}
        />
      ) : null}
      {addFundsStep === AddFundsModalButtonSteps.CARD_BILLING_INFO_AND_CARD ? (
        <AddFundsModalButtonCardBillingInfoAndCardStep
          companyCashBalance={companyCashBalance}
          companyNanoId={companyNanoId}
          companyDeferredPayment={team?.deferredPayment}
          companyTerms={team?.terms}
          addFundsData={addFundsData}
          onStepBack={onStepBack}
        />
      ) : null}
      {addFundsStep === AddFundsModalButtonSteps.WIRE_INVOICE_INFO ? (
        <AddFundsModalButtonWireInvoiceInfoStep
          addFundsData={addFundsData}
          onClose={onClose}
        />
      ) : null}
    </LoadingSkeleton>
  );
}

export default AddFundsModalButtonContent;
