import { IconProps } from '../types';

function Square3Stack3DOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-12 w-12 text-white'}
      fill="none"
      id={id}
      stroke="currentColor"
      strokeWidth="1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
      />
    </svg>
  );
}

export default Square3Stack3DOutlineIcon;
