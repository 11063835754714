export default {
  are_you_sure_you_want_to_delete_permission_this_action_cannot_be_undone:
    'Чи впевнені Ви, що бажаєте видалити дозвол? Ця дія незворотна.',
  are_you_sure_you_want_to_delete_saved_filter_this_action_cannot_be_undone:
    'Чи впевнені Ви, що бажаєте видалити фільтр? Ця дія незворотна.',
  check_your_email: 'Перевірте свою електронну пошту',
  did_not_receive_the_email: {
    did_not_receive: 'Вам не прийшов лист? Перевірте свій спам-фільтр або',
    try_another: 'спробуйте іншу адресу електронної пошти'
  },
  enter_the_email_associated_with_your_account:
    'Введіть електронну адресу, пов’язану з вашим обліковим записом, і ми надішлемо електронне повідомлення з інструкціями щодо скидання пароля.',
  start_typing_to_search_for_users:
    'Почніть вводити ім‘я, щоб знайти користувачів',
  we_have_sent_a_password_recover_instructions:
    'Ми відправили інструкції з відновлення пароля на вашу електронну пошту.'
};
