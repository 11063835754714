export default {
  code401errorText: 'Доступ запрещен',
  code401statusTextText:
    'Извините, но только авторизованные пользователи имеют доступ к этой странице.',
  code403errorText: 'Доступ запрещен',
  code403statusTextText: 'Извините, но у вас нет доступа к этой странице.',
  code404errorText: 'Страница не найдена',
  code404statusTextText: 'Извините, но страница, которую вы ищите, не найдена.',
  code500errorText: 'Внутренняя ошибка сервера',
  code500statusTextText: 'Извините, но произошла ошибка.'
};
