export default {
  plural: 'Email Templates',
  single: 'Email Template',
  description: 'Description',
  search: 'Search',
  subject: 'Subject',
  subjectUk: 'Subject (UK)',
  subjectEn: 'Subject (EN)',
  create: 'Create email template',
  key: 'Key',
  remove: 'Remove',
  edit: 'Edit'
};
