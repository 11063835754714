import React from 'react';
import compact from 'lodash/compact';

import { AddFundsModalButtonDataBillingInfo } from '../../AddFundsModalButton.types';

import { Translate } from '../../../../../../../helpers/Translate';

import { billsKeys } from '../../../../../../../locales/keys';

interface AddFundsModalButtonBillingInfoInfoProps {
  billingInfo: AddFundsModalButtonDataBillingInfo;
}

function AddFundsModalButtonBillingInfoInfo({
  billingInfo
}: AddFundsModalButtonBillingInfoInfoProps) {
  const avBillingInfo = billingInfo?.avBillingInfo;

  const avCompanyName = avBillingInfo?.companyName;
  const avAddress = avBillingInfo?.address;
  const avLocation = compact([
    avBillingInfo?.city,
    avBillingInfo?.state,
    avBillingInfo?.zipCode,
    avBillingInfo?.country
  ]).join(', ');
  const avContactEmail = avBillingInfo?.contactEmail;
  const avContactPhone = avBillingInfo?.contactPhone;
  const avBankDetails = avBillingInfo?.bankDetails;

  const companyName = billingInfo?.companyName;
  const address = billingInfo?.address;
  const location = compact([
    billingInfo?.city,
    billingInfo?.state,
    billingInfo?.zipCode,
    billingInfo?.country
  ]).join(', ');
  const contactEmail = billingInfo?.contactEmail;
  const contactPhone = billingInfo?.contactPhone;

  return (
    <div className="max-w-3xl mx-auto px-6 pt-8 pb-4">
      <div className="flex gap-6 mb-4">
        <div className="flex-1">
          <div className="text-xs">
            <div className="font-semibold">
              <Translate id={billsKeys.billFrom} />
              {':'}
            </div>
            <div className="text-gray-600 dark:text-gray-400">
              {avCompanyName ? <div>{avCompanyName}</div> : null}
              {avAddress ? <div>{avAddress}</div> : null}
              {avLocation ? <div>{avLocation}</div> : null}
              {avContactEmail ? <div>{avContactEmail}</div> : null}
              {avContactPhone ? <div>{avContactPhone}</div> : null}
              {avBankDetails ? <div>{avBankDetails}</div> : null}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="text-xs">
            <div className="font-semibold">
              <Translate id={billsKeys.billTo} />
              {':'}
            </div>
            <div className="text-gray-600 dark:text-gray-400">
              {companyName ? <div>{companyName}</div> : null}
              {address ? <div>{address}</div> : null}
              {location ? <div>{location}</div> : null}
              {contactPhone ? <div>{contactPhone}</div> : null}
              {contactEmail ? <div>{contactEmail}</div> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFundsModalButtonBillingInfoInfo;
