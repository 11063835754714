import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SectionsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  view: LocalesKey;
}

export const sectionsKeys = keyPathMirror<SectionsKeyType, string>(
  {
    list: null,
    plural: null,
    create: null,
    delete: null,
    view: null
  },
  'models.sections.'
);
