import { useEffect } from 'react';

import first from 'lodash/first';

import { TeamNanoID } from '../../../teams/teamsTypes';
import { GeneralLedgerGeneralLedgerTypes } from '../../../generalLedgers/generalLedgersTypes';

import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../queries/fetchAccounts.query';
import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { useFinPaginatedAccounts } from '../useFinPaginatedAccounts';
import { useFinPaginatedGeneralLedgers } from '../../../generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { AccountCache } from '../../AccountCache';
import { GeneralLedgerCache } from '../../../generalLedgers/GeneralLedgerCache';

import { CASH_ACCOUNT_TYPE_CHART_NUMBER } from '../../accountsConstants';

interface AddFundsModalButtonContentProps {
  companyNanoId: TeamNanoID;
}

function useCompanyCashAccount({
  companyNanoId
}: AddFundsModalButtonContentProps) {
  const prevCompanyNanoId = usePreviousValue(companyNanoId);

  const { generalLedgers, generalLedgersError, changeGeneralLedgersFilters } =
    useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
      cacheKey:
        GeneralLedgerCache.companyDefaultGeneralLedgersCacheKey(companyNanoId),
      query: FETCH_GENERAL_LEDGERS_QUERY,
      initialFilters: {
        generalLedgerType: { eq: GeneralLedgerGeneralLedgerTypes.DEFAULT },
        companyNanoId: { eq: companyNanoId }
      },
      options: {
        enabled: !!companyNanoId,
        enabledPlaceholder: !!companyNanoId,
        withoutPrefetch: true
      }
    });

  const prevGeneralLedgersId = usePreviousValue(first(generalLedgers)?.id);

  const {
    accounts,
    accountsErrorMessage,
    accountsFetched,
    changeAccountsFilters
  } = useFinPaginatedAccounts<FetchAccountsQueryResponse>({
    query: FETCH_ACCOUNTS_QUERY,
    cacheKey: AccountCache.companyCashAccountCacheKey(companyNanoId),
    initialFilters: {
      accountTypeChartNumber: { eq: CASH_ACCOUNT_TYPE_CHART_NUMBER },
      companyNanoId: { eq: companyNanoId },
      generalLedgerId: generalLedgers
        ? { eq: first(generalLedgers)?.id }
        : undefined
    },
    options: {
      enabled: !!companyNanoId && !!first(generalLedgers)?.id,
      enabledPlaceholder: !!companyNanoId && !!first(generalLedgers)?.id,
      withoutPrefetch: true
    }
  });

  useEffect(() => {
    if (prevCompanyNanoId !== companyNanoId) {
      changeAccountsFilters({
        companyNanoId: { eq: companyNanoId }
      });
      changeGeneralLedgersFilters({
        companyNanoId: { eq: companyNanoId }
      });
    }
  }, [
    changeAccountsFilters,
    changeGeneralLedgersFilters,
    companyNanoId,
    prevCompanyNanoId
  ]);

  useEffect(() => {
    if (prevGeneralLedgersId !== first(generalLedgers)?.id) {
      changeAccountsFilters({
        generalLedgerId: { eq: first(generalLedgers)?.id }
      });
    }
  }, [
    changeAccountsFilters,
    generalLedgers,
    prevCompanyNanoId,
    prevGeneralLedgersId
  ]);

  return {
    companyCashAccount: first(accounts) || null,
    companyCashAccountErrorMessage: accountsErrorMessage || generalLedgersError,
    companyCashAccountFetched: accountsFetched
  };
}

export default useCompanyCashAccount;
