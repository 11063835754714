import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface BatchOperationsKeyType {
  author: LocalesKey;
  date: LocalesKey;
  progress: LocalesKey;
  status: LocalesKey;
  statuses: {
    completed: LocalesKey;
    failed: LocalesKey;
    processing: LocalesKey;
  };
  tasks: LocalesKey;
  type: LocalesKey;
  types: {
    batch_task_creation: LocalesKey;
    multiple_task_creation: LocalesKey;
    batch_allocate_funds: LocalesKey;
    batch_transactions_import: LocalesKey;
    batch_users_payoneer_ids_import: LocalesKey;
    batch_revenue_share_items_import: LocalesKey;
    batch_users_payoneer_ids_pe_import: LocalesKey;
    batch_revenue_shares_import: LocalesKey;
  };
}

export const batchOperationsKeys = keyPathMirror<
  BatchOperationsKeyType,
  string
>(
  {
    author: null,
    date: null,
    progress: null,
    status: null,
    statuses: {
      completed: null,
      failed: null,
      processing: null
    },
    tasks: null,
    type: null,
    types: {
      batch_task_creation: null,
      multiple_task_creation: null,
      batch_allocate_funds: null,
      batch_transactions_import: null,
      batch_users_payoneer_ids_import: null,
      batch_revenue_share_items_import: null,
      batch_users_payoneer_ids_pe_import: null,
      batch_revenue_shares_import: null
    }
  },
  'models.batchOperations.'
);
