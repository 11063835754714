import { IconProps } from '../types';

function CrownSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-4 w-4'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 12.7L2 6.3c0-.3.2-.5.4-.6.1 0 .3 0 .4.1l3 2.3c.3.2.8.2 1-.1l2.5-3.8c.2-.3.6-.4.9-.2.1 0 .1.1.2.2L12.9 8c.2.3.7.4 1 .2l3-2.3c.2-.2.6-.1.7.1.4 0 .4.2.4.3l-1 6.3-14 .1zm0 1.1h14v1.6c0 .4-.3.7-.7.7H3.7c-.4 0-.7-.3-.7-.7v-1.6z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CrownSolidIcon;
