import { ClassName, I18nText } from '../../../../types';

import { PageNanoID } from '../../../../main/pages/pagesTypes';
import { IconsEnum } from '../../../../assets/icons/types';

import { ShowPageModalButton } from '../../../../main/pages/components/modalButtons/ShowPageModalButton';

import { words } from '../../../../locales/keys';

import { HELP_PAGE_NANOID } from '../../../../config';

interface HelpCenterModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function HelpCenterModalButton({
  className,
  i18nText,
  tooltipI18nText,
  i18nTextClassName,
  icon,
  iconClassName
}: HelpCenterModalButtonProps) {
  return (
    <ShowPageModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      i18nCancelText={words.close}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={words.helpCenter}
      icon={icon || IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE}
      iconClassName={iconClassName || 'h-6 w-6'}
      pageNanoId={HELP_PAGE_NANOID as PageNanoID}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton
    />
  );
}

export default HelpCenterModalButton;
