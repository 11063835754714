import { IconProps } from '../types';

function FolderImageSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-9 8l3-6 2.3 4.5 1.5-3L16 14H7z" />
    </svg>
  );
}

export default FolderImageSolidIcon;
