import {
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  ItemGqlError,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  TrackTimeInItemGqlQuery,
  UpdateItemCacheKeys,
  FetchItemsCacheKey,
  CreateItemCacheKeys
} from '../../../types';
import {
  UpdateItemsCacheKeys,
  UpdateItemsGqlError,
  UpdateItemsGqlQuery,
  UpdateItemsGqlStatus
} from '../../../types/updateItemsTypes';

export * from './tasksFields';
export * from './fetchTasksTypes';

export type CreateTaskGqlQuery = CreateItemGqlQuery;
export type UpdateTaskGqlQuery = UpdateItemGqlQuery;
export type DownloadTaskResultsGqlQuery = UpdateItemGqlQuery;
export type SendResultsToClientInTaskGqlQuery = UpdateItemGqlQuery;
export type DeleteTaskGqlQuery = DeleteItemGqlQuery;
export type TrackTimeInTaskGqlQuery = TrackTimeInItemGqlQuery;
export type SubmitFileAttachmentsToTaskGqlQuery = CreateItemGqlQuery;

export type DownloadTaskSourceFilesGqlQuery = UpdateItemGqlQuery;
export type DownloadTaskSourceFilesGqlStatus = UpdateItemGqlStatus;
export type DownloadTaskSourceFilesGqlError = ItemGqlError;

export type UpdateTaskCacheKeys = UpdateItemCacheKeys;

export type CreateTaskGqlStatus = CreateItemGqlStatus;
export type UpdateTaskGqlStatus = UpdateItemGqlStatus;
export type DownloadTaskResultsGqlStatus = UpdateItemGqlStatus;
export type SendResultsToClientInTaskGqlStatus = UpdateItemGqlStatus;
export type DeleteTaskGqlStatus = DeleteItemGqlStatus;
export type SubmitFileAttachmentsToTaskGqlStatus = CreateItemGqlStatus;
export type TaskGqlError = ItemGqlError;

export type UpdateTrackTasksTimeGqlQuery = UpdateItemsGqlQuery;
export type UpdateTrackTasksTimeCacheKeys = UpdateItemsCacheKeys;
export type UpdateTrackTasksTimeGqlStatus = UpdateItemsGqlStatus;
export type UpdateTrackTasksTimeGqlError = UpdateItemsGqlError;

export type InviteUsersToTasksOptionsGqlQuery = UpdateItemsGqlQuery;
export type InviteUsersToTasksOptionsGqlStatus = UpdateItemsGqlStatus;
export type InviteUsersToTasksOptionsGqlError = UpdateItemsGqlError;

export type UpdateTasksTagIdsGqlQuery = UpdateItemsGqlQuery;
export type UpdateTasksTagIdsGqlStatus = UpdateItemsGqlStatus;
export type UpdateTasksTagIdsGqlError = UpdateItemsGqlError;

export type AcceptTasksResultsGqlQuery = UpdateItemsGqlQuery;
export type AcceptTasksResultsGqlStatus = UpdateItemsGqlStatus;
export type AcceptTasksResultsGqlError = UpdateItemsGqlError;
export type AcceptTasksResultsCacheKeys = UpdateItemsCacheKeys;

export type CreateTasksBillsGqlStatus = CreateItemGqlStatus;
export type CreateTasksBillsGqlError = ItemGqlError;
export type CreateTasksBillsGqlQuery = CreateItemGqlQuery;
export type CreateTasksBillsCacheKey = FetchItemsCacheKey;
export type CreateTasksBillsCacheKeys = CreateTasksBillsCacheKey[];

export type UpdateTasksStatusGqlQuery = UpdateItemsGqlQuery;
export type UpdateTasksStatusGqlStatus = UpdateItemsGqlStatus;
export type UpdateTasksStatusGqlError = UpdateItemsGqlError;
export type UpdateTasksStatusCacheKeys = UpdateItemsCacheKeys;

export type CopyTasksToProjectGqlQuery = UpdateItemsGqlQuery;
export type CopyTasksToProjectGqlStatus = UpdateItemsGqlStatus;
export type CopyTasksToProjectGqlError = UpdateItemsGqlError;
export type CopyTasksToProjectCacheKeys = UpdateItemsCacheKeys;

export type UpdateTasksImplementationDatesGqlQuery = UpdateItemsGqlQuery;
export type UpdateTasksImplementationDatesGqlStatus = UpdateItemsGqlStatus;
export type UpdateTasksImplementationDatesGqlError = UpdateItemsGqlError;
export type UpdateTasksImplementationDatesCacheKeys = UpdateItemsCacheKeys;

export type UpdateTasksAmountsGqlQuery = UpdateItemsGqlQuery;
export type UpdateTasksAmountsGqlStatus = UpdateItemsGqlStatus;
export type UpdateTasksAmountsGqlError = UpdateItemsGqlError;
export type UpdateTasksAmountsCacheKeys = UpdateItemsCacheKeys;

export type CheckInTasksGqlQuery = UpdateItemsGqlQuery;
export type CheckInTasksGqlStatus = UpdateItemsGqlStatus;
export type CheckInTasksGqlError = UpdateItemsGqlError;
export type CheckInTasksCacheKeys = UpdateItemsCacheKeys;

export type AllocateTasksFundsGqlQuery = UpdateItemsGqlQuery;
export type AllocateTasksFundsGqlStatus = UpdateItemsGqlStatus;
export type AllocateTasksFundsGqlError = UpdateItemsGqlError;
export type AllocateTasksFundsCacheKeys = UpdateItemsCacheKeys;

export type MoveTaskItemsCacheKeys = UpdateItemsCacheKeys;
export type VoidTaskItemsCacheKeys = UpdateItemsCacheKeys;
export type CreateTaskItemsCacheKeys = CreateItemCacheKeys;
