import React from 'react';

import { StaticImageSrcSetData } from './StaticImageHelper.types';

import { generateStaticImageSrcSet } from './utils/generateStaticImageSrcSet';

interface StaticImageHelperProps {
  className?: string;
  src: string;
  srcSetData?: StaticImageSrcSetData;
  alt: string;
}

function StaticImageHelper({
  className,
  src,
  srcSetData,
  alt
}: StaticImageHelperProps) {
  return (
    <img
      className={className}
      src={src}
      srcSet={srcSetData ? generateStaticImageSrcSet(srcSetData) : undefined}
      alt={alt}
    />
  );
}

export default StaticImageHelper;
