import { memo } from 'react';

import { IconsEnum } from '../../../../../../assets/icons/types';

import { useMenu } from '../../../../../hooks/useMenu';

import { PureIconButtonHelper } from '../../../../../../helpers/buttons/PureIconButtonHelper';

function PrimaryMenuMobilesToggleSecondaryMenu() {
  const { isSecondaryMenuOpen, hasSecondarySidebar, toggleSecondaryMenu } =
    useMenu();

  if (!hasSecondarySidebar) {
    return null;
  }

  return (
    <PureIconButtonHelper
      className="sm:hidden bg-white dark:bg-gray-900 shadow-md dark:glow-md rounded-full p-4 w-14 h-14 -mt-3 -ml-2 border dark:border-gray-700 focus:ring-base"
      icon={isSecondaryMenuOpen ? IconsEnum.CROSS : IconsEnum.MENU_OUTLINE}
      iconClassName="h-6 w-6 stroke-2"
      onClick={toggleSecondaryMenu}
    />
  );
}

export default memo(PrimaryMenuMobilesToggleSecondaryMenu);
