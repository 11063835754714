import { useState, useEffect } from 'react';

import { ErrorMessage } from '../../../types';

import { useToastNotification } from '../useToastNotification';

interface ShowToastOnErrorChangeOptions {
  error?: ErrorMessage;
}

function useShowToastOnErrorChange({ error }: ShowToastOnErrorChangeOptions) {
  const { showToastNotification } = useToastNotification({
    appearance: 'error'
  });

  const [prevError, setPrevError] = useState<ErrorMessage>(undefined);

  useEffect(() => {
    if (error !== prevError) {
      setPrevError(error || undefined);

      if (error) {
        showToastNotification(error);
      }
    }
  }, [showToastNotification, error, prevError, setPrevError]);
}

export default useShowToastOnErrorChange;
