import React, { Fragment } from 'react';

import { UserNanoID } from '../../../../../main/users/usersTypes';
import { TeamNanoID } from '../../../../../main/teams/teamsTypes';

import {
  FETCH_USER_NEW_MENU_QUERY,
  FetchUserNewMenuQueryResponse
} from '../../../../../main/users/queries/fetchUserNewMenu.query';

import { useUser } from '../../../../../main/users/hooks/useUser';

import { UserAvatar } from '../../../../../main/common/helpers/UserAvatar';
import { Icon } from '../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';

import { UserCache } from '../../../../../main/users/UserCache';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamPath } from '../../../../../main/teams/TeamPath';

interface CompanyViewHeadUserProps {
  companyNanoId: TeamNanoID;
  userNanoId: UserNanoID;
}

function CompanyViewHeadUser({
  companyNanoId,
  userNanoId
}: CompanyViewHeadUserProps) {
  const { user } = useUser<FetchUserNewMenuQueryResponse>({
    cacheKey: UserCache.showMenuCacheKey(),
    uuid: userNanoId,
    query: FETCH_USER_NEW_MENU_QUERY,
    options: {
      enabled: !!userNanoId,
      enabledPlaceholder: !!userNanoId
    }
  });

  if (!user) {
    return null;
  }

  return (
    <Fragment>
      <Icon
        className="h-3 w-3 2xl:h-4 2xl:w-4"
        icon={IconsEnum.CHEVRON_RIGHT}
      />
      <NextLinkHelper
        className="rounded hover:bg-white/20 pr-2.5 flex gap-2 items-center focus:ring-base focus:ring-0"
        href={TeamPath.companyUserProfile(companyNanoId, userNanoId)}
      >
        <Fragment>
          <UserAvatar
            user={user}
            className="h-5 w-5 2xl:h-8 2xl:w-8 rounded object-cover"
          />

          <div className="font-medium">{user.fullName}</div>
        </Fragment>
      </NextLinkHelper>
    </Fragment>
  );
}

export default CompanyViewHeadUser;
