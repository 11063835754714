import { IconProps } from '../types';

function MagnifyingGlassCircleSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M6.5 9a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"></path>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9 5a4 4 0 102.248 7.309l1.472 1.471a.75.75 0 101.06-1.06l-1.471-1.472A4 4 0 009 5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MagnifyingGlassCircleSolidIcon;
