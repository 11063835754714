import {
  FetchGeneralLedgersCacheKey,
  FetchFinGeneralLedgersFilters,
  FetchGeneralLedgersSort,
  FetchGeneralLedgersPage,
  FetchGeneralLedgersLimit,
  FetchGeneralLedgersGqlQuery
} from '../../generalLedgersTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_GENERAL_LEDGERS_FILTERS,
  INITIAL_GENERAL_LEDGERS_LIMIT,
  INITIAL_GENERAL_LEDGERS_PAGE,
  INITIAL_GENERAL_LEDGERS_SORT
} from '../../generalLedgersConstants';
import { NanoIdFilterType } from '../../../../types';

interface FinPaginatedGeneralLedgersOptions {
  cacheKey: FetchGeneralLedgersCacheKey;
  query: FetchGeneralLedgersGqlQuery;
  initialFilters?: FetchFinGeneralLedgersFilters;
  initialSort?: FetchGeneralLedgersSort;
  initialPage?: FetchGeneralLedgersPage;
  initialLimit?: FetchGeneralLedgersLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  additionalParams?: {
    convertTo?: string;
    itemsAvaitedFilters?: {
      projectNanoId?: NanoIdFilterType;
      taskNanoId?: NanoIdFilterType;
    };
  };
}

const scope = 'generalLedgers';

function useFinPaginatedGeneralLedgers<FetchGeneralLedgersGeneralLedgerType>({
  cacheKey,
  initialFilters = INITIAL_GENERAL_LEDGERS_FILTERS,
  initialSort = INITIAL_GENERAL_LEDGERS_SORT,
  initialPage = INITIAL_GENERAL_LEDGERS_PAGE,
  initialLimit = INITIAL_GENERAL_LEDGERS_LIMIT,
  query,
  options = {},
  additionalParams = {}
}: FinPaginatedGeneralLedgersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchGeneralLedgersGeneralLedgerType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    additionalParams
  });

  return {
    generalLedgersData: data,
    generalLedgers: items,
    generalLedgersError: itemsError,
    generalLedgersErrorMessage: itemsErrorMessage,
    generalLedgersTotalCount: itemsTotalCount,
    generalLedgersFetched: isFetched,
    generalLedgersLoading: isLoading,
    generalLedgersIsPlaceholderData: isPlaceholderData,
    generalLedgersFilters: currentFilters,
    generalLedgersSort: currentSort,
    generalLedgersPage: currentPage,
    generalLedgersLimit: currentLimit,
    updateGeneralLedgerCache: updateItemCache,
    filterGeneralLedgers: filterItems,
    changeGeneralLedgersFilters: changeItemsFilters,
    clearGeneralLedgersFilters: clearItemsFilters,
    sortGeneralLedgers: sortItems,
    paginateGeneralLedgers: paginateItems,
    limitGeneralLedgers: limitItems,
    prefetchGeneralLedgers: prefetchItems
  };
}

export default useFinPaginatedGeneralLedgers;
