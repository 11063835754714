import React, { Fragment } from 'react';

import {
  ProgressCircleIcon,
  ProgressCircleIconCommonProps
} from '../../assets/icons/ProgressCircleIcon';

interface ProgressCircleHelperProps {
  progress: number;
}

function ProgressCircleHelper({
  color,
  progress
}: ProgressCircleHelperProps & ProgressCircleIconCommonProps) {
  return (
    <Fragment>
      <ProgressCircleIcon color={color} progress={progress} />
      <div className="flex items-center justify-center absolute inset-0">
        <span className="text-xs">{progress}%</span>
      </div>
    </Fragment>
  );
}

export default ProgressCircleHelper;
