import React from 'react';

import {
  AddFundsModalButtonData,
  AddFundsModalButtonCloseAction
} from '../../AddFundsModalButton.types';

import { AddFundsModalButtonInvoiceInfo } from '../../helpers/AddFundsModalButtonInvoiceInfo';
import { AddFundsModalButtonBillingInfoInfo } from '../../helpers/AddFundsModalButtonBillingInfoInfo';
import { AddFundsModalButtonItemInfo } from '../../helpers/AddFundsModalButtonItemInfo';
import { AddFundsModalButtonInvoiceNotesAndTermsInfo } from '../../helpers/AddFundsModalButtonInvoiceNotesAndTermsInfo';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { words } from '../../../../../../../locales/keys';

interface AddFundsModalButtonWireInvoiceInfoStepProps {
  addFundsData: AddFundsModalButtonData;
  onClose: AddFundsModalButtonCloseAction;
}

function AddFundsModalButtonWireInvoiceInfoStep({
  addFundsData,
  onClose
}: AddFundsModalButtonWireInvoiceInfoStepProps) {
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <div className="space-y-8 -mx-2">
          <div className="bg-white dark:bg-gray-900">
            <AddFundsModalButtonInvoiceInfo
              invoice={addFundsData?.billingInfoAndInvoice?.invoice}
              billingInfo={addFundsData?.billingInfoAndInvoice?.billingInfo}
            />
            <AddFundsModalButtonBillingInfoInfo
              billingInfo={addFundsData?.billingInfoAndInvoice?.billingInfo}
            />
            <div className="max-w-3xl mx-auto px-2 sm:px-6 pt-4 pb-8">
              <AddFundsModalButtonItemInfo
                amount={addFundsData?.paymentMethodAndAmount?.amount}
              />
              <AddFundsModalButtonInvoiceNotesAndTermsInfo
                invoice={addFundsData?.billingInfoAndInvoice?.invoice}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex p-4 space-x-1 justify-between">
        <div className="flex space-x-1">
          <PureButtonHelper
            i18nText={words.cancel}
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            onClick={onClose}
          />
        </div>
        <div className="flex space-x-1" />
        <div className="flex space-x-1">
          {/* Download and Send to email buttons */}
        </div>
      </div>
    </div>
  );
}

export default AddFundsModalButtonWireInvoiceInfoStep;
