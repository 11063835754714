import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface EvaluationSetsKeyType {
  list: LocalesKey;
  plural: LocalesKey;
  search: LocalesKey;
  searchByProjectName: LocalesKey;
  states: {
    canceled: LocalesKey;
    done: LocalesKey;
    waiting: LocalesKey;
  };
}

export const evaluationSetsKeys = keyPathMirror<EvaluationSetsKeyType, string>(
  {
    list: null,
    plural: null,
    search: null,
    searchByProjectName: null,
    states: {
      canceled: null,
      done: null,
      waiting: null
    }
  },
  'models.evaluationSets.'
);
