import { Currencies } from '../../types';

function getCurrencySymbol(currency: Currencies): string {
  switch (currency) {
    case Currencies.RUB:
      return '₽';
    case Currencies.UAH:
      return '₴';
    default:
      return '$';
  }
}

export default getCurrencySymbol;
