export default {
  are_you_sure_you_want_to_delete_permission_this_action_cannot_be_undone:
    'Are you sure you want to delete permission? This action cannot be undone.',
  are_you_sure_you_want_to_delete_saved_filter_this_action_cannot_be_undone:
    'Are you sure you want to delete saved filter? This action cannot be undone.',
  check_your_email: 'Check your email',
  did_not_receive_the_email: {
    did_not_receive: 'Did not receive the email? Check your spam filter, or',
    try_another: 'try another email address'
  },
  enter_the_email_associated_with_your_account:
    "Enter the email associated with your account and we'll send an email with instructions to reset your password.",
  permission_action_must_contain_only_latin_letters_numbers_or_the_underscore_character:
    'Action must contain only latin letters, numbers, or the underscore character.',
  start_typing_to_search_for_users: 'Start typing to search for users',
  we_have_sent_a_password_recover_instructions:
    'We have sent a password recover instructions to your email.'
};
