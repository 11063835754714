import { IconProps } from '../types';

function UnitsMetricCmSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M3.8 18h12.4c1 0 1.8-.8 1.8-1.8V13h-2v3h-1v-3h-2v2h-1v-2h-2v3H9v-3H7v2H6v-2H4v3H3v-3H2v3.2c0 1 .8 1.8 1.8 1.8zM5 10c.4 0 .7-.1.9-.3s.4-.5.4-.8h1.3c0 .4-.1.8-.4 1.1s-.5.6-.9.8-.9.3-1.3.3c-.9 0-1.6-.3-2.1-.9s-.8-1.3-.8-2.3v-.2c0-1 .3-1.7.8-2.3s1.2-.9 2.1-.9c.8 0 1.4.2 1.8.7s.7 1 .7 1.7H6.2c0-.4-.1-.7-.4-.9s-.5-.3-.8-.3c-.5 0-.8.2-1.1.5s-.4.8-.4 1.5v.2c0 .7.1 1.2.4 1.5s.6.6 1.1.6zm5-5.3v.7c.4-.5 1.1-.8 1.8-.8.8 0 1.4.3 1.7 1 .5-.6 1.1-1 1.9-1 .7 0 1.2.2 1.6.6s.5 1 .5 1.7V11h-1.4V6.9c0-.4-.1-.7-.3-.9s-.5-.3-.9-.3c-.3 0-.6.1-.8.3s-.3.4-.4.7V11h-1.4V6.8c0-.7-.4-1.1-1.1-1.1-.6 0-1 .2-1.2.7V11H8.6V4.7H10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UnitsMetricCmSolidIcon;
