import { useEffect, useRef } from 'react';

import { CreateBillingInfoFormData } from '../../CreateBillingInfoForm.types';
import {
  CreateBillingInfoCacheKey,
  BillingInfoFields
} from '../../../../../billingInfosTypes';
import { CompanyNanoID } from '../../../../../../companies/companiesTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import {
  useCreateBillingInfo,
  CreateBillingInfoResponseType
} from '../../../../../hooks/useCreateBillingInfo';

import { createBillingInfoFormSchema } from './useCreateBillingInfoForm.schema';
import { usaCountryName } from '../../../../../billingInfosConstants';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';

interface CreateBillingInfoFormProps {
  companyNanoId: CompanyNanoID;
  cacheKey: CreateBillingInfoCacheKey;
  withoutReceiver?: boolean;
}

const defaultBillingInfoValues: CreateBillingInfoFormData = {
  companyName: '',
  firstName: '',
  lastName: '',
  contactPhone: '',
  contactEmail: '',
  country: '',
  city: '',
  address: '',
  zipCode: '',
  avBillingInfoId: '',
  paymentMethod: InvoicePaymentMethods.WIRE,
  vatNumber: ''
};

function useCreateBillingInfoForm({
  companyNanoId,
  cacheKey,
  withoutReceiver
}: CreateBillingInfoFormProps) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch,
    setValue
  } = useReactHookForm<CreateBillingInfoFormData>({
    defaultValues: defaultBillingInfoValues,
    isModalForm: true,
    schema: createBillingInfoFormSchema(withoutReceiver)
  });

  const {
    createBillingInfoReset,
    createBillingInfoLoading,
    createBillingInfoErrorMessage,
    createBillingInfo
  } = useCreateBillingInfo({
    cacheKeys: [cacheKey]
  });

  const responseRef = useRef<CreateBillingInfoResponseType>(null);

  const watchPaymentMethod = watch(BillingInfoFields.PAYMENT_METHOD);

  const prevPaymentMethod = usePreviousValue(watchPaymentMethod);

  useEffect(() => {
    if (prevPaymentMethod !== watchPaymentMethod) {
      setValue(BillingInfoFields.AV_BILLING_INFO_ID as 'avBillingInfoId', '');
    }
  }, [prevPaymentMethod, watchPaymentMethod, setValue]);

  return {
    validationErrors: {
      companyNameValidationError: errors?.companyName?.message,
      firstNameValidationError: errors?.firstName?.message,
      lastNameValidationError: errors?.lastName?.message,
      contactPhoneValidationError: errors?.contactPhone?.message,
      contactEmailValidationError: errors?.contactEmail?.message,
      cityValidationError: errors?.city?.message,
      countryValidationError: errors?.country?.message,
      stateValidationError: errors?.state?.message,
      addressValidationError: errors?.address?.message,
      zipCodeValidationError: errors?.zipCode?.message,
      avBillingInfoIdValidationError: errors?.avBillingInfoId?.message,
      paymentMethodValidationError: errors?.paymentMethod?.message,
      vatNumberValidationError: errors?.vatNumber?.message
    },
    control,
    createBillingInfoReset,
    createBillingInfoLoading,
    createBillingInfoErrorMessage,
    resetCreateBillingInfoForm: resetForm,
    handleSubmitReactHookForm,
    handleCreateBillingInfo: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        createBillingInfo({
          billingInfo: {
            ...data,
            companyNanoId,
            state: data.country === usaCountryName ? data.state : ''
          }
        })
    }),
    handleCreateBillingInfoWithResponse: async () => {
      await handleSubmitReactHookForm({
        dirtyFieldsOnly: false,
        onSubmit: async (data) => {
          try {
            const response = await createBillingInfo({
              billingInfo: {
                ...data,
                companyNanoId,
                state: data.country === usaCountryName ? data.state : ''
              }
            });
            responseRef.current = response;
            return response;
          } catch (e) {
            responseRef.current = null;
            throw e;
          }
        }
      })();

      return responseRef.current;
    },
    registerFields: {
      registerCompanyName: register(BillingInfoFields.COMPANY_NAME),
      registerFirstName: register(BillingInfoFields.FIRST_NAME),
      registerLastName: register(BillingInfoFields.LAST_NAME),
      registerContactPhone: register(BillingInfoFields.CONTACT_PHONE),
      registerContactEmail: register(BillingInfoFields.CONTACT_EMAIL),
      registerCity: register(BillingInfoFields.CITY),
      registerCountry: register(BillingInfoFields.COUNTRY),
      registerState: register(BillingInfoFields.STATE),
      registerAddress: register(BillingInfoFields.ADDRESS),
      registerZipCode: register(BillingInfoFields.ZIP_CODE),
      registerAvBillingInfoId: register(BillingInfoFields.AV_BILLING_INFO_ID),
      registerPaymentMethod: register(BillingInfoFields.PAYMENT_METHOD),
      registerVatNumber: register(BillingInfoFields.VAT_NUMBER)
    },
    watchCountry: watch(BillingInfoFields.COUNTRY),
    watchPaymentMethod
  };
}

export default useCreateBillingInfoForm;
