import React from 'react';

import { IconProps } from '../types';

function DocumentArchiveSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4 6L12 2.6c-.4-.4-.9-.6-1.4-.6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7.4c0-.5-.2-1-.6-1.4zM10 11H8v1h2v1H8v1h2v1H8v1h2v1H8v-1H6v-1h2v-1H6v-1h2v-1H6v-1h2v-1h2v1zm0-3c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V3h4v5zM9 6H7V4h2v2z"
      />
    </svg>
  );
}

export default DocumentArchiveSolidIcon;
