import { IconProps } from '../types';

function PhotoAiSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M8.4 19.7v.1c0 .1 0 .1-.1.1l-.1.1h-1c-.1 0-.2 0-.2-.1s-.1-.1-.1-.1l-.2-.7H4.3l-.2.7c0 .1-.1.1-.1.1-.1.1-.1.1-.2.1H2.7c-.1 0-.1 0-.1-.1l-.1-.1v-.1l1.9-5.4c0-.1.1-.1.1-.2.1-.1.2-.1.3-.1h1.4c.1 0 .2 0 .2.1.1.1.1.1.1.2l1.9 5.4zm-2.2-2l-.7-2.1-.7 2.1h1.4zm3 2.2s-.1-.1-.1-.2v-5.5c0-.1 0-.1.1-.2 0 0 .1-.1.2-.1h1.1c.1 0 .1 0 .2.1 0 0 .1.1.1.2v5.5c0 .1 0 .1-.1.2 0 0-.1.1-.2.1H9.4c-.1 0-.1 0-.2-.1zM16.8 3H3.2C2 3 1 4 1 5.2v9.5c0 1 .6 1.8 1.5 2.1l.5-1.4c-.3-.1-.4-.4-.4-.7V11l3-3c.3-.3.8-.3 1.1 0l5.2 5.2c.3.3.8.3 1.1 0 .3-.3.3-.8 0-1.1l-.5-.5 1.9-1.9c.3-.3.8-.3 1.1 0l2.2 2.2v2.7c0 .4-.3.8-.8.8h-5V17h5c1.2 0 2.2-1 2.2-2.2V5.2C19 4 18 3 16.8 3zM11 8c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PhotoAiSolidIcon;
