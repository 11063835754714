import { IconProps } from '../types';

function DocumentPdfSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 11.5l.8 1.5H9.2l.8-1.5zm0-2.2l.3-.6s.1-.1.1-.2c0 0 0-.2-.2-.3-.1 0-.2-.1-.2-.1s-.1 0-.2.1c-.1 0-.2.2-.2.3 0 .1 0 .2.1.2l.3.6zm6-1.9V16c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h4.6c.5 0 1 .2 1.4.6L15.4 6c.4.4.6.9.6 1.4zm-1.2 6.3c-.3-.5-.8-.7-1.3-.7h-1.3l-1.5-2.6.7-1.2c.3-.5.3-1.1 0-1.6-.4-.5-.8-.7-1.4-.7s-1 .3-1.3.8-.3 1 0 1.5l.7 1.2L7.9 13H6.5c-.6 0-1 .3-1.3.8s-.3 1 0 1.5c.3.4.8.7 1.3.7.6 0 1-.3 1.3-.8l.7-1.2h3l.7 1.2c.3.5.8.8 1.3.8.6 0 1-.3 1.3-.8.3-.4.3-1 0-1.5zm-1.3.4h-.7l.3.6s0 .1.1.1c.1.1.4.1.5-.1.1-.2.1-.5-.1-.6h-.1zm-7.3.6c.1.2.4.2.5.1l.1-.1.3-.6h-.6s-.1 0-.2.1c-.2 0-.3.3-.1.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DocumentPdfSolidIcon;
