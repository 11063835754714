import { IconProps } from '../types';

function AdjustmentsAltSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path d="M17 3h-3V2c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1V5h3c.6 0 1-.4 1-1s-.4-1-1-1zM2 4c0 .6.4 1 1 1h7V3H3c-.6 0-1 .4-1 1zM17 9h-7v2h7c.6 0 1-.4 1-1s-.4-1-1-1zM7 7c-.6 0-1 .4-1 1v1H3c-.6 0-1 .4-1 1s.4 1 1 1h3v1c0 .6.4 1 1 1s1-.4 1-1V8c0-.6-.4-1-1-1zM17 15h-3v-1c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1v-1h3c.6 0 1-.4 1-1s-.4-1-1-1zM2 16c0 .6.4 1 1 1h7v-2H3c-.6 0-1 .4-1 1z" />
    </svg>
  );
}

export default AdjustmentsAltSolidIcon;
