import { BillingInfosKeyType } from '../../keys';

const billingInfosKeys: BillingInfosKeyType = {
  addBillingInfo: 'Add billing info',
  addYourBillingAddressSoWeCanCreateAnInvoiceForYourProject:
    'Add your billing address so we can create an invoice for your project',
  billingInfoCopied: 'Billing info copied',
  billFromColon: 'Bill from:',
  billToColon: 'Bill to:',
  copyBillingInfo: 'Copy billing info',
  change: 'Change billing info',
  default: 'Default billing info',
  plural: 'Billing infos',
  provideYourBillingInfoInOrderToCreateInvoiceForTheProject:
    'Provide your billing info in order to create invoice for the project',
  receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided:
    'Receiver billing info will fill automatically after payer billing info is provided',
  selectState: 'Select state',
  singular: 'Billing info',
  updateBillingInfo: 'Update billing info',
  useAnotherBillingInfo: 'Use another billing info'
};

export default billingInfosKeys;
