import { useState } from 'react';

import { LocalStorageKey } from './useLocalStorage.types';

function isQuotaExceededError(err: unknown): boolean {
  return (
    err instanceof DOMException &&
    (err.name === 'QuotaExceededError' ||
      err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  );
}

function useLocalStorage<ValueType>(
  key: LocalStorageKey,
  initialValue: ValueType
) {
  const [storedValue, setStoredValue] = useState<ValueType>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  const setValue = (
    value: ValueType,
    replacer?: (this: any, key: string, value: any) => any
  ) => {
    try {
      setStoredValue(value);

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value, replacer));
      }
    } catch (error) {
      if (isQuotaExceededError(error)) {
        console.error('Local storage quota exceeded: ', error);
        window.localStorage.clear();

        window.localStorage.setItem(key, JSON.stringify(value, replacer));

        return;
      }

      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
