import { IconProps } from '../types';

function FolderFileSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h4c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2 1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-2 11h-4c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1h2.3l2.7 2.7V16c0 .6-.4 1-1 1z" />
    </svg>
  );
}

export default FolderFileSolidIcon;
