import React, { useCallback, useMemo } from 'react';
import filter from 'lodash/filter';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import map from 'lodash/map';

import {
  CountryMultiSelectOptionalDataProps,
  CountryMultiSelectValueType
} from './CountryMultiSelectFieldControl.types';
import {
  MultiSelectChangeCallbackType,
  MultiSelectInputChangeCallbackType
} from '../../../../MultiSelect/types';

import {
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueType
} from '../../../../MultiSelect';

interface CountryMultiSelectFieldControlProps {
  value?: CountryMultiSelectValueType;
  onChange: (updatedValue) => void;
  multi: boolean;
  onInputChange?: MultiSelectInputChangeCallbackType;
  isSearchable?: boolean;
  optionsLoading?: boolean;
  onMenuScrollToBottom?: () => void;
  isLoading?: boolean;
}

function CountryMultiSelectFieldControl({
  classNamePrefix = null,
  closeMenuOnSelect = true,
  data = [],
  defaultValue = null,
  disabled = false,
  error,
  errorClassName,
  emptyValue = null,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  isLoading = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  optionsLoading = false,
  placeholder = null,
  showError,
  value,
  onInputChange,
  onBlur,
  onChange,
  onMenuScrollToBottom
}: MultiSelectProps &
  CountryMultiSelectFieldControlProps &
  CountryMultiSelectOptionalDataProps) {
  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue) => {
      onChange(
        isArray(updateValue) ? map(updateValue, 'value') : updateValue?.value
      );
    },
    [onChange]
  );

  const multiSelectValue = useMemo<MultiSelectValueType>(
    () =>
      filter(data, (option) =>
        isArray(value)
          ? some(value, (v) => v === option.value)
          : value === option.value
      ),
    [data, value]
  );

  return (
    <MultiSelect
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={closeMenuOnSelect}
      data={data}
      defaultValue={defaultValue}
      disabled={disabled}
      emptyValue={emptyValue}
      error={error}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isClearable={isClearable}
      isSearchable={isSearchable}
      labelClassName={labelClassName}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      multi={multi}
      onChange={handleChange}
      onInputChange={onInputChange}
      optionsLoading={optionsLoading}
      placeholder={placeholder}
      value={multiSelectValue}
      name={name}
      onMenuScrollToBottom={onMenuScrollToBottom}
      isLoading={isLoading}
      onBlur={onBlur}
      errorClassName={errorClassName}
      showError={showError}
    />
  );
}

export default CountryMultiSelectFieldControl;
