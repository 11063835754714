export enum PrimaryNavTabsEnum {
  DASHBOARD = 'dashboard',
  NOTIFICATIONS = 'notifications',
  TASKS_AND_PROJECTS = 'tasks_and_projects',
  COMPACT_WORKSPACE = 'compact_workspace',
  STOCK = 'stock',
  COMPANY_LIBRARY = 'company_library',
  USERS = 'users',
  WORKERS = 'workers',
  CLIENTS = 'clients',
  PEOPLE = 'people',
  ADMIN = 'admin',
  PAYMENTS = 'payments',
  REPORTS = 'reports',
  COMPANY_VIEW_DASHBOARD = 'company_view_dashboard',
  COMPANY_VIEW_PAYMENTS = 'company_view_payments',
  COMPANY_VIEW_WORKSPACE = 'company_view_workspace',
  COMPANY_VIEW_LIBRARY = 'company_view_library'
}
