import { IconProps } from '../types';

function ListTodoSolid({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M17 11h-6c-.6 0-1-.4-1-1s.4-1 1-1h6c.6 0 1 .4 1 1s-.4 1-1 1zm1-6c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1zm0 10c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1zM5.2 6.5L7.7 4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L4.5 4.3l-.8-.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.5 1.5c.2.3.4.4.7.4s.5-.1.7-.3zm0 5L7.7 9c.4-.4.4-1 0-1.4s-1-.4-1.4 0L4.5 9.3l-.8-.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.5 1.5c.2.2.5.3.7.3s.5 0 .7-.2zm0 5L7.7 14c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.8 1.8-.8-.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.5 1.5c.2.2.5.3.7.3s.5-.1.7-.3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ListTodoSolid;
