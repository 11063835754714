import { IconsEnum } from '../../../assets/icons/types';
import { AppCrmNames } from '../../../types';

import { APP_CRM_NAME } from '../../../config';

function getAppIcon() {
  switch (APP_CRM_NAME) {
    case AppCrmNames.SMASHING_CGI:
      return {
        appIcon: IconsEnum.SMASHING_CGI,
        appIconBgColorClassName: 'bg-gradient-to-tr from-blue-600 to-blue-400'
      };
    case AppCrmNames.MARKET_THRIVE:
      return {
        appIcon: IconsEnum.MARKET_THRIVE,
        appIconBgColorClassName: 'dark:bg-white'
      };
    default:
      return {
        appIcon: IconsEnum.AV,
        appIconBgColorClassName: 'bg-gradient-to-tr from-blue-600 to-blue-400'
      };
  }
}

export default getAppIcon;
