import { IconProps } from '../types';

function KeyboardSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M16 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5.3 2h2v2h-2V6zm0 3h2v2h-2V9zM7.3 6h2v2h-2V6zm0 3h2v2h-2V9zM4 6h2v2H4V6zm0 3h2v2H4V9zm10 5H6v-2h8v2zm2-3h-2V9h2v2zm0-3h-2V6h2v2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default KeyboardSolidIcon;
