import { IconProps } from '../types';

function PinIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-4 w-4'}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M16.3 9.5c.6.2 1.3 0 1.7-.4l.7-.7c.5-.5.5-1.2 0-1.6l-5.4-5.4c-.4-.4-1.2-.4-1.7 0l-.7.6c-.5.5-.6 1.1-.4 1.7.1.3 0 .5-.2.7L7.8 7.1c-.2.1-.4.2-.6.2h-.8c-.5 0-1 .2-1.3.5l-.3.4c-.2.2-.4.5-.4.8s.1.6.3.8l2 2-5.5 5.5c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l5.5-5.5 2 2c.2.2.5.3.8.3.3 0 .6-.1.8-.3l.3-.3c.3-.3.5-.8.5-1.3v-.8c0-.2.1-.4.2-.6l2.6-2.6c.4-.2.7-.2 1-.1z" />
    </svg>
  );
}

export default PinIcon;
