import { FetchSmartContractsSortTypes } from './smartContractsTypes';

export const INITIAL_SMART_CONTRACTS_FILTERS = {};
export const INITIAL_SMART_CONTRACTS_PAGE = 1;
export const INITIAL_SMART_CONTRACTS_SORT = [
  FetchSmartContractsSortTypes.CREATED_AT_DESC
];
export const INITIAL_SMART_CONTRACTS_LIMIT = 50;

export const SMART_CONTRACT_SHARES_INVOICES_AMOUNTS_ROUNDING_ADJUSTMENT_KEY =
  'rounding_adjustment';

export const SMART_CONTRACT_SHARES_INVOICES_AMOUNTS_INVOICE_AMOUNT_KEY =
  'invoices_amount';

export enum SmartContractsPermissions {
  READ_PAYMENTS_SMART_CONTRACTS_MENU_INDEX_LINK = 'read_payments_smart_contracts_menu_index_link',
  READ_PAYMENTS_SMART_CONTRACTS_MENU_DRAFT_LINK = 'read_payments_smart_contracts_menu_draft_link',
  READ_PAYMENTS_SMART_CONTRACTS_MENU_CANCELED_LINK = 'read_payments_smart_contracts_menu_canceled_link',
  READ_PAYMENTS_SMART_CONTRACTS_INDEX_PAGE = 'read_payments_smart_contracts_index_page',
  READ_PAYMENTS_SMART_CONTRACTS_DRAFT_FILTER_PAGE = 'read_payments_smart_contracts_draft_filter_page',
  READ_PAYMENTS_SMART_CONTRACTS_CANCELED_FILTER_PAGE = 'read_payments_smart_contracts_canceled_filter_page',
  READ_SMART_CONTRACTS_TABLE_SELECTED_FIELD = 'read_smart_contracts_table_selected_field',
  READ_SMART_CONTRACTS_TABLE_ID_FIELD = 'read_smart_contracts_table_id_field',
  READ_SMART_CONTRACTS_TABLE_PAYER_FIELD = 'read_smart_contracts_table_payer_field',
  READ_SMART_CONTRACTS_TABLE_STATUS_FIELD = 'read_smart_contracts_table_status_field',
  READ_SMART_CONTRACTS_TABLE_PROJECT_FIELD = 'read_smart_contracts_table_project_field',
  READ_SMART_CONTRACTS_TABLE_TASK_FIELD = 'read_smart_contracts_table_task_field',
  READ_SMART_CONTRACTS_TABLE_TOTAL_AMOUNT_FIELD = 'read_smart_contracts_table_total_amount_field',
  READ_SMART_CONTRACTS_TABLE_NET_AMOUNT_FIELD = 'read_smart_contracts_table_net_amount_field',
  READ_SMART_CONTRACTS_TABLE_INVOICES_FIELD = 'read_smart_contracts_table_invoices_field',
  READ_SMART_CONTRACTS_TABLE_DUE_DATE_FIELD = 'read_smart_contracts_table_due_date_field',
  READ_FILTERED_SMART_CONTRACTS_TABLE_SELECTED_FIELD = 'read_filtered_smart_contracts_table_selected_field',
  READ_FILTERED_SMART_CONTRACTS_TABLE_PROJECT_FIELD = 'read_filtered_smart_contracts_table_project_field',
  READ_FILTERED_SMART_CONTRACTS_TABLE_TASK_FIELD = 'read_filtered_smart_contracts_table_task_field',
  READ_FILTERED_SMART_CONTRACTS_TABLE_TOTAL_PAID_FIELD = 'read_filtered_smart_contracts_table_total_paid_field',
  READ_FILTERED_SMART_CONTRACTS_TABLE_TOTAL_AMOUNT_FIELD = 'read_filtered_smart_contracts_table_total_amount_field'
}
