import { IconProps } from '../types';

function RulersOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM3 9h18M9 21V7m6 2V7m-3 2V6m6 3V6m-9 9H7m2 3H6m3-6H6m0-3V6"
      />
    </svg>
  );
}

export default RulersOutlineIcon;
