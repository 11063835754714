import { IconProps } from '../types';

function CellsSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5 shrink-0'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 12.8l-2.4-1.4V8.6L10 7.2l2.4 1.4v2.8L10 12.8zm-3.2-1.4V8.6L4.4 7.2 2 8.6v2.8l2.4 1.4 2.4-1.4zm2.8 2L7.2 12l-2.4 1.4v2.8l2.4 1.4 2.4-1.4v-2.8zm3.2-1.3l-2.4 1.4v2.8l2.4 1.4 2.4-1.4v-2.8l-2.4-1.4zm.4-3.5v2.8l2.4 1.4 2.4-1.4V8.6l-2.4-1.4-2.4 1.4zm-2.8-2L12.8 8l2.4-1.4V3.8l-2.4-1.4-2.4 1.4v2.8zM7.2 7.9l2.4-1.4V3.7L7.2 2.4 4.8 3.8v2.8l2.4 1.3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CellsSolidIcon;
