import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TeamsKeyType {
  addPerformer: LocalesKey;
  addUser: LocalesKey;
  addTerms: LocalesKey;
  addYourTeamMembersToCompany: LocalesKey;
  addEvent: LocalesKey;
  accountReceivables: LocalesKey;
  agreement: LocalesKey;
  artistsTeam: LocalesKey;
  airTable: LocalesKey;
  allCompanies: LocalesKey;
  allPerformers: LocalesKey;
  automaticDebtRepayment: LocalesKey;
  automaticPaymentSC: LocalesKey;
  automaticContractorPayment: LocalesKey;
  autoCheckIn: LocalesKey;
  autoCheckInRule: LocalesKey;
  autoCheckInRules: LocalesKey;
  author: LocalesKey;
  basicInfo: LocalesKey;
  blockAccount: LocalesKey;
  changeEmail: LocalesKey;
  changeCompanyOwner: LocalesKey;
  createCompany: LocalesKey;
  created: LocalesKey;
  createAndViewCompany: LocalesKey;
  companies: LocalesKey;
  corevsharers: LocalesKey;
  clients: LocalesKey;
  companyMembers: LocalesKey;
  companyHasNoOwner: LocalesKey;
  companyLibrary: LocalesKey;
  companyName: LocalesKey;
  companyUsers: LocalesKey;
  cm: LocalesKey;
  dashboard: LocalesKey;
  documents: LocalesKey;
  debtLimit: LocalesKey;
  deferredPayment: LocalesKey;
  description: LocalesKey;
  deliveryTime: LocalesKey;
  deleteTemplate: LocalesKey;
  downloadAllItems: LocalesKey;
  editCompanyPicture: LocalesKey;
  enterTheEmailAddressOfTheUser: LocalesKey;
  eventDispatcher: LocalesKey;
  eventName: LocalesKey;
  favorite: LocalesKey;
  financeInfo: LocalesKey;
  finance: {
    balance: LocalesKey;
    debt: LocalesKey;
    revenue: LocalesKey;
    sigmaPayments: LocalesKey;
  };
  finLastPayments: LocalesKey;
  finStatus: LocalesKey;
  files: LocalesKey;
  followers: LocalesKey;
  goToProfile: LocalesKey;
  handler: LocalesKey;
  hourlyRate: LocalesKey;
  holdRate: LocalesKey;
  bookId: LocalesKey;
  info: LocalesKey;
  inviteNewUser: LocalesKey;
  lastPayments: LocalesKey;
  lastActivity: LocalesKey;
  list: LocalesKey;
  leaders: LocalesKey;
  marketingData: LocalesKey;
  name: LocalesKey;
  ownerId: LocalesKey;
  payoutStatus: LocalesKey;
  performers: LocalesKey;
  performersTat: LocalesKey;
  performersTeam: LocalesKey;
  plural: LocalesKey;
  pm: LocalesKey;
  profile: LocalesKey;
  provideAccess: LocalesKey;
  provisionForBadDebt: LocalesKey;
  provisionForRevision: LocalesKey;
  permissions: LocalesKey;
  personalization: LocalesKey;
  recentCompanies: LocalesKey;
  recalculateBalances: LocalesKey;
  records: LocalesKey;
  reports: LocalesKey;
  respondTime: LocalesKey;
  requirements: LocalesKey;
  resume: LocalesKey;
  restoreEvent: LocalesKey;
  revenueShare: LocalesKey;
  revisions: LocalesKey;
  singular: LocalesKey;
  source: LocalesKey;
  status: LocalesKey;
  searchCompanies: LocalesKey;
  searchPerformers: LocalesKey;
  selectCompany: LocalesKey;
  selectCompanies: LocalesKey;
  selectCompanyWithoutEllipsis: LocalesKey;
  selectEvent: LocalesKey;
  settings: LocalesKey;
  sendInvitations: LocalesKey;
  shares: LocalesKey;
  smartContractSettings: LocalesKey;
  statuses: {
    new: LocalesKey;
    active: LocalesKey;
    paused: LocalesKey;
    declined: LocalesKey;
    realDecline: LocalesKey;
    noPayments: LocalesKey;
  };
  subbooks: LocalesKey;
  suspendEvent: LocalesKey;
  scTemplates: LocalesKey;
  tasksWithoutSourceFiles: LocalesKey;
  tat: LocalesKey;
  terms: LocalesKey;
  transactionFee: LocalesKey;
  trigger: LocalesKey;
  viewPerTask: LocalesKey;
  vendorForm: LocalesKey;
  billingInfo: LocalesKey;
  items: LocalesKey;
  wire: LocalesKey;
  card: LocalesKey;
  preferredPaymentMethod: LocalesKey;
  addBillingInfoToSetPaymentMethod: LocalesKey;
  pleaseSelectBillingInfo: LocalesKey;
  merge: LocalesKey;
  mergeCompany: LocalesKey;
  mergeCompaniesWarning: {
    caution: LocalesKey;
    allUserInformationFromTheSelected: LocalesKey;
    company: LocalesKey;
    willBe: LocalesKey;
    merged: LocalesKey;
  };
  myCompanies: LocalesKey;
  selectUser: LocalesKey;
  uploadInvoices: LocalesKey;
  usersInvited: LocalesKey;
}

export const teamsKeys = keyPathMirror<TeamsKeyType, string>(
  {
    addPerformer: null,
    addUser: null,
    addTerms: null,
    addYourTeamMembersToCompany: null,
    addEvent: null,
    accountReceivables: null,
    agreement: null,
    artistsTeam: null,
    airTable: null,
    allCompanies: null,
    allPerformers: null,
    automaticDebtRepayment: null,
    automaticPaymentSC: null,
    automaticContractorPayment: null,
    autoCheckIn: null,
    autoCheckInRule: null,
    autoCheckInRules: null,
    author: null,
    basicInfo: null,
    blockAccount: null,
    changeEmail: null,
    changeCompanyOwner: null,
    createCompany: null,
    created: null,
    createAndViewCompany: null,
    companies: null,
    corevsharers: null,
    clients: null,
    companyMembers: null,
    companyHasNoOwner: null,
    companyLibrary: null,
    companyName: null,
    companyUsers: null,
    cm: null,
    dashboard: null,
    documents: null,
    debtLimit: null,
    deferredPayment: null,
    description: null,
    deliveryTime: null,
    deleteTemplate: null,
    downloadAllItems: null,
    editCompanyPicture: null,
    enterTheEmailAddressOfTheUser: null,
    eventDispatcher: null,
    eventName: null,
    favorite: null,
    financeInfo: null,
    finance: {
      balance: null,
      debt: null,
      revenue: null,
      sigmaPayments: null
    },
    finLastPayments: null,
    finStatus: null,
    files: null,
    followers: null,
    goToProfile: null,
    handler: null,
    hourlyRate: null,
    holdRate: null,
    bookId: null,
    info: null,
    inviteNewUser: null,
    lastPayments: null,
    lastActivity: null,
    list: null,
    leaders: null,
    marketingData: null,
    name: null,
    ownerId: null,
    payoutStatus: null,
    performers: null,
    performersTat: null,
    performersTeam: null,
    plural: null,
    pm: null,
    profile: null,
    provideAccess: null,
    provisionForBadDebt: null,
    provisionForRevision: null,
    permissions: null,
    personalization: null,
    recentCompanies: null,
    recalculateBalances: null,
    records: null,
    reports: null,
    respondTime: null,
    requirements: null,
    resume: null,
    restoreEvent: null,
    revenueShare: null,
    revisions: null,
    singular: null,
    source: null,
    status: null,
    searchCompanies: null,
    searchPerformers: null,
    selectCompany: null,
    selectCompanies: null,
    selectCompanyWithoutEllipsis: null,
    selectEvent: null,
    settings: null,
    sendInvitations: null,
    shares: null,
    smartContractSettings: null,
    statuses: {
      new: null,
      active: null,
      paused: null,
      declined: null,
      realDecline: null,
      noPayments: null
    },
    subbooks: null,
    suspendEvent: null,
    scTemplates: null,
    tasksWithoutSourceFiles: null,
    tat: null,
    terms: null,
    transactionFee: null,
    trigger: null,
    viewPerTask: null,
    vendorForm: null,
    billingInfo: null,
    items: null,
    wire: null,
    card: null,
    preferredPaymentMethod: null,
    addBillingInfoToSetPaymentMethod: null,
    pleaseSelectBillingInfo: null,
    merge: null,
    mergeCompany: null,
    mergeCompaniesWarning: {
      caution: null,
      allUserInformationFromTheSelected: null,
      company: null,
      willBe: null,
      merged: null
    },
    myCompanies: null,
    selectUser: null,
    uploadInvoices: null,
    usersInvited: null
  },
  'models.teams.'
);
