import { CreatedAt, NanoID, UUID } from '../../types';

export type AccountTypeUUID = UUID;
export type AccountTypeNanoID = NanoID;
export type AccountTypeName = string;
export type AccountTypeClientName = string;
export type AccountTypePerformerName = string;
export type AccountTypeShortName = string;
export type AccountTypeSystemName = string;
export type AccountTypeChartNumber = string;
export type AccountTypeCreatedAt = CreatedAt;

export const enum AccountTypeIncreaseSides {
  DEBIT = 'debit',
  CREDIT = 'credit'
}

export const enum AccountTypeTypes {
  ASSETS = 'assets',
  EQUITY = 'equity',
  LIABILITY = 'liability',
  PROVISIONS = 'provisions',
  REVENUE = 'revenue',
  EXPENSES = 'expenses'
}
