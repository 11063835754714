import { IconProps } from '../types';

function UnitsMetricInSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        fillRule="evenodd"
        d="M3.8 18h12.4c1 0 1.8-.8 1.8-1.8V13h-2v3h-1v-3h-2v2h-1v-2h-2v3H9v-3H7v2H6v-2H4v3H3v-3H2v3.2c0 1 .8 1.8 1.8 1.8zM5.9 3c0-.2.1-.4.2-.5s.3-.2.6-.2.5.1.6.2.2.3.2.5-.1.4-.2.5-.4.3-.6.3-.5-.1-.6-.3-.2-.3-.2-.5zm1.5 8H6V4.7h1.4V11zm2.8-6.3v.7c.5-.6 1.1-.8 1.8-.8 1.3 0 2 .8 2 2.3V11h-1.4V6.9c0-.4-.1-.7-.3-.9s-.3-.3-.7-.3c-.6 0-1 .3-1.3.8V11H8.9V4.7h1.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UnitsMetricInSolidIcon;
