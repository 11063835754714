import { IconProps } from '../types';

function ArrowDownCircleOutline({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-5 w-5'}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      id={id}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export default ArrowDownCircleOutline;
