import { RevenueShareItemsKeyType } from '../../keys';

const revenueShareItemsKeys: RevenueShareItemsKeyType = {
  addShare: 'Add share',
  areYouSureYouWantToEnableThisRevenueShare:
    'Are you sure you want to enable this revenue share?',
  areYouSureYouWantToDisableThisRevenueShare:
    'Are you sure you want to disable this revenue share?',
  create: 'Create revenue share',
  delete: 'Delete revenue share',
  endDate: 'End date',
  incoming: 'Incoming',
  moveTo: 'Move to',
  name: 'Name',
  outgoing: 'Outgoing',
  past: 'Past revenue shares',
  plural: 'Revenue shares',
  search: 'Search revenue shares',
  select: 'Select revenue share...',
  selectShare: 'Select share',
  selectedUserHasNoShares: 'Selected user has no shares.',
  share: 'Share',
  single: 'Revenue share',
  startDate: 'Start date',
  update: 'Update revenue share',
  willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow:
    ' will be automatically checked into all new smart contracts in which participate users from the list below.',
  usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate:
    'Users from the list below will be automatically checked into all new smart contracts in which participate '
};

export default revenueShareItemsKeys;
