import { UUID } from '../../types';
import {
  DownloadFile,
  DownloadName
} from '../../main/downloads/downloadsTypes';

import { downloadFile } from '../downloadFile';

export interface DownloadMultipleFilesDownloadFile {
  uuid: UUID;
  name: DownloadName;
  file: DownloadFile;
}

interface DownloadMultipleFilesOptions {
  downloadFiles: DownloadMultipleFilesDownloadFile[];
  delay: number;
  delayForStart?: number;
}

function downloadMultipleFiles({
  delay,
  downloadFiles,
  delayForStart
}: DownloadMultipleFilesOptions) {
  function downloadNext(i: number): Promise<void> {
    if (i >= downloadFiles.length) {
      return;
    }

    downloadFile(downloadFiles[i]);

    setTimeout(function () {
      downloadNext(i + 1);
    }, delay);
  }

  setTimeout(function () {
    downloadNext(0);
  }, delayForStart || 0);
}

export default downloadMultipleFiles;
