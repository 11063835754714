export default {
  areYouSureYouWantToCancelTheTask: 'Вы уверены, что хотите отменить задачу?',
  areYouSureYouWantToClearSelected:
    'Вы уверены, что хотите очистить выбранное?',
  approveTaskResult: 'Утвердить результаты задачи',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'Оценивая задачи, вы принимаете результаты задачи для клиента',
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: `Прежде чем Вы примете задачу, мы хотели бы напомнить Вам, что:`,
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: `Как только Вы примете задачу, она закроется, и Вы не сможете оставлять в ней новые комментарии.`,
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
    'Если после этого Вы зхотите, чтобы результат был изменен или скорректирован, для этого будет создана новая задача, за которую будет взиматься соответствующая плата.',
  pleaseProvideYourAddress: 'Пожалуйста, предоставьте ваш адрес',
  pleaseRateTheTask: 'Оцените задачу',
  pleaseRateTaskRusult: 'Оцените качество результата',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'Платеж принят в обработку. Обычно платеж обрабатывается и зачисляется, либо отменяется платежной системой/банком выпустившим карту в течении 10 - 30 минут. В это время не нужно предпринимать новые попытки оплаты.',
  thePaymentHasBeenCanceled: 'Платеж отменен.',
  youWillBeRedirectedToTheProjectPageIn:
    'Вы будете перенаправлены на страницу проекта в течении',
  youWillBeRedirectedToThePaymentsPageIn:
    'Вы будете перенаправлены на страницу платежей в течении',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Вы уверены, что хотите удалить эту запись? Это действие не может быть отменено.',
  thisActionWillAffect: 'Это действие повлияет на',
  indicateHowMuchDoYouAgreeWithTheFollowingStatement:
    'Отметтье насколько Вы согласны с утверждением ниже'
};
