import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';

import { FiltersViewGroups } from '../../../../../types';

import {
  ExtendedFiltersBaseFilters,
  ExtendedFiltersGroups,
  ExtendedFiltersContextStateByViewGroupRef,
  ExtendedFiltersContextState,
  ExtendedFiltersContextChangeStateByViewGroupAction,
  ExtendedFiltersOnFiltersStateChange
} from '../../useExtendedFilters.types';

import { useLocalStorage } from '../../../../../common/hooks/useLocalStorage';

import { extendedFiltersMergeGroups } from '../../utils/extendedFiltersMergeGroups';
import { ExtendedFiltersSettingsStorage } from '../../utils/ExtendedFiltersSettingsStorage';

const resolveFiltersState: ExtendedFiltersOnFiltersStateChange = ({
  prevState,
  nextState
}) => {
  const prevPageFilters = prevState?.[ExtendedFiltersGroups.PAGE];
  const prevTabFilters = prevState?.[ExtendedFiltersGroups.TAB];

  const nextPageFilters = nextState?.[ExtendedFiltersGroups.PAGE];
  const nextTabFilters = nextState?.[ExtendedFiltersGroups.TAB];
  const nextSelectedFilters = nextState?.[ExtendedFiltersGroups.SELECTED];

  const newPageFilterKeys = filter(
    keys(nextPageFilters),
    (key) => isNil(prevPageFilters?.[key]) && !isNil(nextPageFilters?.[key])
  );
  const newTabFilterKeys = filter(
    keys(nextTabFilters),
    (key) => isNil(prevTabFilters?.[key]) && !isNil(nextTabFilters?.[key])
  );

  if (
    !isEmpty(nextSelectedFilters) &&
    (!isEmpty(newPageFilterKeys) || !isEmpty(newTabFilterKeys))
  ) {
    return {
      ...nextState,
      [ExtendedFiltersGroups.SELECTED]: omit(
        nextSelectedFilters,
        concat(newPageFilterKeys, newTabFilterKeys)
      )
    };
  }

  return nextState;
};

interface ExtendedFiltersChangeOptions<T extends ExtendedFiltersBaseFilters> {
  receivedViewGroup?: FiltersViewGroups;
  receivedOrGeneratedViewGroup: FiltersViewGroups | string;
  filtersStateByViewGroupRef?: ExtendedFiltersContextStateByViewGroupRef;
  changeFiltersStateByViewGroup: ExtendedFiltersContextChangeStateByViewGroupAction;
  defaultSettingsFilters?: T;
  filterItems?: (filters: T) => void;
}

function useExtendedFiltersChange<T extends ExtendedFiltersBaseFilters>({
  receivedViewGroup,
  receivedOrGeneratedViewGroup,
  filtersStateByViewGroupRef,
  changeFiltersStateByViewGroup,
  defaultSettingsFilters,
  filterItems
}: ExtendedFiltersChangeOptions<T>) {
  const [initialSettingsFilters, setSettingsFilters] = useLocalStorage<T>(
    ExtendedFiltersSettingsStorage.getKeyByFiltersViewGroup(receivedViewGroup),
    defaultSettingsFilters
  );

  const changeFiltersState = useCallback<
    (
      addFiltersState: ExtendedFiltersContextState<T>,
      options?: {
        doNotSaveSettings: boolean;
      }
    ) => ExtendedFiltersContextState<T>
  >(
    (addFiltersState, options) => {
      const nextFiltersState = {
        ...(filtersStateByViewGroupRef.current?.[
          receivedOrGeneratedViewGroup
        ] as ExtendedFiltersContextState<T>),
        ...addFiltersState
      };

      const prevState = (filtersStateByViewGroupRef.current?.[
        receivedOrGeneratedViewGroup
      ] || {}) as ExtendedFiltersContextState<T>;

      const resolvedFiltersState = resolveFiltersState({
        prevState,
        nextState: nextFiltersState
      }) as ExtendedFiltersContextState<T>;

      if (
        receivedViewGroup &&
        !isEqual(
          prevState?.[ExtendedFiltersGroups.SETTINGS],
          resolvedFiltersState?.[ExtendedFiltersGroups.SETTINGS]
        ) &&
        !options?.doNotSaveSettings
      ) {
        setSettingsFilters(
          resolvedFiltersState?.[ExtendedFiltersGroups.SETTINGS]
        );
      }

      changeFiltersStateByViewGroup(
        receivedOrGeneratedViewGroup,
        resolvedFiltersState
      );

      return resolvedFiltersState;
    },
    [
      changeFiltersStateByViewGroup,
      filtersStateByViewGroupRef,
      receivedOrGeneratedViewGroup,
      receivedViewGroup,
      setSettingsFilters
    ]
  );
  const changeFilters = useCallback<
    (
      addFiltersState: ExtendedFiltersContextState<T>,
      options?: {
        doNotSaveSettings: boolean;
      }
    ) => void
  >(
    (addFiltersState, options) => {
      const resolvedFiltersState = changeFiltersState(addFiltersState, options);

      filterItems(extendedFiltersMergeGroups(resolvedFiltersState));
    },
    [changeFiltersState, filterItems]
  );

  const changePageFilters = useCallback<(newFilters: T) => void>(
    (newFilters) => {
      changeFilters({ [ExtendedFiltersGroups.PAGE]: newFilters });
    },
    [changeFilters]
  );

  const changeTabFilters = useCallback<(newFilters: T) => void>(
    (newFilters) => {
      changeFilters({ [ExtendedFiltersGroups.TAB]: newFilters });
    },
    [changeFilters]
  );

  const changeSettingsFilters = useCallback<(newFilters: T) => void>(
    (newFilters) => {
      changeFilters({ [ExtendedFiltersGroups.SETTINGS]: newFilters });
    },
    [changeFilters]
  );

  const changeSettingsFiltersWithoutSave = useCallback<(newFilters: T) => void>(
    (newFilters) => {
      changeFilters(
        { [ExtendedFiltersGroups.SETTINGS]: newFilters },
        { doNotSaveSettings: true }
      );
    },
    [changeFilters]
  );

  const changeSelectedFilters = useCallback<(newFilters: T) => void>(
    (newFilters) => {
      changeFilters({ [ExtendedFiltersGroups.SELECTED]: newFilters });
    },
    [changeFilters]
  );

  const resetSelectedFilters = useCallback<() => void>(() => {
    changeSelectedFilters({} as T);
  }, [changeSelectedFilters]);

  const resetSettingsFilters = useCallback<() => void>(() => {
    changeSettingsFilters(defaultSettingsFilters || ({} as T));
  }, [defaultSettingsFilters, changeSettingsFilters]);

  return {
    changeFiltersState,
    changePageFilters,
    changeTabFilters,
    changeSettingsFilters,
    changeSettingsFiltersWithoutSave,
    changeSelectedFilters,
    resetSelectedFilters,
    resetSettingsFilters
  };
}

export default useExtendedFiltersChange;
