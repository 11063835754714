export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const SECRET_API_KEY = process.env.NEXT_SECRET_API_KEY;

export const BIT_API_URL = process.env.NEXT_PUBLIC_BIT_API_URL;

export const AI_API_URL = process.env.NEXT_PUBLIC_AI_API_URL;

export const ELASTIC_SEARCH_API_URL = process.env.ELASTIC_SEARCH_API_URL;

export const API_URI = process.env.API_URI;

export const GRAPHQL_READ_FIN_API_URI = process.env.GRAPHQL_READ_FIN_API_URI;

export const LIVE_BLOCKS_API_URL = process.env.LIVE_BLOCKS_API_URL;

export const LIVE_BLOCKS_SECRET_API_KEY =
  process.env.LIVE_BLOCKS_SECRET_API_KEY;

export const LIVE_BLOCKS_WEBHOOKS_SECRET_API_KEY =
  process.env.LIVE_BLOCKS_WEBHOOKS_SECRET_API_KEY;

export const SUPERSET_ADMIN_USERNAME = process.env.SUPERSET_ADMIN_USERNAME;
export const SUPERSET_ADMIN_PASSWORD = process.env.SUPERSET_ADMIN_PASSWORD;

export const SUPERSET_DOMAIN = process.env.NEXT_PUBLIC_SUPERSET_DOMAIN;

export const AWS_S3_ACCESS_KEY_ID = process.env.AWS_S3_ACCESS_KEY_ID;
export const AWS_S3_SECRET_ACCESS_KEY = process.env.AWS_S3_SECRET_ACCESS_KEY;
export const AWS_S3_REGION = process.env.AWS_S3_REGION;
export const AWS_S3_BUCKET_NAME = process.env.AWS_S3_BUCKET_NAME;

export const IMG_PROXY_URL = process.env.IMG_PROXY_URL;
export const IMG_PROXY_READ_STREAM_TIMEOUT =
  process.env.IMG_PROXY_READ_STREAM_TIMEOUT;

export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
