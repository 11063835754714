import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

import { SentMessageUUID } from './sentMessagesTypes';

const basePath = '/sent-messages';

export class SentMessagePath {
  static index() {
    return basePath;
  }

  static show(sentMessageUuid: SentMessageUUID) {
    return `${basePath}/${sentMessageUuid}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }
}
