import { IconProps } from '../types';

function ListBulletAltSolid({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M16 6h-2V4h2c.6 0 1 .4 1 1s-.4 1-1 1zm-4-2H4c-.6 0-1 .4-1 1s.4 1 1 1h8V4zm5 6c0-.6-.4-1-1-1h-2v2h2c.6 0 1-.4 1-1zM3 10c0 .6.4 1 1 1h8V9H4c-.6 0-1 .4-1 1zm11 6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2v2zM3 15c0 .6.4 1 1 1h8v-2H4c-.6 0-1 .4-1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ListBulletAltSolid;
