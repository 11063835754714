import { IconProps } from '../types';

function UploadSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-6 w-6'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </svg>
  );
}

export default UploadSolidIcon;
