export default {
  plural: 'Email Шаблони',
  single: 'Email Шаблон',
  description: 'Опис',
  search: 'Пошук',
  create: 'Свторити email шаблон',
  subject: 'Тема',
  subjectUk: 'Тема (UK)',
  subjectEn: 'Тема (EN)',
  key: 'Ключ',
  remove: 'Видалити',
  edit: 'Змінити'
};
