import startsWith from 'lodash/startsWith';

import { I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { PaymentsSecondaryMenuItems } from '../../../../../../common/components/menus/CompactPaymentsSecondaryMenu/CompactPaymentsSecondaryMenu.types';
import { CompanyNanoID } from '../../../../../../companies/companiesTypes';

import { TeamPath } from '../../../../../TeamPath';

import { invoicesKeys, menuKeys } from '../../../../../../../locales/keys';
import { TeamsPermissions } from '../../../../../teamsConstants';

const teamSlugName = '[slug]' as CompanyNanoID;

export const companyPaymentsMenuLinks = (
  companyNanoId: CompanyNanoID,
  permissions: {
    invoices: TeamsPermissions[];
    performerInvoices: TeamsPermissions[];
    generalLedgers: TeamsPermissions[];
    smartContracts: TeamsPermissions[];
  }
) => [
  {
    name: PaymentsSecondaryMenuItems.INVOICES,
    href: TeamPath.companyPaymentsInvoices(companyNanoId),
    i18nText: invoicesKeys.plural,
    activeTest: (pathname: string) =>
      startsWith(pathname, TeamPath.companyPaymentsInvoices(teamSlugName)) ||
      startsWith(pathname, TeamPath.companyPaymentsProforma(teamSlugName)),
    permissions: permissions.invoices
  },
  {
    name: PaymentsSecondaryMenuItems.PERFORMER_INVOICES,
    href: TeamPath.companyPaymentsContractorInvoicesCurrent(companyNanoId),
    i18nText: invoicesKeys.performerInvoices,
    activeTest: (pathname: string) =>
      startsWith(
        pathname,
        TeamPath.companyPaymentsContractorInvoices(teamSlugName)
      ),
    permissions: permissions.performerInvoices
  },
  {
    name: PaymentsSecondaryMenuItems.GENERAL_LEDGERS,
    href: TeamPath.companyPaymentsBalanceSheet(companyNanoId),
    i18nText: menuKeys.links.generalLedger,
    activeTest: (pathname: string) =>
      startsWith(
        pathname,
        TeamPath.companyPaymentsBalanceSheet(teamSlugName)
      ) ||
      startsWith(pathname, TeamPath.companyPaymentsTransactions(teamSlugName)),
    permissions: permissions.generalLedgers
  },
  {
    name: PaymentsSecondaryMenuItems.SMART_CONTRACTS,
    href: TeamPath.companyPaymentsSmartContracts(companyNanoId),
    i18nText: menuKeys.links.smartContracts,
    activeTest: (pathname: string) =>
      startsWith(
        pathname,
        TeamPath.companyPaymentsSmartContracts(teamSlugName)
      ),
    permissions: permissions.smartContracts
  }
];

export interface CompactPaymentsSecondaryMenuLinksConfigItem {
  action: TeamsPermissions;
  active: boolean;
  count?: number;
  href: string;
  i18nText: I18nText;
  icon: IconsEnum;
  visible?: boolean;
}

export interface CompactPaymentsSecondaryMenuLinksConfig {
  invoices: CompactPaymentsSecondaryMenuLinksConfigItem[];
  performerInvoices: CompactPaymentsSecondaryMenuLinksConfigItem[];
  generalLedgers: CompactPaymentsSecondaryMenuLinksConfigItem[];
  smartContracts: CompactPaymentsSecondaryMenuLinksConfigItem[];
}
