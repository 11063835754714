import { gql } from 'graphql-request';

export const DELETE_DOWNLOADS_QUERY = gql`
  mutation DeleteDownloads($uuids: [ID!]!) {
    deleteDownloads(input: { uuids: $uuids }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
