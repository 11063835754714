import trim from 'lodash/trim';

import { isEmail } from '../../../../../../utils/isEmail';

import { formsErrors } from '../../../../../../locales/keys';

const nameRules = {
  required: formsErrors.name.required
};
const emailRules = {
  required: formsErrors.email.required,
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || formsErrors.email.valid
  }
};
const subjectRules = { required: formsErrors.required };
const bodyRules = { required: formsErrors.feedBackMessage.required };

function useFeedBackValidationRules() {
  return {
    nameRules,
    emailRules,
    subjectRules,
    bodyRules
  };
}

export default useFeedBackValidationRules;
