export default {
  add: 'Добавить модель в библиотеку',
  editProduct: 'Редактировать модель',
  deleteProduct: 'Удалить модель',
  deleteWarning:
    'Вы уверены, что хотите удалить модель? Это действие не может быть отменено.',
  plural: 'Модели',
  search: 'Искать модели',
  searchProductsByNameOrSku: 'Искать модели по имени или SKU',
  selected: 'Выбранные модели',
  singular: 'Модель',
  category: 'Категория модели',
  name: 'Название модели'
};
