import { TeamDeferredPayment } from '../../../teams/teamsTypes';
import { InvoiceDueDate } from '../../invoicesTypes';

const DEFAULT_DEFERRED_PAYMENT = 3;

function calcInvoiceDueDate(
  companyDeferredPayment: TeamDeferredPayment
): InvoiceDueDate {
  const currentDate = new Date();
  currentDate.setDate(
    currentDate.getDate() + (companyDeferredPayment || DEFAULT_DEFERRED_PAYMENT)
  );
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);

  const dueDate = currentDate.toISOString();

  return dueDate;
}

export default calcInvoiceDueDate;
