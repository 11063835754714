import { useMemo } from 'react';
import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';

import { CompactPaymentsSecondaryMenuLinksConfig } from './useCompactPaymentsSecondaryMenu.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';
import { AccountPath } from '../../../../../../accounts/AccountPath';
import { JournalRecordPath } from '../../../../../../journalRecords/JournalRecordPath';
import { SmartContractPath } from '../../../../../../smartContracts/SmartContractPath';

import { getPaymentsActiveMenu } from '../../utils/getPaymentsActiveMenu';

import {
  accountsKeys,
  invoicesKeys,
  journalRecordsKeys,
  smartContractsKeys,
  words
} from '../../../../../../../locales/keys';
import { InvoicesPermissions } from '../../../../../../invoices/invoicesConstants';
import { AccountsPermissions } from '../../../../../../accounts/accountsConstants';
import { JournalRecordsPermissions } from '../../../../../../journalRecords/journalRecordsConstants';
import { SmartContractsPermissions } from '../../../../../../smartContracts/smartContractsConstants';

function useCompactPaymentsSecondaryMenu() {
  const { pathname, asPath } = useRouter();

  const config = useMemo<CompactPaymentsSecondaryMenuLinksConfig>(
    () => ({
      invoices: [
        {
          action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_LINK,
          active:
            pathname === InvoicePath.paymentsInvoices() ||
            pathname === `${InvoicePath.paymentsInvoices()}/[slug]`,
          href: InvoicePath.paymentsInvoices(),
          i18nText: invoicesKeys.allInvoices,
          icon: IconsEnum.VIEW_LIST
        },
        // {
        //   action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_OPEN_LINK,
        //   active: startsWith(asPath, InvoicePath.paymentsInvoicesOpenFilter()),
        //   href: InvoicePath.paymentsInvoicesOpenFilter(),
        //   i18nText: words.open,
        //   icon: IconsEnum.EXCLAMATION_CIRCLE
        // },
        {
          action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_SENT_LINK,
          active: startsWith(asPath, InvoicePath.paymentsInvoicesSentFilter()),
          href: InvoicePath.paymentsInvoicesSentFilter(),
          i18nText: words.sent,
          icon: IconsEnum.TICKET_SOLID
        },
        {
          action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_DRAFT_LINK,
          active: startsWith(asPath, InvoicePath.paymentsInvoicesDraftFilter()),
          href: InvoicePath.paymentsInvoicesDraftFilter(),
          i18nText: words.draft,
          icon: IconsEnum.PENCIL_ALT_SOLID
        },
        {
          action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_CANCELED_LINK,
          active: startsWith(
            asPath,
            InvoicePath.paymentsInvoicesCanceledFilter()
          ),
          href: InvoicePath.paymentsInvoicesCanceledFilter(),
          i18nText: words.cancel,
          icon: IconsEnum.RECEIPT_REFUND
        },
        {
          action: InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_REFUNDS_LINK,
          active: startsWith(
            asPath,
            InvoicePath.paymentsInvoicesRefundsFilter()
          ),
          href: InvoicePath.paymentsInvoicesRefundsFilter(),
          i18nText: words.refunds,
          icon: IconsEnum.ARCHIVE_BOX_X_MARK
        }
      ],
      performerInvoices: [
        {
          action:
            InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_CURRENT_INVOICES_LINK,
          active:
            pathname === InvoicePath.paymentsContractorInvoicesCurrentFilter(),
          href: InvoicePath.paymentsContractorInvoicesCurrentFilter(),
          i18nText: words.current,
          icon: IconsEnum.EXCLAMATION_CIRCLE
        },
        {
          action:
            InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_OPEN_INVOICES_LINK,
          active:
            pathname === InvoicePath.paymentsContractorInvoicesOpenFilter(),
          href: InvoicePath.paymentsContractorInvoicesOpenFilter(),
          i18nText: words.open,
          icon: IconsEnum.BOOK_OPEN_SOLID
        },
        {
          action:
            InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_SENT_INVOICES_LINK,
          active:
            pathname === InvoicePath.paymentsContractorInvoicesSentFilter(),
          href: InvoicePath.paymentsContractorInvoicesSentFilter(),
          i18nText: words.sent,
          icon: IconsEnum.TICKET_SOLID
        },
        {
          action:
            InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_OVERDUE_INVOICES_LINK,
          active:
            pathname === InvoicePath.paymentsContractorInvoicesOverdueFilter(),
          href: InvoicePath.paymentsContractorInvoicesOverdueFilter(),
          i18nText: words.overdue,
          icon: IconsEnum.FIRE_SOLID
        },
        {
          action:
            InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK,
          active: pathname === InvoicePath.paymentsContractorInvoices(),
          href: InvoicePath.paymentsContractorInvoices(),
          i18nText: invoicesKeys.allInvoices,
          icon: IconsEnum.VIEW_LIST
        }
      ],
      generalLedgers: [
        {
          action:
            AccountsPermissions.READ_PAYMENTS_BALANCE_SHEET_MENU_INDEX_LINK,
          active: pathname === AccountPath.paymentsBalanceSheet(),
          href: AccountPath.paymentsBalanceSheet(),
          i18nText: accountsKeys.balanceSheet,
          icon: IconsEnum.LIST_BULLET_ALT
        },
        {
          action:
            JournalRecordsPermissions.READ_PAYMENTS_JOURNAL_RECORDS_MENU_INDEX_LINK,
          active: pathname === JournalRecordPath.paymentsJournalRecords(),
          href: JournalRecordPath.paymentsJournalRecords(),
          i18nText: journalRecordsKeys.transactions,
          icon: IconsEnum.SWITCH_HORIZONTAL_SOLID
        }
      ],
      smartContracts: [
        {
          action:
            SmartContractsPermissions.READ_PAYMENTS_SMART_CONTRACTS_MENU_INDEX_LINK,
          active: pathname === SmartContractPath.paymentsSmartContracts(),
          href: SmartContractPath.paymentsSmartContracts(),
          i18nText: smartContractsKeys.allSmartContracts,
          icon: IconsEnum.VIEW_LIST_SOLID
        },
        {
          action:
            SmartContractsPermissions.READ_PAYMENTS_SMART_CONTRACTS_MENU_CANCELED_LINK,
          active:
            pathname ===
            SmartContractPath.paymentsSmartContractsCanceledFilter(),
          href: SmartContractPath.paymentsSmartContractsCanceledFilter(),
          i18nText: words.canceled,
          icon: IconsEnum.ARCHIVE_BOX_X_MARK
        }
      ]
    }),
    [asPath, pathname]
  );

  const activeMenu = getPaymentsActiveMenu(pathname);

  return {
    activeMenu,
    config,
    permissions: mapValues(config, (item) => map(item, 'action'))
  };
}

export default useCompactPaymentsSecondaryMenu;
