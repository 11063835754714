import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface JournalRecordImportsKeyType {
  url: LocalesKey;
  status: LocalesKey;
  transactionImport: LocalesKey;
  transactionImportId: LocalesKey;
  transactionsImports: LocalesKey;
  progress: LocalesKey;
  rowCount: LocalesKey;
  errorMessage: LocalesKey;
  searchByKey: LocalesKey;
  searchById: LocalesKey;
  searchByStatus: LocalesKey;
}

export const journalRecordImportsKeys = keyPathMirror<
  JournalRecordImportsKeyType,
  string
>(
  {
    url: null,
    transactionImport: null,
    transactionImportId: null,
    transactionsImports: null,
    status: null,
    progress: null,
    rowCount: null,
    errorMessage: null,
    searchByKey: null,
    searchById: null,
    searchByStatus: null
  },
  'models.journalRecordImports.'
);
