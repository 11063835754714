import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface AccountBalancesKeyType {
  accountBalance: LocalesKey;
  amount: LocalesKey;
  balance: LocalesKey;
  prevBalance: LocalesKey;
  date: LocalesKey;
}

export const accountBalancesKeys = keyPathMirror<
  AccountBalancesKeyType,
  string
>(
  {
    accountBalance: null,
    amount: null,
    balance: null,
    prevBalance: null,
    date: null
  },
  'models.accountBalances.'
);
