import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface FormsErrorsKeyType {
  add: {
    lessThanDebt: LocalesKey;
    allocatedLessThanUnallocated: LocalesKey;
  };
  amount: {
    minAmountPayoneerTwenty: LocalesKey;
    nonNegative: LocalesKey;
    amountNoMoreThanYourAccount: LocalesKey;
  };
  required: LocalesKey;
  requiredLowerCase: LocalesKey;
  mustBePositive: LocalesKey;
  unavailableValue: LocalesKey;
  pleaseSelectUserOrCreateCompanyRecord: LocalesKey;
  estimateCost: {
    nonNegative: LocalesKey;
  };
  filterName: {
    required: LocalesKey;
  };
  client: {
    required: LocalesKey;
  };
  company: {
    required: LocalesKey;
  };
  companyName: {
    required: LocalesKey;
  };
  projectName: {
    required: LocalesKey;
  };
  policy: {
    required: LocalesKey;
  };
  regPolicy: {
    required: LocalesKey;
  };
  taskName: {
    required: LocalesKey;
  };
  fullName: {
    required: LocalesKey;
  };
  name: {
    required: LocalesKey;
  };
  share: {
    required: LocalesKey;
  };
  surname: {
    required: LocalesKey;
  };
  email: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  phone: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  city: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  password: {
    doNotMatch: LocalesKey;
    required: LocalesKey;
    valid: LocalesKey;
  };
  positionId: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  dueDate: {
    cantBeBlank: LocalesKey;
    invalid: LocalesKey;
    inFuture: LocalesKey;
    noEarlierInvoiceIssue: LocalesKey;
  };
  hours: {
    nonNegative: LocalesKey;
    lessEight: LocalesKey;
    required: LocalesKey;
  };
  minutes: {
    nonNegative: LocalesKey;
    lessSixty: LocalesKey;
  };
  message: {
    required: LocalesKey;
  };
  feedBackMessage: {
    required: LocalesKey;
  };
  defaultProjectPrice: {
    nonNegative: LocalesKey;
  };
  defaultTaskPrice: {
    nonNegative: LocalesKey;
  };
  dpi: {
    nonNegative: LocalesKey;
  };
  height: {
    nonNegative: LocalesKey;
  };
  width: {
    nonNegative: LocalesKey;
  };
  itemCategory: {
    required: LocalesKey;
  };
  price: {
    min: LocalesKey;
    required: LocalesKey;
  };
  percent: {
    lessThanOneHundred: LocalesKey;
    nonNegative: LocalesKey;
  };
  noDraftSmartContract: LocalesKey;
  user: {
    required: LocalesKey;
  };
  tagName: {
    required: LocalesKey;
  };
  time: {
    from10To19: LocalesKey;
  };
  toDate: {
    laterThanFrom: LocalesKey;
  };
  fromDate: {
    earlierThanTo: LocalesKey;
  };
}

export const formsErrors = keyPathMirror<FormsErrorsKeyType, string>(
  {
    add: {
      lessThanDebt: null,
      allocatedLessThanUnallocated: null
    },
    amount: {
      minAmountPayoneerTwenty: null,
      nonNegative: null,
      amountNoMoreThanYourAccount: null
    },
    required: null,
    requiredLowerCase: null,
    mustBePositive: null,
    unavailableValue: null,
    pleaseSelectUserOrCreateCompanyRecord: null,
    estimateCost: {
      nonNegative: null
    },
    filterName: {
      required: null
    },
    hours: {
      nonNegative: null,
      lessEight: null,
      required: null
    },
    minutes: {
      nonNegative: null,
      lessSixty: null
    },
    client: {
      required: null
    },
    companyName: {
      required: null
    },
    company: {
      required: null
    },
    projectName: {
      required: null
    },
    policy: {
      required: null
    },
    regPolicy: {
      required: null
    },
    taskName: {
      required: null
    },
    fullName: {
      required: null
    },
    name: {
      required: null
    },
    surname: {
      required: null
    },
    email: {
      required: null,
      valid: null
    },
    phone: {
      required: null,
      valid: null
    },
    city: {
      required: null,
      valid: null
    },
    password: {
      doNotMatch: null,
      required: null,
      valid: null
    },
    positionId: {
      required: null,
      valid: null
    },
    dueDate: {
      cantBeBlank: null,
      invalid: null,
      inFuture: null,
      noEarlierInvoiceIssue: null
    },
    message: {
      required: null
    },
    feedBackMessage: {
      required: null
    },
    defaultProjectPrice: {
      nonNegative: null
    },
    defaultTaskPrice: {
      nonNegative: null
    },
    dpi: {
      nonNegative: null
    },
    height: {
      nonNegative: null
    },
    width: {
      nonNegative: null
    },
    itemCategory: {
      required: null
    },
    price: {
      min: null,
      required: null
    },
    percent: { lessThanOneHundred: null, nonNegative: null },
    noDraftSmartContract: null,
    user: {
      required: null
    },
    share: {
      required: null
    },
    tagName: {
      required: null
    },
    toDate: {
      laterThanFrom: null
    },
    time: {
      from10To19: null
    },
    fromDate: {
      earlierThanTo: null
    }
  },
  'forms.errors.'
);
