import React from 'react';

import { IconProps } from '../types';

function EmptyStarOffIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21L3 3m14.8 14.8l.3 1c.3.9-.8 1.7-1.5 1.1l-4-2.9c-.4-.3-.8-.3-1.2 0l-4 2.9c-.8.6-1.8-.2-1.5-1.1l1.5-4.7c.1-.4 0-.9-.4-1.1l-4-2.9c-.7-.6-.3-1.8.7-1.8h4.6m2-3l.7-2.4c.3-.9 1.7-.9 2 0l1.5 4.7c.1.4.5.7 1 .7h4.9c1 0 1.4 1.2.6 1.8l-3.4 2.4"
      />
    </svg>
  );
}

export default EmptyStarOffIcon;
