import cl from 'classnames';

import { ErrorMessage, RegisterFormFieldType } from '../../../../../types';
import { CreateBillingInfoFormData } from './CreateBillingInfoForm.types';
import {
  CreateBillingInfoIsLoading,
  BillingInfoFields,
  BillingInfoCountry,
  BillingInfoPaymentMethod
} from '../../../billingInfosTypes';
import { InvoicePaymentMethods } from '../../../../invoices/invoicesTypes';

import { AvBillingInfosSelectField } from '../../../../../helpers/FormFields/AvBillingInfosSelectField';
import { CountriesNameSelectField } from '../../../../../helpers/FormFields/CountriesNameSelectField';
import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';
import { RadioButtonsField } from '../../../../../helpers/FormFields/RadioButtonsField';
import { SelectFieldRequiredProps } from '../../../../../helpers/FormFields/SelectField';
import { StatesNameSelectField } from '../../../../../helpers/FormFields/StatesNameSelectField';

import {
  formsFields,
  invoicesKeys,
  teamsKeys
} from '../../../../../locales/keys';
import { usaCountryName } from '../../../billingInfosConstants';
import { AvBillingInfoPaymentMethods } from '../../../../avBillingInfos/avBillingInfosTypes';

const paymentMethods = [
  {
    label: teamsKeys.wire,
    value: InvoicePaymentMethods.WIRE
  },
  {
    label: teamsKeys.card,
    value: InvoicePaymentMethods.CARD
  }
];

export type AddFundsCreateBillingInfoFormProps = {
  form: string;
  isLoading: CreateBillingInfoIsLoading;
  companyNameValidationError: ErrorMessage;
  vatNumberValidationError: ErrorMessage;
  firstNameValidationError: ErrorMessage;
  lastNameValidationError: ErrorMessage;
  contactPhoneValidationError: ErrorMessage;
  contactEmailValidationError: ErrorMessage;
  cityValidationError: ErrorMessage;
  countryValidationError: ErrorMessage;
  stateValidationError: ErrorMessage;
  addressValidationError: ErrorMessage;
  zipCodeValidationError: ErrorMessage;
  avBillingInfoIdValidationError: ErrorMessage;
  registerCompanyName: RegisterFormFieldType<HTMLInputElement>;
  registerVatNumber: RegisterFormFieldType<HTMLInputElement>;
  registerFirstName: RegisterFormFieldType<HTMLInputElement>;
  registerLastName: RegisterFormFieldType<HTMLInputElement>;
  registerContactPhone: RegisterFormFieldType<HTMLInputElement>;
  registerContactEmail: RegisterFormFieldType<HTMLInputElement>;
  registerCity: RegisterFormFieldType<HTMLInputElement>;
  registerAddress: RegisterFormFieldType<HTMLInputElement>;
  registerZipCode: RegisterFormFieldType<HTMLInputElement>;
  withPaymentMethod?: boolean;
  withoutReceiver?: boolean;
  watchCountry: BillingInfoCountry;
  watchPaymentMethod: BillingInfoPaymentMethod;
} & SelectFieldRequiredProps<CreateBillingInfoFormData>;

const avPaymentMethods = {
  [InvoicePaymentMethods.WIRE]: [AvBillingInfoPaymentMethods.WIRE],
  [InvoicePaymentMethods.CARD]: [
    AvBillingInfoPaymentMethods.PAYONEER,
    AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT,
    AvBillingInfoPaymentMethods.STRIPE,
    AvBillingInfoPaymentMethods.FONDY,
    AvBillingInfoPaymentMethods.PAYPAL
  ]
};

function AddFundsCreateBillingInfoForm({
  control,
  form,
  isLoading,
  companyNameValidationError,
  vatNumberValidationError,
  firstNameValidationError,
  lastNameValidationError,
  contactPhoneValidationError,
  contactEmailValidationError,
  cityValidationError,
  countryValidationError,
  stateValidationError,
  addressValidationError,
  zipCodeValidationError,
  avBillingInfoIdValidationError,
  registerCompanyName,
  registerVatNumber,
  registerFirstName,
  registerLastName,
  registerContactPhone,
  registerContactEmail,
  registerCity,
  registerAddress,
  registerZipCode,
  withPaymentMethod = false,
  withoutReceiver = false,
  watchCountry,
  watchPaymentMethod
}: AddFundsCreateBillingInfoFormProps) {
  return (
    <Form id={form}>
      <div className="isolate -space-y-px rounded-md shadow-sm">
        {withPaymentMethod && (
          <RadioButtonsField<
            CreateBillingInfoFormData,
            typeof paymentMethods[0]
          >
            className="relative rounded-t-md border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
            control={control}
            disabled={isLoading}
            i18nLabel={invoicesKeys.paymentMethod}
            labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
            name={BillingInfoFields.PAYMENT_METHOD}
            options={paymentMethods}
            optionClassName="text-xs"
            radioButtonLabelClassName="inline-flex items-center gap-2"
          />
        )}

        <InputField
          disabled={isLoading}
          error={companyNameValidationError}
          i18nLabel={formsFields.companyName}
          className={cl(
            'relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300',
            { 'rounded-t-md': !withPaymentMethod }
          )}
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerCompanyName.name}
          onChange={registerCompanyName.onChange}
          ref={registerCompanyName.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={vatNumberValidationError}
          i18nLabel={formsFields.vatNumber}
          className="relative flex-1 border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerVatNumber.name}
          onChange={registerVatNumber.onChange}
          ref={registerVatNumber.ref}
          type="text"
        />

        <div className="flex space-x-2">
          <InputField
            disabled={isLoading}
            error={firstNameValidationError}
            i18nLabel={formsFields.firstName}
            className="relative flex-1 border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
            inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
            labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
            name={registerFirstName.name}
            onChange={registerFirstName.onChange}
            ref={registerFirstName.ref}
            type="text"
          />

          <InputField
            disabled={isLoading}
            error={lastNameValidationError}
            i18nLabel={formsFields.lastName}
            className="relative flex-1 border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
            inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
            labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
            name={registerLastName.name}
            onChange={registerLastName.onChange}
            ref={registerLastName.ref}
            type="text"
          />
        </div>

        <InputField
          disabled={isLoading}
          error={contactPhoneValidationError}
          i18nLabel={formsFields.contactPhone}
          className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerContactPhone.name}
          onChange={registerContactPhone.onChange}
          ref={registerContactPhone.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={contactEmailValidationError}
          i18nLabel={formsFields.contactEmail}
          className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerContactEmail.name}
          onChange={registerContactEmail.onChange}
          ref={registerContactEmail.ref}
          type="text"
        />

        <div className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300">
          <CountriesNameSelectField<CreateBillingInfoFormData>
            control={control}
            withSecondNewAvPrefix
            disabled={isLoading}
            error={countryValidationError}
            i18nLabel={formsFields.country}
            labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
            name={BillingInfoFields.COUNTRY}
          />
        </div>

        {usaCountryName === watchCountry && (
          <div className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300">
            <StatesNameSelectField
              control={control}
              withSecondNewAvPrefix
              disabled={isLoading}
              error={stateValidationError}
              i18nLabel={formsFields.state}
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
              name={BillingInfoFields.STATE}
            />
          </div>
        )}

        <InputField
          disabled={isLoading}
          error={cityValidationError}
          i18nLabel={formsFields.city}
          className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerCity.name}
          onChange={registerCity.onChange}
          ref={registerCity.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={addressValidationError}
          i18nLabel={formsFields.address}
          className="relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300"
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerAddress.name}
          onChange={registerAddress.onChange}
          ref={registerAddress.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={zipCodeValidationError}
          i18nLabel={formsFields.zipCode}
          className={cl(
            'relative border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300',
            { 'rounded-b-md': withoutReceiver }
          )}
          inputClassName="block w-full border-0 p-0 bg-transparent placeholder-gray-500 focus:ring-0 sm:text-sm"
          labelClassName="block text-xs font-medium text-gray-700 dark:text-gray-300"
          name={registerZipCode.name}
          onChange={registerZipCode.onChange}
          ref={registerZipCode.ref}
          type="text"
        />

        {withoutReceiver ? null : (
          <div className="relative rounded-b-md border border-gray-300 dark:border-gray-700 px-3 py-2 focus-within:z-10 focus-within:border-blue-300 focus-within:ring-4 focus-within:ring-blue-300">
            <AvBillingInfosSelectField<CreateBillingInfoFormData>
              control={control}
              withSecondNewAvPrefix
              disabled={isLoading}
              error={avBillingInfoIdValidationError}
              i18nLabel={formsFields.receiver}
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
              name={BillingInfoFields.AV_BILLING_INFO_ID}
              menuPlacement={PopoverPlacement.TOP}
              paymentMethodFilter={avPaymentMethods[watchPaymentMethod]}
              country={watchCountry}
            />
          </div>
        )}
      </div>
    </Form>
  );
}

export default AddFundsCreateBillingInfoForm;
