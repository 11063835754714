import { IconsEnum } from '../../../../../assets/icons/types';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { MainPath } from '../../../../../main/MainPath';

import { menuKeys } from '../../../../../locales/keys';

interface UserMenuDashboardLinkProps {
  closeMenus: () => void;
}

function UserMenuDashboardLink({ closeMenus }: UserMenuDashboardLinkProps) {
  return (
    <NextPureLinkHelper
      href={MainPath.index()}
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm items-center"
      icon={IconsEnum.TEMPLATE_SOLID}
      iconClassName="mr-3 h-5 w-5"
      i18nText={menuKeys.tabs.dashboard}
      onClick={closeMenus}
    />
  );
}

export default UserMenuDashboardLink;
