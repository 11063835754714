import { followCursor } from 'tippy.js';

import {
  TooltipDelay,
  TooltipDuration,
  TooltipOffset,
  TooltipTouch
} from './types';

export const followCursorPlugin = [followCursor];
export const defaultDelay: TooltipDelay = [750, 0];
export const defaultDuration: TooltipDuration = [250, 100];
export const defaultOffset: TooltipOffset = [0, 10];
export const zeroOffset: TooltipOffset = [0, 0];
export const defaultTouch: TooltipTouch = ['hold', 500];

export const bodyTooltipContainer =
  typeof window !== 'undefined' ? window.document.body : undefined;

export const enum TooltipPlacement {
  AUTO = 'auto',
  AUTO_END = 'auto-end',
  AUTO_START = 'auto-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start'
}
