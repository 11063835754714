export default {
  plural: 'Категории',
  seeAll: 'Посмотреть все категории',
  seeLess: 'Видеть меньше категорий',
  singular: 'Категория',
  styles: {
    plural: 'Стили',
    singular: 'Стиль',
    'select...': 'Выбрать стиль...'
  },
  materials: {
    plural: 'Материалы',
    singular: 'Материал',
    'select...': 'Выбрать материал...'
  },
  brands: {
    plural: 'Производители',
    search: 'Искать производителей',
    singular: 'Производитель',
    'add...': 'Добавить производителя...',
    'select...': 'Выбрать производителя...'
  },
  gammas: {
    singular: 'Гамма',
    'select...': 'Выбрать гамму...'
  },
  groups: {
    plural: 'Группы',
    singular: 'Группа',
    'select...': 'Выбрать группы...'
  },
  tones: {
    singular: 'Тон',
    'select...': 'Выбрать тон...'
  },
  accentColors: {
    plural: 'Акцентные цвета',
    singular: 'Акцентный цвет',
    'select...': 'Выбрать акцентный цвет...'
  },
  mainColors: {
    plural: 'Основные цвета',
    singular: 'Основной цвет',
    'select...': 'Выбрать основной цвет...'
  },
  colors: {
    plural: 'Цвета',
    singular: 'Цвет',
    'select...': 'Выбрать цвет...'
  },
  authors: {
    plural: 'Авторы',
    singular: 'Автор',
    select: 'Выбрать автора'
  },
  performers: {
    plural: 'Авторы',
    singular: 'Автор',
    select: 'Выбрать автора',
    'select...': 'Выбрать автора...'
  },
  manufacturers: {
    singular: 'Производитель',
    plural: 'Производители'
  },
  fileVersionTags: {
    plural: 'Метки версии файла',
    singular: 'Метка версии файла',
    'select...': 'Выбрать метку версии файла...'
  },
  collections: {
    plural: 'Коллекции',
    singular: 'Коллекция',
    'select...': 'Выбрать коллекцию...'
  },
  mistakeTypes: {
    plural: 'Типы ошибок',
    singular: 'Тип ошибки',
    select: 'Выбрать тип ошибки',
    'select...': 'Выбрать тип ошибки...'
  },
  statuses: {
    plural: 'Статусы',
    singular: 'Статус',
    select: 'Выбрать статус',
    'select...': 'Выбрать статус...'
  }
};
