import { I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { TeamPath } from '../../../../../main/teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface UserMenuCompanyProfileLinkProps {
  i18nText?: I18nText;
  icon?: IconsEnum;
  closeMenus: () => void;
}

function UserMenuCompanyProfileLink({
  closeMenus,
  icon = IconsEnum.USER_GROUP_SOLID,
  i18nText = words.company
}: UserMenuCompanyProfileLinkProps) {
  return (
    <NextPureLinkHelper
      href={TeamPath.currentCompanyProfile()}
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm items-center"
      icon={icon}
      iconClassName="mr-3 h-5 w-5"
      i18nText={i18nText}
      onClick={closeMenus}
    />
  );
}

export default UserMenuCompanyProfileLink;
