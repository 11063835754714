import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MessagesKeyType {
  addToDescription: LocalesKey;
  addToRecords: LocalesKey;
  addFlag: LocalesKey;
  addStar: LocalesKey;
  all: LocalesKey;
  archive: LocalesKey;
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible: LocalesKey;
  areYouSureYouWantToSendThisMessageToAllProjectTasks: LocalesKey;
  authors: LocalesKey;
  automaticallyPinMessageWithTodoItem: LocalesKey;
  betweenMessagesAtLeast15MinutesApart: LocalesKey;
  cancelReply: LocalesKey;
  casualToneOfVoiceTranslation: LocalesKey;
  chatGpt: LocalesKey;
  chatGptDescription: LocalesKey;
  checkGrammar: LocalesKey;
  correctGrammarWithChatGpt: LocalesKey;
  colorsSwathes: LocalesKey;
  createAMessageThanksALot: LocalesKey;
  createATodoListFromAPdf: LocalesKey;
  criticalRequirements: LocalesKey;
  displayTime: LocalesKey;
  downloadAllAttachments: LocalesKey;
  emailNotificationSent: LocalesKey;
  flagged: LocalesKey;
  flaggedMessages: LocalesKey;
  forward: LocalesKey;
  forwardMessageFrom: LocalesKey;
  forwardedFrom: LocalesKey;
  friendlyToneOfVoiceTranslation: LocalesKey;
  fromClients: LocalesKey;
  fileAttachments: LocalesKey;
  filterByAttachmentType: LocalesKey;
  filterByMessageType: LocalesKey;
  financeActivity: LocalesKey;
  hideFromClient: LocalesKey;
  inProjectsWhereIAmAMember: LocalesKey;
  inTasksWhereIAmAMember: LocalesKey;
  jumpTo: LocalesKey;
  markAllAsRead: LocalesKey;
  markAsRead: LocalesKey;
  markAsRelevant: LocalesKey;
  markAsUnread: LocalesKey;
  markAsUnrelevant: LocalesKey;
  materials: LocalesKey;
  messageId: LocalesKey;
  messageIds: LocalesKey;
  messagesFilter: LocalesKey;
  messageWithAttachments: LocalesKey;
  my: LocalesKey;
  new: LocalesKey;
  nextToEachMessage: LocalesKey;
  noPlural: LocalesKey;
  noAttachments: LocalesKey;
  passwordWasSuccessfullyUpdated: LocalesKey;
  pin: LocalesKey;
  pinMessage: LocalesKey;
  pinnedMessages: LocalesKey;
  plural: LocalesKey;
  project: LocalesKey;
  products: LocalesKey;
  read: LocalesKey;
  removeCommand: LocalesKey;
  removeFlag: LocalesKey;
  removeStar: LocalesKey;
  restore: LocalesKey;
  reply: LocalesKey;
  replyTo: LocalesKey;
  runCommand: LocalesKey;
  lifestyles: LocalesKey;
  search: LocalesKey;
  selectAuthor: LocalesKey;
  send: LocalesKey;
  sendEmailNotification: LocalesKey;
  sending: LocalesKey;
  sent: LocalesKey;
  settings: LocalesKey;
  setHiddenForClient: LocalesKey;
  setVisibleForClient: LocalesKey;
  showOlder: LocalesKey;
  showWhiteboardMessages: LocalesKey;
  showTimeStampForEveryMessage: LocalesKey;
  showToClient: LocalesKey;
  singular: LocalesKey;
  starred: LocalesKey;
  starredMessages: LocalesKey;
  thanksALot: LocalesKey;
  toLatestMessages: LocalesKey;
  todoLists: LocalesKey;
  tracked: LocalesKey;
  translateIntoEnglish: LocalesKey;
  translateTheTextAndMakeItAppropriateForSendingToTheClient: LocalesKey;
  translateTheTextIntoEnglishInACasualTone: LocalesKey;
  translateTheTextIntoEnglishInAFriendlyTone: LocalesKey;
  timeOfEachIndividualMessageWillBeAvailableOnHover: LocalesKey;
  todoListFromPdfOrImage: LocalesKey;
  unpin: LocalesKey;
  unpinMessage: LocalesKey;
  unread: LocalesKey;
  userMessages: LocalesKey;
  visible: LocalesKey;
  withAttachments: LocalesKey;
  whiteboardNotifications: LocalesKey;
}

export const messagesKeys = keyPathMirror<MessagesKeyType, string>(
  {
    addToDescription: null,
    addToRecords: null,
    addFlag: null,
    addStar: null,
    all: null,
    archive: null,
    areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible: null,
    areYouSureYouWantToSendThisMessageToAllProjectTasks: null,
    authors: null,
    automaticallyPinMessageWithTodoItem: null,
    betweenMessagesAtLeast15MinutesApart: null,
    cancelReply: null,
    casualToneOfVoiceTranslation: null,
    chatGpt: null,
    chatGptDescription: null,
    checkGrammar: null,
    correctGrammarWithChatGpt: null,
    colorsSwathes: null,
    createAMessageThanksALot: null,
    createATodoListFromAPdf: null,
    criticalRequirements: null,
    displayTime: null,
    downloadAllAttachments: null,
    emailNotificationSent: null,
    flagged: null,
    flaggedMessages: null,
    forward: null,
    forwardMessageFrom: null,
    forwardedFrom: null,
    friendlyToneOfVoiceTranslation: null,
    fromClients: null,
    fileAttachments: null,
    filterByAttachmentType: null,
    filterByMessageType: null,
    financeActivity: null,
    hideFromClient: null,
    inProjectsWhereIAmAMember: null,
    inTasksWhereIAmAMember: null,
    jumpTo: null,
    markAllAsRead: null,
    markAsRead: null,
    markAsRelevant: null,
    markAsUnread: null,
    markAsUnrelevant: null,
    materials: null,
    messageId: null,
    messageIds: null,
    messagesFilter: null,
    messageWithAttachments: null,
    my: null,
    new: null,
    nextToEachMessage: null,
    noPlural: null,
    noAttachments: null,
    passwordWasSuccessfullyUpdated: null,
    pin: null,
    pinMessage: null,
    pinnedMessages: null,
    plural: null,
    project: null,
    products: null,
    read: null,
    removeCommand: null,
    removeFlag: null,
    removeStar: null,
    restore: null,
    reply: null,
    replyTo: null,
    runCommand: null,
    lifestyles: null,
    search: null,
    selectAuthor: null,
    send: null,
    sendEmailNotification: null,
    sending: null,
    sent: null,
    settings: null,
    setHiddenForClient: null,
    setVisibleForClient: null,
    showOlder: null,
    showWhiteboardMessages: null,
    showTimeStampForEveryMessage: null,
    showToClient: null,
    singular: null,
    starred: null,
    starredMessages: null,
    thanksALot: null,
    toLatestMessages: null,
    todoLists: null,
    tracked: null,
    translateIntoEnglish: null,
    translateTheTextAndMakeItAppropriateForSendingToTheClient: null,
    translateTheTextIntoEnglishInACasualTone: null,
    translateTheTextIntoEnglishInAFriendlyTone: null,
    timeOfEachIndividualMessageWillBeAvailableOnHover: null,
    todoListFromPdfOrImage: null,
    unpin: null,
    unpinMessage: null,
    unread: null,
    userMessages: null,
    visible: null,
    withAttachments: null,
    whiteboardNotifications: null
  },
  'models.messages.'
);
