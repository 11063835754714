import { FetchItemGqlQuery, NanoID, UUID } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLClient } from '../../graphQLClients';

interface FetchItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
}

export function fetchItem({ query, uuid }: FetchItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole('log', 'fetchItem', uuid, { rawName, name });

  const requestId = generateUuid();

  graphQLClient.addQueryParams(name, requestId);

  return graphQLClient.request(query, { uuid }, { requestId });
}
