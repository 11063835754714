import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface BillingInfosKeyType {
  addBillingInfo: LocalesKey;
  addYourBillingAddressSoWeCanCreateAnInvoiceForYourProject: LocalesKey;
  billingInfoCopied: LocalesKey;
  billFromColon: LocalesKey;
  billToColon: LocalesKey;
  copyBillingInfo: LocalesKey;
  change: LocalesKey;
  default: LocalesKey;
  plural: LocalesKey;
  provideYourBillingInfoInOrderToCreateInvoiceForTheProject: LocalesKey;
  receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided: LocalesKey;
  selectState: LocalesKey;
  singular: LocalesKey;
  updateBillingInfo: LocalesKey;
  useAnotherBillingInfo: LocalesKey;
}

export const billingInfosKeys = keyPathMirror<BillingInfosKeyType, string>(
  {
    addBillingInfo: null,
    addYourBillingAddressSoWeCanCreateAnInvoiceForYourProject: null,
    billingInfoCopied: null,
    billFromColon: null,
    billToColon: null,
    copyBillingInfo: null,
    change: null,
    default: null,
    plural: null,
    provideYourBillingInfoInOrderToCreateInvoiceForTheProject: null,
    receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided:
      null,
    selectState: null,
    singular: null,
    updateBillingInfo: null,
    useAnotherBillingInfo: null
  },
  'models.billingInfos.'
);
