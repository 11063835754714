export default {
  plural: 'Приглашения',
  accept: 'Принять',
  acceptAll: 'Принять все',
  acceptSelected: 'Принять выбранные',
  decline: 'Отклонить',
  declineAll: 'Отклонить все',
  declineSelected: 'Отклонить выбранные',
  search: 'Поиск приглашений по названию проекта',
  areTouSureYouWantToAcceptAllProjectInvitationsThisActionCannotBeUndone:
    'Вы уверены, что хотите принять все приглашения в проекты? Это действие не может быть отменено.',
  areTouSureYouWantToDeclineAllProjectInvitationsThisActionCannotBeUndone:
    'Вы уверены, что хотите отклонить все приглашения в проекты? Это действие не может быть отменено.',
  areYouSureYouWantToDeclineProjectInvitationThisActionCannotBeUndone:
    'Вы уверены, что хотите отклонить приглашение в проект? Это действие не может быть отменено.'
};
