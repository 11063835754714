import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface CurrencyRatesKeyType {
  priceIn: LocalesKey;
  exchangeRate: LocalesKey;
}

export const currencyRatesKeys = keyPathMirror<CurrencyRatesKeyType, string>(
  {
    priceIn: null,
    exchangeRate: null
  },
  'models.currencyRates.'
);
