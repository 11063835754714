import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface FinanceAddressesKeyType {
  addAddress: LocalesKey;
  addBillingAddress: LocalesKey;
  selectCountry: LocalesKey;
  selectState: LocalesKey;
  updateBillingAddress: LocalesKey;
  deleteBillingAddress: LocalesKey;
}

export const financeAddressesKeys = keyPathMirror<
  FinanceAddressesKeyType,
  string
>(
  {
    addAddress: null,
    addBillingAddress: null,
    selectCountry: null,
    selectState: null,
    updateBillingAddress: null,
    deleteBillingAddress: null
  },
  'models.financeAddresses.'
);
