import React from 'react';

import { InvoicePaymentMethods } from '../../../../../invoicesTypes';

import { AddFundsModalButtonAmountInput } from '../AddFundsModalButtonAmountInput';

import { Translate } from '../../../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';

import { invoicesKeys, accountsKeys } from '../../../../../../../locales/keys';

interface AddFundsModalButtonDataViewProps {
  companyCashBalance: number;
  paymentMethod?: InvoicePaymentMethods;
  amount: number;
  onAmountChange?: (amount: number) => void;
}

function AddFundsModalButtonDataView({
  companyCashBalance,
  paymentMethod,
  amount,
  onAmountChange
}: AddFundsModalButtonDataViewProps) {
  return (
    <div>
      <div className="text-2xs text-gray-500 text-center mb-1">
        <Translate id={accountsKeys.balance} />
        &nbsp;
        <MoneyHelper value={companyCashBalance} />
      </div>
      <AddFundsModalButtonAmountInput
        value={amount}
        onChange={onAmountChange}
      />
      {paymentMethod ? (
        <div className="text-2xs text-gray-500 text-center mt-5">
          <span>
            <Translate id={invoicesKeys.paymentMethod} />
            {': '}
            {paymentMethod === InvoicePaymentMethods.WIRE ? (
              <Translate id={invoicesKeys.wire} />
            ) : null}
            {paymentMethod === InvoicePaymentMethods.CARD ? (
              <Translate id={invoicesKeys.creditCard} />
            ) : null}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default AddFundsModalButtonDataView;
