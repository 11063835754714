import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface JournalRecordImportRowsKeyType {
  key: LocalesKey;
  value: LocalesKey;
  transactionImportRows: LocalesKey;
  transactionImportInvoices: LocalesKey;
  status: LocalesKey;
  progress: LocalesKey;
  errorMessage: LocalesKey;
  errorBacktrace: LocalesKey;
  singular: LocalesKey;
}

export const journalRecordImportRowsKeys = keyPathMirror<
  JournalRecordImportRowsKeyType,
  string
>(
  {
    key: null,
    value: null,
    transactionImportRows: null,
    transactionImportInvoices: null,
    status: null,
    progress: null,
    errorMessage: null,
    errorBacktrace: null,
    singular: null
  },
  'models.journalRecordImportRows.'
);
