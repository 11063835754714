import { IconProps } from '../types';

function BellCrossedSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path d="M17.7 3.7l-2.1 2.1L7.4 14l-3.7 3.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4L4.6 14H4c-.4 0-.8-.2-.9-.6s-.1-.8.2-1.1l.7-.7V8c0-3.3 2.7-6 6-6 1.8 0 3.4.8 4.5 2.1l1.8-1.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4zm-10.1 13C8.1 17.5 9 18 10 18c1.7 0 3-1.3 3-3H9.3l-1.7 1.7zm8.4-5.1V8.2L10.3 14H16c.4 0 .8-.2.9-.6s.1-.8-.2-1.1l-.7-.7z" />
    </svg>
  );
}

export default BellCrossedSolidIcon;
