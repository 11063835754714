import findIndex from 'lodash/findIndex';

import {
  IndexQueryResponse,
  UpdateItemCacheSelector
} from '../../../../useIndexQuery.types';

interface GetItemIndexOptions<ItemType> {
  data: IndexQueryResponse<ItemType>;
  selector: UpdateItemCacheSelector<ItemType>;
  scope: string;
}

function getItemIndex<ItemType>({
  data,
  selector,
  scope
}: GetItemIndexOptions<ItemType>) {
  const itemIndex = findIndex(
    data?.[scope]?.nodes,
    (currentItem) => currentItem && selector?.(currentItem)
  );

  return { itemIndex };
}

export default getItemIndex;
