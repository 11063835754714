export default {
  select: 'Выбрать команды',
  plural: 'Команды',
  pluralDowncase: 'команды',
  singular: 'Команда',
  add: 'Добавить команду',
  'add...': 'Добавиить команду...',
  change: 'Изменить команды',
  change_creator: 'Сменить создателя команды',
  create: 'Создать команду',
  creator: 'Создатель команды',
  create_and_view: 'Создать и просмотреть команду',
  select_with_dots: 'Выбрать команды...',
  selected: 'Выбранные команды',
  name: 'Название команды',
  no: 'Нет команд',
  of: 'Команда',
  type: 'Тип команды',
  select_type: 'Выбрать тип команды',
  view: 'Просмотреть команду',
  members: 'Участники команды',
  selectUser: 'Выбрать пользователя',
  users: 'Пользователи команды',
  profile: 'Профиль команды',
  delete: 'Удалить команду',
  deleted: 'Команды удалена'
};
