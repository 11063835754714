import React from 'react';
import NumberFormat from 'react-number-format';

import { ClassName } from '../../types';

import { getCompactValue } from './utils/getCompactValue';

interface CompactNumberHelperProps {
  value: number;
  hideZeroValue?: boolean;
  decimalScale?: number;
  className?: ClassName;
}

function CompactNumberHelper({
  value,
  decimalScale = 1,
  className,
  hideZeroValue = false
}: CompactNumberHelperProps) {
  if (hideZeroValue && !value) {
    return null;
  }

  const { compactValue, suffix } = getCompactValue(value);

  return (
    <NumberFormat
      value={compactValue}
      displayType="text"
      decimalScale={decimalScale}
      suffix={suffix}
      className={className}
    />
  );
}

export default CompactNumberHelper;
