import {
  FetchBillingInfosCacheKey,
  FetchFinBillingInfosFilters,
  FetchBillingInfosLimit,
  FetchBillingInfosPage,
  FetchBillingInfosSort,
  FetchBillingInfosGqlQuery
} from '../../billingInfosTypes';
import { IndexQueryDefaultOptionsOpts } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_BILLING_INFOS_FILTERS,
  INITIAL_BILLING_INFOS_LIMIT,
  INITIAL_BILLING_INFOS_PAGE,
  INITIAL_BILLING_INFOS_SORT
} from '../../billingInfosConstants';

interface PaginatedBillingInfosOptions<FetchBillingInfosBillingInfoType> {
  cacheKey: FetchBillingInfosCacheKey;
  initialFilters?: FetchFinBillingInfosFilters;
  initialSort?: FetchBillingInfosSort;
  initialPage?: FetchBillingInfosPage;
  initialLimit?: FetchBillingInfosLimit;
  options?: IndexQueryDefaultOptionsOpts<FetchBillingInfosBillingInfoType>;
  query: FetchBillingInfosGqlQuery;
}

const scope = 'billingInfos';

function useFinPaginatedBillingInfos<FetchBillingInfosBillingInfoType>({
  cacheKey,
  initialFilters = INITIAL_BILLING_INFOS_FILTERS,
  initialSort = INITIAL_BILLING_INFOS_SORT,
  initialPage = INITIAL_BILLING_INFOS_PAGE,
  initialLimit = INITIAL_BILLING_INFOS_LIMIT,
  options = {},
  query
}: PaginatedBillingInfosOptions<FetchBillingInfosBillingInfoType>) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchBillingInfosBillingInfoType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query
  });

  return {
    billingInfosData: data,
    billingInfos: items,
    billingInfosError: itemsError,
    billingInfosErrorMessage: itemsErrorMessage,
    billingInfosTotalCount: itemsTotalCount,
    billingInfosFetched: isFetched,
    billingInfosLoading: isLoading,
    billingInfosIsPlaceholderData: isPlaceholderData,
    billingInfosFilters: currentFilters,
    billingInfosSort: currentSort,
    billingInfosPage: currentPage,
    billingInfosLimit: currentLimit,
    updateBillingInfoCache: updateItemCache,
    filterBillingInfos: filterItems,
    changeBillingInfosFilters: changeItemsFilters,
    clearBillingInfosFilters: clearItemsFilters,
    sortBillingInfos: sortItems,
    paginateBillingInfos: paginateItems,
    limitBillingInfos: limitItems,
    prefetchBillingInfos: prefetchItems
  };
}

export default useFinPaginatedBillingInfos;
