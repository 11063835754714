import get from 'lodash/get';
import includes from 'lodash/includes';

import { CreateItemGqlQuery } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLClient } from '../../graphQLClients';

interface CreateItemProps<CreateItemInput> {
  action: string;
  query: CreateItemGqlQuery;
  queryInput: CreateItemInput;
}

const successQueryStatuses = ['created', 'success'];

export function createItem<CreateItemInput, CreateItemResponse>({
  action,
  query,
  queryInput
}: CreateItemProps<CreateItemInput>) {
  const { rawName, name } = getQueryName(query, 'mutation');

  productionConsole(
    'log',
    'createItem',
    { action, queryInput },
    { rawName, name },
    JSON.stringify(queryInput)
  );

  const requestId = generateUuid();

  graphQLClient.addQueryParams(name, requestId);

  return new Promise<CreateItemResponse>((resolve, reject) => {
    return graphQLClient
      .request<CreateItemResponse, CreateItemInput>(query, queryInput, {
        requestId
      })
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        reject(get(response, `${action}.errors`));
      })
      .catch((reason) => reject(reason));
  });
}
