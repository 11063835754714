import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ChatGptPromptsKeyType {
  applicableTo: LocalesKey;
  create: LocalesKey;
  delete: LocalesKey;
  description: LocalesKey;
  destination: LocalesKey;
  kind: LocalesKey;
  name: LocalesKey;
  new: LocalesKey;
  no: LocalesKey;
  plural: LocalesKey;
  pluralDowncase: LocalesKey;
  prompt: LocalesKey;
  scopes: {
    index: LocalesKey;
    create: LocalesKey;
    read: LocalesKey;
    update: LocalesKey;
    delete: LocalesKey;
  };
  search: LocalesKey;
  user: LocalesKey;
  view: LocalesKey;
}

export const chatGptPromptsKeys = keyPathMirror<ChatGptPromptsKeyType, string>(
  {
    applicableTo: null,
    create: null,
    delete: null,
    description: null,
    destination: null,
    kind: null,
    name: null,
    new: null,
    no: null,
    plural: null,
    pluralDowncase: null,
    prompt: null,
    scopes: {
      index: null,
      create: null,
      read: null,
      update: null,
      delete: null
    },
    search: null,
    user: null,
    view: null
  },
  'models.chatGptPrompts.'
);
