import React from 'react';

import { IconProps } from '../types';

function DocumentUploadSolidIcon({ className, id }: IconProps) {
  return (
    <div className="mr-2">
      <svg
        id={id}
        xmlns="http://www.w3.org/2000/svg"
        className={className || 'h-5 w-5'}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7.4c0-.5-.2-1-.6-1.4L12 2.6c-.4-.4-.9-.6-1.4-.6H6zm3 12c0 .6.4 1 1 1s1-.4 1-1v-3.6l1.3 1.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-3-3c-.4-.4-1-.4-1.4 0l-3 3c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0L9 10.4V14z"
        />
      </svg>
    </div>
  );
}

export default DocumentUploadSolidIcon;
