export class ProjectMemberInviteCache {
  static indexCacheKey() {
    return 'projectMemberInvites';
  }

  static showCacheKey() {
    return 'projectMemberInvite';
  }

  static totalCountCacheKey() {
    return 'projectMemberInvitesTotalCount';
  }
}
