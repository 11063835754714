import { IconProps } from '../types';

function RocketOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      stroke="currentColor"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 16c-1 1.5-1.4 4.6-1.5 5h0c.4-.1 3.5-.5 5-1.5 1-.7.8-2.7 0-3.5-.8-.8-2.7-1-3.5 0h0zM21 3c-5 0-6.5.5-9 3-2.5 0-5 0-7 2l1.5 1.5L9 10v3l2 2h3l.5 2.5L16 19c2-2 2-4 2-7 2.5-2.5 3-4 3-9zm-4.5 5c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
      />
    </svg>
  );
}

export default RocketOutlineIcon;
