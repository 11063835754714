import { PageID, PageNanoID } from './pagesTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

export class PageCache {
  static showPageCacheKey(pageNanoId: PageNanoID) {
    return `page-${pageNanoId}`;
  }

  static indexCacheKey() {
    return 'pages';
  }

  static showCacheKey() {
    return 'page';
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `pages-filtered-${searchFilterNanoId}`;
  }

  static showRedirectCacheKey(pageId: PageID) {
    return `page-${pageId}-redirect`;
  }
}
