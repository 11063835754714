import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface GeneralLedgersKeyType {
  createSubbook: LocalesKey;
  editSubbook: LocalesKey;
  subbookName: LocalesKey;
}

export const generalLedgersKeys = keyPathMirror<GeneralLedgersKeyType, string>(
  {
    createSubbook: null,
    editSubbook: null,
    subbookName: null
  },
  'models.generalLedgers.'
);
