export default {
  plural: 'Запрошення',
  accept: 'Прийняти',
  acceptAll: 'Прийняти всі',
  acceptSelected: 'Прийняти обрані',
  decline: 'Відхилити',
  declineAll: 'Відхилити всі',
  declineSelected: 'Відхилити обрані',
  search: 'Пошук запрошень за назвою завдання',
  areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone:
    'Ви впевнені, що хочете прийняти всі запрошення в задачі? Цю дію неможливо скасувати.',
  areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone:
    'Ви впевнені, що хочете відхилити всі запрошення в задачі? Цю дію неможливо скасувати.',
  areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone:
    'Ви впевнені, що хочете відхилити запрошення в задачу? Цю дію неможливо скасувати.'
};
