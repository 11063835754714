import * as yup from 'yup';
import { BillingInfoFields } from '../../../../../billingInfosTypes';

import { isPhone } from '../../../../../../../utils/isPhone';

import { formsErrors } from '../../../../../../../locales/keys';
import { usaCountryName } from '../../../../../billingInfosConstants';

export const createBillingInfoFormSchema = (withoutReceiver?: boolean) =>
  yup.object({
    [BillingInfoFields.COMPANY_NAME]: yup
      .string()
      .required(formsErrors.required),
    [BillingInfoFields.CONTACT_PHONE]: yup
      .string()
      .test({
        test: isPhone,
        message: formsErrors.phone.valid
      })
      .required(formsErrors.required),
    [BillingInfoFields.CONTACT_EMAIL]: yup
      .string()
      .email(formsErrors.email.valid)
      .required(formsErrors.required),
    [BillingInfoFields.COUNTRY]: yup.string().required(formsErrors.required),
    [BillingInfoFields.STATE]: yup
      .string()
      .nullable()
      .when([BillingInfoFields.COUNTRY], {
        is: (value: string) => value === usaCountryName,
        then: yup.string().required(formsErrors.required)
      }),
    [BillingInfoFields.CITY]: yup.string().required(formsErrors.required),
    [BillingInfoFields.ADDRESS]: yup.string().required(formsErrors.required),
    [BillingInfoFields.ZIP_CODE]: yup.string().required(formsErrors.required),
    [BillingInfoFields.AV_BILLING_INFO_ID]: withoutReceiver
      ? undefined
      : yup.string().required(formsErrors.required),
    [BillingInfoFields.PAYMENT_METHOD]: yup
      .string()
      .required(formsErrors.required),
    [BillingInfoFields.VAT_NUMBER]: yup.string()
  });
