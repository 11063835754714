import { JournalRecordImportID } from './journalRecordImportsTypes';

const journalRecordImportsBasePath = '/transaction-imports';

export class JournalRecordImportsPath {
  static transactionImports() {
    return journalRecordImportsBasePath;
  }

  static transactionImportsRows(journalRecordImportId: JournalRecordImportID) {
    return `${journalRecordImportsBasePath}/${journalRecordImportId}`;
  }

  static transactionImportInvoices(
    journalRecordImportId: JournalRecordImportID
  ) {
    return `${journalRecordImportsBasePath}/${journalRecordImportId}/invoices`;
  }
}
