import React from 'react';
import cl from 'classnames';

import { ClassName } from '../../../../../types';

import { useDownloadManagerCornerModal } from '../../footers/DownloadManagerCornerModal/hooks/useDownloadManagerCornerModal';

import { PureIconBadgeButtonHelper } from '../../../../../helpers/buttons/PureIconBadgeButtonHelper';

import { IconsEnum } from '../../../../../assets/icons/types';
import { downloadsKeys } from '../../../../../locales/keys';

interface DownloadManagerModalButtonProps {
  processingClassName?: ClassName;
  staleClassName?: ClassName;
  i18nTextClassName?: ClassName;
  badgeClassName?: ClassName;
}

function UserDownloadManagerCornerButton({
  processingClassName,
  staleClassName,
  i18nTextClassName,
  badgeClassName
}: DownloadManagerModalButtonProps) {
  const {
    downloadsFetched,
    toggleDownloadManagerBlock,
    processingDownloadsCount,
    totalProgress
  } = useDownloadManagerCornerModal();

  if (!downloadsFetched) {
    return null;
  }

  return (
    <PureIconBadgeButtonHelper
      className={cl(
        processingDownloadsCount === 0 ? staleClassName : processingClassName,
        !processingClassName && !staleClassName
          ? 'focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white items-center gap-1.5 mb-px overflow-hidden sm:flex'
          : null,
        processingDownloadsCount === 0 ? 'hidden' : 'flex'
      )}
      i18nTextClassName={i18nTextClassName}
      i18nText={downloadsKeys.plural}
      icon={IconsEnum.ARROW_DOWN}
      iconClassName={cl('w-3.5 h-3.5', {
        'cycle-to-b': processingDownloadsCount > 0
      })}
      badgeValue={totalProgress}
      badgeSuffix="%"
      badgeClassName={badgeClassName}
      badgeAddClassName={processingDownloadsCount === 0 && 'hidden'}
      onClick={toggleDownloadManagerBlock}
      badgeDecimalScale={0}
    />
  );
}

export default UserDownloadManagerCornerButton;
