const enum SuffixEnum {
  B = 'B',
  M = 'M',
  K = 'K'
}

const multipliers = {
  [SuffixEnum.B]: Math.pow(10, 9),
  [SuffixEnum.M]: Math.pow(10, 6),
  [SuffixEnum.K]: Math.pow(10, 3)
};

interface CompactValue {
  compactValue: number;
  suffix?: SuffixEnum;
}

function getCompactValue(value: number): CompactValue {
  if (value >= multipliers[SuffixEnum.B]) {
    return {
      compactValue: value / multipliers[SuffixEnum.B],
      suffix: SuffixEnum.B
    };
  }
  if (value >= multipliers[SuffixEnum.M]) {
    return {
      compactValue: value / multipliers[SuffixEnum.M],
      suffix: SuffixEnum.M
    };
  }
  if (value >= multipliers[SuffixEnum.K]) {
    return {
      compactValue: value / multipliers[SuffixEnum.K],
      suffix: SuffixEnum.K
    };
  }

  return { compactValue: value };
}

export default getCompactValue;
