import { IconProps } from '../types';

function FolderCubeSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h3.3l4.3 2.5L16 16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-4.8 11l-2.8-1.6v-3.2l2.8 1.6V17zm-2.3-5.7l2.8-1.6 2.8 1.6-2.8 1.6-2.8-1.6zm3.3 2.5l2.8-1.6v3.2L12.2 17v-3.2z" />
    </svg>
  );
}

export default FolderCubeSolidIcon;
