import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ItemTypesKeyType {
  plural: LocalesKey;
  pluralLowerCase: LocalesKey;
  defaultPlural: LocalesKey;
  search: LocalesKey;
  select: LocalesKey;
  create: LocalesKey;
  update: LocalesKey;
  delete: LocalesKey;
  duplicate: LocalesKey;
  oneOrMoreItemsWasNotFound: LocalesKey;
  import: LocalesKey;
}

export const itemTypesKeys = keyPathMirror<ItemTypesKeyType, string>(
  {
    plural: null,
    pluralLowerCase: null,
    defaultPlural: null,
    search: null,
    select: null,
    create: null,
    update: null,
    delete: null,
    duplicate: null,
    oneOrMoreItemsWasNotFound: null,
    import: null
  },
  'models.itemTypes.'
);
