import { components, SingleValueProps } from 'react-select';
import has from 'lodash/has';

import { MultiSelectExtendedDataType } from '../../types';

import { ValueImage } from '../ValueImage';

function SingleValue({
  children,
  ...props
}: SingleValueProps<MultiSelectExtendedDataType>) {
  return (
    <components.SingleValue {...props}>
      {has(props.data, 'image') ? (
        <div className="flex items-center relative">
          <ValueImage
            image={props.data.image}
            label={props.data.label as string}
            className="absolute left-0 rounded-full h-8 w-8"
          />

          <span className="pl-10">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.SingleValue>
  );
}

export default SingleValue;
