import {
  CreatedAt,
  DateType,
  DeviceNanoId,
  ID,
  MoneyType,
  StringFilter,
  UpdatedAt,
  UUID,
  NanoID,
  ModelBrand,
  MayBe
} from '../../../types';

import {
  UserBlocked,
  UserClient,
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserNDA,
  UserRoleName,
  UserUUID,
  UserCurrentTeamNanoID
} from '../../users/usersTypes';

import {
  TaskID,
  TaskName,
  TaskNanoID,
  TaskStatus,
  TaskUUID,
  TaskVisibleForClient
} from '../../tasks/tasksTypes';

import { FileAttachmentID } from '../../fileAttachments/fileAttachmentsTypes';

import {
  MessageBillAmount,
  MessageBillID,
  MessageBillUUID,
  MessageBody,
  MessageColors,
  MessageCreatedAt,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsUUID,
  MessageHours,
  MessageID,
  MessageMinutes,
  MessageSelectedProductsCreatedAt,
  MessageUpdatedAt,
  MessageUserClient,
  MessageUserFullName,
  MessageUserID,
  MessageUserImageFile,
  MessageUserImageUUID,
  MessageUserNanoID,
  MessageUserUUID,
  MessageVisibleForClient
} from '../../messages/messagesTypes';

import { TagID, TagLocalizedName, TagUUID } from '../../tags/tagsTypes';

import {
  SelectedProductID,
  SelectedProductProductName,
  SelectedProductProductUUID,
  SelectedProductUUID
} from '../../selectedProducts/selectedProductsTypes';

import {
  SelectedMaterialID,
  SelectedMaterialMaterialName,
  SelectedMaterialMaterialUUID,
  SelectedMaterialUUID
} from '../../selectedMaterials/selectedMaterialsTypes';

import { SelectedLifestyleID } from '../../selectedLifestyles/selectedLifestylesTypes';
import {
  TeamCreatedAt,
  TeamID,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamNda
} from '../../teams/teamsTypes';

export type ProjectID = ID;
export type ProjectUUID = UUID;
export type ProjectNanoID = ModelBrand<NanoID, 'ProjectNanoID'>;
export type ProjectName = string;
export type ProjectDescription = string;
export type ProjectCreatedAt = CreatedAt;
export type ProjectUpdatedAt = UpdatedAt;
export type ProjectFavorite = boolean;
export type ProjectNotificationsEnabled = boolean;
export type ProjectBurning = boolean;
export type ProjectStatus = ProjectStatusOptions;
export type ProjectTasksClientStatus = ProjectStatusOptions;
export type ProjectEstimateCost = MoneyType;
export type ProjectTasksAmountsSum = MoneyType;
export type ProjectPrepaymentsSum = MoneyType;
export type ProjectDebt = MoneyType;
export type ProjectClientDebt = MoneyType;
export type ProjectProjectAccount = MoneyType;
export type ProjectSetDate = DateType;
export type ProjectImplementationDate = DateType;
export type ProjectLastActivity = DateType;
export type ProjectRequirements = string[];
export type ProjectActiveTasksCount = number;
export type ProjectPerformedTasksCount = number;
export type ProjectCanceledTasksCount = number;
export type ProjectAcceptanceTasksCount = number;
export type ProjectDefinitionTasksCount = number;
export type ProjectCommission = number;
export type ProjectCreditCommission = number;
export type ProjectHostingCommission = number;
export type ProjectIncompleteSpecificationTasksCount = number;
export type ProjectQaTasksCount = number;
export type ProjectImplementationTasksCount = number;
export type ProjectTasksPrepaymentsSum = MoneyType;
export type ProjectTasksClientPrepaymentsSum = ModelBrand<
  MoneyType,
  'ProjectTasksClientPrepaymentsSum'
>;
export type ProjectDeviceNanoId = DeviceNanoId;
export type ProjectUnpaidBillsSum = number;
export type ProjectCoefficient = number;

export type ProjectExpenses = number;
export type ProjectOpenRegular = number;
export type ProjectPaidRegular = number;
export type ProjectSentRegular = number;

export type ProjectStatusOptions =
  | 'acceptance'
  | 'canceled'
  | 'definition'
  | 'implementation'
  | 'incomplete_specification'
  | 'performed'
  | 'qa';

export enum ProjectStatuses {
  SETTING_TASK = 'definition',
  IN_PROGRESS = 'implementation',
  QUALITY_CONTROL = 'qa',
  READY_FOR_ACCEPTANCE = 'acceptance',
  DONE = 'performed',
  PENDING_MORE_INFO = 'incomplete_specification',
  CANCELED = 'canceled'
}

export type ProjectUserID = UserID;
export type ProjectUserUUID = UserUUID;
export type ProjectUserNanoID = UserNanoID;
export type ProjectUserFullName = UserFullName;
export type ProjectUserImageUUID = UserImageUUID;
export type ProjectUserImageFile = UserImageFile;
export type ProjectUserClient = UserClient;
export type ProjectUserNDA = UserNDA;
export type ProjectUserRoleName = UserRoleName;
export type ProjectUserFinanceRoleName = UserFinanceRoleName;
export type ProjectUserBlocked = UserBlocked;
export type ProjectUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type ProjectOwnerID = UserID;
export type ProjectOwnerUUID = UserUUID;
export type ProjectOwnerNanoID = UserNanoID;
export type ProjectOwnerFullName = UserFullName;
export type ProjectOwnerImageUUID = UserImageUUID;
export type ProjectOwnerImageFile = UserImageFile;
export type ProjectOwnerClient = UserClient;
export type ProjectOwnerNDA = UserNDA;
export type ProjectOwnerRoleName = UserRoleName;
export type ProjectOwnerFinanceRoleName = UserFinanceRoleName;
export type ProjectOwnerBlocked = UserBlocked;
export type ProjectOwnerCurrentTeamNanoID = UserCurrentTeamNanoID;

export type ProjectMembersID = UserID;
export type ProjectMembersUUID = UserUUID;
export type ProjectMembersNanoID = UserNanoID;
export type ProjectMembersFullName = UserFullName;
export type ProjectMembersRoleName = UserRoleName;
export type ProjectMembersFinanceRoleName = UserFinanceRoleName;
export type ProjectMembersImageUUID = UserImageUUID;
export type ProjectMembersImageFile = UserImageFile;
export type ProjectMembersClient = UserClient;
export type ProjectMembersBlocked = UserBlocked;
export type ProjectMembersCurrentTeamNanoID = UserCurrentTeamNanoID;

// export type ProjectMembersFinanceRoleName = UserFinanceRoleName; // Deprecated

export type ProjectTaskId = TaskID;
export type ProjectTaskUUID = TaskUUID;
export type ProjectTaskNanoID = TaskNanoID;
export type ProjectTaskName = TaskName;
export type ProjectTaskStatus = TaskStatus;
export type ProjectTaskVisibleForClient = TaskVisibleForClient;
export type ProjectTaskNames = ProjectTaskName[];
export type ProjectFileAttachmentIDs = FileAttachmentID[];
export type ProjectFileAttachmentMuted = boolean;

export type ProjectCreateProjectsBillsAccount = string;
export type ProjectCreateProjectsBillsAmount = number;
export type ProjectCreateProjectsBillsBody = string;
export type ProjectCreateProjectsBillsAllocableUser = string;
export type ProjectCreateProjectsBillsButtons = string;
export type ProjectCreateProjectsBillsCurrency = string;
export type ProjectCreateProjectsBillsTransactionTagId = ID;

export type ProjectMessageID = MessageID;
export type ProjectMessageVisibleForClient = MessageVisibleForClient;
export type ProjectMessageColors = MessageColors;
export type ProjectMessageHours = MessageHours;
export type ProjectMessageMinutes = MessageMinutes;
export type ProjectMessageCreatedAt = MessageCreatedAt;
export type ProjectMessageUpdatedAt = MessageUpdatedAt;

export type ProjectLastMessageCreatedAt = MessageCreatedAt;
export type ProjectLastMessageBody = MessageBody;
export type ProjectLastMessageUserID = MessageUserID;
export type ProjectLastMessageUserNanoID = MessageUserNanoID;
export type ProjectLastMessageUserFullName = MessageUserFullName;
export type ProjectLastMessageUserImageUUID = MessageUserImageUUID;
export type ProjectLastMessageUserImageFile = MessageUserImageFile;

export type ProjectMessageUserUUID = MessageUserUUID;
export type ProjectMessageUserClient = MessageUserClient;
export type ProjectMessageUserImageFile = MessageUserImageFile;

export type ProjectMessageBillID = MessageBillID;
export type ProjectMessageBillUUID = MessageBillUUID;
export type ProjectMessageBillAmount = MessageBillAmount;

export type ProjectMessageFileAttachmentsUUID = MessageFileAttachmentsUUID;
export type ProjectMessageFileAttachmentsFile = MessageFileAttachmentsFile;

export type ProjectMessageProjectID = ProjectID;

export type ProjectMessageSelectedProductsCreatedAt =
  MessageSelectedProductsCreatedAt;

export type ProjectTagsID = TagID;
export type ProjectTagsUUID = TagUUID;
export type ProjectTagsLocalizedName = TagLocalizedName;

export type ProjectPerformerIdsFilter = ProjectMembersID[];

export type ProjectSelectedProductsID = SelectedProductID;
export type ProjectSelectedProductsUUID = SelectedProductUUID;
export type ProjectSelectedProductsProductName = SelectedProductProductName;
export type ProjectSelectedProductsProductUUID = SelectedProductProductUUID;

export type ProjectSelectedMaterialsID = SelectedMaterialID;
export type ProjectSelectedMaterialsUUID = SelectedMaterialUUID;
export type ProjectSelectedMaterialsMaterialName = SelectedMaterialMaterialName;
export type ProjectSelectedMaterialsMaterialUUID = SelectedMaterialMaterialUUID;

export type ProjectSelectedLifestylesID = SelectedLifestyleID;

export type ProjectTeamID = TeamID;
export type ProjectTeamNanoID = TeamNanoID;
export type ProjectTeamCreatedAt = TeamCreatedAt;
export type ProjectTeamName = TeamName;
export type ProjectTeamNda = TeamNda;
export type ProjectTeamImageFile = TeamImageFile;
export type ProjectTeam = {
  name: ProjectTeamName;
  nanoId: ProjectTeamNanoID;
  image?: MayBe<{
    file: ProjectTeamImageFile;
  }>;
};

export const enum ProjectFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  BURNING = 'burning',
  DESCRIPTION = 'description',
  IMPLEMENTATION_DATE = 'implementationDate',
  ESTIMATE_COST = 'estimateCost',
  COMMISSION = 'commission',
  CREDIT_COMMISSION = 'creditCommission',
  HOSTING_COMMISSION = 'hostingCommission',
  CREATED_AT = 'createdAt',
  TASK_NAMES = 'taskNames',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  TASKS = 'tasks'
}

export const enum ProjectTrackTimeField {
  BODY = 'body',
  HOURS = 'hours',
  MINUTES = 'minutes'
}
export const enum ProjectSettingField {
  CANCELED_TASKS = 'canceledTasks',
  HIDDEN_TASKS = 'hiddenTasks',
  ONLY_HIDDEN_TASKS = 'onlyHiddenTasks'
}

export enum ProjectBillFields {
  ID = 'id',
  UUID = 'uuid',
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  BODY = 'body',
  ALLOCABLE_USER = 'allocableUser',
  ACCOUNT = 'account',
  BUTTONS = 'buttons',
  TRANSACTION_TAG_ID = 'transactionTagId',
  CREATED_AT = 'createdAt'
}
