import includes from 'lodash/includes';
import { PaymentsSecondaryMenuItems } from '../../CompactPaymentsSecondaryMenu.types';

function getPaymentsActiveMenu(
  pathname: string
): PaymentsSecondaryMenuItems | null {
  if (includes(pathname, '/invoices') || includes(pathname, '/proforma')) {
    return PaymentsSecondaryMenuItems.INVOICES;
  }

  if (includes(pathname, '/performer-invoices')) {
    return PaymentsSecondaryMenuItems.PERFORMER_INVOICES;
  }

  if (
    includes(pathname, '/balance-sheet') ||
    includes(pathname, '/transactions')
  ) {
    return PaymentsSecondaryMenuItems.GENERAL_LEDGERS;
  }

  if (includes(pathname, '/smart-contracts')) {
    return PaymentsSecondaryMenuItems.SMART_CONTRACTS;
  }

  return null;
}

export default getPaymentsActiveMenu;
