import { IconsEnum } from '../../../../../assets/icons/types';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { TeamPath } from '../../../../../main/teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface UserMenuSettingsLinkProps {
  closeMenus: () => void;
}

function UserMenuSettingsLink({ closeMenus }: UserMenuSettingsLinkProps) {
  return (
    <NextPureLinkHelper
      href={TeamPath.currentCompanyProfileSettings()}
      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm"
      icon={IconsEnum.COG}
      iconClassName="mr-3 h-5 w-5"
      i18nText={words.settings}
      onClick={closeMenus}
    />
  );
}

export default UserMenuSettingsLink;
