import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TeamsUsersKeyType {
  companyReqs: LocalesKey;
  finance: LocalesKey;
  jobTitle: LocalesKey;
  library: LocalesKey;
}

export const teamsUsersKeys = keyPathMirror<TeamsUsersKeyType, string>(
  {
    companyReqs: null,
    finance: null,
    jobTitle: null,
    library: null
  },
  'models.teamsUsers.'
);
