import {
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLReadFinClient } from '../../graphQLClients';

interface FetchFinItemsProps {
  query: FetchItemsGqlQuery;
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  page: FetchItemsPage;
  limit: FetchItemsLimit;
}

export function fetchFinItems({
  query,
  filters,
  sort,
  page,
  limit,
  ...additionalParams
}: FetchFinItemsProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchFinItems',
    { filters, sort, page, limit, ...additionalParams },
    { rawName, name },
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  graphQLReadFinClient.addQueryParams(name, requestId);

  return graphQLReadFinClient.request(
    query,
    {
      filters,
      sort,
      limit,
      offset: (page - 1) * limit,
      ...additionalParams
    },
    { requestId }
  );
}
