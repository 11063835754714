import React, { MouseEventHandler, useCallback } from 'react';

import { I18nText } from '../../../types';

import { Translate } from '../../../helpers/Translate';

type PureButtonHelperOnClick = (e: React.MouseEvent<HTMLButtonElement>) => void;

interface PureButtonHelperDefaultProps {
  id?: string;
  blurOnMouseLeave?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: PureButtonHelperOnClick;
  onMouseEnter?: () => void;
}

interface PureButtonHelperWithI18nProps {
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureButtonHelperWithTextProps {
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureButtonHelperProps = PureButtonHelperDefaultProps &
  (PureButtonHelperWithI18nProps | PureButtonHelperWithTextProps);

function PureButtonHelper({
  blurOnMouseLeave,
  className,
  disabled,
  id,
  onClick,
  onMouseEnter,
  text,
  i18nText,
  i18nTextClassName
}: PureButtonHelperProps) {
  const handleClick = useCallback<PureButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
      type="button"
    >
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
    </button>
  );
}

export default PureButtonHelper;
