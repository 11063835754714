import cl from 'classnames';

import {
  ErrorMessage,
  RegisterFormFieldType,
  ClassName
} from '../../../../../types';
import { CreateBillingInfoFormData } from './CreateBillingInfoForm.types';
import {
  CreateBillingInfoIsLoading,
  BillingInfoFields,
  BillingInfoCountry,
  BillingInfoPaymentMethod
} from '../../../billingInfosTypes';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { SelectFieldRequiredProps } from '../../../../../helpers/FormFields/SelectField';
import { AvBillingInfosSelectField } from '../../../../../helpers/FormFields/AvBillingInfosSelectField';
import { CountriesNameSelectField } from '../../../../../helpers/FormFields/CountriesNameSelectField';
import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';
import { StatesNameSelectField } from '../../../../../helpers/FormFields/StatesNameSelectField';
import { RadioButtonsField } from '../../../../../helpers/FormFields/RadioButtonsField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  billingInfosKeys,
  formsFields,
  invoicesKeys
} from '../../../../../locales/keys';
import {
  BillingInfosPermissions,
  avPaymentMethods,
  paymentMethods,
  usaCountryName
} from '../../../billingInfosConstants';

interface CreateBillingInfoFormProps {
  form: string;
  innerWrapperClassName?: ClassName;
  isLoading: CreateBillingInfoIsLoading;
  companyNameValidationError: ErrorMessage;
  firstNameValidationError: ErrorMessage;
  lastNameValidationError: ErrorMessage;
  contactPhoneValidationError: ErrorMessage;
  contactEmailValidationError: ErrorMessage;
  cityValidationError: ErrorMessage;
  countryValidationError: ErrorMessage;
  stateValidationError: ErrorMessage;
  addressValidationError: ErrorMessage;
  zipCodeValidationError: ErrorMessage;
  avBillingInfoIdValidationError: ErrorMessage;
  vatNumberValidationError: ErrorMessage;
  registerCompanyName: RegisterFormFieldType<HTMLInputElement>;
  registerFirstName: RegisterFormFieldType<HTMLInputElement>;
  registerLastName: RegisterFormFieldType<HTMLInputElement>;
  registerContactPhone: RegisterFormFieldType<HTMLInputElement>;
  registerContactEmail: RegisterFormFieldType<HTMLInputElement>;
  registerCity: RegisterFormFieldType<HTMLInputElement>;
  registerAddress: RegisterFormFieldType<HTMLInputElement>;
  registerZipCode: RegisterFormFieldType<HTMLInputElement>;
  registerVatNumber: RegisterFormFieldType<HTMLInputElement>;
  watchCountry: BillingInfoCountry;
  watchPaymentMethod: BillingInfoPaymentMethod;
}

function CreateBillingInfoForm({
  control,
  form,
  innerWrapperClassName,
  isLoading,
  companyNameValidationError,
  firstNameValidationError,
  lastNameValidationError,
  contactPhoneValidationError,
  contactEmailValidationError,
  cityValidationError,
  countryValidationError,
  stateValidationError,
  addressValidationError,
  zipCodeValidationError,
  avBillingInfoIdValidationError,
  vatNumberValidationError,
  registerCompanyName,
  registerFirstName,
  registerLastName,
  registerContactPhone,
  registerContactEmail,
  registerCity,
  registerAddress,
  registerZipCode,
  registerVatNumber,
  watchCountry,
  watchPaymentMethod
}: CreateBillingInfoFormProps &
  SelectFieldRequiredProps<CreateBillingInfoFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className={cl(innerWrapperClassName || 'p-4 space-y-4')}>
        <RadioButtonsField<CreateBillingInfoFormData, typeof paymentMethods[0]>
          control={control}
          disabled={isLoading}
          i18nLabel={invoicesKeys.paymentMethod}
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
          name={BillingInfoFields.PAYMENT_METHOD}
          options={paymentMethods}
          optionClassName="text-xs"
          radioButtonLabelClassName="inline-flex items-center gap-2"
        />

        <InputField
          disabled={isLoading}
          error={companyNameValidationError}
          i18nLabel={formsFields.companyName}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerCompanyName.name}
          onChange={registerCompanyName.onChange}
          ref={registerCompanyName.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={vatNumberValidationError}
          i18nLabel={formsFields.vatNumber}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerVatNumber.name}
          onChange={registerVatNumber.onChange}
          ref={registerVatNumber.ref}
          type="text"
        />

        <div className="flex space-x-2">
          <InputField
            disabled={isLoading}
            error={firstNameValidationError}
            i18nLabel={formsFields.firstName}
            className="flex-1"
            inputWrapperClassName="relative mt-1"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={registerFirstName.name}
            onChange={registerFirstName.onChange}
            ref={registerFirstName.ref}
            type="text"
          />

          <InputField
            disabled={isLoading}
            error={lastNameValidationError}
            i18nLabel={formsFields.lastName}
            className="flex-1"
            inputWrapperClassName="relative mt-1"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={registerLastName.name}
            onChange={registerLastName.onChange}
            ref={registerLastName.ref}
            type="text"
          />
        </div>

        <InputField
          disabled={isLoading}
          error={contactPhoneValidationError}
          i18nLabel={formsFields.contactPhone}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerContactPhone.name}
          onChange={registerContactPhone.onChange}
          ref={registerContactPhone.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={contactEmailValidationError}
          i18nLabel={formsFields.contactEmail}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerContactEmail.name}
          onChange={registerContactEmail.onChange}
          ref={registerContactEmail.ref}
          type="text"
        />

        <CountriesNameSelectField<CreateBillingInfoFormData>
          control={control}
          disabled={isLoading}
          error={countryValidationError}
          i18nLabel={formsFields.country}
          i18nPlaceholder={formsFields.country}
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={BillingInfoFields.COUNTRY}
        />

        {usaCountryName === watchCountry && (
          <StatesNameSelectField
            control={control}
            disabled={isLoading}
            error={stateValidationError}
            i18nLabel={formsFields.state}
            i18nPlaceholder={billingInfosKeys.selectState}
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={BillingInfoFields.STATE}
          />
        )}

        <InputField
          disabled={isLoading}
          error={cityValidationError}
          i18nLabel={formsFields.city}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerCity.name}
          onChange={registerCity.onChange}
          ref={registerCity.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={addressValidationError}
          i18nLabel={formsFields.address}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerAddress.name}
          onChange={registerAddress.onChange}
          ref={registerAddress.ref}
          type="text"
        />

        <InputField
          disabled={isLoading}
          error={zipCodeValidationError}
          i18nLabel={formsFields.zipCode}
          inputWrapperClassName="relative mt-1"
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={registerZipCode.name}
          onChange={registerZipCode.onChange}
          ref={registerZipCode.ref}
          type="text"
        />

        <CheckPermissions
          action={
            BillingInfosPermissions.READ_CREATE_BILLING_INFO_MODAL_RECIEVER_FIELD
          }
        >
          <AvBillingInfosSelectField<CreateBillingInfoFormData>
            control={control}
            disabled={isLoading}
            error={avBillingInfoIdValidationError}
            i18nLabel={formsFields.receiver}
            i18nPlaceholder={formsFields.receiver}
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={BillingInfoFields.AV_BILLING_INFO_ID}
            menuPlacement={PopoverPlacement.TOP}
            paymentMethodFilter={avPaymentMethods[watchPaymentMethod]}
            country={watchCountry}
          />
        </CheckPermissions>
      </div>
    </Form>
  );
}

export default CreateBillingInfoForm;
