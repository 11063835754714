import {
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoIdFilterType,
  TextFilterType,
  NanoID,
  UUID,
  IntegerFilterType,
  UpdateItemCacheKeys,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  IdFilterType,
  UpdateItemIsLoading,
  DateType,
  MoneyType
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { AccountBalance } from '../accounts/accountsTypes';
import {
  TeamAutoPaymentScAt,
  TeamID,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamOwnerID,
  TeamOwnerNanoID
} from '../teams/teamsTypes';

export const enum GeneralLedgerGeneralLedgerTypes {
  DEFAULT = 'default',
  SUB_BOOK = 'sub_book'
}

export type GeneralLedgerID = ID;
export type GeneralLedgerUUID = UUID;
export type GeneralLedgerNanoID = NanoID;
export type GeneralLedgerCreatedAt = CreatedAt;
export type GeneralLedgerAutoAllocateAt = DateType;
export type GeneralLedgerName = string;
export type GeneralLedgerGeneralLedgerType = GeneralLedgerGeneralLedgerTypes;
export type GeneralLedgerDefaultShare = number;
export type GeneralLedgerDefaultShareType = GeneralLedgerDefaultShareTypes;
export type GeneralLedgerItemsAwaitedTotal = MoneyType;

export type GeneralLedgerCashAccountBalance = AccountBalance;

export type GeneralLedgerCompanyID = TeamID;
export type GeneralLedgerCompanyNanoID = TeamNanoID;
export type GeneralLedgerCompanyName = TeamName;
export type GeneralLedgerCompanyImageFile = TeamImageFile;
export type GeneralLedgerCompanyAutoPaymentScAt = TeamAutoPaymentScAt;

export type GeneralLedgerCompanyOwnerNanoID = TeamOwnerNanoID;
export type GeneralLedgerCompanyOwnerID = TeamOwnerID;

export const enum GeneralLedgerDefaultShareTypes {
  WITH_INVOICE = 'with_invoice',
  WITHOUT_INVOICE = 'without_invoice'
}

export const enum FetchGeneralLedgersSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export interface FetchFinGeneralLedgersFilters {
  companyNanoId?: NanoIdFilterType;
  companyId?: IntegerFilterType;
  companyOwnerId?: IdFilterType;
  generalLedgerType?: TextFilterType;
}

export type FetchGeneralLedgersGqlQuery = FetchItemsGqlQuery;

export type FetchGeneralLedgersCacheKey = FetchItemsCacheKey;
export type FetchGeneralLedgersCacheKeys = FetchGeneralLedgersCacheKey[];

export type FetchGeneralLedgersSort = FetchItemsSort;
export type FetchGeneralLedgersSortGeneralLedgers = FetchItemsSortItems;
export type FetchGeneralLedgersPage = FetchItemsPage;
export type FetchGeneralLedgersLimit = FetchItemsLimit;
export type FetchGeneralLedgersErrorMessage = FetchItemsErrorMessage;
export type FetchGeneralLedgersFetched = FetchItemsFetched;
export type FetchGeneralLedgersIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchGeneralLedgersPaginateGeneralLedgers = FetchItemsPaginateItems;
export type FetchGeneralLedgersPrefetchGeneralLedgers = FetchItemsPrefetchItems;
export type FetchGeneralLedgersTotalCount = FetchItemsTotalCount;

export const enum FetchGeneralLedgersSortableFields {
  CREATED_AT = 'createdAt'
}

export type CreateGeneralLedgerIsLoading = CreateItemIsLoading;
export type CreateGeneralLedgerCacheKeys = CreateItemCacheKeys;

export type UpdateGeneralLedgerIsLoading = UpdateItemIsLoading;
export type UpdateGeneralLedgerCacheKeys = UpdateItemCacheKeys;
export type UpdateGeneralLedgersCacheKeys = UpdateItemsCacheKeys;

export type FetchGeneralLedgerCacheKey = FetchItemCacheKey;
export type FetchGeneralLedgerGqlQuery = FetchItemGqlQuery;
