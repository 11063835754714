import { IconProps } from '../types';

function LinkCrossedAltSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'w-4 h-4'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M14.1 11.1c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2-2c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4c-.8-.8-2.1-.8-2.8 0l-2 2c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l2-2C11.9 2.4 12.9 2 14 2c1.1 0 2.1.4 2.8 1.2.8.7 1.2 1.7 1.2 2.8s-.4 2.1-1.2 2.8l-2 2c-.2.2-.4.3-.7.3zm-5.3 5.7l2-2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2 2c-.8.8-2.1.8-2.8 0-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4l2-2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2 2C2.4 11.9 2 12.9 2 14c0 1.1.4 2.1 1.2 2.8.7.8 1.7 1.2 2.8 1.2s2.1-.4 2.8-1.2zm5.4 1.2c.5-.1.9-.7.7-1.2l-.6-2.4 2.4.6c.5.1 1.1-.2 1.2-.7.1-.5-.2-1.1-.7-1.2l-4-1c-.3-.1-.7 0-.9.3s-.3.6-.3.9l1 4c.1.5.5.8 1 .8.1-.1.2-.1.2-.1zM7.7 7.7c.3-.2.4-.6.3-.9l-1-4c-.2-.6-.7-.9-1.2-.8-.6.2-.9.7-.8 1.2l.6 2.4L3.2 5c-.5-.1-1 .2-1.2.8-.1.5.2 1 .8 1.2l4 1H7c.3 0 .5-.1.7-.3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LinkCrossedAltSolidIcon;
