import * as localforage from 'localforage';
import * as Sentry from '@sentry/nextjs';
import { isIOS, isSafari } from 'react-device-detect';

type ItemWithExpiry<ItemType> = {
  value: ItemType;
  expiry: number;
};

class LocalForage {
  static async initDriver() {
    if (typeof window === 'undefined') {
      return;
    }

    try {
      if (isIOS || isSafari) {
        await localforage.setDriver(localforage.LOCALSTORAGE);
      } else {
        await localforage.setDriver(localforage.INDEXEDDB);
      }
    } catch (error) {
      Sentry.captureException(error);
      Sentry.flush(2000);
    }
  }

  static getItem<ItemType>(key: string) {
    try {
      return localforage.getItem<ItemType>(key);
    } catch (error) {
      Sentry.captureException(error);
      Sentry.flush(2000);
    }
  }

  static async setItem<ItemType>(key: string, value: ItemType) {
    return localforage.setItem<ItemType>(key, value).catch(async (error) => {
      Sentry.captureException(error);
      await Sentry.flush(2000);
    });
  }

  static async getItemWithExpiry<ItemType>(key: string) {
    try {
      const item = await localforage.getItem<ItemWithExpiry<ItemType>>(key);

      if (!item) {
        return null;
      }

      const now = new Date();

      if (now.getTime() > item.expiry) {
        await localforage.removeItem(key);
        return null;
      }

      return item.value;
    } catch (error) {
      Sentry.captureException(error);
      await Sentry.flush(2000);
    }
  }

  static async setItemWithExpiry<ItemType>(
    key: string,
    value: ItemType,
    ttl: number
  ) {
    const now = new Date();

    const item: ItemWithExpiry<ItemType> = {
      value: value,
      expiry: now.getTime() + ttl
    };

    return LocalForage.setItem<ItemWithExpiry<ItemType>>(key, item).catch(
      async (error) => {
        Sentry.captureException(error);
        await Sentry.flush(2000);
      }
    );
  }

  static clear() {
    return localforage.clear();
  }
}

export default LocalForage;
