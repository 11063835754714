import size from 'lodash/size';

import { FetchItemsSort } from '../../../../../../../../types';
import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { INITIAL_SORT, SORT_ITEMS } from '../../indexRequestConstants';

function sortItemsAction(nextSort: FetchItemsSort): IndexRequestAction {
  return {
    type: SORT_ITEMS,
    nextSort: size(nextSort) > 0 ? nextSort : INITIAL_SORT
  };
}

export default sortItemsAction;
