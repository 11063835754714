import React from 'react';
import { Controller } from 'react-hook-form';

import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../MultiSelectField';
import { AvBillingInfoMultiSelectValueType } from './components/AvBillingInfosMultiSelectFieldControl/AvBillingInfosMultiSelectFieldControl.types';
import { MultiSelectProps } from '../../MultiSelect';

import { AvBillingInfosMultiSelectFieldControl } from './components/AvBillingInfosMultiSelectFieldControl';

interface AvBillingInfosSelectFieldProps {
  withSecondNewAvPrefix?: boolean;
  paymentMethodFilter?: string[];
  country?: string;
}

function AvBillingInfosSelectField<FormDataType>({
  control,
  withSecondNewAvPrefix,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error,
  defaultValue,
  menuPlacement,
  paymentMethodFilter,
  country
}: AvBillingInfosSelectFieldProps &
  MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType> &
  Pick<MultiSelectProps, 'menuPlacement'>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <div>
          <AvBillingInfosMultiSelectFieldControl
            paymentMethodFilter={paymentMethodFilter}
            country={country}
            defaultValue={defaultValue}
            disabled={disabled}
            error={fieldError?.message || error}
            i18nLabel={i18nLabel}
            i18nPlaceholder={i18nPlaceholder}
            labelClassName={labelClassName}
            onChange={onChange}
            value={value as AvBillingInfoMultiSelectValueType}
            name={name}
            classNamePrefix={
              withSecondNewAvPrefix
                ? fieldError
                  ? 'second_new_av_error'
                  : 'second_new_av'
                : 'av'
            }
            inputWrapperClassName="w-full"
            isSearchable
            menuPlacement={menuPlacement}
          />
        </div>
      )}
    />
  );
}

export default AvBillingInfosSelectField;
