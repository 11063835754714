import { UserNanoID } from './usersTypes';

const basePath = '/new-users';

const baseProfilePath = '/new-profile';

const baseProfileSettingsPath = '/settings';

const baseAttachmentsPath = '/attachments';

export class SingleUserPath {
  static profile() {
    return baseProfilePath;
  }

  static userProfile(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}`;
  }

  static records() {
    return `${baseProfilePath}/records`;
  }

  static userRecords(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/records`;
  }

  static requirements() {
    return `${baseProfilePath}/requirements`;
  }

  static userRequirements(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/requirements`;
  }

  static reports() {
    return `${baseProfilePath}/reports`;
  }

  static userReports(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/reports`;
  }

  static tasksWithoutSourceFiles() {
    return `${baseProfilePath}/reports/tasks-without-source-files`;
  }

  static userTasksWithoutSourceFiles(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/reports/tasks-without-source-files`;
  }

  static dashboard() {
    return `${baseProfilePath}/dashboard`;
  }

  static userDashboard(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/dashboard`;
  }

  static attachments() {
    return `${baseProfilePath}${baseAttachmentsPath}`;
  }

  static userAttachments(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseAttachmentsPath}`;
  }

  static files() {
    return `${baseProfilePath}${baseAttachmentsPath}/files`;
  }

  static userFiles(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseAttachmentsPath}/files`;
  }

  static images() {
    return `${baseProfilePath}${baseAttachmentsPath}/images`;
  }

  static userImages(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseAttachmentsPath}/images`;
  }

  static settings() {
    return `${baseProfilePath}${baseProfileSettingsPath}`;
  }

  static userSettings(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}`;
  }

  static description() {
    return `${baseProfilePath}/description`;
  }

  static personalization() {
    return `${baseProfilePath}/personalization`;
  }

  static documents() {
    return `${baseProfilePath}/documents`;
  }

  static airtable() {
    return `${baseProfilePath}/airtable`;
  }

  static userDescription(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/description`;
  }

  static userPersonalization(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/personalization`;
  }

  static userDocuments(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/documents`;
  }

  static userAirtable(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/airtable`;
  }

  static profileSmartContractSettings() {
    return `${baseProfilePath}/smart-contract-settings`;
  }
  static userProfileSmartContractSettings(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/smart-contract-settings`;
  }
}
