import React from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';

import { BillingInfoID } from '../../../../../../billingInfos/billingInfosTypes';
import { AddFundsModalButtonDataBillingInfo } from '../../AddFundsModalButton.types';
import { I18nText } from '../../../../../../../types';

import { AddFundsModalButtonBillingInfoSelectItem } from './components/AddFundsModalButtonBillingInfoSelectItem';

import { Translate } from '../../../../../../../helpers/Translate';

import { invoicesKeys } from '../../../../../../../locales/keys';

function addItemClassName(index: number, size: number) {
  if (index === 0) {
    return 'rounded-tl-md rounded-tr-md';
  }

  if (index === size - 1) {
    return 'rounded-bl-md rounded-br-md';
  }

  return undefined;
}

interface AddFundsModalButtonBillingInfoSelectProps {
  anotherBillingInfoI18nText?: I18nText;
  billingInfos: AddFundsModalButtonDataBillingInfo[];
  selectedBillingInfoId: BillingInfoID | null;
  showCreateForm?: boolean;
  onBillingInfoSelect: (
    billingInfo: AddFundsModalButtonDataBillingInfo
  ) => void;
  onShowCreateForm: () => void;
  withPaymentMethod?: boolean;
  withoutShowCreateFormOption?: boolean;
  withAvBillingAddress?: boolean;
}

function AddFundsModalButtonBillingInfoSelect({
  anotherBillingInfoI18nText = invoicesKeys.anotherAddress,
  billingInfos,
  selectedBillingInfoId,
  showCreateForm,
  onBillingInfoSelect,
  onShowCreateForm,
  withPaymentMethod = false,
  withoutShowCreateFormOption = false,
  withAvBillingAddress = false
}: AddFundsModalButtonBillingInfoSelectProps) {
  return (
    <div className="rounded-md -space-y-px">
      {billingInfos.map((billingInfo, index) => (
        <AddFundsModalButtonBillingInfoSelectItem
          addClassName={addItemClassName(
            index,
            billingInfos.length + toNumber(!withoutShowCreateFormOption)
          )}
          key={billingInfo.id}
          billingInfo={billingInfo}
          selected={selectedBillingInfoId === billingInfo.id}
          onBillingInfoSelect={onBillingInfoSelect}
          withPaymentMethod={withPaymentMethod}
          withAvBillingAddress={withAvBillingAddress}
        />
      ))}
      {withoutShowCreateFormOption ? null : (
        <label
          className={cl(
            'relative border p-4 flex cursor-pointer focus:outline-none rounded-bl-md rounded-br-md border-gray-200 dark:border-gray-600',
            isEmpty(billingInfos) ? 'rounded-tl-md rounded-tr-md' : null
          )}
        >
          <input
            type="radio"
            className="basic-radio"
            checked={showCreateForm}
            onChange={onShowCreateForm}
          />
          <span className="ml-3 flex flex-col">
            <span className="block text-sm font-medium text-gray-900 dark:text-gray-200">
              <Translate id={anotherBillingInfoI18nText} />
            </span>
          </span>
        </label>
      )}
    </div>
  );
}

export default AddFundsModalButtonBillingInfoSelect;
