import { PageNanoID } from '../../../../../../main/pages/pagesTypes';

import {
  FetchPageShowPageQueryResponse,
  FETCH_PAGE_SHOW_PAGE_QUERY
} from '../../../../../../main/pages/queries/fetchPageShowPage.query';

import { usePageShowPage } from '../../../../../../main/pages/hooks/usePageShowPage';

import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';

import { PageCache } from '../../../../../../main/pages/PageCache';

interface UserTermsModalBodyProps {
  pageNanoId: PageNanoID;
}

function UserTermsModalBody({ pageNanoId }: UserTermsModalBodyProps) {
  const { page, pageLoading, pageError } =
    usePageShowPage<FetchPageShowPageQueryResponse>({
      uuid: pageNanoId,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(pageNanoId),
      initFetch: true
    });

  return (
    <LoadingSkeleton loaded={!pageLoading}>
      <AlertMessage message={pageError} />
      <div
        dangerouslySetInnerHTML={{
          __html: page?.localizedBody
        }}
      />
    </LoadingSkeleton>
  );
}

export default UserTermsModalBody;
