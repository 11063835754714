import {
  FetchAvBillingInfosCacheKey,
  FetchAvBillingInfosGqlQuery,
  FetchAvBillingInfosLimit,
  FetchAvBillingInfosPage,
  FetchAvBillingInfosSort,
  FetchFinAvBillingInfosFilters
} from '../../avBillingInfosTypes';
import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_AV_BILLING_INFOS_FILTERS,
  INITIAL_AV_BILLING_INFOS_LIMIT,
  INITIAL_AV_BILLING_INFOS_PAGE,
  INITIAL_AV_BILLING_INFOS_SORT
} from '../../avBillingInfosConstants';

interface PaginatedAvBillingInfosOptions {
  cacheKey: FetchAvBillingInfosCacheKey;
  initialFilters?: FetchFinAvBillingInfosFilters;
  initialSort?: FetchAvBillingInfosSort;
  initialPage?: FetchAvBillingInfosPage;
  initialLimit?: FetchAvBillingInfosLimit;
  query: FetchAvBillingInfosGqlQuery;
  options?: {
    withoutPrefetch?: boolean;
  };
}

const scope = 'avBillingInfos';

function useFinPaginatedAvBillingInfos<
  AvBillingInfoItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  initialFilters = INITIAL_AV_BILLING_INFOS_FILTERS,
  initialSort = INITIAL_AV_BILLING_INFOS_SORT,
  initialPage = INITIAL_AV_BILLING_INFOS_PAGE,
  initialLimit = INITIAL_AV_BILLING_INFOS_LIMIT,
  query,
  options
}: PaginatedAvBillingInfosOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<AvBillingInfoItemType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    query,
    options
  });

  return {
    avBillingInfosData: data,
    avBillingInfos: items,
    avBillingInfosError: itemsError,
    avBillingInfosErrorMessage: itemsErrorMessage,
    avBillingInfosTotalCount: itemsTotalCount,
    avBillingInfosFetched: isFetched,
    avBillingInfosLoading: isLoading,
    avBillingInfosIsPlaceholderData: isPlaceholderData,
    avBillingInfosFilters: currentFilters,
    avBillingInfosSort: currentSort,
    avBillingInfosPage: currentPage,
    avBillingInfosLimit: currentLimit,
    updateAvBillingInfoCache: updateItemCache,
    filterAvBillingInfos: filterItems,
    changeAvBillingInfosFilters: changeItemsFilters,
    clearAvBillingInfosFilters: clearItemsFilters,
    sortAvBillingInfos: sortItems,
    paginateAvBillingInfos: paginateItems,
    limitAvBillingInfos: limitItems,
    prefetchAvBillingInfos: prefetchItems
  };
}

export default useFinPaginatedAvBillingInfos;
