import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserNanoID, UserUUID } from '../users/usersTypes';
import { TeamNanoID } from '../teams/teamsTypes';
import { WhiteboardCommentID } from '../whiteboards/whiteboardsTypes';

export class MessageCache {
  static indexCacheKey() {
    return 'messages';
  }

  static compactIndexCacheKey() {
    return 'messages-compact';
  }

  static myMessagesCacheKey() {
    return 'my-messages';
  }

  static totalsCacheKey() {
    return 'messages-totals';
  }

  static unreadCacheKey() {
    return 'unread-messages';
  }

  static compactUnreadCacheKey() {
    return 'compact-unread-messages';
  }

  static visibleForClientCacheKey() {
    return 'visible-for-client-messages';
  }

  static compactVisibleForClientCacheKey() {
    return 'compact-visible-for-client-messages';
  }

  static onlyClientsCacheKey() {
    return 'only-clients-messages';
  }

  static compactOnlyClientsCacheKey() {
    return 'compact-only-clients-messages';
  }

  static userMessagesCacheKey(userNanoId: UserNanoID) {
    return `messages-user-${userNanoId}`;
  }

  static compactUserMessagesCacheKey(userNanoId: UserNanoID) {
    return `compact-messages-user-${userNanoId}`;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `messages-filtered-${searchFilterNanoId}`;
  }

  static compactFilteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `messages-compact-filtered-${searchFilterNanoId}`;
  }

  static filteredCountCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `messages-filtered-count-${searchFilterNanoId}`;
  }

  static showCacheKey() {
    return 'message';
  }

  static unreadTotalCountCacheKey() {
    return 'messages-unread-total-count';
  }

  static onlyClientsTotalCountCacheKey() {
    return 'messages-only-clients-total-count';
  }

  static userTotalCountCacheKey(userUuid: UserUUID) {
    return `user-${userUuid}-messages-total-count`;
  }

  static companyMessagesCacheKey(companyNanoId: TeamNanoID) {
    return `messages-company-${companyNanoId}`;
  }

  static companyMessagesNewCacheKey(companyNanoId: TeamNanoID) {
    return `messages-new-company-${companyNanoId}`;
  }

  static whiteboardCommentMessagesCacheKey(
    whiteboardCommentId: WhiteboardCommentID
  ) {
    return `whiteboard-comment-${whiteboardCommentId}-messages`;
  }

  static whiteboardMessagesCacheKey(whiteboardKeyId: string) {
    return `whiteboard-${whiteboardKeyId}-messages`;
  }

  static itemCreateMessageFormState(scope: string) {
    return `item-create-message-${scope}-form-state`;
  }

  static settingsCacheKey() {
    return 'messages-settings';
  }
}
