import { IconProps } from '../types';

function PulseSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-4 w-4'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2 3v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1zm14 11.6L11.4 10 16 5.4v9.2zm-6-6L5.4 4h9.2L10 8.6zM4 5.4L8.6 10 4 14.6V5.4zm6 6l4.6 4.6H5.4l4.6-4.6z"></path>
    </svg>
  );
}

export default PulseSolidIcon;
