import compact from 'lodash/compact';
import map from 'lodash/map';

import { ImageSrcSetVersions } from '../../index';

import { Files } from '../../../../utils/Files';

function generateImageSrcSet(
  src: string,
  versionsData: ImageSrcSetVersions
): string {
  return compact(
    map(versionsData, (version, resolution) => {
      const versionUrl = Files.urlFromFile(src, version);
      return versionUrl ? `${versionUrl} ${resolution}` : null;
    })
  ).join(', ');
}

export default generateImageSrcSet;
