import { Fragment, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';

import { ClassName, I18nText } from '../../../../../../types';
import { IconsEnum } from '../../../../../../assets/icons/types';
import { PrimaryNavTabsEnum } from '../../../../../hooks/useMenu/types';
import { TooltipDelay } from '../../../../../../helpers/tooltips/types';
import { PrimaryNavTabTooltipItem } from './PrimaryNavTooltipMenuTab.types';
import { TeamNanoID } from '../../../../../../main/teams/teamsTypes';

import { useMenu } from '../../../../../hooks/useMenu';

import { ContentWithTooltip } from '../../../../../../helpers/ContentWithTooltip';
import { Icon } from '../../../../../../helpers/Icon';
import { LocalForage } from '../../../../../../utils/LocalForage';
import { NextLinkHelper } from '../../../../../../helpers/links/NextLinkHelper';
import { PureIconButtonHelper } from '../../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../../helpers/Translate';

import { getNavMenuTabPath } from '../../../../../utils/getNavMenuTabPath';
import {
  bodyTooltipContainer,
  TooltipPlacement,
  zeroOffset
} from '../../../../../../helpers/tooltips/tooltipsConstants';
import { CheckPermissions } from '../../../../../../helpers/CheckPermissions';

const tooltipDelay: TooltipDelay = [0, 0];

interface PrimaryNavTooltipMenuTabProps {
  addClassName?: ClassName;
  companyNanoId?: TeamNanoID;
  href: string;
  icon: IconsEnum;
  tab: PrimaryNavTabsEnum;
  tooltipI18nText: I18nText;
  tooltipLinks?: PrimaryNavTabTooltipItem[];
}

function PrimaryNavTooltipMenuTab({
  addClassName,
  companyNanoId,
  href,
  icon,
  tab,
  tooltipI18nText,
  tooltipLinks
}: PrimaryNavTooltipMenuTabProps) {
  const { push: redirect, asPath } = useRouter();

  const { currentPathTab } = useMenu();

  const handleClick = useCallback<() => void>(() => {
    LocalForage.getItem<string>(getNavMenuTabPath(tab, companyNanoId)).then(
      (path) => {
        if (path) {
          return redirect(path);
        }

        return redirect(href);
      }
    );
  }, [companyNanoId, href, redirect, tab]);

  const handleClickToLink = useCallback<(linkHref: string) => void>(
    (linkHref) =>
      LocalForage.setItem(getNavMenuTabPath(tab, companyNanoId), linkHref),
    [companyNanoId, tab]
  );

  const allLinksPermissions = useMemo(
    () => flatMap(tooltipLinks, (link) => link.permissions),
    [tooltipLinks]
  );

  return (
    <ContentWithTooltip
      appendTo={bodyTooltipContainer}
      className="flex flex-shrink-0 items-center justify-center h-full sm:h-14 flex-grow sm:w-14 sm:flex-grow-0"
      delay={tooltipDelay}
      interactive
      offset={zeroOffset}
      tooltipClassName="mt-0.5"
      tooltipPlacement={TooltipPlacement.RIGHT_START}
      tooltipChildren={
        !isEmpty(tooltipLinks) && (
          <CheckPermissions actions={allLinksPermissions}>
            <div className="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none -my-2 -mx-2.5 py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700">
              <div className="px-2 pt-2 pb-1">
                <div className="px-2 text-gray-400 dark:text-gray-400 text-xs leading-5">
                  <Translate id={tooltipI18nText} />
                </div>
                <hr className="flex-1 dark:border-gray-600" />
              </div>

              {tooltipLinks.map((link) => (
                <Fragment key={link.name}>
                  <CheckPermissions actions={link.permissions}>
                    <NextLinkHelper
                      className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
                      href={link.href}
                      onClick={() => handleClickToLink(link.href)}
                    >
                      <span className="mr-auto">
                        <Translate id={link.i18nText} />
                      </span>

                      {(link.activeTest?.(asPath) ||
                        asPath.startsWith(`/${link.name}`)) && (
                        <span className="text-blue-600 pl-1.5">
                          <Icon icon={IconsEnum.CHECK} className="h-5 w-5" />
                        </span>
                      )}
                    </NextLinkHelper>
                  </CheckPermissions>
                </Fragment>
              ))}
            </div>
          </CheckPermissions>
        )
      }
    >
      <PureIconButtonHelper
        className={cl(
          'inline-flex flex-shrink-0 items-center justify-center h-full sm:h-14 flex-grow sm:w-14 sm:rounded-lg sm:flex-grow-0 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 stroke-1.75',
          currentPathTab === tab
            ? 'text-blue-500 bg-blue-500 bg-opacity-20 dark:text-blue-400'
            : 'hover:bg-gray-200 dark:hover:bg-gray-700',
          addClassName
        )}
        iconClassName="h-6 w-6 sm:h-7 sm:w-7"
        icon={icon}
        onClick={handleClick}
      />
    </ContentWithTooltip>
  );
}

export default PrimaryNavTooltipMenuTab;
