import React, { useCallback } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Translate } from '../../Translate';

type PureIconButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureIconButtonHelperDefaultProps {
  id?: string;
  blurOnMouseLeave?: boolean;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: PureIconButtonHelperOnClick;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
}

export interface PureIconButtonHelperIconProps {
  icon: IconsEnum;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureIconButtonHelperWithI18nProps {
  icon: IconsEnum;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureIconButtonHelperWithTextProps {
  icon: IconsEnum;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureIconButtonHelperProps = PureIconButtonHelperDefaultProps &
  (
    | PureIconButtonHelperIconProps
    | PureIconButtonHelperWithI18nProps
    | PureIconButtonHelperWithTextProps
  );

function PureIconButtonHelper({
  id,
  blurOnMouseLeave,
  className,
  disabled,
  icon,
  iconClassName,
  onClick,
  onMouseEnter,
  onPointerDown,
  i18nTextClassName,
  i18nText,
  text
}: PureIconButtonHelperProps) {
  const handleClick = useCallback<PureIconButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
      onPointerDown={onPointerDown}
      type="button"
    >
      {icon ? (
        <Icon
          className={iconClassName}
          icon={icon}
          id={id ? `${id}-icon` : undefined}
        />
      ) : null}
      {icon && i18nText ? ' ' : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
    </button>
  );
}

export default PureIconButtonHelper;
