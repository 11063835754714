export default {
  downloadExcel: 'Download excel document with all evaluations',
  plural: 'Evaluations',
  set: 'Evaluations set',
  singular: 'Evaluation',
  no: 'No Evaluations',
  count: 'Evaluations Count',
  pleaseHelpUsToBecomeBetter: 'Please help us to become better',
  postponeUntilTomorrow: 'Postpone until tomorrow',
  fields: {
    accuracy: 'Accuracy',
    communication: 'Communication',
    timing: 'Timing',
    quality: 'Quality',
    taskError: 'Task Error',
    projectError: 'Project Error',
    profileError: 'Profile Error',
    briefError: 'Brief Error',
    estimateError: 'Estimate Error',
    styleError: 'Style Error',
    burningTasks: 'Burning Tasks',
    respondRate: 'Respond Rate',
    estimateRate: 'Estimate Rate',
    qualityAssurance: 'Quality Assurance',
    textureLightingCamera: 'Texture/Lighting/Camera',
    compositionDetailsStorytelling: 'Composition/Details/Storytelling'
  }
};
