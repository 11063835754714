import { FetchGeneralLedgersSortTypes } from './generalLedgersTypes';

export enum GeneralLedgersPermissions {}

export const INITIAL_GENERAL_LEDGERS_FILTERS = {};
export const INITIAL_GENERAL_LEDGERS_PAGE = 1;
export const INITIAL_GENERAL_LEDGERS_SORT = [
  FetchGeneralLedgersSortTypes.CREATED_AT_ASC
];
export const INITIAL_GENERAL_LEDGERS_LIMIT = 50;
