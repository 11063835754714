import { IconProps } from '../types';

function AndroidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M5 8v5c0 .6-.4 1-1 1s-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1zm.5-2.1c.2-1.2.8-2.3 1.8-3L7 2.3c-.2-.3-.1-.6.2-.7.2-.1.5-.1.7.2l.4.6C8.8 2.1 9.4 2 10 2c.6 0 1.2.1 1.8.4l.4-.6c.1-.2.4-.3.7-.2.2.1.3.4.1.7l-.4.6c1 .7 1.6 1.8 1.8 3 0 .3-.2.6-.5.6H6c-.3 0-.5-.3-.5-.6zm6-1.4c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4 0c0 .3.2.5.5.5s.5-.2.5-.5S8.3 4 8 4s-.5.2-.5.5zM16 7c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1V8c0-.6-.4-1-1-1zm-2 0H6c-.3 0-.5.2-.5.5v7c0 .3.2.5.5.5h1v2c0 .6.4 1 1 1s1-.4 1-1v-2h2v2c0 .6.4 1 1 1s1-.4 1-1v-2h1c.3 0 .5-.2.5-.5v-7c0-.3-.2-.5-.5-.5z" />
    </svg>
  );
}

export default AndroidIcon;
