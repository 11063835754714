import React, { useEffect, useMemo } from 'react';
import sumBy from 'lodash/sumBy';

import { IconsEnum } from '../../../../../assets/icons/types';
import { UserNanoID } from '../../../../../main/users/usersTypes';
import { AccountTypeTypes } from '../../../../../main/accountTypes/accountTypesTypes';

import {
  FETCH_USER_NEW_MENU_QUERY,
  FetchUserNewMenuQueryResponse
} from '../../../../../main/users/queries/fetchUserNewMenu.query';
import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../../../../main/accounts/queries/fetchAccounts.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useUser } from '../../../../../main/users/hooks/useUser';
import { useCompanyCashAccount } from '../../../../../main/accounts/hooks/useCompanyCashAccount';
import { useFinPaginatedAccounts } from '../../../../../main/accounts/hooks/useFinPaginatedAccounts';

import { UserFinMenuProfileInfoMoneyWithTooltip } from './helpers/UserFinMenuProfileInfoMoneyWithTooltip';

import { Icon } from '../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../helpers/ImageHelper';

import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';

import { Files } from '../../../../../utils/Files';

import { AccountCache } from '../../../../../main/accounts/AccountCache';
import { UserCache } from '../../../../../main/users/UserCache';
import { UsersPermissions } from '../../../../../main/users/usersConstants';

import { accountsKeys } from '../../../../../locales/keys';

interface UserFinMenuProfileInfoProps {
  userNanoId: UserNanoID;
}

function UserFinMenuProfileInfo({ userNanoId }: UserFinMenuProfileInfoProps) {
  const { user, userFetched, userError } =
    useUser<FetchUserNewMenuQueryResponse>({
      cacheKey: UserCache.showMenuCacheKey(),
      query: FETCH_USER_NEW_MENU_QUERY,
      uuid: userNanoId
    });

  const {
    companyCashAccount,
    companyCashAccountErrorMessage,
    companyCashAccountFetched
  } = useCompanyCashAccount({
    companyNanoId: user?.currentTeam?.nanoId
  });

  const {
    accounts: liabilityAccounts,
    accountsErrorMessage: liabilityAccountsErrorMessage,
    accountsFetched: liabilityAccountsFetched,
    accountsFilters: liabilityAccountsFilters,
    changeAccountsFilters: changeLiabilityAccountsFilters
  } = useFinPaginatedAccounts<FetchAccountsQueryResponse>({
    cacheKey: AccountCache.companyPaymentsAccountsLiabilityCacheKey(
      user?.currentTeam?.nanoId
    ),
    query: FETCH_ACCOUNTS_QUERY,
    initialFilters: {
      companyNanoId: { eq: user?.currentTeam?.nanoId },
      accountTypeType: {
        eq: AccountTypeTypes.LIABILITY
      }
    },
    initialLimit: 100,
    options: {
      enabled: true,
      enabledPlaceholder: false
    }
  });

  useEffect(() => {
    if (
      liabilityAccountsFilters.companyNanoId?.eq !== user?.currentTeam?.nanoId
    ) {
      changeLiabilityAccountsFilters({
        companyNanoId: { eq: user?.currentTeam?.nanoId },
        accountTypeType: {
          eq: AccountTypeTypes.LIABILITY
        }
      });
    }
  }, [
    changeLiabilityAccountsFilters,
    liabilityAccountsFilters.companyNanoId?.eq,
    user?.currentTeam?.nanoId
  ]);

  useShowToastOnErrorChange({
    error:
      companyCashAccountErrorMessage ||
      userError ||
      liabilityAccountsErrorMessage
  });

  const liabilitiesAccountsBalanceSum = useMemo<number>(
    () => sumBy(liabilityAccounts, 'balance') || 0,
    [liabilityAccounts]
  );

  return (
    <LoadingSkeleton
      loaded={
        userFetched && companyCashAccountFetched && liabilityAccountsFetched
      }
      count={2}
    >
      {user && (
        <div className="py-1 px-2">
          <div className="flex space-x-3">
            {user.image?.file && Files.isImage(user.image?.file) ? (
              <ImageHelper
                className="h-12 w-12 rounded-md"
                src={user.image.file}
                alt={user.fullName}
                version={ImageItemImageVersions.BigThumb}
              />
            ) : (
              <Icon
                icon={IconsEnum.PHOTOGRAPH_OUTLINE}
                className="h-12 w-12 rounded-md"
              />
            )}
            <div className="leading-snug pr-1 pt-1">
              <div className="text-md sm:text-base font-medium break-wrap">
                {user.fullName}
              </div>
              <div className="text-xs text-gray-500 dark:text-gray-400 break-wrap">
                {user.email}
              </div>
            </div>
          </div>

          <div className="bg-gray-200 dark:bg-gray-800 flex mt-2 py-1.5 rounded-md">
            <div className="border-gray-400 border-r dark:border-gray-700 text-center w-12">
              {getCurrencySymbol(user.currency)}
            </div>
            <div className="flex flex-1 items-center justify-evenly px-3 space-x-2">
              <div className="font-medium text-md">
                <UserFinMenuProfileInfoMoneyWithTooltip
                  value={companyCashAccount?.balance || 0}
                  tooltipI18nText={accountsKeys.cash}
                />
              </div>
              <CheckPermissions
                action={UsersPermissions.READ_USER_MENU_USER_DEBT_FIELD}
              >
                <div className="text-2xs text-red-500">
                  <UserFinMenuProfileInfoMoneyWithTooltip
                    value={liabilitiesAccountsBalanceSum}
                    tooltipI18nText={accountsKeys.types.liabilities}
                  />
                </div>
              </CheckPermissions>
            </div>
          </div>
        </div>
      )}
    </LoadingSkeleton>
  );
}

export default UserFinMenuProfileInfo;
