import { IconProps } from '../types';

function CircleSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      className={className || 'h-4 w-4'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <circle fillRule="evenodd" cx="10" cy="10" r="5" />
    </svg>
  );
}

export default CircleSolidIcon;
