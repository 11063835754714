export default {
  code401errorText: 'У доступі відмовлено',
  code401statusTextText:
    'Вибачте, але лише авторизовані користувачі мають доступ до цієї сторінки.',
  code403errorText: 'У доступі відмовлено',
  code403statusTextText: 'Вибачте, але у ви не маєте доступу до цієї сторінки.',
  code404errorText: 'Сторінку не знайдено',
  code404statusTextText: 'Схоже, такої сторінки не існує.',
  code500errorText: 'Внутрішня помилка сервера',
  code500statusTextText: 'Пробачте, виникла помилка.'
};
