import { gql } from 'graphql-request';

import {
  TeamID,
  TeamUUID,
  TeamNanoID,
  TeamDeferredPayment,
  TeamTerms,
  TeamPreferredPaymentMethod
} from '../teamsTypes';

export interface FetchTeamAddFundsQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  deferredPayment: TeamDeferredPayment;
  terms: TeamTerms;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
}

export const FETCH_TEAM_ADD_FUNDS_QUERY = gql`
  query TeamIdRedirect($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      deferredPayment
      terms
      preferredPaymentMethod
    }
  }
`;
