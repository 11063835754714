import { JournalRecordImportRowsKeyType } from '../../keys';

const journalRecordImportRowsKeys: JournalRecordImportRowsKeyType = {
  key: 'Key',
  value: 'Value',
  transactionImportRows: 'Transaction Import Rows',
  transactionImportInvoices: 'Transaction Import Invoices',
  status: 'Status',
  progress: 'Progress',
  errorMessage: 'Error Message',
  errorBacktrace: 'Error Backtrace',
  singular: 'Journal Record Import Row'
};

export default journalRecordImportRowsKeys;
