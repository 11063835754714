export default {
  accountTypeId: 'Account Type Id',
  increaseSide: 'Account type increase side',
  name: 'Account type name',
  shortName: 'Account type short name',
  systemName: 'Account type system name',
  names: {
    pfbd: 'PFBD',
    pfr: 'PFR'
  }
};
