import { IconProps } from '../types';

function SearchOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      className={className || 'h-6 w-6'}
      fill="none"
      id={id}
      stroke="currentColor"
      strokeWidth={1}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
}

export default SearchOutlineIcon;
