import { IconProps } from '../types';

function UserOrganizationIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.75"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 18.9c-2.4-1-4.1-3.2-4.4-5.9M15 18.9c2.4-1 4.1-3.2 4.4-5.9m-1.6-5.8c-1.4-1.7-3.5-2.7-5.8-2.7S7.6 5.6 6.2 7.2M12 11c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm3 9v-2c0-1.6-1.4-3-3-3h0c-1.6 0-3 1.3-3 3v2c0 .5.4 1 1 1h4c.6 0 1-.5 1-1zm4-17c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm3 9v-2c0-1.6-1.4-3-3-3h0c-1.6 0-3 1.3-3 3v2c0 .6.5 1 1 1h4c.5 0 1-.4 1-1zM5 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm3 9v-2c0-1.6-1.3-3-3-3h0c-1.6 0-3 1.3-3 3v2c0 .6.5 1 1 1h4c.6 0 1-.4 1-1z"
      ></path>
    </svg>
  );
}

export default UserOrganizationIcon;
