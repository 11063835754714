import { useMemo } from 'react';

import { FiltersViewGroups } from '../../../../../types';

import {
  ExtendedFiltersBaseFilters,
  ExtendedFiltersGroups,
  ExtendedFiltersContextState,
  ExtendedFiltersContextStateByViewGroup
} from '../../useExtendedFilters.types';

interface ExtendedFiltersFiltersOptions {
  receivedOrGeneratedViewGroup: FiltersViewGroups | string;
  filtersStateByViewGroup: ExtendedFiltersContextStateByViewGroup;
}

function useExtendedFiltersFilters<T extends ExtendedFiltersBaseFilters>({
  receivedOrGeneratedViewGroup,
  filtersStateByViewGroup
}: ExtendedFiltersFiltersOptions) {
  const filtersContextState = useMemo<ExtendedFiltersContextState<T>>(
    () =>
      (filtersStateByViewGroup[receivedOrGeneratedViewGroup] ||
        {}) as ExtendedFiltersContextState<T>,
    [receivedOrGeneratedViewGroup, filtersStateByViewGroup]
  );

  const pageFilters = useMemo<T>(
    () => filtersContextState[ExtendedFiltersGroups.PAGE] || ({} as T),
    [filtersContextState]
  );

  const tabFilters = useMemo<T>(
    () => filtersContextState[ExtendedFiltersGroups.TAB] || ({} as T),
    [filtersContextState]
  );

  const settingsFilters = useMemo<T>(
    () => filtersContextState[ExtendedFiltersGroups.SETTINGS] || ({} as T),
    [filtersContextState]
  );

  const selectedFilters = useMemo<T>(
    () => filtersContextState[ExtendedFiltersGroups.SELECTED] || ({} as T),
    [filtersContextState]
  );

  return {
    pageFilters,
    tabFilters,
    settingsFilters,
    selectedFilters
  };
}

export default useExtendedFiltersFilters;
