import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useReactQueryState } from '../../../main/common/hooks/base/reactQuery/useReactQueryState';

export const MAIN_SCROLL_CACHE_KEY = 'main-scroll-element';

export const SCROLL_POSITION_CACHE_KEY = 'scroll-position';

export const IS_NAVIGATE_BACK_CACHE_KEY = 'is-navigate-back';

function useMainScroll() {
  const router = useRouter();

  const { setValue: setMainScrollElement, value: mainScrollElement } =
    useReactQueryState<HTMLElement>(MAIN_SCROLL_CACHE_KEY);

  const { setValue: setScrollPosition, value: scrollPosition } =
    useReactQueryState<{ [url: string]: number }>(
      SCROLL_POSITION_CACHE_KEY,
      {}
    );

  const { value: isNavigateBack, setValue: setIsNavigateBack } =
    useReactQueryState<boolean>(IS_NAVIGATE_BACK_CACHE_KEY, false);

  return {
    isNavigateBack,
    setIsNavigateBack,
    mainScrollElement,
    setMainScrollElement,
    saveScrollPosition: useCallback<(scrollPosition: number) => void>(
      (scrollPosition) => {
        setScrollPosition((scroll) => ({
          ...scroll,
          ...{ [router.asPath]: scrollPosition }
        }));
      },
      [router.asPath, setScrollPosition]
    ),
    restoreScrollPosition: useCallback<() => void>(() => {
      const currentScrollPosition = scrollPosition?.[router.asPath];

      if (currentScrollPosition) {
        mainScrollElement.scrollTo({
          top: currentScrollPosition
        });

        delete scrollPosition[router.asPath];
      }
    }, [mainScrollElement, scrollPosition, router.asPath]),
    scrollTop: useCallback<() => void>(() => {
      mainScrollElement?.scrollTo({
        top: 0
      });
    }, [mainScrollElement])
  };
}

export default useMainScroll;
