import React from 'react';

import { IconsEnum } from '../../../assets/icons/types';
import { I18nText } from '../../../types';

import { Icon } from '../../Icon';
import { Translate } from '../../../helpers/Translate';

interface PureLinkHelperDefaultProps {
  className?: string;
  href: string;
  download?: string | boolean;
  icon?: IconsEnum;
  iconClassName?: string;
  target?: '_blank';
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface PureLinkHelperWithI18nProps {
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureLinkHelperWithTextProps {
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}
type PureLinkHelperProps = PureLinkHelperDefaultProps &
  (PureLinkHelperWithI18nProps | PureLinkHelperWithTextProps);

function PureLinkHelper({
  className,
  href,
  download,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  text,
  target,
  onClick
}: PureLinkHelperProps) {
  return (
    <a
      className={className}
      href={href}
      target={target}
      download={download}
      onClick={onClick}
    >
      {icon ? <Icon className={iconClassName} icon={icon} /> : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
    </a>
  );
}

export default PureLinkHelper;
